/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearKpisData } from 'state/actions/kpis';
import { useParams } from 'react-router-dom';
import Table from 'components/Table';
import { fetchProducts } from 'state/actions/products';
import { fetchActivationPositions } from 'state/actions/activations';
import { useFormatDate } from 'hooks';
import { fetchContacts } from 'state/actions/contacts';
import { isArray } from '@amcharts/amcharts4/core';
import { CSVLink } from 'react-csv';
import FilterSearch from 'components/Common/FilterSearch';
import useFilterSearch from 'hooks/useFilterSearch';

const ActivationReporting = () => {
  const { actId } = useParams();
  const {
    actPositions,
    loading,
    productsList,
    productsLoading,
    contacts,
    contactsLoading,
  } = useSelector(
    (state) => ({
      actPositions: state.activations.actPositions,
      productsList: state.products.data,
      productsLoading: state.products.loading,
      contacts: state.contacts.data,
      contactsLoading: state.contacts.loading,
      error: state.kpis.error,
      loading: state.activations.loading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const [groupBy, setGroupBy] = useState({ label: 'Nom', value: 'name' });
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    dispatch(fetchActivationPositions({ actId }));
    dispatch(fetchProducts());
    dispatch(fetchContacts(actId));
    return () => dispatch(clearKpisData());
  }, [dispatch]);

  const marks = [
    'MARLBORO',
    'WINSTON',
    'CAMEL',
    'MQS',
    'GAULOISE',
    'MONTE CARLO',
    'CHESTERFIELD',
    'ROTHMANS',
    'MARQUISE',
    'L&M',
    'VICEROY',
  ];

  const contactsGroupBy = (posId) => {
    const nContacs = contacts.filter((c) => c.idPosition === posId);
    const age1 = nContacs.filter((c) => c.age === 'Entre 18 – 24 ans');
    const age2 = nContacs.filter((c) => c.age === 'Entre 24 – 35 ans');
    const age3 = nContacs.filter((c) => c.age === '35 et plus');
    const s_age1 = age1.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const s_age2 = age2.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const s_age3 = age3.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const multimarque = nContacs.filter((c) => c.isMultiBrands);
    const s_multimarque = multimarque.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const n_refus = nContacs.reduce((acc, c) => {
      const ref = c.brands.every((b) => b.selectedPack === 0);
      if (ref) {
        acc = acc + 1;
      }
      return acc;
    }, 0);
    const n_gouloise_ff = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === '3gkBxhfkICJb3wSaYaIO'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const n_gouloise_light = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === 'PX2brvhcsmURoBE1IiPZ'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const n_gouloise_red_mix = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === 'JInFNEeIfapF6NiTz1qp'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const autres = nContacs.filter(
      (c) =>
        !c.isMultiBrands &&
        marks.every((m) => !c.references.find((r) => r && r.includes(m)))
    );
    const s_autres = autres.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const refs = {};
    marks.forEach((m) => {
      refs[m.toLowerCase().replace(' ', '_')] = nContacs.filter(
        (c) =>
          !c.isMultiBrands &&
          c.references.findIndex((r) => r && r.includes(m)) >= 0
      );
    });
    const srefs = {};
    marks.forEach((m) => {
      srefs[`s_${m.toLowerCase().replace(' ', '_')}`] = refs[
        m.toLowerCase().replace(' ', '_')
      ].filter((c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0);
    });
    const response = {
      age1: age1.length,
      age2: age2.length,
      age3: age3.length,
      s_age1: s_age1.length,
      s_age2: s_age2.length,
      s_age3: s_age3.length,
      multimarque: multimarque.length,
      s_multimarque: s_multimarque.length,
      n_refus,
      n_gouloise_ff,
      n_gouloise_light,
      n_gouloise_red_mix,
      autres: autres.length,
      s_autres: s_autres.length,
    };
    Object.keys(refs).forEach((r) => {
      response[r] = refs[r].length;
    });
    Object.keys(srefs).forEach((sr) => {
      response[sr] = srefs[sr].length;
    });
    return response;
  };

  useEffect(() => {
    if (!loading && actPositions.positions) {
      const posFormat = actPositions.positions
        .filter(
          (pos) =>
            (pos.positionBackup && pos.type === 'INITIAL') ||
            (pos.positionBackup === '' && pos.type === 'INITIAL')
        )
        .map((pos) => {
          const posAgency = actPositions.agencies.find(
            (ag) => ag.id === pos.agencyId
          );
          const backupPos =
            pos.positionBackup !== ''
              ? actPositions.positions.find((p) => p.id === pos.positionBackup)
              : null;
          const setBackPos =
            pos.status === 'CANCELED' && backupPos ? backupPos : null;
          const nPos = {
            date: new Date(pos.date),
            agency: posAgency ? (posAgency.agency || {}).name : '-----',
            init_licence: pos.position.matricule,
            init_status:
              pos.status !== 'INITIAL'
                ? pos.status === 'VALIDATED'
                  ? 'ACTIVEE'
                  : 'ANNULEE'
                : '----',
            back_licence: backupPos ? backupPos.position.matricule : '----',
            back_status: backupPos
              ? backupPos.status !== 'INITIAL'
                ? backupPos.status === 'VALIDATED'
                  ? 'ACTIVEE'
                  : 'ANNULEE'
                : '----'
              : '-----',
            idNum: (setBackPos || pos).animator.idNum,
            startHour: (setBackPos || pos).animator.startHour,
            connextionTime: (setBackPos || pos).animator.connextionTime,
            region:
              (setBackPos || pos).position.region === ''
                ? '-----'
                : pos.position.region,
            zone: (setBackPos || pos).position.zone,
            totalContacts: (setBackPos || pos).totalContacts,
            products: (setBackPos || pos).products,
            totalSuccessContacts: (setBackPos || pos).totalSuccessContacts,
            ...contactsGroupBy((setBackPos || pos).id),
          };
          productsList.forEach((p) => {
            nPos[p.name] =
              (
                (setBackPos || pos).products.find((actp) => actp.id === p.id) ||
                {}
              ).totalProducts || 0;
          });
          return nPos;
        });
      console.log('posFormat', posFormat);
      setPositions(posFormat);
    }
  }, [loading, actPositions, groupBy]);

  let columns = [
    {
      Header: 'DATE',
      accessor: 'date',
      Cell: ({ row }) =>
        useFormatDate(row.original.date, {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }) || '----',
    },
    {
      Header: 'AGENCE',
      accessor: 'agency',
    },
    {
      Header: 'LICENCE INITIALE',
      accessor: 'init_licence',
    },
    {
      Header: 'STATUS',
      accessor: 'init_status',
    },
    {
      Header: 'LICENCE BACKUP',
      accessor: 'back_licence',
    },
    {
      Header: 'STATUS',
      accessor: 'back_status',
    },
    {
      Header: 'ID ANNIMATEUR',
      accessor: 'idNum',
    },
    {
      Header: 'HORRAIRE DEMARRAGE',
      accessor: 'startHour',
      Cell: ({ row }) =>
        useFormatDate(row.original.startHour, {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }) || '----',
    },
    {
      Header: 'TEMPS DE CONNEXION',
      accessor: 'connextionTime',
    },
    {
      Header: 'REGION',
      accessor: 'region',
    },
    {
      Header: 'ZONE',
      accessor: 'zone',
    },
    {
      Header: 'TOTAL NOMBRE DE CONTACTS',
      accessor: 'totalContacts',
    },
    {
      Header: 'MARLBORO',
      accessor: 'marlboro',
    },
    {
      Header: 'WINSTON',
      accessor: 'winston',
    },
    {
      Header: 'CAMEL',
      accessor: 'camel',
    },
    {
      Header: 'MQS',
      accessor: 'mqs',
    },
    {
      Header: 'GAULOISE',
      accessor: 'gauloise',
    },
    {
      Header: 'MONTE CARLO',
      accessor: 'monte_carlo',
    },
    {
      Header: 'CHESTERFIELD',
      accessor: 'chesterfield',
    },
    {
      Header: 'ROTHMANS',
      accessor: 'rothmans',
    },
    {
      Header: 'MARQUISE',
      accessor: 'marquise',
    },
    {
      Header: 'L&M',
      accessor: 'l&m',
    },
    {
      Header: 'VICEROY',
      accessor: 'viceroy',
    },
    {
      Header: 'AUTRES',
      accessor: 'autres',
    },
    {
      Header: 'Multimarque',
      accessor: 'multimarque',
    },
    {
      Header: '18-24 ans',
      accessor: 'age1',
    },
    {
      Header: '24-35 ans',
      accessor: 'age2',
    },
    {
      Header: '35 ans et plus',
      accessor: 'age3',
    },
    {
      Header: 'Nombre de refus',
      accessor: 'n_refus',
    },
    {
      Header: 'Nombre paquet Gauloise FF',
      accessor: 'n_gouloise_ff',
    },
    {
      Header: 'Nombre paquet Gauloise Light',
      accessor: 'n_gouloise_light',
    },
    {
      Header: 'Nombre paquet Gauloise Red Mix',
      accessor: 'n_gouloise_red_mix',
    },
    {
      Header: 'TOTAL NOMBRE DE SUCCESS CONTACTS',
      accessor: 'totalSuccessContacts',
    },
    {
      Header: 'Succ. MARLBORO',
      accessor: 's_marlboro',
    },
    {
      Header: 'Succ. WINSTON',
      accessor: 's_winston',
    },
    {
      Header: 'Succ. Camel',
      accessor: 's_camel',
    },
    {
      Header: 'Succ. MQS',
      accessor: 's_mqs',
    },
    {
      Header: 'Succ. Gauloise classic',
      accessor: 's_gauloise',
    },
    {
      Header: 'Succ. MONTE CARLO',
      accessor: 's_monte_carlo',
    },
    {
      Header: 'Succ. CHESTERFIELD',
      accessor: 's_chesterfield',
    },
    {
      Header: 'Succ. ROTHMANS',
      accessor: 's_rothmans',
    },
    {
      Header: 'Succ. MARQUISE',
      accessor: 's_marquise',
    },
    {
      Header: 'Succ. LM',
      accessor: 's_lm',
    },
    {
      Header: 'Succ.VICEROY',
      accessor: 's_viceroy',
    },
    {
      Header: 'Succ. AUTRES',
      accessor: 's_autres',
    },
    {
      Header: 'Succ. Multimarque',
      accessor: 's_multimarque',
    },
    {
      Header: 'Succ. 18-24 ans',
      accessor: 's_age1',
    },
    {
      Header: 'Succ. 24-35 ans',
      accessor: 's_age2',
    },
    {
      Header: 'Succ. 35 ans et plus',
      accessor: 's_age3',
    },
  ];

  columns = [
    ...columns,
    ...(!productsLoading
      ? productsList.map((p) => ({
          Header: p.name,
          accessor: p.name,
        }))
      : []),
  ];
  const { result, searchProps, selectProps } = useFilterSearch(positions);

  return (
    <div className="page-padding" style={{ overflow: 'auto' }}>
      <div className="flex">
        {/* left */}
        <div className="flex flex-col p-3">
          {!loading && positions.length > 0 && (
            <div className="flex">
              <FilterSearch
                loading={loading}
                hideSelect
                searchProps={searchProps}
                selectProps={selectProps}
              />
              <CSVLink
                style={{
                  background: '#3273dc',
                  color: '#fff',
                  padding: 10,
                  display: 'inline-block',
                  marginBottom: 10,
                  width: 200,
                  marginLeft: 100,
                }}
                data={positions}
                headers={columns.map((h) => ({
                  label: h.Header,
                  key: h.accessor || h.Header,
                }))}
              >
                Télécharger Reporting
              </CSVLink>
            </div>
          )}
          <div className="flex reporting-table">
            <Table columns={columns} data={result} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationReporting;
