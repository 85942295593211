import Controller from './Controller';

export default class AwardController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'awards';
    this.item = 'award';
  }

  /**
   * Get Award data
   * @param {string} awardId Award ID
   * @returns {Object} object of Award data or false
   */
  fetchById = async (awardId) =>
    awardId
      ? this.getDoc(awardId).then((awardSnap) => {
          if (awardSnap.exists) {
            return {
              id: awardSnap.id,
              ...awardSnap.data(),
            };
          }
          return null;
        })
      : null;
}
