import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import UserForm from 'components/UserForm';
import { createUser, modifyUser, generateID } from 'state/actions/users';
import paths from 'pages/Router/paths';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

const User = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.users.success,
    }),
    shallowEqual
  );

  const [user, setUser] = useState({
    name: '',
    email: '',
    idNum: '',
    location: '',
    adresse : "",
    disable: false,
    isAdmin: false,
    file: null,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await firebase
        .firestore()
        .collection('users')
        .doc(id)
        .get();
      return response;
    };
    generateID().then((idNum) => {
      if (id) {
        fetchUserData()
          .then((userData) => {
            setUser({
              ...userData.data(),
              id,
              isAdmin: userData.data().isAdmin,
              disable: userData.data().disable ?? false,
            });
          })
          .catch(() => {
            setUser({ error: true });
          });
      } else {
        setUser({ ...user, idNum });
      }
    });
  }, [id]);

  const isEditing = !!id;

  const userForm =
    (!user.name && id) || (!user.idNum && !id) ? (
      <ClipLoader />
    ) : (
      <UserForm
        isEditing={isEditing}
        userData={user}
        action={isEditing ? modifyUser : createUser}
      />
    );

  const redirect = (user.error || success) && <Redirect to={paths.USERS} />;

  return (
    <>
      {redirect}
      <PageHeader title="Utilisateur" hideButton />
      <div className="page-padding">{userForm}</div>
    </>
  );
};

export default User;
