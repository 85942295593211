/* eslint-disable camelcase */
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
import { toastr } from 'react-redux-toastr';
import Controller from 'controllers/Controller';

const gc = new Controller('actCategories', 'actCategory');

export const ACT_CATEGORIES_FETCH_DATA_INIT = createAction(
  'ACT_CATEGORIES_FETCH_DATA_INIT'
);
export const ACT_CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'ACT_CATEGORIES_FETCH_DATA_SUCCESS'
);
export const ACT_CATEGORIES_FETCH_DATA_FAIL = createAction(
  'ACT_CATEGORIES_FETCH_DATA_FAIL'
);
export const ACT_CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'ACT_CATEGORIES_CREATE_CATEGORY_INIT'
);
export const ACT_CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'ACT_CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const ACT_CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'ACT_CATEGORIES_CREATE_CATEGORY_FAIL'
);
export const ACT_CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'ACT_CATEGORIES_DELETE_ACT_CATEGORIES_INIT'
);
export const ACT_CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'ACT_CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const ACT_CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'ACT_CATEGORIESS_DELETE_ACT_CATEGORIES_FAIL'
);
export const ACT_CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'ACT_CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const ACT_CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'ACT_CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const ACT_CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'ACT_CATEGORIES_MODIFY_CATEGORY_FAIL'
);

export const ACT_CATEGORIES_CLEAR_DATA = createAction(
  'ACT_CATEGORIES_CLEAR_DATA'
);

export const ACT_CATEGORIES_CLEAN_UP = createAction('ACT_CATEGORIES_CLEAN_UP');

// Init the Name of Collection && Item && The unique value

/**
 * Action Fetch actCategories
 * @return dispatch
 */

export const fetchActCategories = () => {
  return async (dispatch) => {
    dispatch(ACT_CATEGORIES_FETCH_DATA_INIT());
    let actCategories;
    try {
      actCategories = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(ACT_CATEGORIES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      ACT_CATEGORIES_FETCH_DATA_SUCCESS({
        actCategories,
      })
    );
  };
};

/**
 * Action Create new actCategory
 * @param {string} name Name of actCategory
 * @return dispatch
 */

export const createActCategory = ({ name, marks }) => {
  return async (dispatch, getState) => {
    dispatch(ACT_CATEGORIES_CREATE_CATEGORY_INIT());
    const { locale } = getState().preferences;
    let actCategory;
    try {
      const displayName = displayText(name);
      actCategory = await gc.create(
        { name, displayName, marks },
        'displayName'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'la création a échoué');
      return dispatch(
        ACT_CATEGORIES_CREATE_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'créé avec succès');
    return dispatch(ACT_CATEGORIES_CREATE_CATEGORY_SUCCESS({ actCategory }));
  };
};

/**
 * Action Delete actCategory
 * @param {string} id ID of actCategory
 * @return dispatch
 */

export const deleteActCategory = (id) => {
  return async (dispatch, getState) => {
    dispatch(ACT_CATEGORIES_DELETE_CATEGORY_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'la suppression a échoué');
      return dispatch(
        ACT_CATEGORIES_DELETE_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'Supprimé avec succès');
    return dispatch(ACT_CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

/**
 * Action Update actCategory
 * @param {string} name Name of actCategory
 * @return dispatch
 */

export const modifyActCategory = ({ name, marks, id }) => {
  return async (dispatch, getState) => {
    dispatch(ACT_CATEGORIES_MODIFY_CATEGORY_INIT());
    const { locale } = getState().preferences;
    let actCategory;
    try {
      const displayName = displayText(name);
      actCategory = await gc.update(
        id,
        { name, displayName, marks },
        'displayName'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'mise à jour a échoué');
      return dispatch(
        ACT_CATEGORIES_MODIFY_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'mis à jour avec succès');
    return dispatch(ACT_CATEGORIES_MODIFY_CATEGORY_SUCCESS({ actCategory }));
  };
};

export const actCategoriesCleanUp = () => (dispatch) =>
  dispatch(ACT_CATEGORIES_CLEAN_UP());

export const clearActCategoriesData = () => {
  return (dispatch) => {
    dispatch(ACT_CATEGORIES_CLEAR_DATA());
  };
};
