import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import firebase from 'firebase.js';

import { fetchLevels } from 'state/actions/levels';
import { productsCleanUp } from 'state/actions/products';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler } from 'hooks';
import FileInput from 'components/Common/FileInput';

import classnames from 'classnames';
import Preview from 'components/Common/Preview';

const ProductForm = ({ productData, action, isEditing }) => {
  const dispatch = useDispatch();

  const { loading, levelsList, levelsLoading } = useSelector(
    (state) => ({
      loading: state.products.loading,
      levelsList: state.levels.data,
      levelsLoading: state.levels.loading,
    }),
    shallowEqual
  );
  const [product, setProduct] = useState(productData);
  const [categoriesList, setCategoriesList] = useState([]);
  const [editingState, setEditingState] = useState(isEditing);

  useEffect(() => {
    return () => {
      dispatch(productsCleanUp());
      setProduct({ name: '' });
      setEditingState(false);
    };
  }, [dispatch]);

  useEffect(() => {
    // get categories
    firebase
      .firestore()
      .collection('proCategories')
      .get()
      .then((categoryRef) => {
        const categories = categoryRef.docs.map((categorySnap) => {
          return {
            value: categorySnap.id,
            label: categorySnap.data().name,
          };
        });
        setCategoriesList(categories);
      });
    dispatch(fetchLevels());
  }, []);

  const onChangeHandler = useChangeHandler(setProduct);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(product));
  };

  const onCategoryChange = ({ value, label }) => {
    setProduct((prevState) => ({
      ...prevState,
      categoryId: value,
      categorie: label,
    }));
  };

  const selectOptions = levelsList.map((level) => ({
    value: level.id,
    label: level.displayName,
  }));

  const onChangeSelect = (opts) => {
    const levels = (opts || []).map((opt) => opt.value);
    setProduct((prevState) => ({ ...prevState, levels: levels || '' }));
  };

  const selectValue = (levels) => {
    if (!levelsLoading)
      return levels.map((perId) => {
        const p = levelsList.find((per) => per.id === perId);
        if (p) return { value: p.id, label: p.displayName };
      });
  };

  const defaultCategory = isEditing
    ? {
        value: product.category.id,
        label: product.category.name,
      }
    : {};

  const canSubmit =
    product.name &&
    product.levels.length &&
    product.price > 0 &&
    product.categoryId &&
    (product.link || product.file);
  //video uploading logic
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setProduct((prevState) => ({ ...prevState, file, link: null }));
  };
  // Added 3 fields according to SMT-I79
  // please update the logic
  // niveau de gain, prix , telecharger une  photo
  return (
    <>
      <form onSubmit={onSubmitHandler}>
        {/* form  */}
        <div className="flex items-start rounded-lg border border-brand-lighterBlue py-2">
          <div className="flex flex-wrap">
            {/* top  */}
            <div className="w-full flex">
              {/* name  */}
              <NormalInput
                className="flex-1"
                label="Nom"
                value={product.name}
                name="name"
                required
                onChange={onChangeHandler}
              />
              {/* category  */}
              <div className="flex-1">
                <SelectField
                  type="form"
                  label="categorie"
                  options={categoriesList}
                  isLoading={!categoriesList}
                  defaultValue={defaultCategory}
                  onChange={onCategoryChange}
                />
              </div>
            </div>
            {/* bottom  */}
            <div className="w-full flex">
              {/* gain level  */}
              <div className="flex-1">
                <SelectField
                  label="Niveau de gain"
                  type="form"
                  isLoading={levelsLoading}
                  isDisabled={levelsLoading}
                  placeholder="Selectioner"
                  onChange={(value) => onChangeSelect(value)}
                  isMulti
                  options={selectOptions}
                  value={selectValue(product.levels)}
                />
              </div>
              {/* price  */}
              <div className="flex-1">
                <NormalInput
                  label="Prix"
                  name="price"
                  type="number"
                  value={product.price}
                  onChange={onChangeHandler}
                />
              </div>
              {/* photo upload */}
              <div className="flex-1">
                <FileInput
                  isForm
                  className={classnames({
                    'is-loading': loading,
                  })}
                  label="Télécharger une photo"
                  accept="image/*"
                  file={product.file}
                  link={product.link}
                  disabled={loading}
                  onChange={onFileChangedHandler}
                />
              </div>
            </div>
          </div>
          <Button
            className={`mt-auto mb-4 mx-8 ${loading && 'is-loading'}`}
            disabled={loading || !canSubmit}
            text="Ajouter"
            icon="mdi-plus-circle"
            color="green"
            size="large"
          />
        </div>
      </form>
      <div className="p-2">
        <Preview file={product.file} link={product.link} />
      </div>
    </>
  );
};

export default ProductForm;
