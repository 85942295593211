import { createReducer } from 'redux-act';
import {
  ACTIVATIONS_FETCH_DATA_INIT,
  ACTIVATIONS_FETCH_DATA_SUCCESS,
  ACTIVATIONS_FETCH_DATA_FAIL,
  MEMBER_FETCH_DATA_INIT,
  MEMBER_FETCH_DATA_SUCCESS,
  MEMBER_FETCH_DATA_FAIL,
  ACTIVATION_FETCH_DATA_INIT,
  ACTIVATION_FETCH_DATA_SUCCESS,
  ACTIVATION_FETCH_DATA_FAIL,
  ACTIVATION_POS_FETCH_DATA_INIT,
  ACTIVATION_POS_FETCH_DATA_SUCCESS,
  ACTIVATION_POS_FETCH_DATA_FAIL,
  ACTIVATIONS_CREATE_ACTIVATION_INIT,
  ACTIVATIONS_CREATE_ACTIVATION_SUCCESS,
  ACTIVATIONS_CREATE_ACTIVATION_FAIL,
  ACTIVATIONS_DELETE_ACTIVATION_INIT,
  ACTIVATIONS_DELETE_ACTIVATION_SUCCESS,
  ACTIVATIONS_DELETE_ACTIVATION_FAIL,
  ACTIVATIONS_MODIFY_ACTIVATION_INIT,
  ACTIVATIONS_MODIFY_ACTIVATION_SUCCESS,
  ACTIVATIONS_MODIFY_ACTIVATION_FAIL,
  ACTIVATIONS_CLEAN_UP,
  ACTIVATIONS_CLEAR_DATA,
} from 'state/actions/activations';

const initialState = {
  animations: [],
  activation: {},
  actPositions: {},
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const activationsReducer = createReducer(
  {
    // for fetching activations
    [ACTIVATIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ACTIVATIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.activations,
      loading: false,
      error: null,
    }),
    [ACTIVATIONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEMBER_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MEMBER_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      animations: payload.animations,
      loading: false,
      error: null,
    }),
    [MEMBER_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACTIVATION_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ACTIVATION_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      activation: payload.activation,
      loading: false,
      error: null,
    }),
    [ACTIVATION_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACTIVATION_POS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ACTIVATION_POS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      actPositions: payload.actPositions,
      loading: false,
      error: null,
    }),
    [ACTIVATION_POS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACTIVATIONS_CREATE_ACTIVATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ACTIVATIONS_CREATE_ACTIVATION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat({ id: state.id }).concat(payload.activation),
      loading: false,
      error: null,
      success: true,
    }),
    [ACTIVATIONS_CREATE_ACTIVATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACTIVATIONS_DELETE_ACTIVATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ACTIVATIONS_DELETE_ACTIVATION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [ACTIVATIONS_DELETE_ACTIVATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACTIVATIONS_MODIFY_ACTIVATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ACTIVATIONS_MODIFY_ACTIVATION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map((elem) => {
        if (elem.id === payload.id) {
          return {
            name: payload.activation.name,
            displayName: payload.activation.displayName,
            id: payload.id,
            createdAt: payload.activation.createdAt,
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true,
    }),
    [ACTIVATIONS_MODIFY_ACTIVATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACTIVATIONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ACTIVATIONS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
