import { createReducer } from 'redux-act';
import {
  CONTACTS_FETCH_DATA_INIT,
  CONTACTS_FETCH_DATA_SUCCESS,
  CONTACTS_FETCH_DATA_FAIL,
  CONTACTS_CREATE_CONTACT_INIT,
  CONTACTS_CREATE_CONTACT_SUCCESS,
  CONTACTS_CREATE_CONTACT_FAIL,
  CONTACTS_CLEAN_UP,
  CONTACTS_CLEAR_DATA,
} from 'state/actions/contacts';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const contactsReducer = createReducer(
  {
    [CONTACTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CONTACTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.contacts,
      loading: false,
      error: null,
    }),
    [CONTACTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONTACTS_CREATE_CONTACT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONTACTS_CREATE_CONTACT_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.contact, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [CONTACTS_CREATE_CONTACT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONTACTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [CONTACTS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
