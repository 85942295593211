/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Notifications from 'components/DashboardParts/Notifications';
import { clearKpisData, fetchKpis } from 'state/actions/kpis';
import { fetchProducts } from 'state/actions/products';
import { fetchPositions } from 'state/actions/positions';
import Select from 'react-select';
import Dashchart from 'components/chart/DashChart';
import PieChart from 'components/chart/Pie';
import Last from 'components/chart/Last';
import SelectField from 'components/Common/SelectField';
import Table from 'components/Table';
import DateRange from 'components/dateRage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataTable from './DataTable';
import 'react-tabs/style/react-tabs.css';
import { Label } from '@amcharts/amcharts4/core';
import useFilterSearch from 'hooks/useFilterSearch';
import { fetchActivations } from 'state/actions/activations';
const Home = () => {
  const { positionList, activations, loading } = useSelector(
    (state) => ({
      positionList: state.positions.data,
      activations: state.activations.data,
      // error: state.users.error,
      loading: state.positions.loading.data,
    }),
    shallowEqual
  );
  console.log('activations :', activations[0]);
  const animation = ['POS', 'Horeca', 'Moussem', 'Souk', 'Event'];
  const marques = ['Gob', 'Davidoff', 'Chesterfield', 'MonteCarlo', 'MQS'];
  const agences = ['121 agency', 'BLS', 'LK', 'DEMO agency', 'abagency'];
  const positionListss = positionList.map((position) => {
    return {
      animation: animation[Math.floor(Math.random() * 5)],
      marque: marques[Math.floor(Math.random() * 5)],
      agence: agences[Math.floor(Math.random() * 5)],
      name: position.zone === '' ? '---' : position.zone,
      region: position.zone === '' ? '---' : position.region,
      secteur: position.sector === '' ? '---' : position.sector,
      zone: position.zone === '' ? '---' : position.zone,
      date: position.updatedAt,
    };
  });
  console.log('loading from index', loading);
  console.log('from the page ----------- : ', activations);
  const dispatch = useDispatch();
  const [showRange, setshowRange] = useState(false);
  useEffect(() => {
    // dispatch(isAdmin ? fetchKpis() : fetchKpis(userID));

    dispatch(fetchPositions());
    dispatch(fetchProducts());
    return () => dispatch(clearKpisData());
  }, [dispatch]);
  // const { result } = useFilterSearch(positionList);
  // Provenace du contact et nombre de convertis
  const dataNombreConvertis = {
    labels: [
      `MARLBORO`,
      `WINSTON`,
      `CAMEL`,
      `MQS`,
      `GAULOISE CLASSIC`,
      `MONTE CARLO`,
      `CHESTERFIELD`,
      `ROTHMANS`,
      `MARQUISE`,
      `LM`,
      `AUTRES`,
    ],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch %',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 2,
        fill: false,
        data: [50, 42, 66, 60, 50, 42, 83, 47, 33, 80, 11],
      },
      {
        label: 'provenance du contact',
        data: [120, 190, 30, 50, 20, 120, 190, 30, 50, 50, 180],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Nombre de convertis',
        data: [60, 80, 20, 30, 10, 100, 90, 10, 20, 40, 20],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  // Taux
  const Marques = [
    {
      Header: 'MARLBORO',
      accessor: 'marlbro',
    },
    {
      Header: 'WINSTON',
      accessor: 'winston',
    },
    {
      Header: 'CAMEL',
      accessor: 'camel',
    },
    {
      Header: 'MQS',
      accessor: 'mqs',
    },
    {
      Header: 'GAULOISE CLASSIC',
      accessor: 'gauloiseClassic',
    },

    {
      Header: 'MONTE CARLO',
      accessor: 'monteCarlo',
    },
    {
      Header: 'MARQUISE ',
      accessor: 'marquise',
    },
    {
      Header: 'LM ',
      accessor: 'lm',
    },
    {
      Header: 'AUTRES',
      accessor: 'autre',
    },
  ];
  const Taux = [
    {
      marlbro: ` ${42}%`,
      winston: `  ${10}%`,
      camel: ` ${60}%`,
      mqs: ` ${50}%`,
      gauloiseClassic: `${20}%`,
      monteCarlo: `${80}%`,
      marquise: `${33}%`,
      lm: ` ${60}%`,
      autre: ` ${44}%`,
    },
  ];

  // data for the  PROVENANCES PAR MARQUE ET PAR TRANCHE D’AGE

  const Chesterfield = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        label: 'Chesterfield-ff C',
        data: [12, 16, 3, 5, 22, 3],
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Chesterfield-ff C.R',
        data: [2, 3, 10, 8, 2, 4],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 1',
      },
    ],
  };
  const Gauloise = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        label: 'Gauloise FF',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Gauloise TG',
        data: [2, 8, 6, 5, 1, 4],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 1',
      },
      {
        label: 'Gauloise AA',
        data: [3, 10, 12, 118, 22, 30],
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 2',
      },
    ],
  };

  // data vente

  const marquiseVent = {
    labels: ['Marquise medium', 'Marquise soft FF'],
    datasets: [
      {
        label: 'nombre de  vente',
        data: [200, 190],
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };
  const marvelVent = {
    labels: ['Marvel-ff'],
    datasets: [
      {
        label: 'nombre de vente',
        data: [300],
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };
  const MqsVent = {
    labels: ['MQS FF', 'MQS light'],
    datasets: [
      {
        label: 'nombre de vente MQS',
        data: [390, 100],
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };
  const maghrebVent = {
    labels: ['Maghreb'],
    datasets: [
      {
        label: '# of vente',
        data: [39],
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };
  const gauloisesVent = {
    labels: ['GAULOISES CB', 'GAULOISES LIGHT', 'GAULOISES LIGHT'],
    datasets: [
      {
        label: '# of vente',
        data: [379, 374, 275],
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };

  // data for contacts

  const contacts = {
    '1-1': 12200,
    '1-2': 10300,
    '2-1': 16010,
    '2-2': 12190,
    '3-1': 8000,
    '3-2': 78100,
  };
  const moyenneParPosition11 = {
    labels: [`18-24 ANS `, `25-35 ANS`, `35 ANS ET PLUS`],
    datasets: [
      {
        label: '',
        data: [10451, 4210, 7990],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const CoutParPosition11 = {
    labels: [
      `Fidélisation `,
      `Premium SMT`,
      `VFM Concurrence`,
      `VFM SMT `,
      `Premium Concurrence`,
    ],
    datasets: [
      {
        data: [1300, 2000, 1830, 1620, 5450],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(55, 99, 132, 0.2)',
          'rgba(5, 160, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(55, 99, 132, 1)',
          'rgba(5, 160, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const moyenneParPosition12 = {
    labels: [`18-24 ANS `, `25-35 ANS`, `35 ANS ET PLUS`],
    datasets: [
      {
        label: '',
        data: [9818, 3510, 7290],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const CoutParPosition12 = {
    labels: [
      `Fidélisation `,
      `Premium SMT`,
      `VFM Concurrence`,
      `VFM SMT `,
      `Premium Concurrence`,
    ],
    datasets: [
      {
        data: [900, 1700, 1230, 1320, 5150],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(55, 99, 132, 0.2)',
          'rgba(5, 160, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(55, 99, 132, 1)',
          'rgba(5, 160, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const moyenneParPosition21 = {
    labels: [`18-24 ANS `, `25-35 ANS`, `35 ANS ET PLUS`],
    datasets: [
      {
        label: '',
        data: [30, 30, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const CoutParPosition21 = {
    labels: [
      `Fidélisation `,
      `Premium SMT`,
      `VFM Concurrence`,
      `VFM SMT `,
      `Premium Concurrence`,
    ],
    datasets: [
      {
        data: [308, 303, 300, 100, 50],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(55, 99, 132, 0.2)',
          'rgba(5, 160, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(55, 99, 132, 1)',
          'rgba(5, 160, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const moyenneParPosition22 = {
    labels: [`18-24 ANS `, `25-35 ANS`, `35 ANS ET PLUS`],
    datasets: [
      {
        data: [20, 30, 50],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(55, 6, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(55, 6, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const CoutParPosition22 = {
    labels: [
      `Fidélisation `,
      `Premium SMT`,
      `VFM Concurrence`,
      `VFM SMT `,
      `Premium Concurrence`,
    ],
    datasets: [
      {
        data: [50, 10, 10, 10, 20],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(55, 99, 132, 0.2)',
          'rgba(5, 160, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(55, 99, 132, 1)',
          'rgba(5, 160, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const period = [
    {
      value: '1',
      label: 'Ce mois',
    },
    {
      value: '2',
      label: 'dernier mois',
    },
    {
      value: '3',
      label: 'Personnaliser la date',
    },
  ];
  // end the data
  const [nombreConvertis, setNombreConvertis] = useState(dataNombreConvertis);
  const [activation, setactivation] = useState('1');
  const [isSuccess, setIsSuccess] = useState('1');
  const [moyenneParPosition, setMoyenneParPosition] = useState(
    moyenneParPosition11
  );
  const [CoutParPosition, setCoutParPosition] = useState(CoutParPosition11);
  const [nbContact, setNbContact] = useState(12000);

  const [range, setRange] = useState('1');
  const onChangeRange = (value) => {
    setactivation(value.value);
    if (value.value === '1') {
      setshowRange(false);
      setRange('1');
    } else if (value.value === '2') {
      setshowRange(false);
      setRange('2');
      console.log('from the root', range);
    } else if (value.value === '3') {
      setRange('3');
      setshowRange(true);
      console.log(showRange);
    }
  };

  const onChangeactivation = (value) => {
    setactivation(value.value);
    if (value.value === '1' && isSuccess === '1') {
      setshowRange(false);
      setRange('1');
      setNbContact(contacts['1-1']);
      setMoyenneParPosition(moyenneParPosition11);
      setCoutParPosition(CoutParPosition11);
    } else if (value.value === '1' && isSuccess === '2') {
      setshowRange(false);
      setRange('1');
      setNbContact(contacts['1-2']);
      setMoyenneParPosition(moyenneParPosition12);
      setCoutParPosition(CoutParPosition12);
    } else if (value.value === '2' && isSuccess === '2') {
      setshowRange(false);
      setRange('2');
      setNbContact(contacts['2-2']);
      setMoyenneParPosition(moyenneParPosition22);
      setCoutParPosition(CoutParPosition22);
    } else if (value.value === '2' && isSuccess === '1') {
      setshowRange(false);
      setRange('2');
      setNbContact(contacts['2-1']);
      setMoyenneParPosition(moyenneParPosition21);
      setCoutParPosition(CoutParPosition21);
    } else if (value.value === '3') {
      setRange('3');
      setshowRange(true);
      setNbContact(contacts['2-1']);
      setMoyenneParPosition(moyenneParPosition21);
      setCoutParPosition(CoutParPosition21);
    }
  };

  const onChangeSuccess = (value) => {
    setIsSuccess(value.value);
    if (activation === '1' && value.value === '1') {
      setNbContact(contacts['1-1']);
      setMoyenneParPosition(moyenneParPosition11);
      setCoutParPosition(CoutParPosition11);
    } else if (activation === '1' && value.value === '2') {
      setNbContact(contacts['1-2']);
      setMoyenneParPosition(moyenneParPosition12);
      setCoutParPosition(CoutParPosition12);
    } else if (activation === '2' && value.value === '2') {
      setNbContact(contacts['2-2']);
      setMoyenneParPosition(moyenneParPosition22);
      setCoutParPosition(CoutParPosition22);
    } else if (activation === '2' && value.value === '1') {
      setNbContact(contacts['2-1']);
      setMoyenneParPosition(moyenneParPosition21);
      setCoutParPosition(CoutParPosition21);
    }
  };
  // const [selectedMarque, setselectedMarque] = useState(marlboro);

  const [selectedMarqueVent, setselectedMarqueVent] = useState(marquiseVent);

  const lesMarqueSmt = [
    { value: 'marquise', label: 'Marquise' },
    { value: 'marvel', label: 'Marvel' },
    { value: 'mqs', label: 'MQS' },
    { value: 'maghreb', label: 'Maghreb' },
    { value: 'gauloises', label: 'Gauloises' },
  ];
  const toutLesMArques = [
    { value: 'marquise', label: 'Marquise' },
    { value: 'marvel', label: 'Marvel' },
    { value: 'rothmans', label: 'Rothmans' },
    { value: 'monteCarlo', label: 'Monte Carlo' },
    { value: 'camel', label: 'Camel' },
    { value: 'davidoff', label: 'DavidOff' },
    { value: 'marlboro', label: 'Marlboro' },
    { value: 'mqs', label: 'MQS' },
    { value: 'ld', label: 'LD' },
    { value: 'l&m', label: 'L&M' },
    { value: 'winston', label: 'Winston' },
    { value: 'chesterfield', label: 'chesterfield' },
    { value: 'maghreb', label: 'Maghreb' },
    { value: 'viceroy', label: 'Viceroy' },
  ];
  // ref by marques

  const marlboroRef = [
    { value: 'marlboro-beyond-blue', label: 'Marlboro-beyond-blue' },
    { value: 'marlboro-beyond-red', label: 'Marlboro-beyond-red' },
    { value: 'marlboro-purpul-mix', label: 'Marlboro-purpul-mix' },
    { value: 'marlboro-gold', label: 'Marlboro-gold' },
    { value: 'marlboro-soft-ff', label: 'Marlboro-soft-ff' },
    { value: 'marlboro-box-ff', label: 'Marlboro-box-ff' },
  ];
  // marlboro ref data
  const marlboroGold = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marlboro-gold %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [50, 70, 33],
      },
      {
        label: 'Contacts',
        data: [120, 100, 30],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [60, 70, 10],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const marlboroBeyondRed = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marlboro-Beyond Red %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [69, 54, 11],
      },
      {
        label: 'Contacts',
        data: [231, 310, 90],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [160, 170, 10],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const marlboroBeyondBlue = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marlboro-Beyond blue %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [76, 79, 5],
      },
      {
        label: 'Contacts',
        data: [130, 214, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [100, 170, 10],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const marlborPurpulMix = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marlboro Purpul Mix %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [80, 63, 68],
      },
      {
        label: 'Contacts',
        data: [200, 110, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [160, 70, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const marlborSoftFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marlboro soft FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [76, 33, 44],
      },
      {
        label: 'Contacts',
        data: [210, 210, 290],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [160, 70, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const marlborBoxFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marlboro Box FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [80, 60, 68],
      },
      {
        label: 'Contacts',
        data: [190, 100, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [140, 60, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const marquiseRef = [
    { value: 'marquise-medium', label: 'Marquise-medium' },
    { value: 'marquise-ff', label: 'Marquise-ff' },
  ];
  // marquise ref data
  const marquiseMedium = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marquise medium %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [63, 80, 46],
      },
      {
        label: 'Contacts',
        data: [300, 210, 490],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [190, 170, 230],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const marquiseFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marquise FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [63, 80, 46],
      },
      {
        label: 'Contacts',
        data: [300, 210, 490],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [190, 170, 230],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const marvelRef = [{ value: 'marvel-ff', label: 'Marvel-ff' }];
  // marvel ref data
  const marvelFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch marvel FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [30, 63, 33],
      },
      {
        label: 'Contacts',
        data: [300, 110, 90],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [90, 70, 30],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const rothmansRef = [
    { value: 'rothmans-ff', label: 'Rothmans-ff' },
    { value: 'rothmans-light', label: 'Rothmans-light' },
  ];
  // rothmans ref
  const rothmansFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Rothmans %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 63, 33],
      },
      {
        label: 'Contacts',
        data: [99, 110, 90],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [90, 70, 30],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const rothmansLight = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Rothmans light %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [50, 50, 33],
      },
      {
        label: 'Contacts',
        data: [99, 55, 90],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [44, 35, 30],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const monteCarloRef = [
    { value: 'monteCarlo-classic', label: 'Monte carlo-classic' },
    { value: 'monteCarlo-soft-light', label: 'Monte carlo-light' },
    { value: 'monteCarlo-box-ff', label: 'Monte carlo-ff' },
  ];
  // monte carlo
  const monteCarloClassic = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch monte Carlo Classic %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 63, 33],
      },
      {
        label: 'Contacts',
        data: [99, 110, 90],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [90, 70, 30],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const monteCarloSoftLight = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch monteCarlo Soft Light %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 80, 68],
      },
      {
        label: 'Contacts',
        data: [199, 210, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [180, 170, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const monteCarloBoxFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch monteCarlo box FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 63, 33],
      },
      {
        label: 'Contacts',
        data: [99, 110, 90],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [90, 70, 30],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const camelRef = [
    { value: 'camel-ff', label: 'Camel-ff' },
    { value: 'camel-activate', label: 'Camel-activate' },
    { value: 'camel-light', label: 'Camel-light' },
  ];
  // camel ref
  const camelfF = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch camel FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 80, 68],
      },
      {
        label: 'Contacts',
        data: [199, 210, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [180, 170, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const camelActivate = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch camel activate %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 80, 68],
      },
      {
        label: 'Contacts',
        data: [199, 210, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [180, 170, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const camelLight = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Camel light %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 80, 68],
      },
      {
        label: 'Contacts',
        data: [199, 210, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [180, 170, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const davidoffRef = [
    { value: 'davidoff-light', label: 'Davidoff-light' },
    { value: 'davidoff-ff', label: 'Davidoff-ff' },
    { value: 'davidoff-slim-menthol', label: 'Davidoff-slim-menthol' },
  ];
  // davidoff ref
  const davidoffLight = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch davidoff Light %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 80, 68],
      },
      {
        label: 'Contacts',
        data: [199, 210, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [180, 170, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const davidoffFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch davidoff FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 80, 68],
      },
      {
        label: 'Contacts',
        data: [199, 210, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [180, 170, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const davidofSlimMenthol = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch davidoff Slim menthol %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [90, 80, 68],
      },
      {
        label: 'Contacts',
        data: [199, 210, 190],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [180, 170, 130],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const mqsRef = [
    { value: 'mqs-light', label: 'MQS-light' },
    { value: 'mqs-ff', label: 'MQS-ff' },
  ];
  // MQS ref
  const mqsLight = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch MQS-light %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [49, 51, 37],
      },
      {
        label: 'Contacts',
        data: [199, 330, 80],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [100, 170, 30],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const mqsFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch MQS-FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [56, 96, 60],
      },
      {
        label: 'Contacts',
        data: [399, 315, 332],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [227, 304, 202],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const ldRef = [{ value: '-ff', label: 'LD-ff' }];
  // Ld ref

  const ldFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch LD FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [85, 58, 64],
      },
      {
        label: 'Contacts',
        data: [400, 230, 380],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [342, 134, 244],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const lmRef = [
    { value: 'lm-light', label: 'L&M-light' },
    { value: 'lm-ff', label: 'L&M-ff' },
  ];
  // ml Ref

  const lmLight = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch M&L light %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [85, 58, 64],
      },
      {
        label: 'Contacts',
        data: [200, 230, 380],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [142, 134, 244],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const lmFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch L&M FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [99, 42, 55],
      },
      {
        label: 'Contacts',
        data: [305, 290, 80],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [302, 124, 44],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const chesterfieldRef = [
    { value: 'chesterfield-rich', label: 'Chesterfield-rich' },
    { value: 'chesterfield-intense', label: 'Chesterfield-intense' },
  ];
  // chesterfield ref

  const chesterfieldRich = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Chesterfield Rich %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [49, 42, 20],
      },
      {
        label: 'Contacts',
        data: [205, 26, 20],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [102, 24, 4],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const chesterfieldIntense = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Chesterfield Intense %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [5, 60, 45],
      },
      {
        label: 'Contacts',
        data: [35, 40, 80],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [2, 24, 44],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const winstonRef = [
    { value: 'winston-blue', label: 'Winston-blue' },
    { value: 'winston-red', label: 'Winston-red' },
    { value: 'winston-silver', label: 'Winston-silver' },
    { value: 'winston-blender', label: 'Winston-blender' },
    { value: 'winston-soft-ff', label: 'Winston-soft-ff' },
    { value: 'winston-box-ff', label: 'Winston-box-ff' },
  ];

  // winston ref
  const winstonBlue = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Winston Blue %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [52, 66, 30],
      },
      {
        label: 'Contacts',
        data: [435, 440, 480],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [230, 294, 144],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const winstonSilver = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Winston Silver %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [86, 51, 80],
      },
      {
        label: 'Contacts',
        data: [335, 400, 180],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [289, 204, 144],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const winstonBlender = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Winston blender %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [89, 53, 29],
      },
      {
        label: 'Contacts',
        data: [325, 420, 820],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [290, 224, 244],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const winstonRed = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Winston blender %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [50, 30, 50],
      },
      {
        label: 'Contacts',
        data: [380, 420, 488],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [190, 126, 244],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const winstonSoftFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch Winston soft FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [71, 66, 55],
      },
      {
        label: 'Contacts',
        data: [357, 400, 80],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [254, 264, 44],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const winstonBoxFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch winston Box FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [81, 60, 24],
      },
      {
        label: 'Contacts',
        data: [335, 404, 180],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [273, 244, 44],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };

  const maghrebRef = [
    {
      value: 'maghreb',
      label: 'MAGHREB',
    },
  ];
  // maghreb ref
  const maghreb = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch maghreb %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [54, 42, 24],
      },
      {
        label: 'Contacts',
        data: [135, 104, 180],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [73, 44, 44],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const viceroyRef = [{ value: 'viceroy-ff', label: 'Viceroy-ff' }];
  // viceroy ref
  const viceroyFf = {
    labels: ['18-25 ANS', '25-35 ANS', '35 ANS ET PLUS'],
    datasets: [
      {
        type: 'line',
        label: 'Taux de switch viceroy-FF %',
        borderColor: 'rgb(1, 162, 3)',
        borderWidth: 2,
        fill: false,
        data: [60, 88, 86],
      },
      {
        label: 'Contacts',
        data: [200, 260, 300],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 0',
      },
      {
        label: 'Contacts réussis',
        data: [120, 230, 260],
        backgroundColor: 'rgb(255, 43, 60)',
        stack: 'Stack 1',
      },
    ],
  };
  const [RefMarque, setRefMarque] = useState(marlboroRef);
  // const [selectedRef, setSelectedRef] = useState(marlboroRef[0]);
  const [reference, setreference] = useState(marlboroGold);

  const onChangeMarque = (value) => {
    switch (value.value) {
      case 'marlboro':
        setRefMarque(marlboroRef);

        break;
      case 'marquise':
        setRefMarque(marquiseRef);

        break;
      case 'marvel':
        setRefMarque(marvelRef);

        break;
      case 'rothmans':
        setRefMarque(rothmansRef);
        break;
      case 'monteCarlo':
        setRefMarque(monteCarloRef);
        break;
      case 'camel':
        setRefMarque(camelRef);
        break;
      case 'davidoff':
        setRefMarque(davidoffRef);
        break;
      case 'mqs':
        setRefMarque(mqsRef);
        break;
      case 'ld':
        setRefMarque(ldRef);
        break;
      case 'l&m':
        setRefMarque(lmRef);
        break;
      case 'chesterfield':
        setRefMarque(chesterfieldRef);
        break;
      case 'winston':
        setRefMarque(winstonRef);
        break;
      case 'maghreb':
        setRefMarque(maghrebRef);
        break;
      case 'viceroy':
        setRefMarque(viceroyRef);
        break;

      default:
        break;
    }
  };

  const onChangeMarqueSmt = (value) => {
    switch (value.value) {
      case 'marquise':
        setselectedMarqueVent(marquiseVent);
        break;
      case 'marvel':
        setselectedMarqueVent(marvelVent);
        break;
      case 'mqs':
        setselectedMarqueVent(MqsVent);
        break;
      case 'maghreb':
        setselectedMarqueVent(maghrebVent);
        break;
      case 'gauloises':
        setselectedMarqueVent(gauloisesVent);
        break;
      default:
        break;
    }
  };

  const onChangeRef = (value) => {
    console.log(value);
    switch (value.value) {
      case 'marlboro-beyond-blue':
        setreference(marlboroBeyondBlue);

        break;
      case 'marlboro-beyond-red':
        setreference(marlboroBeyondRed);
        break;
      case 'marlboro-purpul-mix':
        setreference(marlborPurpulMix);
        break;
      case 'marlboro-gold':
        setreference(marlboroGold);
        break;
      case 'marlboro-soft-ff':
        setreference(marlborSoftFf);
        break;
      case 'marlboro-box-ff':
        setreference(marlborBoxFf);
        break;
      case 'marquise-medium':
        setreference(marquiseMedium);
        break;
      case 'marquise-ff':
        setreference(marquiseFf);

        break;
      case 'rothmans-light':
        setreference(rothmansLight);
        break;
      case 'rothmans-ff':
        setreference(rothmansFf);
        break;
      case 'marvel-ff':
        setreference(marvelFf);
        break;
      case 'monteCarlo-box-ff':
        setreference(monteCarloBoxFf);
        break;
      case 'monteCarlo-soft-light':
        setreference(monteCarloSoftLight);
        break;
      case 'monteCarlo-classic':
        setreference(monteCarloClassic);
        break;
      case 'camel-light':
        setreference(camelLight);
        break;
      case 'camel-activate':
        setreference(camelActivate);
        break;
      case 'camel-ff':
        setreference(camelfF);
        break;
      case 'davidoff-slim-menthol':
        setreference(davidofSlimMenthol);
        break;
      case 'davidoff-light':
        setreference(davidoffLight);
        break;
      case 'davidoff-ff':
        setreference(davidoffFf);
        break;
      case 'mqs-ff':
        setreference(mqsFf);
        break;
      case 'mqs-light':
        setreference(mqsLight);
        break;
      case 'ld-ff':
        setreference(ldFf);
        break;
      case 'lm-ff':
        setreference(lmFf);
        break;
      case 'lm-light':
        setreference(lmLight);
        break;
      case 'chesterfield-rich':
        setreference(chesterfieldRich);
        break;
      case 'chesterfield-intense':
        setreference(chesterfieldIntense);
        break;
      case 'winston-blue':
        setreference(winstonBlue);
        break;
      case 'winston-soft-ff':
        setreference(winstonSoftFf);
        break;
      case 'winston-silver':
        setreference(winstonSilver);
        break;
      case 'winston-red':
        setreference(winstonRed);
        break;
      case 'winston-blender':
        setreference(winstonBlender);
        break;
      case 'winston-box-ff':
        setreference(winstonBoxFf);
        break;
      case 'maghreb':
        setreference(maghreb);
        break;
      case 'viceroy-ff':
        setreference(viceroyFf);
        break;
      default:
        break;
    }

    // setRefMarque(value.value);
    // if (value.value === 'marlboro') {
    //   setRefMarque(marlboro);
  };
  const options = [
    { value: 'marlboro', label: 'Marlboro' },
    { value: 'chesterfield', label: 'chesterfield' },
    { value: 'gauloise', label: 'Gauloise' },
  ];

  useEffect(() => {}, [moyenneParPosition]);

  return (
    <>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-3/4 overflow-hidden ">
          <DataTable data={positionListss} loading={loading} />
        </div>

        <div className="w-1/4 overflow-hidden ">
          <Notifications />
        </div>
      </div>
      <div className=" flex flex-wrap activation-filters">
        <div className="w-1/2">
          <SelectField
            placeholder="Ce mois"
            type="form"
            onChange={onChangeactivation}
            options={period}
          />
        </div>
        <div className="w-1/2">
          <DateRange isShown={showRange} range={range} />
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>Contacts</Tab>
          <Tab>positions</Tab>
          <Tab>Provenance </Tab>
        </TabList>
        <TabPanel>
          <div className="w-full  mb-8  overflow-hidden">
            <div
              // style={{ width: '97.3%', height: '635px' }}
              className=" items-center border-2 ml-10  h-full"
            >
              <div className=" flex flex-wrap activation-filters">
                {/* <div className="w-1/4 select-activations">
                  <SelectField
                    placeholder="this months"
                    type="form"
                    onChange={onChangeactivation}
                    options={period}
                  />
                </div>
                <div className="w-2/4">
                  <DateRange isShown={showRange} range={range} />
                </div> */}
                <div className="w-full select-activations">
                  <SelectField
                    placeholder="Contacts"
                    type="form"
                    onChange={onChangeSuccess}
                    options={[
                      {
                        value: '1',
                        label: 'Contacts',
                      },
                      {
                        value: '2',
                        label: 'Contacts réussis',
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="flex  activation-filters">
                <div
                  style={{ backgroundColor: '#FDF6F0' }}
                  className=" m-3  w-1/3 overflow-hidden xl:w-1/2"
                >
                  <h2 className="inline text-xl font-bold text-blue-500 ">
                    {' '}
                    par tranche d'âge :
                  </h2>
                  <PieChart data={moyenneParPosition} />
                </div>
                <div
                  style={{ backgroundColor: '#FDF6F0' }}
                  className=" m-3  w-1/3 overflow-hidden xl:w-1/2"
                >
                  <h2 className="inline text-xl font-bold text-blue-500 ">
                    {' '}
                    Par Provenance :
                  </h2>
                  <PieChart data={CoutParPosition} />
                </div>
                <div
                  style={{ backgroundColor: '#FDF6F0' }}
                  className=" m-3  w-1/3 overflow-hidden xl:w-1/2"
                >
                  <h1 className="m-3 text-brand-darkBlue text-xl font-semibold">
                    Nombre de positions activées : {300}
                  </h1>
                  <div>
                    <h2 className="block m-4 text-xl font-bold text-blue-500 ">
                      Total contact: {nbContact}
                    </h2>{' '}
                    <h2 className="block m-4 text-xl font-bold text-blue-500 ">
                      Moyenne par Position : {parseInt(nbContact / 300)}
                    </h2>
                    <h2 className="block m-4 text-xl font-bold text-blue-500 ">
                      Cout :{33} DH
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{ backgroundColor: '#FDF6F0' }}
                className=" m-3  w-1/1 overflow-hidden "
              >
                <h2 className="inline text-xl font-bold text-blue-500 ">
                  {' '}
                  Provenance du contact et nombre de convertis:
                </h2>
                <Dashchart data={nombreConvertis} />
              </div>
              <div
                style={{ backgroundColor: '#FDF6F0' }}
                className=" m-3  w-1/1 overflow-hidden "
              >
                {/* <h2 className="inline w-2/12 text-xl font-bold text-blue-500 ">
                  {' '}
                  Taux de switch :
                </h2>
                <Table columns={Marques} data={Taux} /> */}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="w-full  mb-8  overflow-hidden">
            <div
              style={{ width: '97.3%', height: '635px' }}
              className=" items-center border-2 ml-10  h-full "
            >
              <div className=" flex flex-wrap activation-filters">
                {/* <div className="w-1/2">
                  <SelectField
                    placeholder="this months"
                    type="form"
                    onChange={onChangeRange}
                    options={period}
                  />
                </div>
                <div className="w-1/2">
                  <DateRange isShown={showRange} range={range} />
                </div> */}

                <div
                  style={{ backgroundColor: '#FDF6F0' }}
                  className=" w-full h-full overflow-hidden "
                >
                  <h1 className="m-3 text-brand-darkBlue text-xl font-semibold">
                    Positions
                  </h1>
                  <div className="header">
                    <h2 className="inline m-3 text-xl font-bold text-blue-500 ">
                      Positions uniques : 160 Positions
                    </h2>
                    <h2 className="inline m-3 text-xl font-bold text-blue-500 ">
                      Nombre d’activation : 2 Activations
                    </h2>
                    <h2 className="inline m-3 text-xl font-bold text-blue-500 ">
                      Nombre de positions annulées : 6 positions
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="w-full  mb-8  overflow-hidden">
            <div
              style={{ width: '97.3%', height: '665px' }}
              className=" items-center border-2 ml-10  h-full "
            >
              <div className=" flex flex-wrap activation-filters">
                {/* <div className="w-1/2">
                  <SelectField
                    placeholder="this months"
                    type="form"
                    onChange={onChangeRange}
                    options={period}
                  />
                </div>
                <div className="w-1/2">
                  <DateRange isShown={showRange} range={range} />
                </div> */}
                {/* <DateRange isShown={showRange} /> */}
              </div>
              <div className="flex  activation-filters">
                <div
                  style={{ backgroundColor: '#FDF6F0' }}
                  className=" m-3 w-1/2 overflow-hidden xl:w-1/2"
                >
                  <div className="w-1/1 select-activations">
                    <SelectField
                      type="form"
                      placeholder="Marlboro"
                      // placeholder="les marques de provenances "
                      onChange={onChangeMarque}
                      w-1
                      options={toutLesMArques}
                    />
                    <SelectField
                      type="form"
                      placeholder="marlboro-gold"
                      onChange={onChangeRef}
                      w-1
                      options={RefMarque}
                      // isMulti
                      // value={selectedRef}
                    />
                  </div>

                  <h1 className="m-3 text-brand-darkBlue text-xl font-semibold">
                    Provenance par Reference et par tranche d'age
                  </h1>
                  <Dashchart data={reference} />
                </div>

                <div
                  style={{ backgroundColor: '#FDF6F0' }}
                  className="m-3 w-1/2 overflow-hidden xl:w-1/2"
                >
                  <div className="w-1/1 select-activations">
                    <SelectField
                      type="form"
                      placeholder="Les marque SMT"
                      onChange={onChangeMarqueSmt}
                      w-1
                      options={lesMarqueSmt}
                    />
                  </div>
                  <h1 className="m-3 text-brand-darkBlue text-xl font-semibold">
                    Répartition ventes par référence
                  </h1>
                  <Dashchart data={selectedMarqueVent} loading={loading} />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default Home;
