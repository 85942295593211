import { createAction } from 'redux-act';
import { displayText } from 'utils';
/* eslint-disable camelcase */
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';

export const BRANDS_FETCH_DATA_INIT = createAction('BRANDS_FETCH_DATA_INIT');
export const BRANDS_FETCH_DATA_SUCCESS = createAction(
  'BRANDS_FETCH_DATA_SUCCESS'
);
export const BRANDS_FETCH_DATA_FAIL = createAction('BRANDS_FETCH_DATA_FAIL');
export const BRANDS_CREATE_INIT = createAction('BRANDS_CREATE_INIT');
export const BRANDS_CREATE_SUCCESS = createAction('BRANDS_CREATE_SUCCESS');
export const BRANDS_CREATE_FAIL = createAction('BRANDS_CREATE_FAIL');
export const BRANDS_CLEAR_DATA = createAction('BRANDS_CLEAR_DATA');
export const BRANDS_DELETE_INIT = createAction('BRANDS_DELETE_BRANDS_INIT');
export const BRANDS_DELETE_SUCCESS = createAction('BRANDS_DELETE_SUCCESS');
export const BRANDS_DELETE_FAIL = createAction('BRANDSS_DELETE_BRANDS_FAIL');
export const BRANDS_MODIFY_INIT = createAction('BRANDS_MODIFY_INIT');
export const BRANDS_MODIFY_SUCCESS = createAction('BRANDS_MODIFY_SUCCESS');
export const BRANDS_MODIFY_FAIL = createAction('BRANDS_MODIFY_FAIL');
export const BRANDS_CLEAN_UP = createAction('BRANDS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const c = new ControllerWithFileUpload('brands', 'brand', 'displayName');

/**
 * Action Fetch brands
 * @return dispatch
 */
export const fetchBrands = () =>
  c.fetch(
    BRANDS_FETCH_DATA_INIT,
    BRANDS_FETCH_DATA_SUCCESS,
    BRANDS_FETCH_DATA_FAIL
  );

/**
 * Action Create new brand
 * @param {string} name Name of Brand
 * @param {string} imageUrl image of the Brand
 * @return dispatch
 */
export const createBrand = ({ name, file }) => {
  const displayName = displayText(name);
  return c.create(
    { name, displayName },
    BRANDS_CREATE_INIT,
    BRANDS_CREATE_SUCCESS,
    BRANDS_CREATE_FAIL,
    file
  );
};

/**
 * Action Delete Brand
 * @param {string} id ID of Brand
 * @return dispatch
 */
export const deleteBrand = (id, link) =>
  c.destroy(
    id,
    BRANDS_DELETE_INIT,
    BRANDS_DELETE_FAIL,
    BRANDS_DELETE_SUCCESS,
    link
  );

/**
 * Action Update Brand
 * @param {string} name Name of Brand
 * @return dispatch
 */
export const modifyBrand = ({ name, id, file, link }) => {
  const displayName = displayText(name);
  return c.update(
    id,
    { name, displayName, link },
    BRANDS_MODIFY_INIT,
    BRANDS_MODIFY_SUCCESS,
    BRANDS_MODIFY_FAIL,
    file
  );
};

export const brandsCleanUp = () => (dispatch) => dispatch(BRANDS_CLEAN_UP());

export const clearBrandsData = () => {
  return (dispatch) => {
    dispatch(BRANDS_CLEAR_DATA());
  };
};
