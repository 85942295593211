import firebase from 'firebase.js';
import Controller from './Controller';
import RelationController from './RelationController';
import UserController from './UserController';

export default class TeamController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'teams';
    this.item = 'team';
  }

  fetch = (id) => {
    const rc = new RelationController();
    return rc.getTeams(id);
  };

  create = (id, data) =>
    firebase
      .firestore()
      .collection('users')
      .doc(id)
      .collection('teams')
      .add({
        ...data,
        createdAt: new Date().toGMTString(),
        updatedAt: new Date().toGMTString(),
      })
      .then(async (ref) => {
        const promises = data.groups.map((g) => {
          g.animators.concat(g.supervisor).map((member) =>
            firebase.firestore().collection('users').doc(member).update({
              teamId: ref.id,
            })
          );
        });
        return Promise.all(promises);
      })
 

  update = (id, idTeam, data) => {
    firebase
      .firestore()
      .collection('users')
      .doc(id)
      .collection('teams')
      .doc(idTeam)
      .update({
        ...data,
        updatedAt: new Date().toGMTString(),
      })
      .then(async () => {
        const newMembers = [];
        const oldMembers = await firebase
          .firestore()
          .collection('users')
          .where('teamId', '==', idTeam)
          .get()
          .then((usersSnap) => {
            if (!usersSnap.empty) return usersSnap.docs.map((user) => user.id);
            return [];
          });
        data.groups.map((g) =>
          newMembers.push(...g.animators.concat(g.supervisor))
        );
        const mergeMembers = [...oldMembers, ...newMembers];
        const promises = mergeMembers.map((member) => {
          if (mergeMembers.indexOf(member) > 0) {
            return firebase
              .firestore()
              .collection('users')
              .doc(member)
              .update({
                teamId: oldMembers.includes(member) ? '' : idTeam,
              });
          }
          return null;
        });
        return Promise.all(promises);
      });
  };

  delete = (id, idTeam) =>
    firebase
      .firestore()
      .collection('users')
      .doc(id)
      .collection('teams')
      .doc(idTeam)
      .delete()
      .then(async () => {
        await firebase
          .firestore()
          .collection('users')
          .where('teamId', '==', idTeam)
          .get()
          .then(async (usersSnap) => {
            if (!usersSnap.empty) {
              const promises = usersSnap.docs.map((member) =>
                firebase
                  .firestore()
                  .collection('users')
                  .doc(member.id)
                  .update({ teamId: '' })
              );
              return Promise.all(promises);
            }
          });
      });

  /**
   * Get Team data
   * @param {string} teamId Team ID
   * @returns {Object} object of Team data or false
   */
  fetchById = async (agencyId, teamId) =>
    agencyId && teamId
      ? firebase
          .firestore()
          .collection('users')
          .doc(agencyId)
          .collection(this.collection)
          .doc(teamId)
          .get()
          .then(async (teamSnap) => {
            const uc = new UserController();
            if (teamSnap.exists) {
              const groups = teamSnap.data().groups.map(async (group) => {
                const supervisor = await uc.fetchById(group.supervisor);
                const animators = group.animators.map((animator) =>
                  uc.fetchById(animator)
                );
                return { supervisor, animators: await Promise.all(animators) };
              });
              return {
                id: teamSnap.id,
                ...teamSnap.data(),
                groups: await Promise.all(groups),
              };
            }
            return null;
          })
      : null;
}
