import { createReducer } from 'redux-act';
import {
  PRODUCTS_FETCH_DATA_INIT,
  PRODUCTS_FETCH_DATA_SUCCESS,
  PRODUCTS_FETCH_DATA_FAIL,
  PRODUCTS_CREATE_INIT,
  PRODUCTS_CREATE_SUCCESS,
  PRODUCTS_CREATE_FAIL,
  PRODUCTS_CLEAN_UP,
  PRODUCTS_CLEAR_DATA,
  PRODUCTS_MODIFY_INIT,
  PRODUCTS_MODIFY_SUCCESS,
  PRODUCTS_MODIFY_FAIL,
  PRODUCTS_DELETE_INIT,
  PRODUCTS_DELETE_SUCCESS,
  PRODUCTS_DELETE_FAIL,
} from 'state/actions/products';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const productsReducer = createReducer(
  {
    [PRODUCTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [PRODUCTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.products,
      loading: false,
      error: null,
    }),
    [PRODUCTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRODUCTS_CREATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRODUCTS_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      data: [...state.data, { ...payload.product, id: state.id }],
      loading: false,
      error: null,
      success: true,
    }),
    [PRODUCTS_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRODUCTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [PRODUCTS_DELETE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRODUCTS_DELETE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: state.data.filter((elem) => elem.id !== payload.id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [PRODUCTS_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRODUCTS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
    [PRODUCTS_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRODUCTS_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.product.id) {
            return {
              ...payload.product
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [PRODUCTS_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
