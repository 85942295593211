import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import firebase from 'firebase.js';

export const POS_CATEGORIES_FETCH_DATA_INIT = createAction(
  'POS_CATEGORIES_FETCH_DATA_INIT'
);
export const POS_CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'POS_CATEGORIES_FETCH_DATA_SUCCESS'
);
export const POS_CATEGORIES_FETCH_DATA_FAIL = createAction(
  'POS_CATEGORIES_FETCH_DATA_FAIL'
);
export const POS_CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'POS_CATEGORIES_CREATE_CATEGORY_INIT'
);
export const POS_CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'POS_CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const POS_CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'POS_CATEGORIES_CREATE_CATEGORY_FAIL'
);
export const POS_CATEGORIES_CLEAR_DATA = createAction(
  'POS_CATEGORIES_CLEAR_DATA'
);
export const POS_CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'POS_CATEGORIES_DELETE_POS_CATEGORIES_INIT'
);
export const POS_CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'POS_CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const POS_CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'POS_CATEGORIESS_DELETE_POS_CATEGORIES_FAIL'
);
export const POS_CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'POS_CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const POS_CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'POS_CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const POS_CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'POS_CATEGORIES_MODIFY_CATEGORY_FAIL'
);
export const POS_CATEGORIES_CLEAN_UP = createAction('POS_CATEGORIES_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('posCategories', 'posCategory');

/**
 * Action Fetch posCategories
 * @return dispatch
 */
export const fetchPosCategories = () => {
  return async (dispatch) => {
    dispatch(POS_CATEGORIES_FETCH_DATA_INIT());
    let posCategories;
    try {
      posCategories = await gc.fetch();
    } catch (error) {
      toastr.error('Catègorie', 'Operation a échoué');
      return dispatch(POS_CATEGORIES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      POS_CATEGORIES_FETCH_DATA_SUCCESS({
        posCategories,
      })
    );
  };
};

/**
 * Action Create new posCategory
 * @param {string} name Name of PosCategory
 * @return dispatch
 */
export const createPosCategory = ({ name }) => {
  return async (dispatch, getState) => {
    dispatch(POS_CATEGORIES_CREATE_CATEGORY_INIT());
    const { locale } = getState().preferences;
    let posCategory;
    try {
      const displayName = displayText(name);
      posCategory = await gc.create({ name, displayName }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'la création a échoué');
      return dispatch(
        POS_CATEGORIES_CREATE_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'créé avec succès');
    return dispatch(POS_CATEGORIES_CREATE_CATEGORY_SUCCESS({ posCategory }));
  };
};

/**
 * Action Delete posCategory
 * @param {string} id ID of PosCategory
 * @return dispatch
 */
export const deletePosCategory = (id) => {
  return async (dispatch, getState) => {
    dispatch(POS_CATEGORIES_DELETE_CATEGORY_INIT());
    const { locale } = getState().preferences;
    try {
      const positions = await firebase
        .firestore()
        .collection('positions')
        .where('categoryId', '==', id)
        .get();
      if (positions.size > 0) {
        const err = new Error('position already used');
        err.code = 401;
        throw err;
      }
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'la suppression a échoué');
      return dispatch(
        POS_CATEGORIES_DELETE_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'Supprimé avec succès');
    return dispatch(POS_CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

/**
 * Action Update posCategory
 * @param {string} name Name of PosCategory
 * @return dispatch
 */
export const modifyPosCategory = ({ name, id }) => {
  return async (dispatch, getState) => {
    dispatch(POS_CATEGORIES_MODIFY_CATEGORY_INIT());
    const { locale } = getState().preferences;
    let posCategory;
    try {
      const displayName = displayText(name);
      posCategory = await gc.update(id, { name, displayName }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'mise à jour a échoué');
      return dispatch(
        POS_CATEGORIES_MODIFY_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'mis à jour avec succès');
    return dispatch(POS_CATEGORIES_MODIFY_CATEGORY_SUCCESS({ posCategory }));
  };
};

export const posCategoriesCleanUp = () => (dispatch) =>
  dispatch(POS_CATEGORIES_CLEAN_UP());

export const clearPosCategoriesData = () => {
  return (dispatch) => {
    dispatch(POS_CATEGORIES_CLEAR_DATA());
  };
};
