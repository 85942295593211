/* eslint-disable camelcase */
import {
  firebaseError
} from 'utils';
import {
  createAction
} from 'redux-act';
import {
  toastr
} from 'react-redux-toastr';
import PositionController from '../../controllers/PositionController';
import ActivationController from 'controllers/ActivationController';

const pc = new PositionController();
const ac = new ActivationController();
export const POSITIONS_FETCH_DATA_INIT = createAction(
  'POSITIONS_FETCH_DATA_INIT'
);
export const POSITIONS_FETCH_DATA_SUCCESS = createAction(
  'POSITIONS_FETCH_DATA_SUCCESS'
);
export const POSITIONS_FETCH_DATA_FAIL = createAction(
  'POSITIONS_FETCH_DATA_FAIL'
);
export const POSITIONS_CREATE_POSITION_INIT = createAction(
  'POSITIONS_CREATE_POSITION_INIT'
);
export const POSITIONS_CREATE_POSITION_SUCCESS = createAction(
  'POSITIONS_CREATE_POSITION_SUCCESS'
);
export const POSITIONS_CREATE_POSITION_FAIL = createAction(
  'POSITIONS_CREATE_POSITION_FAIL'
);
export const POSITIONS_CLEAR_DATA = createAction('POSITIONS_CLEAR_DATA');
export const POSITIONS_DELETE_POSITION_INIT = createAction(
  'POSITIONS_DELETE_POSITIONS_INIT'
);
export const POSITIONS_DELETE_POSITION_SUCCESS = createAction(
  'POSITIONS_DELETE_POSITION_SUCCESS'
);
export const POSITIONS_DELETE_POSITION_FAIL = createAction(
  'POSITIONSS_DELETE_POSITIONS_FAIL'
);
export const POSITIONS_MODIFY_POSITION_INIT = createAction(
  'POSITIONS_MODIFY_POSITION_INIT'
);
export const POSITIONS_MODIFY_POSITION_SUCCESS = createAction(
  'POSITIONS_MODIFY_POSITION_SUCCESS'
);
export const POSITIONS_MODIFY_POSITION_FAIL = createAction(
  'POSITIONS_MODIFY_POSITION_FAIL'
);
export const POSITIONS_CLEAN_UP = createAction('POSITIONS_CLEAN_UP');

/**
 *
 * Action Fetch positions
 * Author: Ennaim Yassine <ennaimyassine@gmail.com>
 * @return dispatch
 */
export const fetchPositions = (agencyId = null) => {
  return async (dispatch) => {
    dispatch(POSITIONS_FETCH_DATA_INIT());
    try {
      const positions = await pc.fetch(agencyId);

      // const positions = await ac.fetchActivationBydate();
      console.log('from the action', positions);
      return dispatch(POSITIONS_FETCH_DATA_SUCCESS({
        positions
      }));
    } catch (error) {
      toastr.error('Position', 'Operation a échoué');
      return dispatch(POSITIONS_FETCH_DATA_FAIL({
        error
      }));
    }
  };
};

/**
 * Action Fetch a specific position
 * @param {string} ID ID of the position
 */

export const fetchPositionData = async (id) => pc.fetchById(id);

/**
 * Action Create new position
 * Author: Ennaim Yassine <ennaimyassine@gmail.com>
 * @param {string} name Name of Position
 * @return dispatch
 */
export const createPosition = ({
  name,
  region,
  zone,
  sector,
  startHour,
  cumulHour,
  matricule,
  location,
  city,
  address,
  status,
  categoryId,
}) => {
  return async (dispatch, getState) => {
    dispatch(POSITIONS_CREATE_POSITION_INIT());
    const {
      locale
    } = getState().preferences;
    let position;
    try {
      position = await pc.create({
          name,
          region,
          zone,
          sector,
          startHour,
          cumulHour,
          matricule,
          location,
          city,
          address,
          status: 'INITIAL',
          categoryId,
        },
        'matricule'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Position', 'la création a échoué');
      return dispatch(
        POSITIONS_CREATE_POSITION_FAIL({
          error: errorMessage,
        })
      );
    }
    toastr.success('Position', 'créé avec succès');
    return dispatch(POSITIONS_CREATE_POSITION_SUCCESS({
      position
    }));
  };
};

/**
 * Action Delete position
 * Author: Ennaim Yassine <ennaimyassine@gmail.com>
 * @param {string} id ID of Position
 * @return dispatch
 */
export const deletePosition = (id) => {
  return async (dispatch, getState) => {
    dispatch(POSITIONS_DELETE_POSITION_INIT());
    const {
      locale
    } = getState().preferences;
    try {
      await pc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Position', 'la suppression a échoué');
      return dispatch(
        POSITIONS_DELETE_POSITION_FAIL({
          error: errorMessage,
        })
      );
    }
    toastr.success('Position', 'Supprimé avec succès');
    return dispatch(POSITIONS_DELETE_POSITION_SUCCESS({
      id
    }));
  };
};

/**
 * Action Update position
 * Author: Ennaim Yassine <ennaimyassine@gmail.com>
 * @param {string} name Name of Position
 * @return dispatch
 */
export const modifyPosition = ({
  id,
  name,
  region,
  zone,
  sector,
  startHour,
  cumulHour,
  matricule,
  location,
  city,
  address,
  status,
  categoryId,
}) => {
  return async (dispatch, getState) => {
    dispatch(POSITIONS_MODIFY_POSITION_INIT());
    const {
      locale
    } = getState().preferences;
    let position;
    try {
      position = await pc.update(
        id, {
          name,
          region,
          zone,
          sector,
          startHour,
          cumulHour,
          matricule,
          location,
          city,
          address,
          categoryId,
          status,
          updatedAt: new Date().toGMTString(),
        },
        'matricule'
      );
      toastr.success('Position', 'mis à jour avec succès');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Position', 'mise à jour a échoué');
      return dispatch(
        POSITIONS_MODIFY_POSITION_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('Position', 'mis à jour avec succès');
    return dispatch(POSITIONS_MODIFY_POSITION_SUCCESS({
      position
    }));
  };
};

export const positionsCleanUp = () => (dispatch) =>
  dispatch(POSITIONS_CLEAN_UP());

export const clearPositionsData = () => {
  return (dispatch) => {
    dispatch(POSITIONS_CLEAR_DATA());
  };
};