import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useChangeHandler } from 'hooks';
import firebase from 'firebase.js';
import Button from 'components/Common/Button';
import FileInput from 'components/Common/FileInput';
import NormalCheckbox from 'components/Common/NormalCheckbox';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';

const ReferenceForm = ({ isEditing, referenceData, action }) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.references.loading,
    }),
    shallowEqual
  );
  const [reference, setReference] = useState(referenceData);
  const onChangeHandler = useChangeHandler(setReference);
  const [marksList, setMarksList] = useState([]);
  const dispatch = useDispatch();

  // Start change handlers
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (event.target)
      setReference((prevState) => ({
        ...prevState,
        [name]: file,
        imageUrl: name === 'image' ? '' : prevState.imageUrl,
      }));
  };

  const handleCheckBoxChange = () => {
    setReference((prevState) => ({
      ...prevState,
      active: !prevState.active,
    }));
  };

  const handleIsMultiMarkChange = () => {
    setReference((prevState) => ({
      ...prevState,
      isMultiMark: !prevState.isMultiMark,
    }));
  };

  const onMarksChange = ({ value, label }) => {
    setReference((prevState) => ({
      ...prevState,
      brand: value,
      brandLabel: label,
    }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(reference));
  };
  // End change handlers

  // Start Effects
  useEffect(() => {
    firebase
      .firestore()
      .collection('marks')
      .get()
      .then((markRef) => {
        const marks = markRef.docs.map((markSnap) => {
          return {
            value: markSnap.id,
            label: markSnap.data().name,
          };
        });
        setMarksList(marks);
      });
  }, []);

  // End Effects

  // Start Conditional variables
  const defaultMark = isEditing
    ? {
        value: reference.brand.id,
        label: reference.brand.name,
      }
    : {};

  const imagePreviewUrl = !reference.imageUrl
    ? reference.image && URL.createObjectURL(reference.image)
    : reference.imageUrl;

  const sourceList = [
    {
      value: 'VFM SMT',
      label: 'VFM SMT',
    },
    {
      value: 'VFM Concurrence',
      label: 'VFM Concurrence',
    },
    {
      value: 'Prémium Concurrence',
      label: 'Prémium Concurrence',
    },
    {
      value: 'Fidélisation',
      label: 'Fidélisation',
    },
    {
      value: 'Premium SMT',
      label: 'Premium SMT',
    },
  ];

  const defaultSource =
    isEditing && !!reference.source
      ? {
          value: reference.source,
          label: reference.source,
        }
      : {};

  const onSourceChange = ({ value }) => {
    setReference((prevState) => ({
      ...prevState,
      source: value,
    }));
  };

  const canSubmit =
    reference.name &&
    (reference.imageUrl || reference.image) &&
    (reference.argUrl || reference.arg) &&
    reference.brand;

  return (
    <>
      <form
        onSubmit={onSubmitHandler}
        className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
      >
        {/* image  preview  */}
        {imagePreviewUrl && (
          <div className="w-full flex-none pb-4">
            <div className="is-user-avatar image has-max-width is-aligned-center">
              <img
                className="user-avatar"
                src={imagePreviewUrl}
                alt="User profile logo preview"
              />
            </div>
          </div>
        )}
        {/* end image preview  */}
        <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
          {/* name  */}
          <div className="lg:max-w-sm lg:w-1/2">
            <NormalInput
              label="Nom de référence"
              id="name"
              type="text"
              value={reference.name}
              onChange={onChangeHandler}
              required
              name="name"
            />
          </div>

          {/* image upload  */}
          <div className="lg:max-w-sm lg:w-1/2">
            <FileInput
              required={!isEditing}
              isForm
              label="Image"
              type="file"
              accept="image/*"
              name="image"
              file={reference.image}
              className={`${loading && 'is-loading'}`}
              onChange={onFileChangedHandler}
              disabled={loading}
            />
          </div>
          {/* pdf upload  */}
          <div className="lg:max-w-sm lg:w-1/2">
            <FileInput
              isForm
              label="Upload PDF (argumentaires)"
              type="file"
              name="arg"
              accept="application/pdf"
              file={reference.arg}
              className={`${loading && 'is-loading'}`}
              disabled={loading}
              onChange={onFileChangedHandler}
            />
          </div>
          {isEditing && reference.argUrl && (
            <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
              <div className="lg:max-w-sm lg:w-1/2">
                <a
                  href={reference.argUrl}
                  className="px-2 font-semibold text-brand-lightBlue"
                >
                  Fichier argumentaire
                </a>
              </div>
            </div>
          )}
          <div className="lg:max-w-sm lg:w-1/2">
            <SelectField
              options={sourceList}
              type="form"
              className="max-w-xs"
              label="Source"
              name="category"
              defaultValue={defaultSource}
              onChange={onSourceChange}
            />
          </div>
          {/* Select a brand  */}
          <div className="lg:max-w-sm lg:w-1/2">
            <SelectField
              type="form"
              label="Marque"
              options={marksList}
              isLoading={!marksList}
              defaultValue={defaultMark}
              onChange={onMarksChange}
            />
          </div>
          {/* enable / disable  */}
          <div className="lg:pt-3 lg:max-w-sm lg:w-1/2">
            <NormalCheckbox
              className="flex-1"
              label="Activer"
              type="checkbox"
              name="active"
              checked={reference.active}
              onChange={handleCheckBoxChange}
            />
          </div>
          <div className="lg:pt-3 lg:max-w-sm lg:w-1/2">
            <NormalCheckbox
              className="flex-1"
              label="Multimarque"
              type="checkbox"
              name="multiMark"
              checked={reference.isMultiMark}
              onChange={handleIsMultiMarkChange}
            />
          </div>
        </div>
        {/* actions  */}
        <div className="flex w-full max-w-md lg:w-1/3 lg:items-end lg:justify-end items-center justify-end">
          <Button
            type="submit"
            className={`${loading && 'is-loading'}`}
            disabled={loading || !canSubmit}
            color="green"
            size="large"
            icon="mdi-plus-circle"
            text="Enregistrer"
          />
        </div>
      </form>
    </>
  );
};

export default ReferenceForm;
