import { createReducer } from 'redux-act';
import {
  PERMISSIONS_FETCH_DATA_INIT,
  PERMISSIONS_FETCH_DATA_SUCCESS,
  PERMISSIONS_FETCH_DATA_FAIL,
  PERMISSIONS_CREATE_PERMISSION_INIT,
  PERMISSIONS_CREATE_PERMISSION_SUCCESS,
  PERMISSIONS_CREATE_PERMISSION_FAIL,
  PERMISSIONS_DELETE_PERMISSION_INIT,
  PERMISSIONS_DELETE_PERMISSION_SUCCESS,
  PERMISSIONS_DELETE_PERMISSION_FAIL,
  PERMISSIONS_MODIFY_PERMISSION_INIT,
  PERMISSIONS_MODIFY_PERMISSION_SUCCESS,
  PERMISSIONS_MODIFY_PERMISSION_FAIL,
  PERMISSIONS_CLEAN_UP,
  PERMISSIONS_CLEAR_DATA,
} from 'state/actions/permissions';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const permissionsReducer = createReducer(
  {
    // for fetching permissions
    [PERMISSIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [PERMISSIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.permissions,
      loading: false,
      error: null,
    }),
    [PERMISSIONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PERMISSIONS_CREATE_PERMISSION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PERMISSIONS_CREATE_PERMISSION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat({ id: state.id }).concat(payload.permission),
      loading: false,
      error: null,
      success: true,
    }),
    [PERMISSIONS_CREATE_PERMISSION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PERMISSIONS_DELETE_PERMISSION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PERMISSIONS_DELETE_PERMISSION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [PERMISSIONS_DELETE_PERMISSION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PERMISSIONS_MODIFY_PERMISSION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PERMISSIONS_MODIFY_PERMISSION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map((elem) => {
        if (elem.id === payload.id) {
          return {
            name: payload.permission.name,
            displayName: payload.permission.displayName,
            id: payload.id,
            createdAt: payload.permission.createdAt,
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true,
    }),
    [PERMISSIONS_MODIFY_PERMISSION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PERMISSIONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [PERMISSIONS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
