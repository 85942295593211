import { createReducer } from 'redux-act';
import {
  PRO_CATEGORIES_FETCH_DATA_INIT,
  PRO_CATEGORIES_FETCH_DATA_SUCCESS,
  PRO_CATEGORIES_FETCH_DATA_FAIL,
  PRO_CATEGORIES_CREATE_CATEGORY_INIT,
  PRO_CATEGORIES_CREATE_CATEGORY_SUCCESS,
  PRO_CATEGORIES_CREATE_CATEGORY_FAIL,
  PRO_CATEGORIES_CLEAN_UP,
  PRO_CATEGORIES_DELETE_CATEGORY_INIT,
  PRO_CATEGORIES_DELETE_CATEGORY_SUCCESS,
  PRO_CATEGORIES_DELETE_CATEGORY_FAIL,
  PRO_CATEGORIES_CLEAR_DATA,
  PRO_CATEGORIES_MODIFY_CATEGORY_INIT,
  PRO_CATEGORIES_MODIFY_CATEGORY_SUCCESS,
  PRO_CATEGORIES_MODIFY_CATEGORY_FAIL,
} from 'state/actions/proCategories';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const proCategoriesReducer = createReducer(
  {
    [PRO_CATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [PRO_CATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.proCategories,
      loading: false,
      error: null,
    }),
    [PRO_CATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRO_CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRO_CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      data: [...state.data, { ...payload.proCategory, id: state.id }],
      loading: false,
      error: null,
      success: true,
    }),
    [PRO_CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRO_CATEGORIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [PRO_CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRO_CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [PRO_CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRO_CATEGORIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
    [PRO_CATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRO_CATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.proCategory.id) {
            return {
              name: payload.proCategory.name,
              displayName: payload.proCategory.displayName,
              id: payload.id,
              createdAt: payload.proCategory.createdAt,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [PRO_CATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
