import firebase from 'firebase.js';
import Controller from './Controller';
import ProductController from './ProductController';
import ReferenceController from './ReferenceController';

export default class ContactController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'contacts';
    this.item = 'contact';
  }

  chunk = (array, size) => {
    if (array.length <= size) {
      return [array];
    }
    return [array.slice(0, size), ...this.chunk(array.slice(size), size)];
  };

  fetch = (activationId) => {
    const pc = new ProductController();
    const rc = new ReferenceController();
    return activationId
      ? firebase
          .firestore()
          .collection('contacts')
          .where('idActivation', '==', activationId)
          .get()
          .then((contactSnaps) => {
            if (contactSnaps.size > 0) {
              const promises = contactSnaps.docs.map(async (doc) => {
                const references =
                  doc.data().references.length > 0
                    ? doc
                        .data()
                        .references.map(
                          async (ref) =>
                            ((await rc.fetchById(ref.referenceDocId)) || {})
                              .name
                        )
                    : [];
                return {
                  id: doc.id,
                  ...doc.data(),
                  productName: doc.data().productId
                    ? ((await pc.fetchById(doc.data().productId)) || {}).name
                    : null,
                  references: await Promise.all(references),
                };
              });
              return Promise.all(promises);
            }
            return [];
          })
      : [];
  };

  fetchByIds = (actIds) => {
    const pc = new ProductController();
    const rc = new ReferenceController();
    if (actIds.length > 0) {
      const chunkPromises = this.chunk(actIds, 10).map((chunkIds) =>
        firebase
          .firestore()
          .collection('contacts')
          .where('idActivation', 'in', chunkIds)
          .get()
          .then((contactSnaps) => {
            if (contactSnaps.size > 0) {
              const promises = contactSnaps.docs.map(async (doc) => {
                const references =
                  doc.data().references.length > 0
                    ? doc
                        .data()
                        .references.map(
                          async (ref) =>
                            ((await rc.fetchById(ref.referenceDocId)) || {})
                              .name
                        )
                    : [];
                return {
                  id: doc.id,
                  ...doc.data(),
                  productName: doc.data().productId
                    ? ((await pc.fetchById(doc.data().productId)) || {}).name
                    : null,
                  references: await Promise.all(references),
                };
              });
              return Promise.all(promises);
            }
            return [];
          })
      );
      return Promise.all(chunkPromises);
    }
    return [];
  };

  /**
   * Get Contact data
   * @param {string} agencyId Contact ID
   * @returns {Object} object of Contact data or false
   */
  fetchById = async (agencyId) =>
    agencyId
      ? this.getDoc(agencyId).then((contactSnap) => {
          if (contactSnap.exists) {
            return {
              id: contactSnap.id,
              ...contactSnap.data(),
            };
          }
          return null;
        })
      : null;
}
