import React, { useState } from 'react';

import Table from 'components/Table';
import { CSVLink } from 'react-csv';
import DateRange from 'components/Common/DateRange';
import { useFormatMessage } from 'hooks';

const Detaildecalage = () => {
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(),
    periodEnd: new Date(),
  });

  const rawData = [
    {
      date: 'Fri, 01 Jan 2021 13:48:39 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `serviette-plage-s`,
      manquantSuperviseur: 'Stock remis par l’agence insuffisant',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Fri, 01 Jan 2021 13:48:39 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `casquettes-vlm`,
      manquantSuperviseur: 'Stock remis par l’agence insuffisant',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Wed, 10 Feb 2021 11:27:50 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `ecouteur-triangle`,
      manquantSuperviseur: 'Manquant constaté lors de l’ouverture du colis',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Tue, 09 Mar 2021 13:48:39 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `coffee-mug-en-silicone`,
      manquantSuperviseur: 'Article défaillant',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Fri, 12 Mar 2021 13:48:39 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `set-de-bricolage`,
      manquantSuperviseur: 'Manquant constaté lors de l’ouverture du colis',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Tue, 09 Mar 2021 13:48:39 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `verre-silicone-gob`,
      manquantSuperviseur: 'Manquant constaté lors de l’ouverture du colis',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Wed, 10 Feb 2021 11:27:50 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `sac-a-dos-avec-filet`,
      manquantSuperviseur: 'Manquant constaté lors de l’ouverture du colis',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Tue, 09 Mar 2021 13:48:39 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `sac-a-dos-avec-filet`,
      manquantSuperviseur: 'Manquant constaté lors de l’ouverture du colis',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Wed, 10 Feb 2021 11:27:50 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `briquet-jeep`,
      manquantSuperviseur: 'Article défaillant',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
    {
      date: 'Wed, 27 Feb 2021 11:27:50 GMT',
      position: `P${Math.floor(Math.random() * 10000)}`,
      decalageSuperviseur: `chargeur-avec-boite`,
      manquantSuperviseur: 'Manquant constaté lors de l’ouverture du colis',
      raisonSuperviseur: Math.floor(Math.random() * 100),
      decalageAnimatricer: Math.floor(Math.random() * 100),
      manquantAnimatrice: 'Stock remis par le superviseur insuffisant',
      raisonAnimatrice: Math.floor(Math.random() * 100),
    },
  ];
  const columns = [
    {
      Header: 'DATE',
      accessor: 'date',
    },
    {
      Header: 'POSITION',
      accessor: 'position',
    },
    {
      Header: 'ARTICLE AVEC DECALAGE STOCK (superviseur) ',
      accessor: 'decalageSuperviseur',
    },
    {
      Header: 'NOMBRE STOCK MANQUANT (superviseur) ',
      accessor: 'manquantSuperviseur',
    },
    {
      Header: 'RAISON DECALAGE (superviseur) ',
      accessor: 'raisonSuperviseur',
    },
    {
      Header: ' ARTICLE AVEC DECALAGE STOCK (animatrice) ',
      accessor: 'decalageAnimatricer',
    },
    {
      Header: ' NOMBRE STOCK MANQUANT(animatrice) ',
      accessor: 'manquantAnimatrice',
    },
    {
      Header: ' RAISON DECALAGE (animatrice)',
      accessor: 'raisonAnimatrice',
    },
  ];

  const data = rawData.slice(Math.random() * 4, Math.random() * 10 + 5);
  return (
    <div className="page-padding" style={{ overflow: 'auto' }}>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="text-brand-darkBlue text-3xl font-semibold">
                  DETAIL DECALAGE ET RAISONS DE DECALAGE PAR UTILISATEUR
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="flex flex-wrap place-items-center activation-filters">
          <div className="w-1/2 overflow-hidden lg:w-1/3">
            <DateRange
              label={useFormatMessage('ActivationsDetails.card.period')}
              isForm
              fromText={useFormatMessage('Activation.informationsTab.from')}
              fromProps={{
                name: 'periodStart',
                setState: setDateSearch,
                date: new Date(dateSearch.periodStart),
              }}
              toText={useFormatMessage('Activation.informationsTab.to')}
              toProps={{
                name: 'periodEnd',
                setState: setDateSearch,
                date: new Date(dateSearch.periodEnd),
              }}
            />
          </div>
          <div className="w-1/2 overflow-hidden lg:w-1/3">
            <CSVLink
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                background: '#3273dc',
                color: '#fff',
                borderRadius: 9999,
                display: 'inline-block',
                padding: 3,
                backgroundColor: '#2699fb',
                marginLeft: 50,
                height: 'max-content',
                marginTop: '38px',
              }}
              data={data}
            >
              Télécharger Reporting
            </CSVLink>
          </div>
        </div>
      </section>
      <div className="flex">
        <div className="flex flex-col p-3">
          <div className="w-full reporting-table ">
            <Table
              tailwind="w-64 bg-black"
              columns={columns}
              data={data || []}
              loading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detaildecalage;
