import { createReducer } from 'redux-act';
import {
  PRODUCT_DETAILS_FETCH_DATA_INIT,
  PRODUCT_DETAILS_FETCH_DATA_SUCCESS,
  PRODUCT_DETAILS_FETCH_DATA_FAIL,
  PRODUCT_DETAILS_CLEAN_UP,
  PRODUCT_DETAILS_CLEAR_DATA,
} from 'state/actions/productDetails';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const productDetailsReducer = createReducer(
  {
    [PRODUCT_DETAILS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [PRODUCT_DETAILS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.productDetails,
      loading: false,
      error: null,
    }),
    [PRODUCT_DETAILS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRODUCT_DETAILS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [PRODUCT_DETAILS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
