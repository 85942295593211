import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFormatMessage, useFormatDate } from 'hooks';
import { Link } from 'react-router-dom';
import FilterSearch from 'components/Common/FilterSearch';
import { fetchTeams, deleteTeam, clearTeamsData } from 'state/actions/teams';
import { fetchUsers } from 'state/actions/users';
import ConfirmationModal from 'components/ConfirmationModal';
import { ClipLoader } from 'react-spinners';
import PageHeader from 'components/PageHeader';
import paths from 'pages/Router/paths';
import useFilterSearch from 'hooks/useFilterSearch';
import Button from 'components/Common/Button';
import Table from 'components/Table';
import sortByDate from 'utils/sortByDate';

const Teams = () => {
  const {
    usersList,
    usersLoading,
    teamsList,
    error,
    loading,
    deleted,
  } = useSelector(
    (state) => ({
      teamsList: state.teams.data,
      usersList: state.users.data,
      usersLoading: state.users.loading,
      error: state.teams.error,
      loading: state.teams.loading,
      deleted: state.teams.deleted,
    }),
    shallowEqual
  );
  const [deleteModal, setDeleteModal] = useState({
    teamId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchTeams());
    return () => dispatch(clearTeamsData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        teamId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (teamId) => {
    setDeleteModal((prevState) => ({
      teamId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ teamId: null, isOpen: false });
  };

  const onDeleteTeamHandler = () => {
    dispatch(deleteTeam(deleteModal.teamId));
  };

  const formatDisplayDate = (date) => {
    const splitedDate = date.split(' ');
    return `${splitedDate[0]} ${splitedDate[1]} ${splitedDate[2]} ${splitedDate[3]}`;
  };

  const deleteMessage = useFormatMessage('Teams.delete');

  const confirmMessage = useFormatMessage('Teams.confirm');

  const permDeleteMessage = useFormatMessage('Teams.permDelete');

  const cancelMessage = useFormatMessage('Teams.cancel');

  const tabledate = useFormatMessage('Teams.table.date');
  const tableIdNumber = useFormatMessage('Teams.table.idNumber');
  const tablePicture = useFormatMessage('Teams.table.picture');
  const tableName = useFormatMessage('Teams.table.name');
  const tableTeamId = useFormatMessage('Teams.table.teamId');
  const tableSupervisor = useFormatMessage('Teams.table.supervisor');
  const tableAnimators = useFormatMessage('Teams.table.animators');
  const tableAction = useFormatMessage('Teams.table.action');
  const tableActionEdit = useFormatMessage('Teams.table.action.edit');
  const tableActionDelete = useFormatMessage('Teams.table.action.delete');

  /**
   * by issam
   * @param {String} userId user id
   * @param {String} type supervisor/animator
   */
  const getUserName = (user, type) => {
    if (user) {
      const imageWithName = (
        <div className="py-2">
          {/* image  */}
          <div className="w-12 h-12 mb-2 rounded-full bg-gray-200">
            {user.logoUrl && (
              <img className="rounded-full" src={user.logoUrl} alt="" />
            )}
          </div>
          {/* then the name  */}
          <p className=""> {user.name}</p>
        </div>
      );
      if (type === 'supervisor') {
        // cell content for supervisor
        return <div key={user.id} className="supervisor-cell">{imageWithName}</div>;
      } else {
        // cell content for animator
        return <div key={user.id} className="animator-cell">{imageWithName}</div>;
      }
    } else {
      return null;
    }
  };
  const getIdNum = (user) => {
    if (user) {
      return (
        <div key={user.idNum} className="idNum-cell  flex items-center justify-center">
          <p>{user.idNum || '???'}</p>
        </div>
      );
    } else {
      return null;
    }
  };
  // table columns
  const columns = [
    {
      Header: tableTeamId,
      accessor: 'teamId',
    },
    {
      Header: tableName,
      accessor: 'name',
    },
    {
      Header: 'Groupes',
      accessor: 'groups',
      className: 'cell-with-table-inside',
      Cell({
        row: {
          original: { groups },
        },
      }) {
        return (
          <table className="">
            {
              <thead>
                <th>{tableSupervisor}</th>
                <th>{tableAnimators}</th>
                <th>{tableIdNumber}</th>
              </thead>
            }
            <tbody>
              {groups && groups.map((g, i) => (
                <tr key={i}>
                  {/* superviseur  */}
                  <td className="cell-with-multiple-rows">
                    {getUserName(g.supervisor, 'supervisor')}
                  </td>
                  {/* animateurs  */}
                  <td className="cell-with-multiple-rows">
                    {g.animators.map((anim) => getUserName(anim, 'animateur'))}
                  </td>
                  {/* Numero ID  */}
                  <td className="cell-with-multiple-rows">
                    {g.animators.map((anim) => getIdNum(anim))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      },
    },
    {
      Header: tabledate,
      accessor: 'createdAt',
      sortType: sortByDate,
    },
    {
      Header: tableAction,
      accessor: 'action',
      Cell({ row: { original: team } }) {
        return (
          <div className="flex justify-center">
            <Button
              isIconOnly
              className="mx-1"
              to={`/teams/${team.id}`}
              text={tableActionEdit}
              color="blue"
              size="small"
              icon="mdi-pencil"
            />

            <Button
              isIconOnly
              className="mx-1"
              onClick={() => onRemoveButtonClickHandler(team.id)}
              text={tableActionDelete}
              color="red"
              size="small"
              icon="mdi-delete"
            />
          </div>
        );
      },
    },
  ];
  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(teamsList || []);
  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteTeamHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title={useFormatMessage('Teams.title')} to={paths.ADD_TEAM} />
      <div className="page-padding">
        {/* fiters & table  */}
        <div className="">
          {/* filters  */}
          <div className="flex px-2">
            <FilterSearch
              hideSelect
              loading={loading}
              selectProps={selectProps}
              searchProps={searchProps}
            />
          </div>
          {/* table  */}
          <div className="p-2">
            <Table loading={loading} columns={columns} data={result} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
