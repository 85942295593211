import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import firebase from 'firebase.js';

export const REPORTINGS_FETCH_DATA_INIT = createAction(
  'REPORTINGS_FETCH_DATA_INIT'
);
export const REPORTINGS_FETCH_DATA_SUCCESS = createAction(
  'REPORTINGS_FETCH_DATA_SUCCESS'
);
export const REPORTINGS_FETCH_DATA_FAIL = createAction(
  'REPORTINGS_FETCH_DATA_FAIL'
);
export const REPORTINGS_CREATE_CONTACT_INIT = createAction(
  'REPORTINGS_CREATE_CONTACT_INIT'
);
export const REPORTINGS_CREATE_CONTACT_SUCCESS = createAction(
  'REPORTINGS_CREATE_CONTACT_SUCCESS'
);
export const REPORTINGS_CREATE_CONTACT_FAIL = createAction(
  'REPORTINGS_CREATE_CONTACT_FAIL'
);
export const REPORTINGS_CLEAR_DATA = createAction('REPORTINGS_CLEAR_DATA');
export const REPORTINGS_CLEAN_UP = createAction('REPORTINGS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const c = new Controller('notifications', 'notification');

/**
 * @return dispatch
 */
export const fetchReports = () => {
  return async (dispatch) => {
    dispatch(REPORTINGS_FETCH_DATA_INIT());
    let reportings;
    try {
      reportings = await firebase
        .firestore()
        .collection('notifications')
        .where('isProduct', '==', true)
        .get();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(REPORTINGS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      REPORTINGS_FETCH_DATA_SUCCESS({
        reportings: reportings.docs.map((notification) => notification.data()),
      })
    );
  };
};

export const reportingsCleanUp = () => (dispatch) =>
  dispatch(REPORTINGS_CLEAN_UP());

export const clearReportingsData = () => {
  return (dispatch) => {
    dispatch(REPORTINGS_CLEAR_DATA());
  };
};
