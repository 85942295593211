import Controller from './Controller';

export default class MarkController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'marks';
    this.item = 'mark';
  }

  /**
   * Get Mark data
   * @param {string} markId Mark ID
   * @returns {Object} object of Mark data or false
   */
  fetchById = async (markId) =>
    markId
      ? this.getDoc(markId).then((markSnap) => {
          if (markSnap.exists) {
            return {
              id: markSnap.id,
              ...markSnap.data(),
            };
          }
          return null;
        })
      : null;
}
