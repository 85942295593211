import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import firebase from 'firebase.js';
import { firebaseError } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';

import UserController from 'controllers/UserController';
import { AUTH_UPDATE_USER_DATA } from './auth';

export const AGENCIES_FETCH_DATA_INIT = createAction(
  'AGENCIES_FETCH_DATA_INIT'
);
export const AGENCIES_FETCH_DATA_SUCCESS = createAction(
  'AGENCIES_FETCH_DATA_SUCCESS'
);
export const AGENCIES_FETCH_DATA_FAIL = createAction(
  'AGENCIES_FETCH_DATA_FAIL'
);

export const AGENCIES_DELETE_AGENCY_INIT = createAction(
  'AGENCIES_DELETE_AGENCY_INIT'
);
export const AGENCIES_DELETE_AGENCY_SUCCESS = createAction(
  'AGENCIES_DELETE_AGENCY_SUCCESS'
);
export const AGENCIES_DELETE_AGENCY_FAIL = createAction(
  'AGENCIES_DELETE_AGENCY_FAIL'
);

export const AGENCIES_CLEAR_DATA = createAction('AGENCIES_CLEAR_DATA');

export const AGENCIES_CREATE_AGENCY_INIT = createAction(
  'AGENCIES_CREATE_AGENCY_INIT'
);
export const AGENCIES_CREATE_AGENCY_SUCCESS = createAction(
  'AGENCIES_CREATE_AGENCY_SUCCESS'
);
export const AGENCIES_CREATE_AGENCY_FAIL = createAction(
  'AGENCIES_CREATE_AGENCY_FAIL'
);

export const AGENCIES_MODIFY_AGENCY_INIT = createAction(
  'AGENCIES_MODIFY_AGENCY_INIT'
);
export const AGENCIES_MODIFY_AGENCY_SUCCESS = createAction(
  'AGENCIES_MODIFY_AGENCY_SUCCESS'
);
export const AGENCIES_MODIFY_AGENCY_FAIL = createAction(
  'AGENCIES_MODIFY_AGENCY_FAIL'
);

export const AGENCIES_CLEAN_UP = createAction('AGENCIES_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('users', 'user');
const uc = new UserController();

/**
 * fetch agencies
 * @return dispatch
 */
export const fetchAgencies = () => {
  return async (dispatch) => {
    dispatch(AGENCIES_FETCH_DATA_INIT());
    let users;
    let agencies;
    let agenciesList;
    let filtredAgencies;
    try {
      users = await gc.fetch();
      agencies = users.map(async (user) => {
        if (user.roleId) {
          const roleRef = await firebase
            .firestore()
            .collection('roles')
            .doc(user.roleId)
            .get();
          const role = roleRef.data();
          if (role.displayName === 'agence')
            return {
              ...user,
              roleName: role.displayName,
            };
        }
      });
      agenciesList = await Promise.all(agencies);
      filtredAgencies = agenciesList.filter((user) => {
        return user && user.roleName === 'agence';
      });
    } catch (error) {
      toastr.error('Agences', "Operation a échoué");
      return dispatch(AGENCIES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(AGENCIES_FETCH_DATA_SUCCESS({ agencies: filtredAgencies }));
  };
};

export const generateID = () => uc.genIdNum();

export const fetchRoleUsers = (roleId) => uc.roleUsers(roleId);

export const fetchAgenceRole = async () => {
  const role = await firebase
    .firestore()
    .collection('roles')
    .where('displayName', '==', 'agence')
    .get();
  return role;
};

export const createAgency = (data) => {
  return async (dispatch, getState) => {
    dispatch(AGENCIES_CREATE_AGENCY_INIT());
    const { locale } = getState().preferences;
    const { id, isAdmin: admin } = getState().auth.userData;

    let agency;
    try {
      agency = await uc.create(id, admin, data);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Agence', "la création a échoué");
      return dispatch(AGENCIES_CREATE_AGENCY_FAIL({ error: errorMessage }));
    }
    toastr.success('Agence', "créé avec succès");
    return dispatch(AGENCIES_CREATE_AGENCY_SUCCESS({ agency }));
  };
};

export const modifyAgency = (data) => {
  return async (dispatch, getState) => {
    dispatch(AGENCIES_MODIFY_AGENCY_INIT());
    const { locale } = getState().preferences;
    const { id, isProfile, isEditing } = data;
    // Update Logo

    let agency;
    try {
      const { logoUrl } = isProfile
        ? getState().auth.userData
        : getState()
            .agencies.data.filter((usr) => usr.id === id)
            .pop();
      const { uid } = firebase.auth().currentUser;

      agency = await uc.update(data, logoUrl);
      if (id === uid) {
        dispatch(AUTH_UPDATE_USER_DATA({ ...agency, id }));
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Agence', "mise à jour a échoué");
      return dispatch(AGENCIES_MODIFY_AGENCY_FAIL({ error: errorMessage }));
    }

    // show succuss message
    if (isProfile) {
      toastr.success('Profile', "mis à jour avec succès");
    } else if (isEditing) {
      toastr.success('Agence', "mis à jour avec succès");
    }
    return dispatch(AGENCIES_MODIFY_AGENCY_SUCCESS({ agency }));
  };
};

export const deleteAgency = (id) => {
  return async (dispatch, getState) => {
    dispatch(AGENCIES_DELETE_AGENCY_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .agencies.data.filter((user) => user.id === id)
      .pop();
    try {
      await uc.delete(id, logoUrl);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Agence', "la suppression a échoué");
      return dispatch(AGENCIES_DELETE_AGENCY_FAIL({ error: errorMessage }));
    }

    toastr.success('Agence', "Supprimé avec succès");
    return dispatch(AGENCIES_DELETE_AGENCY_SUCCESS({ id }));
  };
};

export const agenciesCleanUp = () => (dispatch) =>
  dispatch(AGENCIES_CLEAN_UP());

export const clearAgenciesData = () => {
  return (dispatch) => {
    dispatch(AGENCIES_CLEAR_DATA());
  };
};
