import Controller from './Controller';

export default class LevelController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'levels';
    this.item = 'level';
  }

  /**
   * Get Level data
   * @param {string} levelId Level ID
   * @returns {Object} object of Level data or false
   */
  fetchById = async (levelId) =>
    levelId
      ? this.getDoc(levelId).then((levelSnap) => {
          if (levelSnap.exists) {
            return {
              id: levelSnap.id,
              ...levelSnap.data(),
            };
          }
          return null;
        })
      : null;
}
