import React from 'react'

const TextArea = ({ label, style, ...props }) => {
      /**
     * @param {String} label : label for the input
     * @param {Object} style : regular styles object to style the container
     */
    return (
        <div className="p-2" style={style}>
            <label className="px-2 font-semibold text-brand-lightBlue" htmlFor={props.name || ""} >{label}</label>
            <div className="p-1 tw-textarea">
                <textarea className="px-3 py-2 rounded-lg border bg-brand-veryLightBlue border-blue-300 " {...props} />
            </div>
        </div>
    )
}

export default React.memo(TextArea)