import { createReducer } from 'redux-act';
import {
  CRITERIAS_FETCH_DATA_INIT,
  CRITERIAS_FETCH_DATA_SUCCESS,
  CRITERIAS_FETCH_DATA_FAIL,
  CRITERIAS_CREATE_CRITERIA_INIT,
  CRITERIAS_CREATE_CRITERIA_SUCCESS,
  CRITERIAS_CREATE_CRITERIA_FAIL,
  CRITERIAS_DELETE_CRITERIA_INIT,
  CRITERIAS_DELETE_CRITERIA_SUCCESS,
  CRITERIAS_DELETE_CRITERIA_FAIL,
  CRITERIAS_MODIFY_CRITERIA_INIT,
  CRITERIAS_MODIFY_CRITERIA_SUCCESS,
  CRITERIAS_MODIFY_CRITERIA_FAIL,
  CRITERIAS_CLEAN_UP,
  CRITERIAS_CLEAR_DATA,
} from 'state/actions/criterias';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const criteriasReducer = createReducer(
  {
    [CRITERIAS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CRITERIAS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.criterias,
      loading: false,
      error: null,
    }),
    [CRITERIAS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CRITERIAS_CREATE_CRITERIA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CRITERIAS_CREATE_CRITERIA_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.game, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [CRITERIAS_CREATE_CRITERIA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CRITERIAS_DELETE_CRITERIA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CRITERIAS_DELETE_CRITERIA_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [CRITERIAS_DELETE_CRITERIA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CRITERIAS_MODIFY_CRITERIA_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CRITERIAS_MODIFY_CRITERIA_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.criteria.id) {
            return {
              name: payload.criteria.name,
              displayName: payload.criteria.displayName,
              id: payload.id,
              createdAt: payload.criteria.createdAt,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [CRITERIAS_MODIFY_CRITERIA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CRITERIAS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [CRITERIAS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
