/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler, useFormatMessage } from 'hooks';
import Button from 'components/Common/Button';
import FileInput from 'components/Common/FileInput';
import SelectField from 'components/Common/SelectField';

import NormalInput from 'components/Common/NormalInput';
import NormalCheckbox from 'components/Common/NormalCheckbox';

import { marksCleanUp } from 'state/actions/marks';

const MarkForm = ({ markData, action, isEditing }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state) => ({
      loading: state.marks.loading,
    }),
    shallowEqual
  );
  const [mark, setMark] = useState(markData);

  useEffect(() => {
    return () => dispatch(marksCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setMark);

  const handleIsFavotireChange = () => {
    setMark((prevState) => ({
      ...prevState,
      isFavorite: !prevState.isFavorite,
    }));
  };

  // Start change handlers
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (event.target)
      setMark((prevState) => ({
        ...prevState,
        [name]: file,
        imageUrl: name === 'image' ? '' : prevState.imageUrl,
      }));
  };

  const canSubmit = mark.name;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(mark));
  };

  const imagePreviewUrl = !mark.imageUrl
    ? mark.image && URL.createObjectURL(mark.image)
    : mark.imageUrl;

  const sourceList = [
    {
      value: 'VFM SMT',
      label: 'VFM SMT',
    },
    {
      value: 'VFM Concurrence',
      label: 'VFM Concurrence',
    },
    {
      value: 'Prémium Concurrence',
      label: 'Prémium Concurrence',
    },
    {
      value: 'Fidélisation',
      label: 'Fidélisation',
    },
    {
      value: 'Premium SMT',
      label: 'Premium SMT',
    },
  ];

  const defaultSource = isEditing
    ? {
        value: mark.source,
        label: mark.source,
      }
    : {};

  const onSourceChange = ({ value }) => {
    setMark((prevState) => ({
      ...prevState,
      source: value,
    }));
  };

  return (
    <form
      className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
      onSubmit={onSubmitHandler}
    >
      {/* image  preview  */}
      {imagePreviewUrl && (
        <div className="w-full flex-none pb-4">
          <div className="is-user-avatar image has-max-width is-aligned-center">
            <img
              className="user-avatar"
              src={imagePreviewUrl}
              alt="User profile logo preview"
            />
          </div>
        </div>
      )}
      {/* end image preview  */}
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        <div className="lg:max-w-sm lg:w-1/2">
          <NormalInput
            className="flex-1 max-w-sm"
            label={useFormatMessage('Mark.markForm.name')}
            required
            name="name"
            value={mark.name}
            onChange={onChangeHandler}
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        <div className="lg:max-w-sm lg:w-1/2">
          <FileInput
            isForm
            label="Image"
            type="file"
            accept="image/*"
            name="image"
            file={mark.image}
            className={`${loading && 'is-loading'}`}
            onChange={onFileChangedHandler}
            disabled={loading}
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        <div className="lg:max-w-sm lg:w-1/2">
          <FileInput
            isForm
            label="Upload PDF (argumentaires)"
            type="file"
            name="pdf"
            accept="application/pdf"
            file={mark.pdf}
            className={`${loading && 'is-loading'}`}
            disabled={loading}
            onChange={onFileChangedHandler}
          />
        </div>
      </div>
      {isEditing && mark.pdfUrl && (
        <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
          <div className="lg:max-w-sm lg:w-1/2">
            <a
              href={mark.pdfUrl}
              className="px-2 font-semibold text-brand-lightBlue"
            >
              Fichier argumentaire
            </a>
          </div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        <div className="lg:max-w-sm lg:w-1/2">
          <SelectField
            options={sourceList}
            type="form"
            className="max-w-xs"
            label="Source"
            name="category"
            defaultValue={defaultSource}
            onChange={onSourceChange}
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        <div className="lg:max-w-sm lg:w-1/2">
          <NormalCheckbox
            className="flex-none"
            checked={mark.isFavorite}
            name="disabled"
            onChange={handleIsFavotireChange}
            label={useFormatMessage('Mark.markForm.prefered')}
          />
        </div>
      </div>
      <Button
        className={`mt-6 mx-4 ${loading && 'is-loading'}`}
        text={useFormatMessage('Mark.markForm.save')}
        icon="mdi-plus"
        color="green"
        size="large"
        disabled={!canSubmit}
        type="submit"
      />
    </form>
  );
};

export default MarkForm;
