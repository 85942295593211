import React, { useState, useEffect } from 'react';
import { fetchContacts, clearContactsData } from 'state/actions/contacts';
import PageHeader from 'components/PageHeader';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Table from 'components/Table';
import DatePicker from 'components/DatePicker';
import Button from 'components/Common/Button';
import FilterSearch from 'components/Common/FilterSearch';
import paths from 'pages/Router/paths';
import NormalCheckbox from 'components/Common/NormalCheckbox';
import useFilterSearch from 'hooks/useFilterSearch';
import fitsCriteria from 'utils/fitsCriteria';

import { useFormatMessage } from 'hooks';
import CenteredLoader from 'components/Common/Loader';
import sortByDate from 'utils/sortByDate';

function formatDisplayDate(date) {
  const dateObject = new Date(date);
  return `${dateObject.getDate()}/${
    dateObject.getMonth() + 1
  }/${dateObject.getFullYear()}`;
}

const ActivationsParticipants = () => {
  const [date, setDate] = useState({ date: new Date() });

  const { contactsList, loading } = useSelector(
    (state) => ({
      contactsList: state.contacts.data,
      loading: state.contacts.loading,
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual
  );

  const beConnected = useFormatMessage('ActivationsParticipants.beConnected');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContacts());
    return () => dispatch(clearContactsData());
  }, [dispatch]);

  const columns = [
    {
      Header: useFormatMessage('ActivationsParticipants.table.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('ActivationsParticipants.table.zone'),
      accessor: 'zone',
    },
    {
      Header: useFormatMessage('ActivationsParticipants.table.city'),
      accessor: 'ville',
    },
    {
      Header: useFormatMessage('ActivationsParticipants.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => formatDisplayDate(row.original.createdAt),
    },
    {
      Header: useFormatMessage('ActivationsParticipants.table.position'),
      accessor: 'positionName',
    },
    {
      Header: useFormatMessage('ActivationsParticipants.table.agency'),
      accessor: 'agenceName',
    },
    {
      Header: useFormatMessage('ActivationsParticipants.table.activation'),
      accessor: 'activationName',
    },
    {
      Header: useFormatMessage('ActivationsParticipants.table.beConnected'),
      accessor: 'Accepter_être_contacté',
      Cell({ row }) {
        if (row.original.connected) {
          return (
            <span className="icon text-brand-green">
              <i className="mdi mdi-check" />
            </span>
          );
        }
        return (
          <span className="icon text-brand-red">
            <i className="mdi mdi-close" />
          </span>
        );
      },
    },
  ];

  // filter search logic
  const { result, searchProps, selectProps } = useFilterSearch(contactsList, [
    {
      label: useFormatMessage('ActivationsParticipants.filter.position'),
      accessor: 'position.name',
    },
    {
      label: useFormatMessage('ActivationsParticipants.filter.agency'),
      accessor: 'agency.name',
    },
    {
      label: useFormatMessage('ActivationsParticipants.filter.city'),
      accessor: 'ville',
    },
    {
      label: useFormatMessage('ActivationsParticipants.filter.activation'),
      accessor: 'activation',
    },
  ]);

  // end filter search logic
  // checkbox logic
  const [acceptedContactCheckbox, setAcceptedContactCheckbox] = useState({
    checked: false,
    evaluate: (data) => data.connected,
  });
  const handleCheckBoxState = (prevState) => ({
    ...prevState,
    checked: !prevState.checked,
  });
  // end checkbox logic
  // Data is the end result, after evaluating all filters
  const data = result.filter((el) =>
    fitsCriteria(el, [acceptedContactCheckbox])
  );

  return (
    <>
      <PageHeader
        title={useFormatMessage('ActivationsParticipants.participants')}
        to={paths.ADD_CONTACT}
      />
      <div className="page-padding">
        {/* table and filters  */}
        <div className="">
          {/* filters  */}
          {loading ? (
            <CenteredLoader noPadding />
          ) : (
            <div className="flex justify-between">
              <div className="flex">
                <FilterSearch
                  selectProps={selectProps}
                  searchProps={searchProps}
                />
              </div>
              <div className="flex items-center">
                <DatePicker
                  name="date"
                  setState={setDate}
                  date={new Date(date.date)}
                  isFilter
                />
                <NormalCheckbox
                  isFilter
                  label={beConnected}
                  checked={acceptedContactCheckbox.checked}
                  onChange={(e) =>
                    setAcceptedContactCheckbox(handleCheckBoxState)
                  }
                />
              </div>
            </div>
          )}
          {/* table  */}
          <div className="">
            <Table columns={columns} data={data} loading={loading} />
          </div>
        </div>
        {/* results count & a download button */}
        <div className="py-6 flex justify-between items-center sm:hidden">
          {/* results count  */}
          <p className="text-2xl text-brand-darkBlue">
            <span className="font-semibold text-brand-lightGreen">256 </span>{' '}
            {useFormatMessage('ActivationsParticipants.resultFound')}
          </p>
          {/* download link  */}
          <Button
            className="font-semibold"
            color="blue"
            size="medium"
            icon="mdi-attachment"
            text={useFormatMessage('ActivationsParticipants.downloadSection')}
          />
        </div>
      </div>
    </>
  );
};

export default ActivationsParticipants;
