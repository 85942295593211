import React, { useState, useEffect } from 'react';
import {
  createPosCategory,
  modifyPosCategory,
} from 'state/actions/posCategories';
import PosCategoryForm from 'components/PosCategoryForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

import { useFormatMessage } from 'hooks';

const PosCategory = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.posCategories.success,
    }),
    shallowEqual
  );
  const [posCategory, setPosCategory] = useState({ name: '' });

  useEffect(() => {
    const fetchPosCategoryData = async () => {
      const response = await firebase
        .firestore()
        .collection('posCategories')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchPosCategoryData()
        .then((posCategoryData) => {
          setPosCategory({
            ...posCategoryData.data(),
            id,
          });
        })
        .catch(() => {
          setPosCategory({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const posCategoryForm =
    !posCategory.name && id ? (
      <ClipLoader />
    ) : (
      <PosCategoryForm
        isEditing={isEditing}
        posCategoryData={posCategory}
        action={isEditing ? modifyPosCategory : createPosCategory}
      />
    );

  const redirect = (posCategory.error || success) && (
    <Redirect to={paths.POSITIONS_CATEGORIES} />
  );

  return (
    <>
      {redirect}
      <PageHeader
        title={useFormatMessage('PositionsCategory.title')}
        hideButton
      />
      <div className="page-padding">{posCategoryForm}</div>
    </>
  );
};

export default PosCategory;
