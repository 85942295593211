/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import NormalInput from 'components/Common/NormalInput';
import FileInput from 'components/Common/FileInput';
import Button from 'components/Common/Button';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { fetchAgenceRole, clearAgenciesData } from 'state/actions/agencies';
import paths from 'pages/Router/paths';
import { fetchRoles } from 'state/actions/roles';
import { useCan, useChangeHandler, useFormatMessage } from 'hooks';
import { validateEmail } from 'utils';
import './UserForm.scss';

const AgencyForm = ({ isEditing, userData, action }) => {
  const { loading, isAdmin } = useSelector(
    (state) => ({
      loading: state.agencies.loading,
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const authorize = useCan([
    'create-member',
    'update-member',
    'create-user',
    'edit-user',
  ]);

  useEffect(() => {
    dispatch(fetchRoles());
    return () => dispatch(clearAgenciesData());
  }, [dispatch]);

  const [user, setUser] = useState(userData);

  const onChangeHandler = useChangeHandler(setUser);

  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setUser((prevState) => ({ ...prevState, file, logoUrl: null }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    fetchAgenceRole().then((role) => {
      dispatch(action({ ...user, roleId: role.docs[0].id, isEditing }));
    });
  };

  let emailInput = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  const invalidEmail = user.email && !validateEmail(user.email);

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  if (invalidEmail) {
    emailInput = {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: invalidEmailMessage,
      },
    };
  }

  const canSubmit =
    user.name && user.email && !invalidEmail && user.adresse && user.location;

  const imagePreviewUrl = !user.logoUrl
    ? user.file && URL.createObjectURL(user.file)
    : user.logoUrl;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');

  const emailMessage = useFormatMessage('UserForm.email');

  const adminMessage = useFormatMessage('UserForm.admin');

  const rolesMessage = useFormatMessage('Roles.roles');

  const idNumMsg = useFormatMessage('UserForm.idNum');

  const redirect = !authorize && <Redirect to={paths.ROOT} />;

  // another field 'adresse' was added
  // not dynamic yet
  return (
    <>
      {redirect}
      <form
        onSubmit={onSubmitHandler}
        className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
      >
        {/* logo preview  */}
        {imagePreviewUrl && (
          <div className="w-full flex-none pb-4">
            <div className="is-user-avatar image has-max-width is-aligned-center">
              <img
                className="user-avatar"
                src={imagePreviewUrl}
                alt="User profile logo preview"
              />
            </div>
          </div>
        )}
        {/* end logo preview  */}
        <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
          {/* id  */}
          <div className=" lg:max-w-sm lg:w-1/2">
            <NormalInput
              label={idNumMsg}
              id="idNum"
              type="text"
              required
              disabled
              name="idNum"
              value={user.idNum}
            />
          </div>
          {/* end id  */}
          {/* name  */}
          <div className=" lg:max-w-sm lg:w-1/2">
            <NormalInput
              label={useFormatMessage('UserForm.name')}
              id="name"
              type="text"
              required
              name="name"
              value={user.name}
              onChange={onChangeHandler}
            />
          </div>

          {/* location  */}
          <div className=" lg:max-w-sm lg:w-1/2">
            <NormalInput
              // label={useFormatMessage('UserForm.location')}
              label="Ville"
              className=""
              type="text"
              name="location"
              required
              value={user.location}
              onChange={onChangeHandler}
            />
          </div>
          {/* end location  */}
          {/* location  */}
          <div className=" lg:max-w-sm lg:w-1/2">
            <NormalInput
              label="Adresse"
              name="adresse"
              type="text"
              required
              value={user.adresse || ''}
              onChange={onChangeHandler}
            />
          </div>
          {/* end location  */}
          {/* mail  */}
          {isAdmin && (
            <div className=" lg:max-w-sm lg:w-1/2">
              {isEditing ? (
                <NormalInput
                  label={emailMessage}
                  type="text"
                  readOnly="readOnly"
                  value={user.email}
                />
              ) : (
                <div className="">
                  <NormalInput
                    label={emailMessage}
                    className={`${emailInput.modifier}`}
                    type="email"
                    required
                    name="email"
                    value={user.email}
                    onChange={onChangeHandler}
                  />
                  {emailInput.message.content && (
                    <p
                      className={`help is-${emailInput.message.modifier} px-4`}
                    >
                      {emailInput.message.content}
                    </p>
                  )}
                </div>

                // {/* // end email */}
              )}
            </div>
          )}
          {/* image upload  */}
          <div className=" lg:max-w-sm lg:w-1/2">
            <FileInput
              isForm
              label="Image"
              type="file"
              accept="image/*"
              file={user.file}
              className={`${loading && 'is-loading'}`}
              disabled={loading}
              onChange={onFileChangedHandler}
            />
          </div>

          {/* end image upload  */}
        </div>
        {/* actions  */}
        <div className="flex w-full max-w-md lg:w-1/3 lg:items-end lg:justify-end items-center justify-end">
          <div className="">
            {/* submit  */}
            <Button
              type="submit"
              className={`${loading && 'is-loading'}`}
              disabled={!canSubmit || loading}
              color="green"
              size="large"
              icon="mdi-plus-circle"
              text={useFormatMessage('UserForm.submit')}
            />
            {/* end submit  */}
          </div>
        </div>
      </form>
    </>
  );
};

AgencyForm.propTypes = {
  isEditing: PropTypes.bool,
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default AgencyForm;
