/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useFormatMessage } from 'hooks';
import Button from 'components/Common/Button';
import FileInput from 'components/Common/FileInput';
import NormalCheckbox from 'components/Common/NormalCheckbox';

import { alatigesCleanUp } from 'state/actions/alatige';

const AlatigeForm = ({ alatigeData, action, isEditing }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state) => ({
      loading: state.alatiges.loading,
    }),
    shallowEqual
  );
  const [alatige, setAlatige] = useState(alatigeData);

  useEffect(() => {
    return () => dispatch(alatigesCleanUp());
  }, [dispatch]);

  // Start change handlers
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (event.target)
      setAlatige((prevState) => ({
        ...prevState,
        [name]: file,
        imageUrl: name === 'image' ? '' : prevState.imageUrl,
      }));
  };

  const handleisActiveChange = () => {
    setAlatige((prevState) => ({
      ...prevState,
      isActive: !prevState.isActive,
    }));
  };
  const canSubmit = alatige.pdf || alatige.pdfUrl;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(alatige));
  };

  return (
    <form
      className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
      onSubmit={onSubmitHandler}
    >
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        <div className="lg:max-w-sm lg:w-1/2">
          <FileInput
            isForm
            label="Upload PDF (argumentaires)"
            type="file"
            name="pdf"
            accept="application/pdf"
            file={alatige.pdf}
            className={`${loading && 'is-loading'}`}
            disabled={loading}
            onChange={onFileChangedHandler}
          />
        </div>
      </div>
      {isEditing && (
        <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
          <div className="lg:max-w-sm lg:w-1/2">
            <a
              href={alatige.pdfUrl}
              className="px-2 font-semibold text-brand-lightBlue"
            >
              Fichier argumentaire
            </a>
          </div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        <div className="lg:max-w-sm lg:w-1/2">
          <NormalCheckbox
            className="flex-none"
            checked={alatige.isActive}
            name="disabled"
            onChange={handleisActiveChange}
            label={useFormatMessage('Multimark.form.active')}
          />
        </div>
      </div>

      <Button
        className={`mt-6 mx-4 ${loading && 'is-loading'}`}
        text={useFormatMessage('Mark.markForm.save')}
        icon="mdi-plus"
        color="green"
        size="large"
        disabled={!canSubmit}
        type="submit"
      />
    </form>
  );
};

export default AlatigeForm;
