import { createReducer } from 'redux-act';
import {
  REFERENCES_FETCH_DATA_INIT,
  REFERENCES_FETCH_DATA_SUCCESS,
  REFERENCES_FETCH_DATA_FAIL,
  REFERENCES_CREATE_REFERENCE_INIT,
  REFERENCES_CREATE_REFERENCE_SUCCESS,
  REFERENCES_CREATE_REFERENCE_FAIL,
  REFERENCES_DELETE_REFERENCE_INIT,
  REFERENCES_DELETE_REFERENCE_SUCCESS,
  REFERENCES_DELETE_REFERENCE_FAIL,
  REFERENCES_MODIFY_REFERENCE_INIT,
  REFERENCES_MODIFY_REFERENCE_SUCCESS,
  REFERENCES_MODIFY_REFERENCE_FAIL,
  REFERENCES_CLEAN_UP,
  REFERENCES_CLEAR_DATA,
} from 'state/actions/references';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const referencesReducer = createReducer(
  {
    [REFERENCES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [REFERENCES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.references,
      loading: false,
      error: null,
    }),
    [REFERENCES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [REFERENCES_CREATE_REFERENCE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [REFERENCES_CREATE_REFERENCE_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.game, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [REFERENCES_CREATE_REFERENCE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [REFERENCES_DELETE_REFERENCE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [REFERENCES_DELETE_REFERENCE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [REFERENCES_DELETE_REFERENCE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [REFERENCES_MODIFY_REFERENCE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [REFERENCES_MODIFY_REFERENCE_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.reference.id) {
            return {
              name: payload.reference.name,
              displayName: payload.reference.displayName,
              imageUrl: payload.reference.imageUrl,
              argUrl: payload.reference.argUrl,
              brand: payload.reference.brand,
              isMultiMark: payload.reference.isMultiMark,
              id: payload.id,
              createdAt: payload.reference.createdAt,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [REFERENCES_MODIFY_REFERENCE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [REFERENCES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [REFERENCES_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
