import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
import { isArray } from '@amcharts/amcharts4/core';

/* eslint-disable camelcase */
import ContactController from 'controllers/ContactController';

export const CONTACTS_FETCH_DATA_INIT = createAction(
  'CONTACTS_FETCH_DATA_INIT'
);
export const CONTACTS_FETCH_DATA_SUCCESS = createAction(
  'CONTACTS_FETCH_DATA_SUCCESS'
);
export const CONTACTS_FETCH_DATA_FAIL = createAction(
  'CONTACTS_FETCH_DATA_FAIL'
);
export const CONTACTS_CREATE_CONTACT_INIT = createAction(
  'CONTACTS_CREATE_CONTACT_INIT'
);
export const CONTACTS_CREATE_CONTACT_SUCCESS = createAction(
  'CONTACTS_CREATE_CONTACT_SUCCESS'
);
export const CONTACTS_CREATE_CONTACT_FAIL = createAction(
  'CONTACTS_CREATE_CONTACT_FAIL'
);
export const CONTACTS_CLEAR_DATA = createAction('CONTACTS_CLEAR_DATA');
export const CONTACTS_CLEAN_UP = createAction('CONTACTS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const cc = new ContactController('contacts', 'contact');

/**
 * Action contacts
 * @return dispatch
 */
export const fetchContacts = (activationId) => {
  return async (dispatch) => {
    dispatch(CONTACTS_FETCH_DATA_INIT());
    let contacts;
    try {
      contacts = isArray(activationId)
        ? await cc.fetchByIds(activationId)
        : await cc.fetch(activationId);
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(CONTACTS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      CONTACTS_FETCH_DATA_SUCCESS({
        contacts:
          isArray(activationId) && contacts.length > 0 ? contacts[0] : contacts,
      })
    );
  };
};

/**
 * Action Create new contact
 * @param {string} name Name of the contact
 * @param {string} positionId contact positionId
 * @param {string} ville contact ville
 * @param {string} agenceId contact agenceId
 * @param {string} activationId contact activationId
 * @param {string} date contact date
 * @param {string} connected to be connected
 * @return dispatch
 */
export const createContact = ({
  name,
  positionId,
  ville,
  agenceId,
  activationId,
  date,
  connected,
}) => {
  return async (dispatch, getState) => {
    dispatch(CONTACTS_CREATE_CONTACT_INIT());
    const { locale } = getState().preferences;
    let contact;
    try {
      const displayName = displayText(name);
      contact = await cc.create(
        {
          name,
          displayName,
          positionId,
          ville,
          agenceId,
          activationId,
          date,
          connected,
        },
        'displayName'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Contact', 'la création a échoué');
      return dispatch(CONTACTS_CREATE_CONTACT_FAIL({ error: errorMessage }));
    }
    toastr.success('Contact', 'créé avec succès');
    return dispatch(CONTACTS_CREATE_CONTACT_SUCCESS({ contact }));
  };
};

export const contactsCleanUp = () => (dispatch) =>
  dispatch(CONTACTS_CLEAN_UP());

export const clearContactsData = () => {
  return (dispatch) => {
    dispatch(CONTACTS_CLEAR_DATA());
  };
};
