import React, { useState, useEffect } from 'react';
import readXlsxFile, { parseExcelDate } from 'read-excel-file';
import { toastr } from 'react-redux-toastr';
import paths from 'pages/Router/paths';
import Table from 'components/Table';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import Button from 'components/Common/Button';
import {
  fetchPositions,
  clearPositionsData,
  deletePosition,
} from 'state/actions/positions';
import { useCan, useFormatMessage } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import useFilterSearch from 'hooks/useFilterSearch';
import classnames from 'classnames';
import FileInput from 'components/Common/FileInput';
import PositionController from 'controllers/PositionController';
import firebase from 'firebase.js';

const moment = require('moment');

const Positions = () => {
  const authorizeView = useCan(['view-agency-positions']);
  const authorizeCRUD = useCan([
    'create-position',
    'edit-position',
    'delete-position',
  ]);

  const { isAdmin, userID, positionsList, loading, deleted } = useSelector(
    (state) => ({
      success: state.positions.success,
      positionsList: state.positions.data,
      isAdmin: state.auth.userData.isAdmin,
      userID: state.auth.userData.id,
      error: state.positions.error,
      loading: state.positions.loading,
      deleted: state.positions.deleted,
    }),
    shallowEqual
  );
  const [deleteModal, setDeleteModal] = useState({
    positionId: null,
    isOpen: false,
  });
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showErrors, setShowErrors] = useState([]);
  const [uploadMessage, setUploadMessage] = useState({ done: 0, exists: 0 });

  const dispatch = useDispatch();

  useEffect(() => {
    const agencyId = authorizeView && !isAdmin ? userID : null;
    dispatch(fetchPositions(agencyId));
    return () => dispatch(clearPositionsData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        positionId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearPositionsData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (positionId) => {
    setDeleteModal((prevState) => ({
      positionId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ positionId: null, isOpen: false });
  };

  const onDeletePositionHandler = () => {
    dispatch(deletePosition(deleteModal.positionId));
  };

  /**
   * ************************************************************************************
   * Create positions with upload files
   * if position exsits on positions root collection it will return position ID
   * if not exists it will sotre the position on root collection then recover the position ID
   * *****************************************************************************************
   */
  const onUpload = () => {
    // Get the Id and Licence of existed
    const actPoses = positionsList.map((actPos) => ({
      id: actPos.id,
      licence: actPos.matricule,
    }));
    // Schema to check the excel file columns
    const schema = {
      /* Check if this row of licence number exsists on "positions" array
       * if true the value of this row will be the id of "position"
       * if false return just the licence number of "position"
       * then check if returned value includes on "positions Ids"
       * if row return the "licence number" the postion will added to positons root collection
       * */
      Licence: {
        prop: 'licence',
        type: String,
        required: true,
        parse(value) {
          const actPosition = actPoses.find(
            (pos) => String(pos.licence) === String(value)
          );
          return actPosition ? actPosition.id : value;
        },
      },
      Region: {
        prop: 'region',
        type: String,
      },
      Zone: {
        prop: 'zone',
        type: String,
      },
      Secteur: {
        prop: 'sector',
        type: String,
      },
      Ville: {
        prop: 'city',
        type: String,
      },
      Adresse: {
        prop: 'address',
        type: String,
      },
    };

    // Set button to upload file
    const input = document.getElementById('input');

    // On upload file Event
    input.addEventListener('change', () => {
      // Initialize Loading & Empty the Array of errors on upload event
      setShowErrors([]);
      setUploadLoading(true);
      // Read the file rows with schema
      readXlsxFile(input.files[0], { schema })
        .then(async ({ rows, errors }) => {
          if (errors.length > 0) {
            setShowErrors(errors);
          } else {
            const pc = new PositionController();
            let createdPos = [];
            let done = 0;
            let exists = 0;
            const setPositionsPromises = rows.map(async (row) => {
              const pos = {
                name: `Position ${row.licence}`,
                region: row.region || '',
                zone: row.zone || '',
                sector: row.sector || '',
                matricule: String(row.licence),
                city: row.city || '',
                address: row.address || '----',
                status: 'INITIAL',
              };
              // create new Position if is not exicts
              if (
                !positionsList
                  .map((posItem) => posItem.id)
                  .includes(row.licence) &&
                !createdPos.includes(row.licence)
              ) {
                createdPos = [...createdPos, row.licence];
                await pc.create(pos);
                done = done + 1;
              } else {
                await firebase
                  .firestore()
                  .collection('positions')
                  .doc(row.licence)
                  .update({
                    address: pos.address,
                    city: pos.city,
                    sector: pos.sector,
                    status: pos.status,
                    zone: pos.zone,
                    updatedAt: new Date().toGMTString(),
                  });
                exists = exists + 1;
              }
            });
            await Promise.all(setPositionsPromises);
            return { done, exists };
          }
        })
        .then((data) => {
          toastr.success(
            'Positions',
            `(${data.done}) créés et (${data.exists}) existaient`
          );
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    });
  };

  // Function to show errors related to excel file
  function ParseExcelError(error) {
    const { value } = error;
    return (
      <p>
        <code>&quot;{error.error}&quot;</code>
        {' pour la valeur '}
        <code>&quot;{value}&quot;</code>
        {' dans la colonne '}
        <code>&quot;{error.column}&quot;</code>
        {error.type && ' de type '}
        {error.type && <code>&quot;{error.type.name}&quot;</code>}
        {' dans la ligne'}
        <code>&quot;{error.row}&quot;</code>
      </p>
    );
  }
  /**
   * *********************
   * End Uploading part
   * *********************
   */
  const deleteMessage = useFormatMessage('Positions.delete');
  const confirmMessage = useFormatMessage('Positions.confirm');
  const permDeleteMessage = useFormatMessage('Positions.permDelete');
  const cancelMessage = useFormatMessage('Positions.cancel');

  let columns = [
    {
      Header: 'Licence',
      Cell: ({ row }) =>
        isAdmin
          ? row.original.matricule
          : (row.original.position || {}).matricule,
    },
    {
      Header: 'Région',
      Cell: ({ row }) =>
        (isAdmin
          ? row.original.region
          : (row.original.position || {}).region) || '------',
    },
    {
      Header: 'Zone',
      Cell: ({ row }) =>
        (isAdmin ? row.original.zone : (row.original.position || {}).zone) ||
        '------',
    },
    {
      Header: 'Secteur',
      Cell: ({ row }) =>
        (isAdmin
          ? row.original.sector
          : (row.original.position || {}).sector) || '------',
    },
    !isAdmin && {
      Header: 'Equipe',
      Cell: ({ row }) => (row.original.team || {}).name || '-----',
    },
    !isAdmin && {
      Header: 'N°équipe',
      Cell: ({ row }) => (row.original.team || {}).teamId || '-----',
    },
    {
      Header: 'Localization',
      Cell: ({ row }) => (
        <div className="">
          {isAdmin ? row.original.city : row.original.position.city || '------'}
          <span className="icon text-brand-lightBlue">
            <i className="mdi mdi-map-marker" />
          </span>
        </div>
      ),
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <div className="flex">
          {/* consulting/details route available only for agency  */}
          {!isAdmin && (
            <Button
              to={`/positions/${row.original.position.id}/details`}
              className="mx-1"
              text="Consulter"
              isIconOnly
              size="small"
              icon="mdi-chevron-right"
              color="blue"
            />
          )}
          {/* delete & modify actions, available only for smt  */}
          {isAdmin && (
            <>
              <Button
                to={`/positions/${row.original.id}`}
                className="mx-1"
                text="Modifier"
                isIconOnly
                size="small"
                icon="mdi-pencil"
                color="blue"
              />
              <Button
                className="mx-1"
                text="Supprimer"
                isIconOnly
                size="small"
                icon="mdi-delete"
                color="red"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
              />
            </>
          )}
        </div>
      ),
    },
  ];
  // clean columns from falsy values
  columns = columns.filter((col) => col);
  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(positionsList, [
    {
      // for smt & agency.
      label: 'Region',
      accessor: 'region',
    },
    {
      // for smt & agency.
      label: 'Ville',
      accessor: 'city',
    },
    {
      // for smt only.
      label: 'Secteur',
      accessor: 'sector',
    },
    {
      // for smt only.
      label: 'Zone',
      accessor: 'zone',
    },
    {
      label: 'Matricule',
      accessor: 'matricule',
    },
  ]);

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeletePositionHandler}
          onCancel={onCloseModalHandler}
        />
      )}

      <PageHeader
        title="Positions en cours"
        to={paths.ADD_POSITION}
        hideButton={!authorizeCRUD}
      />

      <div className="page-padding">
        {/* title & active rate  */}
        <div className="flex justify-between">
          {/* this part is hidden until it becomes dynamic  */}
          <div className="p-2 hidden">
            <span className="text-4xl text-brand-lightGreen">225</span>
            <span className="p-2 text-gray-500 text-lg">active sur</span>
            <span className="text-lg">245</span>
          </div>
          {/* end */}
        </div>
        {/* table and filters  */}
        {/* filters  */}
        <div className="flex items-center justify-between px-2">
          <FilterSearch
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
          {!loading && (
            <div className="flex">
              <FileInput
                id="input"
                label="Upload positions"
                isSmall
                onClick={() => onUpload()}
              />
            </div>
          )}
          {/* link to all positions  */}
          {!isAdmin && (
            <Button
              className="text-brand-darkBlue"
              to={paths.POSITIONS_ALL}
              icon="mdi-arrow-right"
              text="Tous les positions"
            />
          )}
        </div>
        {/* table  */}
        <div className="p-2">
          {!loading &&
            showErrors.length > 0 &&
            showErrors.map((err) => ParseExcelError(err))}
          {!isAdmin ? (
            positionsList.map((posList) => (
              <>
                <h3 className="text-brand-darkBlue text-2xl font-semibold">
                  Activation : {posList.name}
                </h3>
                <div style={{ overflow: 'auto' }}>
                  <Table
                    columns={columns}
                    data={posList.positions || []}
                    loading={loading}
                  />
                </div>
              </>
            ))
          ) : (
            <Table columns={columns} data={result} loading={loading} />
          )}
        </div>
      </div>
    </>
  );
};

export default Positions;
