import firebase from 'firebase.js';

export default class Controller {
  constructor(collection, item) {
    this.collection = collection;
    this.item = item;
  }

  /**
   * Fetch collection data with pagination
   */
  fetch = () =>
    firebase
      .firestore()
      .collection(this.collection)
      .orderBy('createdAt', 'desc')
      .get()
      .then((snaps) => {
        if (snaps.size > 0) {
          return snaps.docs.map((value) => ({
            id: value.id,
            ...value.data(),
          }));
        }
        return [];
      });

  /**
   * Get document by ID
   * @param {string} collection name of collection
   * @param {string} docId document ID
   */
  getDoc = (docId, col = this.collection) =>
    firebase.firestore().collection(col).doc(docId).get();

  getSubDoc = (docId, subColl, subId, col = this.collection) =>
    firebase
      .firestore()
      .collection(col)
      .doc(docId)
      .collection(subColl)
      .doc(subId)
      .get();
  /**
   * Get document by ID
   * @param {string} collection name of collection
   * @param {string} docId document ID
   */
  fetchSubColl = (docId, subColl, col = this.collection) =>
    firebase.firestore().collection(col).doc(docId).collection(subColl).get();

  /**
   * Add new item to collection
   * @param {object} data to add
   * @param {any} init action to init
   * @param {any} success action on success
   * @param {any} fail action on fail
   */
  create = async (data, unique = null) => {
    const exists = unique ? await this.exists(unique, data[unique]) : null;
    if (!exists)
      return firebase
        .firestore()
        .collection(this.collection)
        .add({
          ...data,
          createdAt: new Date().toGMTString(),
          updatedAt: new Date().toGMTString(),
        })
        .then((ref) =>
          ref.get().then((snap) => ({ id: snap.id, ...snap.data() }))
        );
    throw new Error('This item already exists');
  };

  /**
   * Set New document with ID and data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  setDoc = (docId, data, col = this.collection) =>
    firebase.firestore().collection(col).doc(docId).set(data);

  /**
   * Set New subcollection document with ID and data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  setSubDoc = (docId, subCol, subId, data, col = this.collection) =>
    firebase
      .firestore()
      .collection(col)
      .doc(docId)
      .collection(subCol)
      .doc(subId)
      .set(data);

  /**
   * Add New document with data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  addDoc = (data) => firebase.firestore().collection(this.collection).add(data);

  /**
   * Add New document with data to Sub Collection
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  addSubDoc = (docId, subCollection, data) =>
    firebase
      .firestore()
      .collection(this.collection)
      .doc(docId)
      .collection(subCollection)
      .add(data);

  /**
   * Update item from  collection
   * @param {string} id of item
   * @param {*} data to update
   * @param {*} init initialize ation
   * @param {*} success on success action
   * @param {*} fail on failed action
   */
  update = async (id, data, unique = null) => {
    const exists = unique ? await this.exists(unique, data[unique]) : null;
    if (!exists) {
      const ref = await firebase
        .firestore()
        .collection(this.collection)
        .doc(id);
      const response = await ref
        .update({
          ...data,
          updatedAt: new Date().toGMTString(),
        })
        .then(() =>
          ref.get().then((snap) => ({ id: snap.id, ...snap.data() }))
        );
      return response;
    }
    throw new Error('This item already exists');
  };

  /**
   * Set Update document with ID and data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  updateDoc = (docId, data) =>
    firebase.firestore().collection(this.collection).doc(docId).update(data);

  /**
   * Set Update document with ID and data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  updateSubDoc = (docId, subColl, subId, data) =>
    firebase
      .firestore()
      .collection(this.collection)
      .doc(docId)
      .collection(subColl)
      .doc(subId)
      .update(data);

  /**
   * Delete Item from Collection
   * @param {string} id of item
   * @param {any} init initialize action
   * @param {any} fail on fail action
   * @param {any} remove on delete success
   */
  destroy = (id) =>
    firebase.firestore().collection(this.collection).doc(id).delete();

  /**
   * Remove document by ID
   * @param {string} docId document ID
   * @returns Promise
   */
  deleteSubDoc = (docId, subColl, subId) =>
    firebase
      .firestore()
      .collection(this.collection)
      .doc(docId)
      .collection(subColl)
      .doc(subId)
      .delete();

  /**
   * Check if unique value exixt on collection
   * @param {Object} data
   * @returns boolean
   */
  exists = (key, value) =>
    firebase
      .firestore()
      .collection(this.collection)
      .where(key, '==', value)
      .get()
      .then((snap) => snap.exists);

  /**
   * Get file path from storage
   * @param {string} uid user id
   * @param {string} file file path
   * @returns {string} file path
   */
  getFileUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/${this.collection}%2F${uid}_200x200.${fileExtension}?alt=media`;
  };

  /**
   * Upload file path from storage
   * @param {string} uid user id
   * @param {string} file file path
   * @returns Promise
   */
  uploadFile = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`${this.collection}/${fileName}`).put(file);
  };

  /**
   * Delete file path from storage
   * @param {string} file file path
   * @returns Promise
   */
  deleteFile = (oldFile) => {
    if (!oldFile.includes('firebasestorage')) {
      return null;
    }
    const FilePath = oldFile
      .split(`${this.collection}%2F`)
      .pop()
      .split('?alt=media')
      .shift();
    return firebase.storage().ref(`${this.collection}/${FilePath}`).delete();
  };

  /**
   * Generate random string
   * @param {int} length number of chars
   * @returns {string} text
   */
  makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  /**
   * Generate unique ID
   * @returns {string} Generated ID
   */
  genIdNum = async () => {
    const idNum = this.makeid(5);
    return (await this.exists('idNum', idNum)) ? this.genCode() : idNum;
  };
}
