/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import NormalCheckbox from 'components/Common/NormalCheckbox';
import firebase from 'firebase.js';
import { useFormatMessage, useChangeHandler } from 'hooks';
import { positionsCleanUp } from 'state/actions/positions';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

const PositionForm = ({ positionData, action, isEditing }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state) => ({
      loading: state.positions.loading,
    }),
    shallowEqual
  );
  const [position, setPosition] = useState(positionData);
  const [categoriesList, setCategoriesList] = useState([]);
  const [editingState, setEditingState] = useState(isEditing);

  useEffect(() => {
    return () => dispatch(positionsCleanUp());
  }, [dispatch]);

  useEffect(() => {
    firebase
      .firestore()
      .collection('posCategories')
      .get()
      .then((categoryRef) => {
        const categories = categoryRef.docs.map((categorySnap) => {
          return {
            value: categorySnap.id,
            label: categorySnap.data().name,
          };
        });
        setCategoriesList(categories);
      });
  }, []);

  const onChangeHandler = useChangeHandler(setPosition);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(position));
    setPosition({
      name: '',
      region: '',
      zone: '',
      sector: '',
      startHour: '',
      cumulHour: '',
      matricule: '',
      location: '',
      city: '',
      address: '',
      status: 'INITIAL',
    });
    setEditingState(false);
  };

  const onCategoryChange = ({ value, label }) => {
    setPosition((prevState) => ({
      ...prevState,
      categoryId: value,
      categorie: label,
    }));
  };

  const onStatusChange = ({ value }) => {
    setPosition((prevState) => ({
      ...prevState,
      status: value,
    }));
  };

  const statusList = [
    {
      value: 'INITIAL',
      label: 'INITIAL',
    },
    {
      value: 'VALIDATED',
      label: 'VALIDATED',
    },
    {
      value: 'CANCELED',
      label: 'CANCELED',
    },
  ];

  const defaultValue = editingState
    ? {
        value: position.category.id,
        label: position.category.name,
      }
    : {};

  const defaultStatus = editingState
    ? {
        value: position.status,
        label: position.status,
      }
    : {};

  const canSubmit =
    position.name &&
    position.region &&
    position.zone &&
    position.sector &&
    position.startHour &&
    position.cumulHour &&
    position.matricule &&
    position.categoryId &&
    position.location &&
    position.city &&
    position.address;

  return (
    <form
      onSubmit={onSubmitHandler}
      className="flex p-2 rounded-lg border border-brand-lighterBlue"
    >
      {/* div fields  */}
      <div className="flex-auto flex flex-wrap">
        <div className="w-1/3">
          <NormalInput
            value={position.name}
            label={useFormatMessage('Position.positionForm.name')}
            name="name"
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            value={position.region}
            label={useFormatMessage('Position.positionForm.region')}
            name="region"
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            value={position.zone}
            label={useFormatMessage('Position.positionForm.zone')}
            name="zone"
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            value={position.sector}
            label={useFormatMessage('Position.positionForm.sector')}
            name="sector"
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            value={position.startHour}
            label={useFormatMessage('Position.positionForm.startHour')}
            name="startHour"
            onChange={onChangeHandler}
          />
        </div>
        {/* old Cumulheure input  */}
        {/* <div className="w-1/3">
          <NormalInput
            value={position.cumulHour}
            label={useFormatMessage('Position.positionForm.cumulHour')}
            name="cumulHour"
            onChange={onChangeHandler}
          />
        </div> */}
        {/* new cumul field  */}
        <div className="w-1/3">
          <SelectField
            type="form"
            options={[
              { label: 'Position Initiale', value: 'Position Initiale' },
              {
                label: 'Position de changement',
                value: 'Position de changement',
              },
            ]}
            value={{ label: position.cumulHour, value: position.cumulHour }}
            label="Type"
            name="cumulHour"
            onChange={(op) =>
              setPosition((prevState) => ({
                ...prevState,
                cumulHour: op.value,
              }))
            }
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label={useFormatMessage('Position.positionForm.registrationNumber')}
            type="number"
            name="matricule"
            value={position.matricule}
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <SelectField
            options={categoriesList}
            type="form"
            className="max-w-xs"
            label={useFormatMessage('Position.positionForm.category')}
            name="category"
            defaultValue={defaultValue}
            onChange={onCategoryChange}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label={useFormatMessage('Position.positionForm.localization')}
            name="location"
            value={position.location}
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label={useFormatMessage('Position.positionForm.city')}
            value={position.city}
            name="city"
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label={useFormatMessage('Position.positionForm.address')}
            name="address"
            value={position.address}
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <SelectField
            options={statusList}
            type="form"
            className="max-w-xs"
            label={useFormatMessage('Position.positionForm.status')}
            name="status"
            defaultValue={defaultStatus}
            onChange={onStatusChange}
          />
        </div>
      </div>
      {/* submit button  */}
      <div className={`p-2 pt-8`}>
        <Button
          className={`${loading && 'is-loading'}`}
          disabled={!canSubmit}
          type="submit"
          text={useFormatMessage('Position.positionForm.add')}
          color="green"
          size="large"
          icon="mdi-plus-circle"
        />
      </div>
    </form>
  );
};
export default PositionForm;
