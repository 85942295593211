/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
import React, { useEffect, useState } from 'react';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useFilterSearch from 'hooks/useFilterSearch';
import Button from 'components/Common/Button';
import FilterSearch from 'components/Common/FilterSearch';
import Table from 'components/Table';
import DateRange from 'components/Common/DateRange';
import { fetchProducts } from 'state/actions/products';
import { isArray } from '@amcharts/amcharts4/core';
import { CSVLink } from 'react-csv';
import sortByDate from 'utils/sortByDate';
import SelectField from 'components/Common/SelectField';
import ActivationController from 'controllers/ActivationController';
import ContactController from 'controllers/ContactController';
import { Link } from 'react-router-dom';

import { GiPositionMarker, FiUsers } from 'react-icons/gi';

import { FaUserFriends, FaHouseUser } from 'react-icons/fa';
import { CgPerformance } from 'react-icons/cg';

import LineChart from 'components/chart/LineCharts';
import VerticalBar from 'components/chart/vertical';
import PieChart from 'components/chart/Pie';

const data = {
  labels: [
    'Camel',
    'Gauloise',
    'Chesterfield',
    'Marlboro',
    'MonteCarlo',
    'Davidoff',
  ],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 2,
    },
  ],
};

const Reporting = () => {
  const { productsList, productsLoading } = useSelector(
    (state) => ({
      productsList: state.products.data,
      productsLoading: state.products.loading,
      error: state.kpis.error,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const [positions, setPositions] = useState([]);
  const [actPositions, setActPositions] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [activationsIds, setActivationsIds] = useState([]);
  const [activationsTags, setActivationsTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(),
    periodEnd: new Date(),
  });

  const searchReporting = () => {
    setPositions([]);
    setActPositions([]);
    setContacts([]);
    setActivationsIds([]);
    setActivationsTags([]);
    setLoading(true);
    setSearch(true);
  };

  const onChange = (value, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
        const indexR = activationsTags.findIndex(
          (at) => at.activationId === removedValue.value
        );
        activationsTags[indexR].active = false;
        setActivationsTags(activationsTags);
        if (value) {
          setActivationsIds(
            activationsTags
              .filter((act) => act.active)
              .map((act) => act.activationId)
          );
        } else {
          setActivationsIds(activationsTags.map((act) => act.activationId));
        }
        break;
      case 'clear':
        setActivationsIds(activationsTags.map((act) => act.activationId));
        break;
      default:
        const index = activationsTags.findIndex(
          (at) => at.activationId === value[value.length - 1].value
        );
        activationsTags[index].active = true;
        setActivationsTags(activationsTags);
        setActivationsIds(
          activationsTags
            .filter((act) => act.active)
            .map((act) => act.activationId)
        );
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // useEffect(() => {
  //   return () => dispatch(clearContactsData());
  // }, [dispatch]);

  const marks = [
    'MARLBORO',
    'WINSTON',
    'CAMEL',
    'MQS',
    'GAULOISE',
    'MONTE CARLO',
    'CHESTERFIELD',
    'ROTHMANS',
    'MARQUISE',
    'L&M',
    'VICEROY',
  ];

  useEffect(() => {
    if (search) {
      const ac = new ActivationController();
      const cc = new ContactController();
      ac.fetchActivationPositions({
        actId: null,
        periodStart: dateSearch.periodStart,
        periodEnd: dateSearch.periodEnd,
      }).then(async (aR) => {
        if (aR) {
          const { actIds, data } = aR;
          if (actIds && actIds.length > 0 && data) {
            setActivationsIds(actIds.map((aid) => aid.activationId));
            setActivationsTags(actIds);
            setActPositions(data);
            await cc
              .fetchByIds(actIds.map((aid) => aid.activationId))
              .then((cR) => {
                setContacts([].concat(...cR.map((cr) => cr)));
              });
          }
        }
        setLoading(false);
        setSearch(false);
      });
    }
  }, [search]);

  const contactsGroupBy = (posId) => {
    const nContacs = contacts.filter((c) => c.idPosition === posId);
    const age1 = nContacs.filter((c) => c.age === 'Entre 18 – 24 ans');
    const age2 = nContacs.filter((c) => c.age === 'Entre 24 – 35 ans');
    const age3 = nContacs.filter((c) => c.age === '35 et plus');
    const s_age1 = age1.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const s_age2 = age2.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const s_age3 = age3.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const multimarque = nContacs.filter((c) => c.isMultiBrands);
    const s_multimarque = multimarque.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const n_refus = nContacs.reduce((acc, c) => {
      const ref = c.brands.every((b) => b.selectedPack === 0);
      if (ref) {
        acc = acc + 1;
      }
      return acc;
    }, 0);
    const n_gouloise_ff = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === '3gkBxhfkICJb3wSaYaIO'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const n_gouloise_light = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === 'PX2brvhcsmURoBE1IiPZ'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const n_gouloise_red_mix = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === 'JInFNEeIfapF6NiTz1qp'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const autres = nContacs.filter(
      (c) =>
        !c.isMultiBrands &&
        marks.every((m) => !c.references.find((r) => r && r.includes(m)))
    );
    const s_autres = autres.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const refs = {};
    marks.forEach((m) => {
      refs[m.toLowerCase().replace(' ', '_')] = nContacs.filter(
        (c) =>
          !c.isMultiBrands &&
          c.references.findIndex((r) => r && r.includes(m)) >= 0
      );
    });
    const srefs = {};
    marks.forEach((m) => {
      srefs[`s_${m.toLowerCase().replace(' ', '_')}`] = refs[
        m.toLowerCase().replace(' ', '_')
      ].filter((c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0);
    });
    const response = {
      age1: age1.length,
      age2: age2.length,
      age3: age3.length,
      s_age1: s_age1.length,
      s_age2: s_age2.length,
      s_age3: s_age3.length,
      multimarque: multimarque.length,
      s_multimarque: s_multimarque.length,
      n_refus,
      n_gouloise_ff,
      n_gouloise_light,
      n_gouloise_red_mix,
      autres: autres.length,
      s_autres: s_autres.length,
    };
    Object.keys(refs).forEach((r) => {
      response[r] = refs[r].length;
    });
    Object.keys(srefs).forEach((sr) => {
      response[sr] = srefs[sr].length;
    });
    return response;
  };

  useEffect(() => {
    if (actPositions && contacts.length > 0) {
      const posFormat = actPositions.positions
        .filter(
          (pos) =>
            activationsIds.includes(pos.activationId) &&
            pos.type === 'INITIAL' &&
            (pos.positionBackup || pos.positionBackup === '')
        )
        .map((pos) => {
          const posAgency = actPositions.agencies.find(
            (ag) => ag.id === pos.agencyId
          );
          const backupPos =
            pos.positionBackup !== ''
              ? actPositions.positions.find((p) => p.id === pos.positionBackup)
              : null;
          const setBackPos =
            pos.status === 'CANCELED' && backupPos ? backupPos : null;
          const nPos = {
            date: new Date(pos.date),
            agency: posAgency ? (posAgency.agency || {}).name : '-----',
            init_licence: pos.position.matricule,
            init_status:
              pos.status !== 'INITIAL'
                ? pos.status === 'VALIDATED'
                  ? 'ACTIVEE'
                  : 'ANNULEE'
                : '----',
            back_licence: backupPos ? backupPos.position.matricule : '----',
            back_status: backupPos
              ? backupPos.status !== 'INITIAL'
                ? backupPos.status === 'VALIDATED'
                  ? 'ACTIVEE'
                  : 'ANNULEE'
                : '----'
              : '-----',
            idNum: (setBackPos || pos).animator.idNum,
            startHour: (setBackPos || pos).animator.startHour,
            connextionTime: (setBackPos || pos).animator.connextionTime,
            region:
              (setBackPos || pos).position.region === ''
                ? '-----'
                : pos.position.region,
            zone: (setBackPos || pos).position.zone,
            totalContacts: (setBackPos || pos).totalContacts,
            products: (setBackPos || pos).products,
            totalSuccessContacts: (setBackPos || pos).totalSuccessContacts,
            ...contactsGroupBy((setBackPos || pos).id),
          };
          productsList.forEach((p) => {
            nPos[p.name] =
              (
                (setBackPos || pos).products.find((actp) => actp.id === p.id) ||
                {}
              ).totalProducts || 0;
          });
          return nPos;
        });
      setPositions(posFormat);
    }
  }, [activationsIds, contacts]);

  let columns = [
    {
      Header: 'DATE',
      accessor: 'date',
      sortType: sortByDate,
      Cell: ({ row }) =>
        useFormatDate(row.original.date, {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }) || '----',
    },
    {
      Header: 'AGENCE',
      accessor: 'agency',
    },
    {
      Header: 'LICENCE INITIALE',
      accessor: 'init_licence',
    },
    {
      Header: 'STATUS',
      accessor: 'init_status',
    },
    {
      Header: 'LICENCE BACKUP',
      accessor: 'back_licence',
    },
    {
      Header: 'STATUS',
      accessor: 'back_status',
    },
    {
      Header: 'ID ANNIMATEUR',
      accessor: 'idNum',
    },
    {
      Header: 'HORRAIRE DEMARRAGE',
      accessor: 'startHour',
      Cell: ({ row }) =>
        useFormatDate(row.original.startHour, {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }) || '----',
    },
    {
      Header: 'TEMPS DE CONNEXION',
      accessor: 'connextionTime',
    },
    {
      Header: 'REGION',
      accessor: 'region',
    },
    {
      Header: 'ZONE',
      accessor: 'zone',
    },
    {
      Header: 'TOTAL NOMBRE DE CONTACTS',
      accessor: 'totalContacts',
    },
    {
      Header: 'MARLBORO',
      accessor: 'marlboro',
    },
    {
      Header: 'WINSTON',
      accessor: 'winston',
    },
    {
      Header: 'CAMEL',
      accessor: 'camel',
    },
    {
      Header: 'MQS',
      accessor: 'mqs',
    },
    {
      Header: 'GAULOISE',
      accessor: 'gauloise',
    },
    {
      Header: 'MONTE CARLO',
      accessor: 'monte_carlo',
    },
    {
      Header: 'CHESTERFIELD',
      accessor: 'chesterfield',
    },
    {
      Header: 'ROTHMANS',
      accessor: 'rothmans',
    },
    {
      Header: 'MARQUISE',
      accessor: 'marquise',
    },
    {
      Header: 'L&M',
      accessor: 'l&m',
    },
    {
      Header: 'VICEROY',
      accessor: 'viceroy',
    },
    {
      Header: 'AUTRES',
      accessor: 'autres',
    },
    {
      Header: 'Multimarque',
      accessor: 'multimarque',
    },
    {
      Header: '18-24 ans',
      accessor: 'age1',
    },
    {
      Header: '24-35 ans',
      accessor: 'age2',
    },
    {
      Header: '35 ans et plus',
      accessor: 'age3',
    },
    {
      Header: 'Nombre de refus',
      accessor: 'n_refus',
    },
    {
      Header: 'Nombre paquet Gauloise FF',
      accessor: 'n_gouloise_ff',
    },
    {
      Header: 'Nombre paquet Gauloise Light',
      accessor: 'n_gouloise_light',
    },
    {
      Header: 'Nombre paquet Gauloise Red Mix',
      accessor: 'n_gouloise_red_mix',
    },
    {
      Header: 'TOTAL NOMBRE DE SUCCESS CONTACTS',
      accessor: 'totalSuccessContacts',
    },
    {
      Header: 'Succ. MARLBORO',
      accessor: 's_marlboro',
    },
    {
      Header: 'Succ. WINSTON',
      accessor: 's_winston',
    },
    {
      Header: 'Succ. Camel',
      accessor: 's_camel',
    },
    {
      Header: 'Succ. MQS',
      accessor: 's_mqs',
    },
    {
      Header: 'Succ. Gauloise classic',
      accessor: 's_gauloise',
    },
    {
      Header: 'Succ. MONTE CARLO',
      accessor: 's_monte_carlo',
    },
    {
      Header: 'Succ. CHESTERFIELD',
      accessor: 's_chesterfield',
    },
    {
      Header: 'Succ. ROTHMANS',
      accessor: 's_rothmans',
    },
    {
      Header: 'Succ. MARQUISE',
      accessor: 's_marquise',
    },
    {
      Header: 'Succ. LM',
      accessor: 's_lm',
    },
    {
      Header: 'Succ.VICEROY',
      accessor: 's_viceroy',
    },
    {
      Header: 'Succ. AUTRES',
      accessor: 's_autres',
    },
    {
      Header: 'Succ. Multimarque',
      accessor: 's_multimarque',
    },
    {
      Header: 'Succ. 18-24 ans',
      accessor: 's_age1',
    },
    {
      Header: 'Succ. 24-35 ans',
      accessor: 's_age2',
    },
    {
      Header: 'Succ. 35 ans et plus',
      accessor: 's_age3',
    },
  ];

  columns = [
    ...columns,
    ...(!productsLoading
      ? productsList.map((p) => ({
          Header: p.name,
          accessor: p.name,
        }))
      : []),
  ];

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="text-brand-darkBlue text-3xl font-semibold">
                  Reporting
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="m-4 flex bg-gray-100  flex-wrap overflow-hidden">
        <div className="w-full overflow-hidden  lg:w-1/3">
          <GiPositionMarker className="inline" />
          <h1 className="inline m-3 text-xl font-bold text-blue-500 ">
            Positions
          </h1>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/CanceledPositions"
          >
            Positions annulées
          </Link>
        </div>
        <div className="w-full overflow-hidden lg:w-1/3">
          <FaUserFriends className="inline" />
          <h1 className="inline m-3 text-xl font-bold text-blue-500 ">
            Participants
          </h1>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/participantData"
          >
            Données des participants
          </Link>
        </div>
        <div className="w-full overflow-hidden lg:w-1/3">
          <FaHouseUser className="inline" />
          <h1 className="inline m-3 text-xl font-bold text-blue-500 ">Stock</h1>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/stockAgency"
          >
            Etat de stock par agence
          </Link>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/detailDecalage"
          >
            Detail decalage et raisons de decalage par utilisateur
          </Link>
        </div>
        <div className="w-full overflow-hidden lg:w-1/3">
          <CgPerformance className="inline" />
          <h1 className="inline m-3 text-2xl font-bold text-blue-500 ">
            Performances générales
          </h1>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/performancePosition"
          >
            Performances par position
          </Link>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/performanceZone"
          >
            Performances par zone
          </Link>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/performanceSecteur"
          >
            Performances par secteur
          </Link>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/performanceAnimatrices"
          >
            Performances Animatrices
          </Link>
          <Link
            style={{ width: 'fit-content' }}
            className="block m-4 border-dashed border-b-2 border-light-blue-700 max-w-max"
            to="/reporting/performanceEquipes"
          >
            Performances Equipes
          </Link>
        </div>
      </div>
    </>
  );
};
export default Reporting;
