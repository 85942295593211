/**
 * 
 * @param {Object} el data item that will be checked if it fits all the criteria
 * @param {Array} checkboxes list of checkboxes state, {checked : Boolean, evaluate : Function}
 * *****
 * evaluate is a function that gets an element should return the truthy state of the target property
 * example: we have a checkbox named "older than 10", the evaluate function
 * will be (person) => person.age > 10
 * ****
 * only checked checkboxes are considered
 * the unchecked don't affect the result
 */
const fitsCriteria = (el, checkboxes) => {
    const checkedCheckboxes = checkboxes.filter(c => c.checked);

    if (checkedCheckboxes !== []) {
        return checkedCheckboxes.every(c => c.evaluate(el))
    } else {
        return true
    }
}

export default fitsCriteria;