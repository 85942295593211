import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';
import firebase from 'firebase.js';

export const ALATIGES_FETCH_DATA_INIT = createAction(
  'ALATIGES_FETCH_DATA_INIT'
);
export const ALATIGES_FETCH_DATA_SUCCESS = createAction(
  'ALATIGES_FETCH_DATA_SUCCESS'
);
export const ALATIGES_FETCH_DATA_FAIL = createAction(
  'ALATIGES_FETCH_DATA_FAIL'
);
export const ALATIGES_CREATE_ALATIGE_INIT = createAction(
  'ALATIGES_CREATE_ALATIGE_INIT'
);
export const ALATIGES_CREATE_ALATIGE_SUCCESS = createAction(
  'ALATIGES_CREATE_ALATIGE_SUCCESS'
);
export const ALATIGES_CREATE_ALATIGE_FAIL = createAction(
  'ALATIGES_CREATE_ALATIGE_FAIL'
);
export const ALATIGES_CLEAR_DATA = createAction('ALATIGES_CLEAR_DATA');
export const ALATIGES_DELETE_ALATIGE_INIT = createAction(
  'ALATIGES_DELETE_ALATIGES_INIT'
);
export const ALATIGES_DELETE_ALATIGE_SUCCESS = createAction(
  'ALATIGES_DELETE_ALATIGE_SUCCESS'
);
export const ALATIGES_DELETE_ALATIGE_FAIL = createAction(
  'GAMESS_DELETE_ALATIGES_FAIL'
);
export const ALATIGES_MODIFY_ALATIGE_INIT = createAction(
  'ALATIGES_MODIFY_ALATIGE_INIT'
);
export const ALATIGES_MODIFY_ALATIGE_SUCCESS = createAction(
  'ALATIGES_MODIFY_ALATIGE_SUCCESS'
);
export const ALATIGES_MODIFY_ALATIGE_FAIL = createAction(
  'ALATIGES_MODIFY_ALATIGE_FAIL'
);
export const ALATIGES_CLEAN_UP = createAction('ALATIGES_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('alatiges', 'alatige');
const cUpload = new ControllerWithFileUpload(
  'alatiges',
  'alatige',
  'displayName'
);

/**
 * Action alatige
 * @return dispatch
 */
export const fetchAlatiges = () => {
  return async (dispatch) => {
    dispatch(ALATIGES_FETCH_DATA_INIT());
    let alatiges;
    try {
      alatiges = await gc.fetch();
      console.log('fetched alatiges: ', alatiges);
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(ALATIGES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(ALATIGES_FETCH_DATA_SUCCESS({ alatiges }));
  };
};

/**
 * Action Create new alatige
 * @param {file} pdf alatige argument
 * @param {boolean} isActive Is the alatige favorite
 * @return dispatch
 */
export const createAlatige = ({ pdf, isActive }) => {
  return async (dispatch, getState) => {
    dispatch(ALATIGES_CREATE_ALATIGE_INIT());
    const { locale } = getState().preferences;
    let pdfUrl;
    let alatige;

    try {
      const alatigeReference = await firebase
        .firestore()
        .collection('alatiges')
        .add({
          isActive,
          createdAt: new Date().toGMTString(),
          updatedAt: new Date().toGMTString(),
        });

      if (pdf) {
        await cUpload.uploadFile(alatigeReference.id, pdf);
        pdfUrl = cUpload.getFileUrl(alatigeReference.id, pdf);
        await cUpload.update(alatigeReference.id, {
          pdfUrl,
          updatedAt: new Date().toGMTString(),
        });
      }

      alatige = await alatigeReference
        .update({
          isActive,
          pdfUrl,
        })
        .then(() => alatigeReference.get().then((snap) => snap.data()));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('A la tige', 'la création a échoué');
      return dispatch(ALATIGES_CREATE_ALATIGE_FAIL({ error: errorMessage }));
    }
    toastr.success('A la tige', 'créé avec succès');
    return dispatch(ALATIGES_CREATE_ALATIGE_SUCCESS({ alatige }));
  };
};

/**
 * Action Delete a alatige
 * @param {string} id ID of the alatige
 * @return dispatch
 */
export const deleteAlatige = (id) => {
  return async (dispatch, getState) => {
    dispatch(ALATIGES_DELETE_ALATIGE_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('A la tige', 'la suppression a échoué');
      return dispatch(ALATIGES_DELETE_ALATIGE_FAIL({ error: errorMessage }));
    }
    toastr.success('A la tige', 'Supprimé avec succès');
    return dispatch(ALATIGES_DELETE_ALATIGE_SUCCESS({ id }));
  };
};

/**
 * Action Update alatige
 * @param {file} pdf alatige argument
 * @param {boolean} isActive Is the alatige favorite
 * @return dispatch
 */
export const modifyAlatige = ({ isActive, id, pdfUrl, pdf }) => {
  return async (dispatch, getState) => {
    dispatch(ALATIGES_MODIFY_ALATIGE_INIT());
    const { locale } = getState().preferences;
    let alatige;

    try {
      let oldpdf = pdfUrl;
      const alatiges = await firebase
        .firestore()
        .collection('alatiges')
        .doc(id);

      if (pdf) {
        await cUpload.deleteFile(pdfUrl);
        await cUpload.uploadFile(id, pdf);
        oldpdf = cUpload.getFileUrl(id, pdf);
      }

      alatige = await alatiges
        .update({
          pdfUrl: oldpdf,
          isActive,
          updatedAt: new Date().toGMTString(),
        })
        .then(() =>
          alatiges.get().then((snap) => ({ id: snap.id, ...snap.data() }))
        );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('A la tige', 'mise à jour a échoué');
      return dispatch(ALATIGES_MODIFY_ALATIGE_FAIL({ error: errorMessage }));
    }
    toastr.success('A la tige', 'mis à jour avec succès');
    return dispatch(ALATIGES_MODIFY_ALATIGE_SUCCESS({ alatige }));
  };
};

export const alatigesCleanUp = () => (dispatch) =>
  dispatch(ALATIGES_CLEAN_UP());

export const clearAlatigesData = () => {
  return (dispatch) => {
    dispatch(ALATIGES_CLEAR_DATA());
  };
};
