/* eslint-disable radix */
import {
  toastr
} from 'react-redux-toastr';
import firebase from 'firebase.js';
import Controller from './Controller';
import PositionController from './PositionController';
import RelationController from './RelationController';
import AgencyController from './AgencyController';
import TeamController from './TeamController';
import ProductController from './ProductController';

const moment = require('moment');

export default class ActivationController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'activations';
    this.item = 'activation';
  }

  /**
   * Fetch Activation data with pagination
   * @param {String} agencyId connected agency id
   * @param {Boolean} history get finnished or activated activation
   * @returns {Array} list of activations with related data
   */
  // fetch = (agencyId = null, history = null, date) => {
  //   const rc = new RelationController();
  //   const start = new Date();
  //   start.setHours(0, 0, 0, 0);
  //   let activationRef;

  //   if (date) {

  //     date.setHours(0, 0, 0, 0);
  //     activationRef = firebase
  //       .firestore()
  //       .collection(this.collection)
  //       .orderBy('periodStart', 'desc')
  //       .where('periodStart', '>=', date).limit(3)
  //       .get()
  //       .then((activations) => {
  //         let i = 0;
  //         activations.forEach((element) => {
  //           const position = firebase
  //             .firestore()
  //             .collection(this.collection)
  //             .doc(element.id)
  //             .collection('positions')
  //             .get().then((positions) => {
  //               positions.forEach((positionss) => {
  //                 console.log('activation __________ : ', element.id, 'activation key :', i, 'positions', positionss.id);
  //               });


  //             });
  //           // activationRef[i];
  //           i++;


  //         });
  //       });
  //   }

  //   // check if history is true get activations before now date
  //   // activationRef = history ?
  //   //   firebase
  //   //   .firestore()
  //   //   .collection(this.collection)
  //   //   .orderBy('periodEnd', 'desc')
  //   //   .where('periodEnd', '<', new Date(start))
  //   //   .get() :
  //   //   firebase
  //   //   .firestore()
  //   //   .collection(this.collection)
  //   //   .orderBy('periodEnd', 'desc')
  //   //   .where('periodEnd', '>=', new Date(start))
  //   //   .get();

  //   return activationRef.then((actSnaps) => {
  //     if (actSnaps.size > 0) {
  //       const promises = actSnaps.docs.map(async (value) => {
  //         // check if the account is agency, filter just activations of this agency
  //         // each activation verify is has this agencyId on position subcollection
  //         // TODO check if this agencies subcollection has this agencyId
  //         if (agencyId) {
  //           const agencies = await rc.getActivationAgencies(value.id);
  //           if (agencies.find((agency) => agency.agencyId === agencyId))
  //             return this.fetchData({}, value.id);
  //           return null;
  //         }
  //         console.log('value from controller ------------- :', actSnaps.docs);
  //         return this.fetchData({}, value.id);

  //       });

  //       return Promise.all(promises.filter((promise) => promise));
  //     }
  //     return [];
  //   });
  // };

  fetch = (agencyId = null, history = null) => {
    const rc = new RelationController();
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    // check if history is true get activations before now date
    const activationRef = history ?
      firebase
      .firestore()
      .collection(this.collection)
      .orderBy('periodEnd', 'desc')
      .where('periodEnd', '<', new Date(start))
      .get() :
      firebase
      .firestore()
      .collection(this.collection)
      .orderBy('periodEnd', 'desc')
      .where('periodEnd', '>=', new Date(start))
      .get();
    return activationRef.then((actSnaps) => {
      if (actSnaps.size > 0) {
        const promises = actSnaps.docs.map(async (value) => {
          // check if the account is agency, filter just activations of this agency
          // each activation verify is has this agencyId on position subcollection
          // TODO check if this agencies subcollection has this agencyId
          if (agencyId) {
            const agencies = await rc.getActivationAgencies(value.id);
            if (agencies.find((agency) => agency.agencyId === agencyId))
              return this.fetchData({}, value.id);
            return null;
          }
          return this.fetchData({}, value.id);
        });
        return Promise.all(promises.filter((promise) => promise));
      }
      return [];
    });
  };



  fetchActivationPositions = ({
    actId,
    periodStart,
    periodEnd
  }) => {
    if (actId) {
      return this.getDoc(actId).then(async (actSnap) =>
        this.getActivationPositions(actSnap));
    }
    const start = periodStart.setHours(0, 0, 0, 0);
    const end = periodEnd.setHours(23, 59, 59, 999);
    return firebase
      .firestore()
      .collection('activations')
      .where('periodStart', '>=', new Date(start))
      .get()
      .then(async (actSnaps) => {
        if (actSnaps.size > 0) {
          const actIds = [];
          const promises = actSnaps.docs
            .filter((adoc) => adoc.data().periodEnd.toDate() <= new Date(end))
            .map((actDoc) => {
              actIds.push({
                activationId: actDoc.id,
                name: actDoc.data().name,
              });
              return this.getActivationPositions(actDoc);
            });
          const r = await Promise.all(promises);
          return {
            actIds,
            data: {
              agencies: [].concat(...r.map((ra) => ra.agencies)),
              positions: [].concat(...r.map((rp) => rp.positions)),
            },
          };
        }
        return null;
      });
  };

  getActivationPositions = async (actSnap) => {
    const pc = new PositionController();
    const ac = new AgencyController();
    const agencies = await actSnap.ref
      .collection('agencies')
      .get()
      .then((agnSnap) => {
        if (agnSnap.size > 0) {
          const promises = agnSnap.docs.map(async (agnDoc) => ({
            id: agnDoc.id,
            ...agnDoc.data(),
            agency: await ac.fetchById(agnDoc.data().agencyId),
          }));
          return Promise.all(promises);
        }
        return [];
      });
    const positions = await actSnap.ref
      .collection('positions')
      .get()
      .then((posSnap) => {
        if (posSnap.size > 0) {
          const promises = posSnap.docs.map(async (posDoc) => ({
            activationId: actSnap.id,
            id: posDoc.id,
            ...posDoc.data(),
            date: posDoc.data().date.toDate().toString(),
            position: await pc.fetchById(String(posDoc.data().positionId)),
            animator: await posDoc.ref
              .collection('distribution')
              .get()
              .then(async (distSnap) => {
                if (distSnap.size > 0) {
                  const disInfo = distSnap.docs[0].data();
                  const animInfo = await firebase
                    .firestore()
                    .collection('users')
                    .doc(disInfo.animatorId)
                    .get();
                  return {
                    idNum: animInfo.data() ?
                      animInfo.data().idNum : 'NOT FOUND',
                    startHour: disInfo.workTime.toDate(),
                    connextionTime: moment('2015-01-01')
                      .startOf('day')
                      .seconds(posDoc.data().workTime || 0)
                      .format('H:mm:ss') || '00 min',
                  };
                }
                return {};
              }),
            products: await posDoc.ref
              .collection('products')
              .get()
              .then((productsSnap) => {
                if (productsSnap.size > 0) {
                  const prodPromises = productsSnap.docs.map(
                    async (productDoc) => ({
                      id: productDoc.id,
                      ...productDoc.data(),
                      product: await firebase
                        .firestore()
                        .collection('products')
                        .doc(productDoc.id)
                        .get()
                        .then((productSnap) => ({
                          ...productSnap.data(),
                        })),
                    })
                  );
                  return Promise.all(prodPromises);
                }
                return [];
              }),
          }));
          return Promise.all(promises);
        }
        return [];
      });
    return {
      agencies,
      positions
    };
  };

  /**
   * Fetch Activation Data with SubCollections
   * @param {Object} activation object with keys
   * @param {String} id activation id
   * @param {String} agencyId agency id
   * @returns {Array} list of activations
   */
  fetchData = async (activation, id, kpis) => {
    const rc = new RelationController();
    const ac = new AgencyController();
    const pc = new PositionController();
    const prc = new ProductController();
    let response = activation;
    // get activation information data
    await this.getDoc(id).then(async (actSnap) => {
      if (actSnap.exists) {
        const actData = actSnap.data();
        response = {
          ...response,
          id,
          ...actData,
          createdAt: actData.createdAt.toDate(),
          updatedAt: actData.updatedAt.toDate(),
          periodStart: actData.periodStart.toDate(),
          periodEnd: actData.periodEnd.toDate(),
        };

        response = {
          ...response,
          // get activation video data by (videoId)
          video: actData.videoId && !kpis ?
            await this.getDoc(actData.videoId, 'videos').then(
              (videoSpan) => ({
                name: videoSpan.name,
                videoId: videoSpan.id,
              })
            ) : null,
          // get activation category by (categoryId)
          category: actData.categoryId && !kpis ?
            await rc.getCategory('actCategories', actData.categoryId) : null,
          // get agencies from subcollection
          agencies: await actSnap.ref
            .collection('agencies')
            .get()
            .then((agnSnap) => {
              if (agnSnap.size > 0) {
                const promises = agnSnap.docs.map(async (agnDoc) => ({
                  id: agnDoc.id,
                  ...agnDoc.data(),
                  agency: await ac.fetchById(agnDoc.data().agencyId),
                }));
                return Promise.all(promises);
              }
              return [];
            }),
          // Get positions in case of KPIS
          positions: kpis ?
            await actSnap.ref
            .collection('positions')
            .get()
            .then((posSnap) => {
              if (posSnap.size > 0) {
                const promises = posSnap.docs.map(async (posDoc) => ({
                  id: posDoc.id,
                  ...posDoc.data(),
                  position: await pc.fetchById(
                    String(posDoc.data().positionId)
                  ),
                }));
                return Promise.all(promises);
              }
              return [];
            }) : null,
          // Get Products in case of KPIS
          products: kpis ?
            await actSnap.ref
            .collection('products')
            .get()
            .then((prodSnap) => {
              if (prodSnap.size > 0) {
                const promises = prodSnap.docs.map(async (prodDoc) => ({
                  id: prodDoc.id,
                  ...prodDoc.data(),
                  product: await prc.fetchById(prodDoc.id),
                }));
                return Promise.all(promises);
              }
              return [];
            }) : null,
        };
      }
    });
    return response;
  };

  fetchAgency = async (agency, id, agencyId) => {
    const ac = new AgencyController();
    const pc = new PositionController();
    const tm = new TeamController();
    const prc = new ProductController();
    let response = agency;
    // get agency information data
    await this.getDoc(id).then(async (actSnap) => {
      if (actSnap.exists) {
        const agencyStock = await actSnap.ref
          .collection('agencies')
          .where('agencyId', '==', agencyId)
          .get()
          .then(async (agenSnap) => {
            if (agenSnap.size > 0) {
              return {
                ...agenSnap.docs[0].data(),
                products: await agenSnap.docs[0].ref
                  .collection('products')
                  .get()
                  .then((prodSnap) => {
                    return Promise.all(
                      prodSnap.docs.map(async (prod) => ({
                        id: prod.id,
                        name: ((await prc.fetchById(prod.id)) || {}).name,
                        ...prod.data(),
                      }))
                    );
                  }),
              };
            }
            return null;
          });
        response = {
          id: actSnap.id,
          name: actSnap.data().name,
          date: {
            periodEnd: actSnap.data().periodEnd.toDate(),
            periodStart: actSnap.data().periodStart.toDate(),
          },
          agencyStock,
          agency: await ac.fetchById(agencyId),
          users: await this.fetchActivationUsers(actSnap.ref, agencyId),
          // get agencies from subcollection
          positions: await actSnap.ref
            .collection('positions')
            .where('agencyId', '==', agencyId)
            .get()
            .then((agnSnap) => {
              if (agnSnap.size > 0) {
                const promises = agnSnap.docs.map(async (agnDoc) => ({
                  id: agnDoc.id,
                  ...agnDoc.data(),
                  position: await pc.fetchById(
                    String(agnDoc.data().positionId)
                  ),
                  team: await tm.fetchById(agencyId, agnDoc.data().teamId),
                }));
                return Promise.all(promises);
              }
              return [];
            }),
        };
      }
    });
    return response;
  };

  /**
   * Create Activation with init value
   * @param {Object} data user object data
   */
  create = (data) => {
    const {
      name,
      agencyPrice,
      contactObjective,
      activationType,
      categoryId,
      periodStart,
      periodEnd,
      description,
      region,
      video,
      products,
      marks,
      games,
      positions,
      agencies,
      activationTeams,
      initAgencies,
      initProducts,
      initProductsVolume,
      initPositions,
    } = data;
    const start = new Date(periodStart);
    start.setHours(0, 0, 0, 0);
    const end = new Date(periodEnd);
    end.setHours(23, 59, 59, 999);
    return this.addDoc({
      name,
      agencyPrice: parseInt(agencyPrice),
      contactObjective: parseInt(contactObjective),
      activationType,
      categoryId,
      periodStart: new Date(start),
      periodEnd: new Date(end),
      region,
      marks,
      games,
      description,
      videoId: video.videoId,
      createdAt: new Date(),
      updatedAt: new Date(),
      enable: true,
      initAgencies,
      initProducts,
      initProductsVolume,
      initPositions,
      totalProducts: 0,
      totalContacts: 0,
      totalSuccessContacts: 0,
    }).then(async (actRef) => {
      // store assgined (products) to activation as subcollection
      const productPromise = products.map((p) =>
        this.setSubDoc(actRef.id, 'products', p.productId, {
          quantity: parseInt(p.quantity),
          levelId: p.levelId,
          price: p.price,
          bigwin: p.isbigwin,
          category: p.categories
        })
      );
      // store assgined (positions, agencies) to activation as subcollection
      const positionPromise = positions.map(async (p) => {
        // get initial data of position
        const data = {
          agencyId: p.agencyId,
          teamId: p.teamId || '',
          date: p.date || '',
          promotorId: p.promotorId || '',
          startHour: p.startHour || '',
          initProductsVolume: p.initProductsVolume || 0,
          totalProducts: 0,
          totalContacts: 0,
          totalSuccessContacts: 0,
          status: 'INITIAL',
          type: 'INITIAL',
        };
        // check if the position has backup postion
        if (p.positionBackup && p.positionBackup !== '') {
          // if true create position with type backup then
          // create position initial with this backup position ID
          return this.addSubDoc(actRef.id, 'positions', {
            positionId: p.positionBackup,
            ...data,
            type: 'BACKUP',
          }).then(async (posBackupSnap) => {
            // add prodcuts to backup position
            const subProductPromise = products.map((sp) =>
              posBackupSnap
              .collection('products')
              .doc(sp.productId)
              .set({
                quantity: parseInt(sp.quantity),
                bigwin: sp.isbigwin,
                category: sp.categories
              })
            );
            await Promise.all(subProductPromise);

            // create the position initial
            return this.addSubDoc(actRef.id, 'positions', {
              positionId: p.positionId,
              positionBackup: posBackupSnap.id || '',
              ...data,
            }).then(async (posSnap) => {
              // add products to initial position
              const subProductPromise = products.map((sp) =>
                posSnap
                .collection('products')
                .doc(sp.productId)
                .set({
                  quantity: parseInt(sp.quantity),
                  bigwin: sp.isbigwin,
                  category: sp.categories
                })
              );
              await Promise.all(subProductPromise);
            });
          });
          // if position has not backup position create just initial position
          // and keep positionBackup empty
        }
        await this.addSubDoc(actRef.id, 'users', {
          positionId: p.positionId,
          positionBackup: '',
          ...data,
        });
        return this.addSubDoc(actRef.id, 'positions', {
          positionId: p.positionId,
          positionBackup: '',
          ...data,
        }).then(async (posSnap) => {
          const subProductPromise = products.map((sp) =>
            posSnap
            .collection('products')
            .doc(sp.productId)
            .set({
              quantity: parseInt(sp.quantity),
              bigwin: p.isbigwin,
              category: p.categories
            })
          );
          await Promise.all(subProductPromise);
        });
      });

      // store assgined (agencies) to activation as subcollection
      const agencyPromise = agencies.map(async (p) => {
        this.setSubDoc(actRef.id, 'agencies', p.agencyId, {
          ...p,
        }).then(async (agenSnap) => {
          const agencyRef = await this.getSubDoc(
            actRef.id,
            'agencies',
            p.agencyId
          );
          const subProductPromise = products.map((sp) =>
            agencyRef.ref
            .collection('products')
            .doc(sp.productId)
            .set({
              quantity: parseInt(sp.quantity),
            })
          );
          await Promise.all(subProductPromise);
        });
      });

      const teamPromise = await activationTeams.map(async (team) => {
        await this.setSubDoc(actRef.id, 'users', team.supervisor.id, {
          agencyId: team.agencyId,
          teamId: team.teamId,
          quantityInitial: 0,
          quantityReceived: 0,
          workTime: 0,
          startTime: new Date(0),
        });
        const animPromise = await team.animators.map(async (anim) => {
          await this.setSubDoc(actRef.id, 'users', anim.id, {
            agencyId: team.agencyId,
            quantityInitial: 0,
            quantityReceived: 0,
            workTime: 0,
            startTime: new Date(0),
          });
        });
        await Promise.all(animPromise);
      });
      return Promise.all([
        productPromise,
        positionPromise,
        agencyPromise,
        teamPromise,
      ]);
    });
  };

  /**
   * Update Activation
   * @param {Object} data user object data
   * @param {function} init action to initialize
   * @param {function} success action store data on success
   * @param {function} fail action on failed
   */
  update = (data) => {
    const {
      id,
      name,
      categoryId,
      periodStart,
      periodEnd,
      description,
      marks,
      videoId,
      products,
      positions,
      deletedProducts,
      deletedPositions,
    } = data;

    return this.updateDoc(id, {
      name,
      categoryId,
      periodStart,
      periodEnd,
      marks,
      description,
      videoId,
      updatedAt: new Date(),
    }).then(async () => {
      const deletedProductsPromises = deletedProducts.map((dprodId) =>
        this.deleteSubDoc(id, 'products', dprodId)
      );

      const productPromise = products.map((p) => {
        const prodData = {
          productId: p.productId,
          quantity: parseInt(p.quantity),
          levelId: p.levelId,
          price: p.price,
        };
        if (p.id) this.updateSubDoc(id, 'products', p.id, prodData);
        else this.addSubDoc(id, 'products', prodData);
      });

      const deletedPositionsPromises = deletedPositions.map((dposId) => {
        this.deleteSubDoc(id, 'positions', dposId);
      });

      const positionPromise = positions.map((p) => {
        const posData = {
          positionId: p.positionId,
          agencyId: p.agencyId,
          productId: p.productId,
        };
        if (p.id) this.updateSubDoc(id, 'positions', p.id, posData);
        else this.addSubDoc(id, 'positions', posData);
      });
      return Promise.all([
        deletedProductsPromises,
        productPromise,
        deletedPositionsPromises,
        positionPromise,
      ]);
    });
  };

  updatePlanning = async (activationId, positions, planTeams) =>
    this.getDoc(activationId).then(async (actSnap) => {
      const teamPromise = await planTeams.map(async (team) => {
        await actSnap.ref
          .collection('users')
          .doc(team.supervisor.id)
          .set({
            agencyId: team.agencyId,
            teamId: team.teamId,
            quantityInitial: 0,
            quantityReceived: 0,
            workTime: 0,
            startTime: new Date(0),
          });
        const animPromise = await team.animators.map(async (anim) => {
          await actSnap.ref
            .collection('users')
            .doc(anim.id)
            .set({
              agencyId: team.agencyId,
              quantityInitial: 0,
              quantityReceived: 0,
              workTime: 0,
              startTime: new Date(0),
            });
        });
        await Promise.all(animPromise);
      });
      const promises = await positions.map(async (pos) => {
        await actSnap.ref
          .collection('positions')
          .doc(pos.posId)
          .update({
            teamId: pos.teamId
          });
      });
      return Promise.all([teamPromise, promises]);
    });

  /**
   * Enable or Disable Activation
   * @param {string} actId activation ID
   * @param {boolean} option enable option
   */
  enable = (actId, option) => {
    try {
      if (option)
        firebase
        .firestore()
        .collection(this.collection)
        .where('enable', '==', true)
        .get()
        .then((actSnap) => {
          if (actSnap.size > 0) {
            toastr.error(
              'Activation',
              "veuillez vous assurer de désactiver l'activation précédente"
            );
          } else this.updateDoc(actId, {
            enable: option
          });
        });
      else this.updateDoc(actId, {
        enable: option
      });
    } catch (error) {
      toastr.error('Activation', 'Operation a échoué');
    }
  };

  getActPostionsByAgency = (agencyId) => {
    try {
      const pc = new PositionController();
      const ac = new AgencyController();
      const tm = new TeamController();
      return firebase
        .firestore()
        .collection(this.collection)
        .where('periodEnd', '>=', new Date())
        .get()
        .then((actSnap) => {
          if (actSnap.size > 0) {
            const posPromises = actSnap.docs.map(async (act) => ({
              name: act.data().name,
              positions: await firebase
                .firestore()
                .collection(this.collection)
                .doc(act.id)
                .collection('positions')
                .where('agencyId', '==', agencyId)
                .get()
                .then((actPosSnaps) => {
                  if (actPosSnaps.size > 0) {
                    const promises = actPosSnaps.docs.map(async (pos) => ({
                      id: pos.id,
                      ...pos.data(),
                      team: await tm.fetchById(agencyId, pos.data().teamId),
                      position: await pc.fetchById(
                        String(pos.data().positionId)
                      ),
                      agency: await ac.fetchById(pos.data().agencyId),
                    }));
                    return Promise.all(promises);
                  }
                  return [];
                }),
            }));
            return Promise.all(posPromises);
          }
          return [];
        });
    } catch (error) {
      toastr.error('Activation', 'Operation a échoué');
    }
  };

  /**
   * Get Activation data
   * @param {string} agencyId Activation ID
   * @returns {Object} object of Activation data or false
   */
  fetchById = async (activationId) =>
    activationId ?
    this.getDoc(activationId).then((activationSnap) => {
      if (activationSnap.exists) {
        return {
          id: activationSnap.id,
          ...activationSnap.data(),
        };
      }
      return null;
    }) :
    null;

  setTeam = async (activationId, planning) => {
    try {
      const promises = planning.map((plan) => {
        const {
          positionId,
          teamId
        } = plan;
        return this.updateSubDoc(activationId, 'positions', positionId, {
          teamId,
        });
      });
      await Promise.all(promises);
      toastr.success('Plan', 'créé avec succès');
      window.location.reload();
    } catch (error) {
      toastr.error('Activation', 'Operation a échoué');
    }
  };

  /**
   *
   * @param {Promise} ref firebase reference
   * @param {String} agencyId agency ID
   */
  fetchActivationUsers = (ref, agencyId) =>
    ref
    .collection('users')
    .where('agencyId', '==', agencyId)
    .get()
    .then((usersSnap) => {
      if (usersSnap.size > 0) {
        return usersSnap.docs.map((userDoc) => ({
          id: userDoc.id,
          ...userDoc.data(),
        }));
      }
      return [];
    });

  fetchAnimations = async (activationId, memberId, type = 'animator') => {
    const pc = new PositionController();
    const memberAttr = type === 'animator' ? 'animatorId' : 'supervisorId';
    return firebase
      .firestore()
      .collection('activations')
      .doc(activationId)
      .collection('positions')
      .get()
      .then(async (positionsSnap) => {
        if (positionsSnap.size > 0) {
          const promises = positionsSnap.docs.map((posDoc) =>
            posDoc.ref
            .collection('distribution')
            .where(memberAttr, '==', memberId)
            .get()
            .then(async (disSnap) => {
              if (disSnap.size > 0) {
                return {
                  position: await pc.fetchById(
                    String(disSnap.docs[0].data().positionId)
                  ),
                  ...disSnap.docs[0].data(),
                };
              }
              return null;
            })
          );
          return Promise.all(promises);
        }

      });
  };


}