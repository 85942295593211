import React, { useState, useEffect } from 'react';
import {
  fetchAwards,
  deleteAward,
  clearAwardsData,
} from 'state/actions/awards';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import paths from 'pages/Router/paths';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import useFilterSearch from 'hooks/useFilterSearch';
import transformArrayToObject from 'utils/transformArrayToObject';
import { fetchLevels } from 'state/actions/levels';
import sortByDate from 'utils/sortByDate';

const Awards = () => {
  const {
    awardsList,
    error,
    loading,
    deleted,
    levelsList,
  } = useSelector(
    (state) => ({
      success: state.awards.success,
      awardsList: state.awards.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.awards.error,
      loading: state.awards.loading,
      deleted: state.awards.deleted,
      pageCount: state.awards.pageCount,
      levelsList: state.levels.data,
      levelsLoading: state.levels.loading,
    }),
    shallowEqual
  );

  const [levelsIndex, setLevelsIndex] = useState(null);

  useEffect(() => {
    setLevelsIndex(transformArrayToObject(levelsList));
  }, [levelsList]);

  const getLevelName = (id) => {
    try {
      return levelsIndex[`${id}`].name;
    } catch (e) {
      return 'id invalide';
    }
  };

  const [deleteModal, setDeleteModal] = useState({
    awardId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAwards());
    dispatch(fetchLevels());
    return () => dispatch(clearAwardsData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        awardId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearAwardsData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (awardId) => {
    setDeleteModal((prevState) => ({
      awardId,
      isOpen: !prevState.isOpen,
    }));
  };
  const onCloseModalHandler = () => {
    setDeleteModal({ awardId: null, isOpen: false });
  };
  const onDeleteAwardHandler = () => {
    dispatch(deleteAward(deleteModal.awardId));
  };

  const deleteMessage = useFormatMessage('Awards.delete');
  const confirmMessage = useFormatMessage('Awards.confirm');
  const permDeleteMessage = useFormatMessage('Awards.permDelete');
  const cancelMessage = useFormatMessage('Awards.cancel');

  const columns = [
    {
      Header: useFormatMessage('Awards.table.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Awards.table.levels'),
      accessor: 'levels',
      Cell({
        row: {
          original: { levels },
        },
      }) {
        if (levels && levelsIndex) {
          return (
            <div className="flex flex-wrap justify-center">
              {levels.map((levelId) => (
                <span
                  className="green-pill"
                  key={levelId}
                >
                  {getLevelName(levelId)}
                </span>
              ))}
            </div>
          );
        } else {
          return <span>N/A</span>;
        }
      },
    },
    {
      Header: useFormatMessage('Awards.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            to={`/awards/${row.original.id}`}
            isIconOnly
            text={useFormatMessage('Awards.table.action.edit')}
            icon="mdi-pencil"
            color="blue"
            size="small"
          />
          <Button
            isIconOnly
            className="mx-2"
            text={useFormatMessage('Awards.table.action.delete')}
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];

  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(awardsList);

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteAwardHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader
        title={useFormatMessage('Awards.title')}
        to={paths.ADD_AWARD}
      />
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Awards;
