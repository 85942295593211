import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'hooks';
import firebase from 'firebase.js';
import { agenciesCleanUp } from 'state/actions/agencies';

const Notifications = () => {
  const { isAdmin, userID } = useSelector((state) => {
    return {
      isAdmin: state.auth.userData.isAdmin,
      userID: state.auth.userData.id,
    };
  });

  // const getUserInfo =

  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    const notifRef = isAdmin
      ? firebase
          .firestore()
          .collection('notifications')
          .where('takenByAdmin', '==', false)
      : firebase
          .firestore()
          .collection('notifications')
          .where('takenByAgency', '==', false);
    const unsubscribe = notifRef.onSnapshot((querySnapshot) => {
      if (querySnapshot.size) {
        querySnapshot.docChanges().forEach(async (change) => {
          const userProfile = await firebase
            .firestore()
            .collection('users')
            .doc(change.doc.data().from)
            .get();
          const agence =
            change.doc.data().agency &&
            (await firebase
              .firestore()
              .collection('users')
              .doc(change.doc.data().agency)
              .get());

          const agenceName = agence && agence.data().name;

          setNotifications((prev) => [
            ...prev.filter((pnotif) => pnotif.id !== change.doc.id),
          ]);
          if (change.type === 'added') {
            if (isAdmin && change.doc.data().to === 'admin') {
              setNotifications((prev) => [
                ...prev,
                {
                  id: change.doc.id,
                  ...change.doc.data(),
                  imageUrl: (userProfile.data() || {}).logoUrl,
                  agency: agenceName,
                },
              ]);
            } else if (change.doc.data().to === userID) {
              setNotifications((prev) => [
                ...prev,
                {
                  id: change.doc.id,
                  ...change.doc.data(),
                  imageUrl: (userProfile.data() || {}).logoUrl,
                  agency: agenceName,
                },
              ]);
            }
          }
          if (change.type === 'modified') {
            setNotifications((prev) => [
              ...prev.filter((pnotif) => pnotif.id !== change.doc.id),
            ]);
          }
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [firebase]);

  const notifTypeColor = (type) => {
    switch (type) {
      case 'danger':
        return 'lightRed';
      case 'warning':
        return 'lightYellow';
      case 'success':
        return 'lightGreen';
      default:
        return 'lightBlue';
    }
  };

  const takenNotif = (notifId) => {
    const toUpdate = isAdmin ? { takenByAdmin: true } : { takenByAgency: true };
    firebase
      .firestore()
      .collection('notifications')
      .doc(notifId)
      .update(toUpdate);
  };

  const getNotificationDetails = (notification) => {
    if (notification.notificationType === 'product') {
      return (
        <>
          <div>
            <strong>Activation:</strong> {notification.activationName}
          </div>
          <div>
            <strong>Agence:</strong> {notification.agency}
          </div>
          <div>
            <strong>Produit:</strong> {notification.productName}
          </div>
          <div>
            <strong>Quantité initiale:</strong> {notification.qtyInitial}
          </div>
          <div>
            <strong>Quantité reçue:</strong> {notification.qtyRecieved}
          </div>
          <br />
        </>
      );
    }
    if (notification.notificationType === 'position') {
      return (
        <>
          <div>
            <strong>Activation:</strong> {notification.activationName}
          </div>
          <div>
            <strong>Agence:</strong> {notification.agency}
          </div>
          <div>
            <strong>Licence:</strong> {notification.positionLicence}
          </div>
          <div>
            <strong>Type de position:</strong> {notification.positionType}
          </div>
          <br />
        </>
      );
    }
    return <></>;
  };

  function formatDisplayDate(date) {
    const dateObject = new Date(date);
    return `${dateObject.getDate()}/${
      dateObject.getMonth() + 1
    }/${dateObject.getFullYear()}`;
  }

  return (
    <section className="p-2">
      <div
        className="bg-brand-lighterBlue"
        style={{ height: 700, overflowX: 'scroll' }}
      >
        {/* header  */}
        <div className="flex">
          <p className="bg-brand-blue font-bold text-white text-2xl px-4 py-3 rounded-br-md">
            {useFormatMessage('Home.notifications')}
          </p>
        </div>
        {/* notifications list  */}
        <div className="p-6">
          {notifications.map((notif) => (
            <div
              className="relative p-4"
              key={notif.id}
              onClick={() => takenNotif(notif.id)}
            >
              <span
                className={`absolute top-0 right-0 -mt-1 bg-white rounded-full border-2 border-brand-lighterBlue text-brand-${notifTypeColor(
                  notif.type
                )} w-10 h-10 flex items-center justify-center`}
              >
                <i className="mdi mdi-24px mdi-bell-ring" />
              </span>
              <div className="p-3 bg-white rounded-md">
                <div className="card">
                  <div className="card-content">
                    <div className="media">
                      <div className="media-left">
                        <div className="w-12 h-12 mb-2 rounded-full bg-gray-200">
                          <img
                            className="w-12 h-12 mb-2 rounded-full bg-gray-20"
                            src={notif.imageUrl}
                          />
                        </div>
                      </div>
                      <div className="media-content">
                        <p className="title is-4">{notif.userName}</p>
                      </div>
                    </div>

                    <div className="content">
                      {getNotificationDetails(notif)}
                      <div>
                        <strong>Message:</strong> {notif.message}
                      </div>
                      <br />
                      <time dateime="2016-1-1">
                        {formatDisplayDate(notif.createdAt.seconds * 1000)}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Notifications;
