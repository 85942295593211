/* eslint-disable camelcase */
import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError, displayText } from 'utils';
import RoleController from 'controllers/RoleController';
import Controller from 'controllers/Controller';
import firebase from 'firebase.js';

export const ROLES_FETCH_DATA_INIT = createAction('ROLES_FETCH_DATA_INIT');
export const ROLES_FETCH_DATA_SUCCESS = createAction(
  'ROLES_FETCH_DATA_SUCCESS'
);
export const ROLES_FETCH_DATA_FAIL = createAction('ROLES_FETCH_DATA_FAIL');
export const ROLES_CREATE_ROLE_INIT = createAction('ROLES_CREATE_ROLE_INIT');
export const ROLES_CREATE_ROLE_SUCCESS = createAction(
  'ROLES_CREATE_ROLE_SUCCESS'
);
export const ROLES_CREATE_ROLE_FAIL = createAction('ROLES_CREATE_ROLE_FAIL');
export const ROLES_CLEAR_DATA = createAction('ROLES_CLEAR_DATA');
export const ROLES_DELETE_ROLE_INIT = createAction('ROLES_DELETE_ROLES_INIT');
export const ROLES_DELETE_ROLE_SUCCESS = createAction(
  'ROLES_DELETE_ROLE_SUCCESS'
);
export const ROLES_DELETE_ROLE_FAIL = createAction('ROLES_DELETE_ROLES_FAIL');
export const ROLES_MODIFY_ROLE_INIT = createAction('ROLES_MODIFY_ROLE_INIT');
export const ROLES_MODIFY_ROLE_SUCCESS = createAction(
  'ROLES_MODIFY_ROLE_SUCCESS'
);
export const ROLES_MODIFY_ROLE_FAIL = createAction('ROLES_MODIFY_ROLE_FAIL');
export const ROLES_CLEAN_UP = createAction('ROLES_CLEAN_UP');

const rc = new RoleController();
const gc = new Controller('roles', 'role');

export const fetchRoles = () => {
  return async (dispatch) => {
    dispatch(ROLES_FETCH_DATA_INIT());
    let roles;
    try {
      roles = await gc.fetch();
    } catch (error) {
      toastr.error('Role', 'Operation a échoué');
      return dispatch(ROLES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(ROLES_FETCH_DATA_SUCCESS({ roles }));
  };
};

export const getRoleId = (name) => rc.getRoleId(name);

export const createRole = ({ name, permissions }) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_CREATE_ROLE_INIT());
    const { locale } = getState().preferences;
    let role;
    try {
      const displayName = displayText(name);
      role = await gc.create({ name, displayName, permissions }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Role', 'la création a échoué');
      return dispatch(ROLES_CREATE_ROLE_FAIL({ error: errorMessage }));
    }
    toastr.success('Role', 'créé avec succès');
    return dispatch(ROLES_CREATE_ROLE_SUCCESS({ role }));
  };
};

export const deleteRole = (id) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_DELETE_ROLE_INIT());
    const { locale } = getState().preferences;
    try {
      const users = await firebase
        .firestore()
        .collection('users')
        .where('roleId', '==', id)
        .get();
      if (users.size > 0) {
        const err = new Error('role already used');
        err.code = 401;
        throw err;
      }
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Role', 'la suppression a échoué');
      return dispatch(ROLES_DELETE_ROLE_FAIL({ error: errorMessage }));
    }

    toastr.success('Role', 'Supprimé avec succès');
    return dispatch(ROLES_DELETE_ROLE_SUCCESS({ id }));
  };
};

export const modifyRole = ({ name, id, permissions }) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_MODIFY_ROLE_INIT());
    const { locale } = getState().preferences;
    let role;
    try {
      const displayName = displayText(name);
      role = gc.update(id, { name, displayName, permissions }, 'displayName');
      toastr.success('Role', 'mis à jour avec succès');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Role', 'mise à jour a échoué');
      return dispatch(ROLES_MODIFY_ROLE_FAIL({ error: errorMessage }));
    }

    toastr.success('Role', 'mis à jour avec succès');
    return dispatch(ROLES_MODIFY_ROLE_SUCCESS({ role }));
  };
};

export const rolesCleanUp = () => (dispatch) => dispatch(ROLES_CLEAN_UP());

export const clearRolesData = () => {
  return (dispatch) => {
    dispatch(ROLES_CLEAR_DATA());
  };
};
