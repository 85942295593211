import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler } from 'hooks';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';

import { proCategoriesCleanUp } from 'state/actions/proCategories';

const ProductsCategoryForm = ({ proCategoryData, action }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state) => ({
      loading: state.posCategories.loading,
    }),
    shallowEqual
  );
  const [proCategory, setProCategory] = useState(proCategoryData);

  useEffect(() => {
    return () => dispatch(proCategoriesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setProCategory);

  const canSubmit = proCategory.name;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(proCategory));
    setProCategory({ name: '' });
  };

  return (
    <form onSubmit={onSubmitHandler}>
      {/* form  */}
      <div className="flex items-center rounded-lg border border-brand-lighterBlue py-2 max-w-2xl">
        <NormalInput
          className="flex-auto max-w-md"
          label="Nome"
          required
          name="name"
          value={proCategory.name}
          onChange={onChangeHandler}
        />
        <Button
          className={`mt-6 mx-4 ${loading && 'is-loading'}`}
          text="Ajouter"
          icon="mdi-plus-circle"
          color="green"
          size="large"
          disabled={!canSubmit}
          type="submit"
        />
      </div>
    </form>
  );
};

export default ProductsCategoryForm;
