import React from 'react';

const NormalInput = ({ className, label, style, icon, isFilter, ...props }) => {
  /**
   * @param {String} label : label for the input
   * @param {String} icon : an mdi class to display an icon on the right, ex: mdi-map-marker
   * @param {Object} style : regular styles object to style the container
   * @param {String} className : classes for the container
   * @param {Boolean} isFilter : if true , the filter variant is returned, if not the form variant is returned
   */
  
   const FilterInput = (
    <div className={`tw-filter-input ${className}`} style={style}>
      <span className="tw-icon">
        <i className={`mdi mdi-18px ${icon || ''}`}></i>
      </span>
      <input type="text" {...props} />
    </div>
  );
  const FormInput = (
    <div className={`p-2 ${className}`} style={style}>
      <label
        className="px-2 font-semibold text-brand-lightBlue"
        htmlFor={props.name || ''}
      >
        {label}
      </label>
      <div className="control p-1">
        <div className="tw-input relative">
          <input {...props} />
          <span className="tw-icon">
            <i className={`mdi mdi-18px ${icon || ''}`}></i>
          </span>
        </div>
      </div>
    </div>
  );
  return isFilter ? FilterInput : FormInput;
};

export default React.memo(NormalInput);
