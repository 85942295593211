import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';

export const AWARDS_FETCH_DATA_INIT = createAction('AWARDS_FETCH_DATA_INIT');
export const AWARDS_FETCH_DATA_SUCCESS = createAction(
  'AWARDS_FETCH_DATA_SUCCESS'
);
export const AWARDS_FETCH_DATA_FAIL = createAction('AWARDS_FETCH_DATA_FAIL');
export const AWARDS_CREATE_AWARD_INIT = createAction(
  'AWARDS_CREATE_AWARD_INIT'
);
export const AWARDS_CREATE_AWARD_SUCCESS = createAction(
  'AWARDS_CREATE_AWARD_SUCCESS'
);
export const AWARDS_CREATE_AWARD_FAIL = createAction(
  'AWARDS_CREATE_AWARD_FAIL'
);
export const AWARDS_CLEAR_DATA = createAction('AWARDS_CLEAR_DATA');
export const AWARDS_DELETE_AWARD_INIT = createAction(
  'AWARDS_DELETE_AWARDS_INIT'
);
export const AWARDS_DELETE_AWARD_SUCCESS = createAction(
  'AWARDS_DELETE_AWARD_SUCCESS'
);
export const AWARDS_DELETE_AWARD_FAIL = createAction(
  'GAMESS_DELETE_AWARDS_FAIL'
);
export const AWARDS_MODIFY_AWARD_INIT = createAction(
  'AWARDS_MODIFY_AWARD_INIT'
);
export const AWARDS_MODIFY_AWARD_SUCCESS = createAction(
  'AWARDS_MODIFY_AWARD_SUCCESS'
);
export const AWARDS_MODIFY_AWARD_FAIL = createAction(
  'AWARDS_MODIFY_AWARD_FAIL'
);
export const AWARDS_CLEAN_UP = createAction('AWARDS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('awards', 'award');

/**
 * Action awards
 * @return dispatch
 */
export const fetchAwards = () => {
  return async (dispatch) => {
    dispatch(AWARDS_FETCH_DATA_INIT());
    let awards;
    try {
      awards = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', "Operation a échoué");
      return dispatch(AWARDS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(AWARDS_FETCH_DATA_SUCCESS({ awards }));
  };
};

/**
 * Action Create new award
 * @param {string} name Name of the award
 * @return dispatch
 */
export const createAward = ({ name, levels }) => {
  return async (dispatch, getState) => {
    dispatch(AWARDS_CREATE_AWARD_INIT());
    const { locale } = getState().preferences;
    let award;
    try {
      const displayName = displayText(name);
      award = await gc.create({ name, levels, displayName }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', "la création a échoué"
      );
      return dispatch(AWARDS_CREATE_AWARD_FAIL({ error: errorMessage }));
    }
    toastr.success('Gain', "créé avec succès");
    return dispatch(AWARDS_CREATE_AWARD_SUCCESS({ award }));
  };
};

/**
 * Action Delete an award
 * @param {string} id ID of the award
 * @return dispatch
 */
export const deleteAward = (id) => {
  return async (dispatch, getState) => {
    dispatch(AWARDS_DELETE_AWARD_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', "la suppression a échoué"
      );
      return dispatch(AWARDS_DELETE_AWARD_FAIL({ error: errorMessage }));
    }
    toastr.success('Gain', "Supprimé avec succès");
    return dispatch(AWARDS_DELETE_AWARD_SUCCESS({ id }));
  };
};

/**
 * Action Update an award
 * @param {string} name Name of the award
 * @return dispatch
 */
export const modifyAward = ({ name, levels, id }) => {
  return async (dispatch, getState) => {
    dispatch(AWARDS_MODIFY_AWARD_INIT());
    const { locale } = getState().preferences;
    let award;
    try {
      const displayName = displayText(name);
      award = gc.update(id, { name, levels, displayName }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', "mise à jour a échoué"
      );
      return dispatch(AWARDS_MODIFY_AWARD_FAIL({ error: errorMessage }));
    }
    toastr.success('Gain', "mis à jour avec succès");
    return dispatch(AWARDS_MODIFY_AWARD_SUCCESS({ award }));
  };
};

export const awardsCleanUp = () => (dispatch) => dispatch(AWARDS_CLEAN_UP());

export const clearAwardsData = () => {
  return (dispatch) => {
    dispatch(AWARDS_CLEAR_DATA());
  };
};
