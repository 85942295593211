import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import { useFormatDate, useSecondToHours } from 'hooks';

const moment = require('moment');

const TeamDetails = ({ activation, loading }) => {
  const [animators, setAnimators] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  useEffect(() => {
    const { positions, users } = activation;
    if (positions) {
      positions.forEach((pos) => {
        if (pos.team) {
          const {
            team: { groups },
            position: { name: positionName },
          } = pos;
          if (groups && positionName) {
            groups.map((group) => {
              if (group.supervisor)
                setSupervisors([
                  ...supervisors,
                  {
                    ...group.supervisor,
                    positionName,
                    ...users.find((usr) => usr.id === group.supervisor.id),
                  },
                ]);
              if (group.animators)
                setAnimators([
                  ...animators,
                  ...group.animators.map((anim) => ({
                    ...anim,
                    positionName,
                    ...users.find((usr) => usr.id === anim.id),
                  })),
                ]);
            });
          }
        }
      });
    }
  }, [activation]);

  const columnsAnimators = [
    {
      Header: 'Nom',
      accessor: 'name',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.name || '-----'}
        </div>
      ),
    },
    {
      Header: 'Stock initial',
      accessor: 'initialStock',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.quantityInitial || 0}
        </div>
      ),
    },
    {
      Header: 'Stock Observé',
      accessor: 'stockObserved',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.quantityReceived || 0}
        </div>
      ),
    },
    {
      Header: 'Stock écoulé',
      accessor: 'stockEcoule',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.totalProducts || 0}
        </div>
      ),
    },
    {
      Header: 'Contacts récupérés',
      accessor: 'contactsRecuperes',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.totalContacts || 0}
        </div>
      ),
    },
    {
      Header: 'Temps travail',
      accessor: 'Temps_travail',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {moment('2015-01-01')
            .startOf('day')
            .seconds(row.original.workTime ? row.original.workTime : 0)
            .format('H:mm:ss') || '00 min'}
        </div>
      ),
    },
    {
      Header: 'Heure début connexion',
      accessor: 'Heure_début_connexion',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {useFormatDate(
            row.original.startTime
              ? row.original.startTime.toDate()
              : new Date(0),
            {
              hour: 'numeric',
              minute: 'numeric',
            }
          ) || '-----'}
        </div>
      ),
    },
    {
      Header: 'Plus',
      accessor: '',
      Cell({ row }) {
        return (
          <Button
            to={`/member/${activation.id}/${row.original.id}/animator`}
            className="mx-auto"
            isIconOnly
            color="blue"
            text="Consulter"
            icon="mdi-eye"
            size="small"
          />
        );
      },
    },
  ];

  const columnsSupervisors = [
    {
      Header: 'Nom',
      accessor: 'name',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.name || '----'}
        </div>
      ),
    },
    {
      Header: 'Stock initial',
      accessor: 'initialStock',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.quantityInitial || 0}
        </div>
      ),
    },
    {
      Header: 'Stock Observé',
      accessor: 'observedStock',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.quantityReceived || 0}
        </div>
      ),
    },
    {
      Header: 'Stock écoulé',
      accessor: 'stockEcoule',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.totalProducts || 0}
        </div>
      ),
    },
    {
      Header: 'Stock restant',
      accessor: 'restStock',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {(row.original.quantityReceived || 0) - (row.original.totalProducts || 0)}
        </div>
      ),
    },
    {
      Header: 'Plus',
      accessor: '',
      Cell({ row }) {
        return (
          <Button
            to={`/member/${activation.id}/${row.original.id}/supervisor`}
            className="mx-auto"
            isIconOnly
            color="blue"
            text="Consulter"
            icon="mdi-eye"
            size="small"
          />
        );
      },
    },
  ];

  return (
    <div className="p-4 border">
      {/* table  1 */}
      <div className="pb-6">
        {/* title */}
        <div className="pb-2 flex justify-between">
          <h1 className="text-2xl text-brand-darkBlue font-semibold">
            Compte rendu animateurs à la date indiqué
          </h1>
          {/* date filter  */}
          <NormalInput placeholder="Date" isFilter icon="mdi-calendar" />
        </div>
        <div className="">
          <Table
            loading={loading}
            data={animators}
            columns={columnsAnimators}
          />
        </div>
      </div>
      {/* table  1 */}
      <div className="pb-6">
        {/* title */}
        <div className="pb-2 flex justify-between">
          <h1 className="text-2xl text-brand-darkBlue font-semibold">
            Compte rendu superviseurs à la même date
          </h1>
        </div>
        <div className="">
          <Table
            loading={loading}
            data={supervisors}
            columns={columnsSupervisors}
          />
        </div>
      </div>
    </div>
  );
};
//
export default TeamDetails;
