import React from 'react';
import DatePickerStyled from 'components/DatePicker';
import classnames from 'classnames';

const DateRange = ({
  fromProps,
  toProps,
  fromText,
  toText,
  isForm,
  label,
  className,
}) => {
  return (
    <div className={classnames('p-2', { className: className })}>
      {isForm && label && (
        <label className="text-brand-lightBlue font-semibold px-1">
          {label}
        </label>
      )}
      <div
        style={{ width: '300px' }}
        className={classnames('tw-date-range', { 'is-form': isForm })}
      >
        <span>{fromText || 'From'} </span>
        <DatePickerStyled {...fromProps} />
        <span>{toText || 'To'} </span>
        <DatePickerStyled {...toProps} />
        <span className="icon">
          <i className="mdi mdi-calendar mdi-24px"></i>
        </span>
      </div>
    </div>
  );
};

export default DateRange;
