import Controller from './Controller';

export default class GameController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'games';
    this.item = 'game';
  }

  /**
   * Get Game data
   * @param {string} gameId Game ID
   * @returns {Object} object of Game data or false
   */
  fetchById = async (gameId) =>
    gameId
      ? this.getDoc(gameId).then((gameSnap) => {
          if (gameSnap.exists) {
            return {
              id: gameSnap.id,
              ...gameSnap.data(),
            };
          }
          return null;
        })
      : null;
}
