import PageHeader from 'components/PageHeader';
import React from 'react';

import { useFormatMessage } from 'hooks';
import { useState } from 'react';
import CenteredLoader from 'components/Common/Loader';
import { useParams } from 'react-router-dom';
import PositionController from 'controllers/PositionController';
import { useEffect } from 'react';

const posCont = new PositionController();

const PositionDetails = () => {
  // get pos id
  const { id } = useParams();
  // loader state
  const [loading, setLoading] = useState(true);
  // pos state
  const [position, setPosition] = useState(null);
  // error state
  const [error, setError] = useState('');
  // on mount
  useEffect(() => {
    // definition
    const fetchPos = async () => {
      try {
        // get data
        const data = await posCont.fetchById(id);
        // handle data if not empty
        if (data) {
          setPosition(data);
        }
        // if empty
        else {
          setError("Oops, quelque chose s'est mal passé");
        }
        setLoading(false);
      } catch (e) {}
    };
    // execution
    fetchPos();
  }, [id]);

  // translations

  const numberOfGoodies = useFormatMessage('PositionsDetails.numberOfGoodies');
  const startTime = useFormatMessage('PositionsDetails.startTime');
  const workHours = useFormatMessage('PositionsDetails.workHours');
  const status = useFormatMessage('PositionsDetails.status');

  // comp parts
  const errorJsx = (
    <div className="p-4 text-2xl text-center text-brand-darkBlue">{error}</div>
  );
  const detailsCard = position && (
    <div className=" rounded-lg bg-brand-veryLightBlue border border-blue-300 p-3">
      {/* top row  */}
      <div className="flex">
        {/* item  */}
        <div className="flex w-3/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">Nom</h1>
          <p className="text-gray-700 p-2"> {position.name}</p>
        </div>
        {/* item  */}
        <div className="flex w-4/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Licence
          </h1>
          <p className="text-gray-700 p-2">{position.matricule}</p>
        </div>
        {/* item  */}
        <div className="flex w-5/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            {startTime}
          </h1>
          <p className="text-gray-700 p-2">{position.startHour}</p>
        </div>
      </div>
      <hr className="border-b-0 border-solid border-blue-300 m-3" />
      {/* bottom row  */}
      <div className="flex">
        {/* item  */}
        <div className="flex w-6/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Zone
          </h1>
          <p className="text-brand-lightGreen font-semibold p-2">{position.zone}</p>
        </div>
        {/* item  */}
        <div className="flex w-6/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">{status}</h1>
          <p className="text-brand-lightGreen font-semibold p-2">{position.status}</p>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <PageHeader title="Details de position" hideButton />
      <div className="page-padding">
        {loading ? <CenteredLoader /> : error ? errorJsx : detailsCard}
      </div>
    </>
  );
};

export default PositionDetails;
