/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState } from 'react';

import Table from 'components/Table';
import { CSVLink } from 'react-csv';
import DateRange from 'components/Common/DateRange';

import { useFormatMessage } from 'hooks';
import Button from 'components/Common/Button';

const ReportingParticipants = () => {
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(),
    periodEnd: new Date(),
  });

  const data = [
    {
      date: '05/11/2021',
      position: 'POS 237877',
      nomClient: 'amal',
      telClient: '0698477848',
      contacte: 'oui',
      tranchAge: 'Entre 18 – 24 ans"',
      provenceMarque: 'davidoff-slim-violet',
      refus: 'oui',
      gFF: 'non',
      gLight: 'non',
      gRedMix: 'non',
      gratuit: '----',
    },
    {
      date: '05/11/2021',
      position: 'POS 278712',
      nomClient: 'mohammed',
      telClient: '0698477848',
      contacte: 'oui',
      tranchAge: 'Entre 25 – 30 ans"',
      provenceMarque: 'Winston',
      refus: 'non',
      gFF: 'oui',
      gLight: 'non',
      gRedMix: 'non',
      gratuit: 'CASQUETTES VLM',
    },
    {
      date: '05/11/2021',
      position: 'POS 162761',
      nomClient: 'rachid',
      telClient: '0698477848',
      contacte: 'oui',
      tranchAge: 'Entre 30 – 35 ans"',
      provenceMarque: 'winston-silver',
      refus: 'non',
      gFF: 'oui',
      gLight: 'non',
      gRedMix: 'non',
      gratuit: 'CASQUETTES VLM',
    },
    {
      date: '05/11/2021',
      position: 'POS 2083625',
      nomClient: 'ahmed',
      telClient: '0698477848',
      contacte: 'oui',
      tranchAge: 'Entre 25 – 30 ans"',
      provenceMarque: 'marlboro-gold',
      refus: 'non',
      gFF: 'oui',
      gLight: 'non',
      gRedMix: 'non',
      gratuit: 'CASQUETTES VLM',
    },
    {
      date: '05/11/2021',
      position: 'POS 321184',
      nomClient: 'anas',
      telClient: '0698477848',
      contacte: 'oui',
      tranchAge: 'Entre 18 – 25 ans"',
      provenceMarque: 'davidoff-light',
      refus: 'non',
      gFF: 'oui',
      gLight: 'non',
      gRedMix: 'non',
      gratuit: 'CASQUETTES VLM',
    },
    {
      date: '05/11/2021',
      position: 'POS 112312',
      nomClient: 'moustapha',
      telClient: '0698477848',
      contacte: 'oui',
      tranchAge: 'Entre 25 – 30 ans"',
      provenceMarque: 'marquise-soft-ff',
      refus: 'non',
      gFF: 'oui',
      gLight: 'non',
      gRedMix: 'non',
      gratuit: 'CASQUETTES VLM',
    },
  ];

  const columns = [
    {
      Header: 'INFO ANIMATION',
      columns: [
        {
          Header: 'Date',
          accessor: 'date',
        },
        {
          Header: 'Position',
          accessor: 'position',
        },
      ],
    },
    {
      Header: 'INFO CLIENT',
      columns: [
        {
          Header: 'Nom',
          accessor: 'nomClient',
        },
        {
          Header: 'N Tel',
          accessor: 'telClient',
        },
        {
          Header: 'Accepter de étre contacté ',
          accessor: 'contacte',
        },
      ],
    },
    {
      Header: 'PROFILE',
      columns: [
        {
          Header: 'Tranch de age',
          accessor: 'tranchAge',
        },
        {
          Header: 'Provence marque',
          accessor: 'provenceMarque',
        },
      ],
    },
    {
      Header: 'ACT DE ACHAT',
      columns: [
        {
          Header: 'Refus',
          accessor: 'refus',
        },
        {
          Header: 'Achat Gauloise FF',
          accessor: 'gFF',
        },
        {
          Header: 'Achat Gauloise Light ',
          accessor: 'gLight',
        },
        {
          Header: 'Achat Gauloise Red Mix ',
          accessor: 'gRedMix',
        },
      ],
    },
    {
      Header: 'AGAIN',
      columns: [
        {
          Header: 'Nom gratuit',
          accessor: 'gratuit',
        },
      ],
    },
  ];

  return (
    <div className="page-padding" style={{ overflow: 'auto' }}>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="text-brand-darkBlue text-3xl font-semibold">
                  Données des participants
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="flex">
          <DateRange
            label={useFormatMessage('ActivationsDetails.card.period')}
            isForm
            fromText={useFormatMessage('Activation.informationsTab.from')}
            fromProps={{
              name: 'periodStart',
              setState: setDateSearch,
              date: new Date(dateSearch.periodStart),
            }}
            toText={useFormatMessage('Activation.informationsTab.to')}
            toProps={{
              name: 'periodEnd',
              setState: setDateSearch,
              date: new Date(dateSearch.periodEnd),
            }}
          />
          <Button
            style={{ height: 'max-content', marginTop: '38px' }}
            size="small"
            color="blue"
            text="Afficher Reporting"
            // onClick={searchReporting}
          />
          <CSVLink
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              background: '#3273dc',
              color: '#fff',
              borderRadius: 9999,
              display: 'inline-block',
              padding: 3,
              backgroundColor: '#2699fb',
              marginLeft: 50,
              height: 'max-content',
              marginTop: '38px',
            }}
            data={data}
          >
            Télécharger Reporting
          </CSVLink>
        </div>
      </section>
      <div className="flex">
        <div className="flex flex-col p-3">
          <div className="w-full reporting-table ">
            <Table
              tailwind="w-64 bg-black"
              columns={columns}
              data={data || []}
              loading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingParticipants;
