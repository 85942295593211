import React from 'react';
import { ClipLoader } from 'react-spinners';
import classnames from 'classnames';

const CenteredLoader = ({ noPadding, className }) => {
  return (
    <div
      className={classnames(`flex justify-center`, {
        'py-10': !noPadding,
        [`${className}`]: className,
      })}
    >
      <ClipLoader color="#90cdf4" />
    </div>
  );
};

export default CenteredLoader;
