export const getDateYearArray = (agenciesList) => {
  var newList = [];
  agenciesList.map((item) => {
    if (!newList.includes(new Date(item.createdAt).getFullYear()))
      newList.push(new Date(item.createdAt).getFullYear());
  });
  return newList.map((item) => {
    return { label: item, accessor: 'createdAt' };
  });
};
