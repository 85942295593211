import React, { useState, useEffect } from 'react';
import firebase from 'firebase.js';
import { createReference, modifyReference } from 'state/actions/references';
import PageHeader from 'components/PageHeader';
import ReferenceForm from 'components/ReferenceForm';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import paths from 'pages/Router/paths';
import ClipLoader from 'react-spinners/ClipLoader';

const Reference = () => {
  const { success } = useSelector(
    (state) => ({
      success: state.references.success,
    }),
    shallowEqual
  );
  const { id } = useParams();
  const [reference, setReference] = useState({
    name: '',
    brand: '',
    active: false,
    isMultiMark: false,
    imageUrl: '',
    argUrl: '',
    source: '',
  });

  useEffect(() => {
    const fetchReferenceData = async () => {
      const response = await firebase
        .firestore()
        .collection('references')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchReferenceData()
        .then(async (referenceData) => {
          if (typeof referenceData.data().brand === 'string') {
            const brandRef = await firebase
              .firestore()
              .collection('marks')
              .doc(referenceData.data().brand)
              .get();

            setReference({
              ...referenceData.data(),
              brand: { ...brandRef.data(), id: brandRef.id },
              id,
            });
          } else {
            const objectbrandRef = await firebase
              .firestore()
              .collection('marks')
              .where(
                'displayName',
                '==',
                referenceData.data().brand.displayName
              )
              .get();

            setReference({
              ...referenceData.data(),
              brand: {
                ...referenceData.data().brand,
                id: objectbrandRef.docs[0].id,
              },
              id,
            });
          }
        })
        .catch(() => {
          setReference({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const referenceForm =
    !reference.name && id ? (
      <ClipLoader />
    ) : (
      <ReferenceForm
        isEditing={isEditing}
        referenceData={reference}
        action={isEditing ? modifyReference : createReference}
      />
    );

  const redirect = (reference.error || success) && (
    <Redirect to={paths.REFERENCES} />
  );

  return (
    <>
      {redirect}
      <PageHeader title="Référence" hideButton />
      <div className="page-padding">{referenceForm}</div>
    </>
  );
};

export default Reference;
