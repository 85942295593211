import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate, useCan } from 'hooks';
import Table from 'components/Table';
import { fetchUsers, deleteUser, clearUsersData } from 'state/actions/users';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import PageHeader from 'components/PageHeader';
import FilterSearch from 'components/Common/FilterSearch';
import useFilterSearch from 'hooks/useFilterSearch';
import Button from 'components/Common/Button';
import sortByDate from 'utils/sortByDate';

const Users = () => {
  const { usersList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      usersList: state.users.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
    }),
    shallowEqual
  );

  const authorize = useCan(['view-members', 'view-users']);

  const [search, setSearch] = useState();
  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (authorize) {
      dispatch(fetchUsers());
    }
    return () => dispatch(clearUsersData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const redirect = !authorize && <Redirect to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteUser(deleteModal.userId));
  };

  const columns = [
    {
      Header: useFormatMessage('Users.idNum'),
      accessor: 'idNum',
    },
    {
      Header: useFormatMessage('Users.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Users.location'),
      accessor: 'location',
    },
    {
      Header: useFormatMessage('Users.role'),
      accessor: 'role',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.isAdmin ? 'Admin' : (row.original.role || {}).name}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Users.created'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="flex justify-center">
              <Button
                isIconOnly
                className="mx-1"
                to={`/users/${row.original.id}`}
                text="modifer"
                color="blue"
                size="small"
                icon="mdi-pencil"
              />

              <Button
                isIconOnly
                className="mx-1"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
                text="modifer"
                color="red"
                size="small"
                icon="mdi-delete"
              />
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];
  if (isAdmin) {
    columns.splice(2, 0, {
      Header: 'email',
      accessor: 'email',
    });
  }
  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(usersList);

  // end filter logic
  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Utilisateurs" to={paths.ADD_USER} />
      <div className="page-padding">
        <div className="px-2">
          <FilterSearch
            hideSelect
            loading={loading}
            selectProps={selectProps}
            searchProps={searchProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Users;
