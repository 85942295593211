import React from 'react';
import { Redirect } from 'react-router-dom';
import ContactForm from 'components/ContactForm';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import PageHeader from 'components/PageHeader';

const Contact = () => {
  const { success } = useSelector(
    (state) => ({
      success: state.contacts.success,
    }),
    shallowEqual
  );

  const redirect = success && <Redirect to={paths.ACTIVATIONS_PARTICIPANTS} />;

  return (
    <>
      {redirect}
      <PageHeader title="Participant" hideButton />
      <div className="page-padding">
        <ContactForm />
      </div>
    </>
  );
};

export default Contact;
