import { createReducer } from 'redux-act';
import {
  PARAM_VIDEOS_FETCH_DATA_INIT,
  PARAM_VIDEOS_FETCH_DATA_SUCCESS,
  PARAM_VIDEOS_FETCH_DATA_FAIL,
  PARAM_VIDEOS_CREATE_VIDEO_INIT,
  PARAM_VIDEOS_CREATE_VIDEO_SUCCESS,
  PARAM_VIDEOS_CREATE_VIDEO_FAIL,
  PARAM_VIDEOS_DELETE_VIDEO_INIT,
  PARAM_VIDEOS_DELETE_VIDEO_SUCCESS,
  PARAM_VIDEOS_DELETE_VIDEO_FAIL,
  PARAM_VIDEOS_MODIFY_VIDEO_INIT,
  PARAM_VIDEOS_MODIFY_VIDEO_SUCCESS,
  PARAM_VIDEOS_MODIFY_VIDEO_FAIL,
  PARAM_VIDEOS_CLEAN_UP,
  PARAM_VIDEOS_CLEAR_DATA,
} from 'state/actions/paramVideos';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const paramVideosReducer = createReducer(
  {
    [PARAM_VIDEOS_FETCH_DATA_INIT]: () => {
      return {
        ...initialState,
        loading: true,
      };
    },
    [PARAM_VIDEOS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.paramVideos,
      loading: false,
      error: null,
    }),
    [PARAM_VIDEOS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PARAM_VIDEOS_CREATE_VIDEO_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PARAM_VIDEOS_CREATE_VIDEO_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: [{ ...payload.paramVideo }, ...state.data],
        loading: false,
        error: null,
        success: true,
      };
    },
    [PARAM_VIDEOS_CREATE_VIDEO_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PARAM_VIDEOS_DELETE_VIDEO_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PARAM_VIDEOS_DELETE_VIDEO_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [PARAM_VIDEOS_DELETE_VIDEO_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PARAM_VIDEOS_MODIFY_VIDEO_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PARAM_VIDEOS_MODIFY_VIDEO_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: [
          ...state.data.map((elem) => {
            if (elem.id === payload.paramVideo.id) {
              return {
                name: payload.paramVideo.name,
                link: payload.paramVideo.link,
                displayName: payload.paramVideo.displayName,
                id: payload.paramVideo.id,
                createdAt: payload.paramVideo.createdAt,
              };
            }
            return elem;
          }),
        ],
        loading: false,
        error: null,
        success: true,
      };
    },
    [PARAM_VIDEOS_MODIFY_VIDEO_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PARAM_VIDEOS_CLEAN_UP]: (state) => {
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
      };
    },
    [PARAM_VIDEOS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
