import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import paths from 'pages/Router/paths';

import {
  fetchProCategories,
  deleteProCategory,
  clearProCategoriesData,
} from 'state/actions/proCategories';
import PageHeader from 'components/PageHeader';
import FilterSearch from 'components/Common/FilterSearch';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const ProduitsCategories = () => {
  const [search, setSearch] = useState('');
  const [deleteModal, setDeleteModal] = useState({
    proCatId: null,
    isOpen: false,
  });
  const dispatch = useDispatch();
  const { proCategoriesList, error, loading, deleted } = useSelector(
    (state) => ({
      success: state.proCategories.success,
      proCategoriesList: state.proCategories.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.proCategories.error,
      loading: state.proCategories.loading,
      deleted: state.proCategories.deleted,
    }),
    shallowEqual
  );

  // Effect handlers
  useEffect(() => {
    dispatch(fetchProCategories());
    return () => dispatch(clearProCategoriesData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        proCatId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  // Event handlers
  const onRemoveButtonClickHandler = (proCatId) => {
    setDeleteModal((prevState) => ({
      proCatId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ proCatId: null, isOpen: false });
  };

  const onDeleteProCategoryHandler = () => {
    dispatch(deleteProCategory(deleteModal.proCatId));
  };

  const deleteMessage = useFormatMessage('Permissions.delete');
  const confirmMessage = useFormatMessage('Permissions.confirm');
  const permDeleteMessage = useFormatMessage('Permissions.permDelete');
  const cancelMessage = useFormatMessage('Permissions.cancel');

  const columns = [
    {
      Header: 'Nature',
      accessor: 'name',
    },
    {
      Header: "Date de création",
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <div className="text-brand-darkBlue">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </div>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            isIconOnly
            to={`/produits/categories/${row.original.id}`}
            className="mx-2"
            text="Modifier"
            icon="mdi-pencil"
            color="blue"
            size="small"
          />
          <Button
            isIconOnly
            className="mx-2"
            text="Supprimer"
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];
  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(
    proCategoriesList
  );

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteProCategoryHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Natures" to={paths.ADD_PRODUCTS_CATEGORY} />
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            hideSelect
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default ProduitsCategories;
