import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
/* eslint-disable camelcase */
import firebase from 'firebase.js';

export const PRODUCT_DETAILS_FETCH_DATA_INIT = createAction(
  'PRODUCT_DETAILS_FETCH_DATA_INIT'
);
export const PRODUCT_DETAILS_FETCH_DATA_SUCCESS = createAction(
  'PRODUCT_DETAILS_FETCH_DATA_SUCCESS'
);
export const PRODUCT_DETAILS_FETCH_DATA_FAIL = createAction(
  'PRODUCT_DETAILS_FETCH_DATA_FAIL'
);
export const PRODUCT_DETAILS_CLEAR_DATA = createAction(
  'PRODUCT_DETAILS_CLEAR_DATA'
);
export const PRODUCT_DETAILS_CLEAN_UP = createAction(
  'PRODUCT_DETAILS_CLEAN_UP'
);

export const fetchProductDetails = (productId, activationId) => {
  return async (dispatch) => {
    dispatch(PRODUCT_DETAILS_FETCH_DATA_INIT());
    let marks;
    try {
      // await withPromise();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(PRODUCT_DETAILS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(PRODUCT_DETAILS_FETCH_DATA_SUCCESS({ marks }));
  };
};

export const productDetailsCleanUp = () => (dispatch) =>
  dispatch(PRODUCT_DETAILS_CLEAN_UP());

export const clearProductDetailsData = () => {
  return (dispatch) => {
    dispatch(PRODUCT_DETAILS_CLEAR_DATA());
  };
};
