/* eslint-disable no-param-reassign */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import readXlsxFile, { parseExcelDate } from 'read-excel-file';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useFormatMessage, useFormatDate, useChangeHandler } from 'hooks';
import { PropagateLoader } from 'react-spinners';
import { rolesName } from 'utils';

import { fetchPositions } from 'state/actions/positions';
import { fetchUsers } from 'state/actions/users';
import { createActivation, modifyActivation } from 'state/actions/activations';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import DatePickerStyled from 'components/DatePicker';
import FileInput from 'components/Common/FileInput';
import Button from 'components/Common/Button';
import Table from 'components/Table';

/**
 * @param {function} next go to next form step
 * @param {function} previous return to prev form step
 * @param {function} setActivation assign values to the root activation object
 * @param {object} activation get the root activation object data
 * @param {boolean} isEditing if forms for editing
 */
const Positions = ({
  next,
  previous,
  setActivation,
  activation,
  isEditing,
}) => {
  const dispatch = useDispatch();

  const {
    loading,
    success,
    actPositions,
    actPositionsLoading,
    users,
    usersLoading,
  } = useSelector(
    (state) => ({
      loading: state.activations.loading,
      success: state.activations.success,
      actPositions: state.positions.data,
      actPositionsLoading: state.positions.loading,
      users: state.users.data,
      usersLoading: state.users.loading,
    }),
    shallowEqual
  );
  // Global states
  const [positions, setPositions] = useState(activation.positions || []);
  const [position, setPosition] = useState({
    positionName: '',
    positionId: '',
    agencyName: '',
    agencyId: '',
    promotorName: '',
    promotorId: '',
    date: new Date(),
    status: 'INITIAL',
    startHour: '',
    positionBackup: '',
  });
  // State used for uploading
  const [finish, setFinish] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showErrors, setShowErrors] = useState([]);
  const [activationTeams, setActivationTeams] = useState([]);

  const selectPosOptions = actPositions.map((actPos) => ({
    value: actPos.id,
    label: actPos.name,
  }));

  const selectAgencyOptions = users
    .filter((u) => u.role && u.role.name === rolesName.manager)
    .map((actAgc) => ({
      value: actAgc.id,
      label: actAgc.name,
    }));

  const selectPromotorOptions = users
    .filter((u) => u.role && u.role.name === rolesName.promotor)
    .map((actProm) => ({
      value: actProm.id,
      label: actProm.name,
    }));

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchPositions());
  }, [dispatch]);

  useEffect(() => {
    setActivation({ ...activation, positions });
  }, [positions]);

  const onChangeHandler = useChangeHandler(setPosition);

  const onChangeSelect = (type, opt) => {
    let setOpt;
    switch (type) {
      case 'pos':
        setOpt = !actPositionsLoading
          ? { positionName: opt.label, positionId: opt.value }
          : null;
        break;
      case 'backup':
        setOpt = !actPositionsLoading
          ? { positionName: opt.label, positionBackup: opt.value }
          : null;
        break;
      case 'promotor':
        setOpt = !usersLoading
          ? { promotorName: opt.label, promotorId: opt.value }
          : null;
        break;
      default:
        setOpt = !usersLoading
          ? { agencyName: opt.label, agencyId: opt.value }
          : null;
        break;
    }
    setPosition((pos) => ({ ...pos, ...setOpt }));
  };

  const selectValue = (type, pId) => {
    let select;
    switch (type) {
      case 'pos':
        select = !actPositionsLoading
          ? actPositions.find((pos) => pos.value === pId)
          : null;
        break;
      case 'backup':
        select = !actPositionsLoading
          ? actPositions.find((pos) => pos.value === pId)
          : null;
        break;
      case 'promotor':
        select = !usersLoading
          ? users
              .filter((u) => u.role && u.role.name === rolesName.promotor)
              .find((pos) => pos.value === pId)
          : null;
        break;
      default:
        select = !usersLoading
          ? users
              .filter((u) => u.role && u.role.name === rolesName.manager)
              .find((agc) => agc.value === pId)
          : null;
        break;
    }
    if (select) return { value: select.value, label: select.label };
  };

  // add position to positions array after Check is not duplicated
  const addPosition = (e, getPos = null) => {
    if (e) e.preventDefault();
    const setPos = getPos ?? position;
    const setPoses = positions;
    if (
      !positions.some(
        (p) =>
          p.positionId === setPos.positionId &&
          p.agencyId === setPos.agencyId &&
          p.date === setPos.date
      )
    ) {
      setPoses.push(setPos);
      setPosition({ ...setPos });
    }
    setPositions(setPoses);
  };

  /** after finish adding the positions create the activation
   * then go to next page to create confirmation screen
   */
  useEffect(() => {
    if (finish) {
      if (!isEditing) {
        dispatch(createActivation(activation)).then(() => {
          next();
        });
      } else {
        dispatch(modifyActivation(activation)).then(() => {
          next();
        });
      }
    }
  }, [finish]);

  const addActivation = (e) => {
    e.preventDefault();
    const productsVolume = activation.products.reduce(
      (acc, prod) => acc + prod.quantity,
      0
    );
    const agencies = activation.positions.reduce((group, pos) => {
      const posIndex = group.findIndex((gp) => gp.agencyId === pos.agencyId);
      if (posIndex !== -1) {
        group[posIndex] = {
          ...group[posIndex],
          initPositions: group[posIndex].initPositions + 1,
          initProductsVolume:
            group[posIndex].initProductsVolume + productsVolume,
        };
      } else {
        return [
          ...group,
          {
            agencyId: pos.agencyId,
            initPositions: 1,
            initProductsVolume: productsVolume,
            totalProducts: 0,
            totalContacts: 0,
            totalSuccessContacts: 0,
          },
        ];
      }
      return group;
    }, []);
    setActivation({
      ...activation,
      activationTeams,
      agencies,
      initAgencies: agencies.length,
      initPositions: agencies.reduce((acc, ag) => acc + ag.initPositions, 0),
      initProductsVolume: agencies.reduce(
        (acc, ag) => acc + ag.initProductsVolume,
        0
      ),
    });
    setFinish(true);
  };

  const canAddPos =
    position.positionId && position.agencyId && position.startHour;

  const canSubmit = positions.length > 0;

  const columns = [
    {
      Header: useFormatMessage('Activation.positionTab.table.name'),
      accessor: 'positionName',
    },
    {
      Header: useFormatMessage('Activation.positionTab.table.agency'),
      accessor: 'agencyName',
    },
    {
      Header: 'Equipe',
      accessor: 'team',
      Cell: ({ row }) => (row.original.teamNum ? row.original.teamNum : '-----'),
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ row }) =>
        useFormatDate(row.original.date, {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
    },
    {
      Header: 'Promotor',
      accessor: 'promotorName',
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: ({ row }) => (
        <Button
          className="mx-auto text-gray-600"
          text={useFormatMessage('Activation.positionTab.table.action.delete')}
          icon="mdi-close text-brand-red"
          onClick={(e) => {
            e.preventDefault();
            positions.splice(row.index, 1);
            if (row.original.id)
              setActivation({
                ...activation,
                deletedPositions: [
                  ...activation.deletedPositions,
                  row.original.id,
                ],
              });
            setPositions([...positions]);
          }}
        />
      ),
    },
  ];

  /**
   * ************************************************************************************
   * Create positions with upload files
   * if position exsits on positions root collection it will return position ID
   * if not exists it will sotre the position on root collection then recover the position ID
   * *****************************************************************************************
   */
  const onUpload = () => {
    // Calculate the total quantity initial of products
    const productsVolume = activation.products.reduce(
      (acc, prod) => acc + prod.quantity,
      0
    );
    const teamsNums = [];
    const teams = [];
    // Get users how have the Agency Role
    const agencies = users
      .filter((u) => u.role && u.role.name === rolesName.manager)
      .map((agency) => {
        if (agency.teams && agency.teams.length > 0) {
          teamsNums.push(...agency.teams.map((t) => t.teamId));
          teams.push(...agency.teams);
        }
        return {
          id: agency.id,
          name: agency.name,
          teams: agency.teams,
        };
      });
    // Get users how have the Promotor Role
    const promotors = users
      .filter((u) => u.role && u.role.name === rolesName.promotor)
      .map((promotor) => ({ id: promotor.id, name: promotor.name }));
    // Get the Id and Licence of existed
    const actPoses = actPositions.map((actPos) => ({
      id: actPos.id,
      licence: actPos.matricule,
    }));
    // Schema to check the excel file columns
    const schema = {
      Date: {
        prop: 'date',
        type: Date,
        required: true,
      },
      Agence: {
        prop: 'agency',
        type: String,
        required: true,
        /* Check if this row of agency name exsists  on agencies array
         * if true the value of this row will be the id of agency
         * if false return just the name of agency
         * then check if returned value includes on agencies Ids, of course
         * if row return the name of agency will not be exists
         * */
        parse(value) {
          const agency = agencies.find(
            (ag) => ag.name.toLowerCase() === value.toLowerCase()
          );
          return agency ? agency.id : value;
        },
        oneOf: [...agencies.map((ag) => ag.id)],
      },
      Equipe: {
        prop: 'team',
        type: String,
        required: true,
        oneOf: teamsNums,
      },
      /* Check if this row of licence number exsists on "positions" array
       * if true the value of this row will be the id of "position"
       * if false return just the licence number of "position"
       * then check if returned value includes on "positions Ids"
       * if row return the "licence number" the postion will added to positons root collection
       * */
      Licence: {
        prop: 'licence',
        type: String,
        required: true,
        parse(value) {
          const actPosition = actPoses.find(
            (pos) => String(pos.licence) === String(value)
          );
          return actPosition ? actPosition.id : value;
        },
        oneOf: [...actPoses.map((pos) => pos.id)],
      },
      Changement: {
        prop: 'backup',
        type: String,
        parse(value) {
          const actPosition = actPoses.find(
            (pos) => String(pos.licence) === String(value)
          );
          return actPosition ? actPosition.id : value;
        },
        oneOf: [...actPoses.map((pos) => pos.id)],
      },
      /**
       * Check if this row of promotor name exsists  on promotors array
       * if true the value of this row will be the id of promotor
       * if false return just the name of promotor
       * then check if returned value includes on promotors Ids, of course
       * if row return the name of promotor will not be exists
       * */
      Promoteur: {
        prop: 'promotor',
        type: String,
        required: true,
        parse(value) {
          const promotor = promotors.find(
            (pro) => pro.name.toLowerCase() === value.toLowerCase()
          );
          return promotor ? promotor.id : value;
        },
        oneOf: promotors.map((pr) => pr.id),
      },
      Horaire: {
        prop: 'startHour',
        type: String,
        required: true,
      },
    };

    // Set button to upload file
    const input = document.getElementById('input');

    // On upload file Event
    input.addEventListener('change', () => {
      // Initialize Loading & Empty the Array of errors on upload event
      setShowErrors([]);
      setUploadLoading(true);
      // Read the file rows with schema
      readXlsxFile(input.files[0], { schema })
        .then(async ({ rows, errors }) => {
          if (errors.length > 0) {
            setShowErrors(errors);
          } else {
            rows.map(async (row) => {
              const setTm = teams.find((t) => t.teamId === row.team);
              if (!activationTeams.find((t) => t.teamNum === row.team)) {
                setActivationTeams((prev) => [
                  ...prev,
                  {
                    agencyId: row.agency,
                    teamNum: row.team,
                    teamId: setTm.id,
                    supervisor: setTm.groups[0].supervisor,
                    animators: setTm.groups[0].animators,
                  },
                ]);
              }
              addPosition(null, {
                positionName:
                  (actPoses.find((p) => p.id === row.licence) || {}).licence ||
                  `Position ${row.licence}`,
                positionId: row.licence,
                agencyName: agencies.find((ag) => ag.id === row.agency).name,
                agencyId: row.agency,
                promotorName:
                  row.type !== 'INITIAL'
                    ? promotors.find((ag) => ag.id === row.promotor).name
                    : '',
                promotorId: row.type !== 'INITIAL' ? row.promotor : '',
                initProductsVolume: productsVolume,
                date: row.date,
                startHour: row.startHour,
                positionBackup: row.backup,
                teamId: setTm.id,
                teamNum: row.team,
              });
            });
          }
        })
        .then(() => {
          setUploadLoading(false);
        });
    });
  };
  // Function to show errors related to excel file
  function ParseExcelError(error) {
    let { value } = error;
    if (error.type === Date) {
      value = parseExcelDate(value).toString();
    }
    return (
      <p>
        <code>&quot;{error.error}&quot;</code>
        {' pour la valeur '}
        <code>&quot;{value}&quot;</code>
        {' dans la colonne '}
        <code>&quot;{error.column}&quot;</code>
        {error.type && ' de type '}
        {error.type && <code>&quot;{error.type.name}&quot;</code>}
        {' dans la ligne'}
        <code>&quot;{error.row}&quot;</code>
      </p>
    );
  }

  if (actPositionsLoading || usersLoading) {
    // return loader comp
    return (
      <div className="py-10 px-8 flex flex-col items-center">
        <h1 className="pb-4">Chargement des agences et des position</h1>
        <PropagateLoader color="#90cdf4" />
      </div>
    );
  }
  /**
   * *********************
   * End Uploading part
   * *********************
   */
  return (
    <div className="">
      <h1 className="text-2xl font-semibold text-brand-darkBlue py-3">
        Ajouter un position et lui attribuer une agence
      </h1>
      <form className="">
        <div className="flex flex-wrap px-4 py-6 border border-brand-lighterBlue rounded-lg">
          <div className="flex flex-wrap w-full">
            <div className="w-1/3">
              <NormalInput
                label="Horaire"
                onChange={onChangeHandler}
                type="Horaire"
                required
                name="startHour"
                value={position.startHour}
              />
            </div>
            <div className="lg:w-1/3">
              <SelectField
                isLoading={actPositionsLoading}
                isDisabled={actPositionsLoading}
                type="form"
                label="Position"
                onChange={(value) => onChangeSelect('pos', value)}
                options={selectPosOptions}
                value={selectValue('pos', position.positionId)}
              />
            </div>
            <div className="lg:w-1/3">
              <SelectField
                isLoading={actPositionsLoading}
                isDisabled={actPositionsLoading}
                type="form"
                label="Changement"
                onChange={(value) => onChangeSelect('backup', value)}
                options={selectPosOptions}
                value={selectValue('backup', position.positionId)}
              />
            </div>
            <div className="lg:w-1/3">
              <SelectField
                isLoading={usersLoading}
                isDisabled={usersLoading}
                type="form"
                label="Agence"
                onChange={(value) => onChangeSelect('agency', value)}
                options={selectAgencyOptions}
                value={selectValue('agency', position.agencyId)}
              />
            </div>
            <div className="lg:w-1/3">
              <SelectField
                isLoading={usersLoading}
                isDisabled={usersLoading}
                type="form"
                label="Promoteur"
                onChange={(value) => onChangeSelect('promotor', value)}
                options={selectPromotorOptions}
                value={selectValue('promotor', position.promotorId)}
              />
            </div>
            <div className="lg:w-1/3">
              <div className="p-2">
                <label className="text-brand-lightBlue font-semibold px-1">
                  Date
                </label>
                <div className="tw-date-range is-form">
                  <DatePickerStyled
                    name="date"
                    setState={setPosition}
                    date={new Date(position.date)}
                  />
                  <span className="icon">
                    <i className="mdi mdi-calendar mdi-24px" />
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                className="mt-4"
                size="large"
                icon="mdi-plus-circle"
                color="green"
                text="Ajouter"
                disabled={!canAddPos}
                onClick={addPosition}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h1 className="text-2xl font-semibold flex items-end text-brand-darkBlue py-3">
            <span>Personnaliser les positions</span>
            <div className="ml-auto">
              <FileInput
                id="input"
                label="Telechargez un fichier"
                isSmall
                onClick={() => onUpload()}
              />
            </div>
          </h1>
          {!loading &&
            showErrors.length > 0 &&
            showErrors.map((err) => ParseExcelError(err))}
          <Table data={positions} columns={columns} loading={loading} />
        </div>
        <div className="w-full py-8 px-4 flex justify-between">
          <Button
            className=""
            size="medium"
            icon="mdi-chevron-left"
            color="blue"
            text="Produits"
            onClick={(e) => {
              e.preventDefault();
              previous();
            }}
          />
          <Button
            className=""
            size="medium"
            icon="mdi-chevron-right"
            color="blue"
            text="Terminer Creation"
            disabled={!canSubmit}
            onClick={addActivation}
          />
          <Button
            className="text-gray-500 text-sm"
            text="Annuler la creation"
            onClick={() => window.location.reload()}
          />
        </div>
      </form>
    </div>
  );
};

export default Positions;
