import { createReducer } from 'redux-act';
import {
  AWARDS_FETCH_DATA_INIT,
  AWARDS_FETCH_DATA_SUCCESS,
  AWARDS_FETCH_DATA_FAIL,
  AWARDS_CREATE_AWARD_INIT,
  AWARDS_CREATE_AWARD_SUCCESS,
  AWARDS_CREATE_AWARD_FAIL,
  AWARDS_DELETE_AWARD_INIT,
  AWARDS_DELETE_AWARD_SUCCESS,
  AWARDS_DELETE_AWARD_FAIL,
  AWARDS_MODIFY_AWARD_INIT,
  AWARDS_MODIFY_AWARD_SUCCESS,
  AWARDS_MODIFY_AWARD_FAIL,
  AWARDS_CLEAN_UP,
  AWARDS_CLEAR_DATA,
} from 'state/actions/awards';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const awardsReducer = createReducer(
  {
    [AWARDS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AWARDS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.awards,
      loading: false,
      error: null,
    }),
    [AWARDS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AWARDS_CREATE_AWARD_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AWARDS_CREATE_AWARD_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.game, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [AWARDS_CREATE_AWARD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AWARDS_DELETE_AWARD_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AWARDS_DELETE_AWARD_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [AWARDS_DELETE_AWARD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AWARDS_MODIFY_AWARD_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AWARDS_MODIFY_AWARD_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.award.id) {
            return {
              name: payload.award.name,
              displayName: payload.award.displayName,
              id: payload.id,
              createdAt: payload.award.createdAt,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [AWARDS_MODIFY_AWARD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AWARDS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [AWARDS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
