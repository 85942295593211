import React from 'react';
import { createActVideo, modifyActVideo } from 'state/actions/actVideos';
import VideoForm from 'components/VideoForm';
import PageHeader from 'components/PageHeader';
import { Redirect, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import firebase from 'firebase.js';
import { ClipLoader } from 'react-spinners';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const AddVideo = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.actVideos.success,
    }),
    shallowEqual
  );
  const [actVideo, setActVideo] = useState({ name: '', link: null });

  useEffect(() => {
    const fetchActVideoData = async () => {
      const response = await firebase
        .firestore()
        .collection('actVideos')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchActVideoData()
        .then((actVideoData) => {
          setActVideo({
            ...actVideoData.data(),
            id,
          });
        })
        .catch(() => {
          setActVideo({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const videoForm =
    !actVideo.name && id ? (
      <ClipLoader />
    ) : (
      <VideoForm
        isEditing={isEditing}
        actVideoData={actVideo}
        action={isEditing ? modifyActVideo : createActVideo}
      />
    );

  const redirect = (actVideo.error || success) && (
    <Redirect to={paths.ACTIVATIONS_VIDEOS} />
  );
  return (
    <>
      {redirect}
      <PageHeader
        title={useFormatMessage('ActivationsVideo.title')}
        hideButton
      />
      <div className="page-padding">{videoForm}</div>
    </>
  );
};

export default AddVideo;
