import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import FilterSearch from 'components/Common/FilterSearch';
import useFilterSearch from 'hooks/useFilterSearch';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import { useCan, useFormatMessage } from 'hooks';
import {
  clearActivationsData,
  deleteActivation,
  fetchActivations,
} from 'state/actions/activations';
import PageHeader from 'components/PageHeader';

const Activations = () => {
  const authorizeView = useCan(['view-agency-activations']);
  const authorizeCRUD = useCan([
    'view-activation',
    'create-activation',
    'edit-activation',
    'delete-activation',
  ]);

  const {
    isAdmin,
    userID,
    activationsList,
    error,
    loading,
    deleted,
  } = useSelector((state) => {
    return {
      success: state.activations.success,
      activationsList: state.activations.data,
      isAdmin: state.auth.userData.isAdmin,
      userID: state.auth.userData.id,
      error: state.activations.error,
      loading: state.activations.loading,
      deleted: state.activations.deleted,
      pageCount: state.activations.pageCount,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    const agencyId = authorizeView && !isAdmin ? userID : null;
    dispatch(fetchActivations(agencyId, true));
    return () => dispatch(clearActivationsData());
  }, [dispatch]);

  const [deleteModal, setDeleteModal] = useState({
    activationId: null,
    isOpen: false,
  });

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        activationId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (activationId) => {
    setDeleteModal((prevState) => ({
      activationId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ activationId: null, isOpen: false });
  };

  const onDeleteActivationHandler = () => {
    dispatch(deleteActivation(deleteModal.activationId));
  };

  let columns = [
    {
      Header: 'Nom',
      accessor: 'name',
      Cell: ({ row }) => {
        const name = row.original && row.original.name;
        return <div className="text-brand-lightBlue">{name}</div>;
      },
    },
    {
      Header: 'Catégorie',
      accessor: 'category',
      hideForAgency: true,
      Cell: ({ row }) => row.original.category.name,
    },
    {
      Header: useFormatMessage('Activations.table.ProductsCount'),
      accessor: 'initProducts',
      hideForAgency: true,
    },
    {
      Header: useFormatMessage('Activations.table.ProductsVolume'),
      accessor: 'initProductsVolume',
      hideForAgency: true,
    },
    {
      Header: useFormatMessage('Activations.table.PositionsCount'),
      accessor: 'initPositions',
      hideForAgency: true,
    },
    {
      Header: "Nombre d'agence",
      accessor: 'initAgencies',
      hideForAgency: true,
      Permission: authorizeCRUD,
    },
    {
      Header: 'Produits Ecoule',
      accessor: 'totalProducts',
    },
    {
      Header: 'Total contacts',
      accessor: 'totalContacts',
    },
    {
      Header: 'Total contact réussis',
      accessor: 'totalSuccessContacts',
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row: { index, ...row } }) => {
        return (
          <div className="flex justify-center">
            <Button
              to={
                isAdmin
                  ? `/activations/${row.original.id}/details`
                  : `/activations/${row.original.id}/${userID}/details/agency`
              }
              isIconOnly
              className="mx-2"
              text="Consulter"
              icon="mdi-chevron-right"
              color="blue"
              size="medium"
            />
            {isAdmin && (
              <Button
                isIconOnly
                className="mx-1"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
                text="modifer"
                color="red"
                size="small"
                icon="mdi-delete"
              />
            )}
          </div>
        );
      },
    },
  ];

  if (!isAdmin) {
    // getting rid of cols that shouldn't appear on agency area
    columns = columns.filter((col) => !col.hideForAgency);
  }

  const { result, searchProps, selectProps } = useFilterSearch(
    activationsList,
    [
      {
        label: 'Catégories',
        accessor: 'category.name',
      },
    ]
  );
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage="Supprimer"
          title="Supprimer Activation"
          body="voulez-vous vraiment supprimer ?"
          cancelButtonMessage="Annuler"
          onConfirmation={onDeleteActivationHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Historique" hideButton />
      <div className="page-padding">
        <div className="p-2">
          <div className="flex justify-between px-2">
            <FilterSearch
              loading={loading}
              searchProps={searchProps}
              selectProps={selectProps}
            />
          </div>
          <div className="p-2">
            <Table columns={columns} data={result} loading={loading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Activations;
