import { createReducer } from 'redux-act';
import {
  LEVELS_FETCH_DATA_INIT,
  LEVELS_FETCH_DATA_SUCCESS,
  LEVELS_FETCH_DATA_FAIL,
  LEVELS_CREATE_INIT,
  LEVELS_CREATE_SUCCESS,
  LEVELS_CREATE_FAIL,
  LEVELS_CLEAN_UP,
  LEVELS_CLEAR_DATA,
  LEVELS_MODIFY_INIT,
  LEVELS_MODIFY_SUCCESS,
  LEVELS_MODIFY_FAIL,
  LEVELS_DELETE_INIT,
  LEVELS_DELETE_SUCCESS,
  LEVELS_DELETE_FAIL,
} from 'state/actions/levels';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const levelsReducer = createReducer(
  {
    [LEVELS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [LEVELS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.levels,
      loading: false,
      error: null,
    }),
    [LEVELS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [LEVELS_CREATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [LEVELS_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      data: [...state.data, { ...payload.level, id: state.id }],
      loading: false,
      error: null,
      success: true,
    }),
    [LEVELS_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [LEVELS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [LEVELS_DELETE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [LEVELS_DELETE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: state.data.filter((elem) => elem.id !== payload.id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [LEVELS_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [LEVELS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
    [LEVELS_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [LEVELS_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.level.id) {
            return {
              name: payload.level.name,
              displayName: payload.level.displayName,
              id: payload.id,
              createdAt: payload.level.createdAt,
              categorie: payload.level.categorie,
              purchaseNumber: payload.level.purchaseNumber,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [LEVELS_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
