import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = ({
  icon,
  size,
  color,
  text,
  isIconOnly,
  className,
  to,
  ...props
}) => {
  /**
   * author issam@abweb.ma
   *@param {String} icon: mdi icon classname ex: mdi-chevron-right
   *@param {String} size: one of [small, medium, large]
   *@param {String} color: one of [blue, red, green]
   *@param {String} text: any string value
   *@param {Boolean} isIconOnly: show only the icon, the text passed becomes a tooltip
   *@param {String} className: classes to extend the styling of the button
   *@param {String} to: the route path to navigate to
   */
  const button = (
    <button
      {...props}
      className={`tw-button tw-button-${size} tw-button-${color} ${
        isIconOnly ? 'tw-is-icon-button' : ''
      } ${className} ${!color && 'underline'}`}
      data-name={text ? text : ''}
    >
      <span className="tw-button-icon">
        <i
          className={`mdi ${
            size === 'large' ? 'mdi-24px' : 'mdi-18px'
          } ${icon}`}
        />
      </span>
      <span className="tw-button-text">{text}</span>
    </button>
  );
  return to ? <Link to={to}>{button}</Link> : button;
};

Button.propTypes = {
  icon: propTypes.string,
  text: propTypes.string,
  size: propTypes.string,
  color: propTypes.string,
  isIconOnly: propTypes.bool,
  className: propTypes.string,
};

export default React.memo(Button);
