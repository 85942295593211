import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Tabs from 'components/Common/Tabs';
import TimeLine from 'components/Common/TimeLine';
import Informations from 'components/ActivationForm/InformationsTab';
import Produits from 'components/ActivationForm/ProduitsTab';
import Positions from 'components/ActivationForm/PositionsTab';
// import Animations from 'components/ActivationForm/AnimationsTab';
import ActivationSubmittedDetails from 'components/ActivationForm/ActivationSubmittedDetailsTab';
import { useCan, useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import ActivationController from '../../controllers/ActivationController';

const ac = new ActivationController();

const Activation = () => {
  /**
   * useCan Hook used to give access to authorized user has permisions
   * edit-activation or create-activation
   */
  const authorizeCRUD = useCan(['edit-activation', 'create-activation']);

  /**
   * Get the activation id from link in case of edit
   * but this function still in progress
   */
  const [isEditing, setIsEditing] = useState(false);

  /**
   * Initialize the activation object
   */
  const [activation, setActivation] = useState({
    id: '',
    name: '',
    categoryId: '',
    region: '',
    periodStart: new Date(),
    periodEnd: new Date(),
    description: '',
    marks: [],
    video: {
      name: '',
      videoId: '',
    },
    games: [],
    agencies: [],
    products: [],
    positions: [],
    initProducts: 0,
    initProductsVolume: 0,
    initAgencies: 0,
    initPositions: 0,
    deletedProducts: [],
    deletedPositions: [],
  });

  /**
   * Set activation form steps
   * activation info > select products > uplaod or select positions
   * and last step show the activation info as confirmation of create
   */
  const [{ tabs, currentTabIndex }, setTabs] = useState({
    currentTabIndex: 0,
    tabs: [
      {
        name: useFormatMessage('Activation.tabs.information'),
        isActive: true,
        component: Informations,
        props: {},
      },
      {
        name: useFormatMessage('Activation.tabs.products'),
        isActive: false,
        component: Produits,
        props: {},
      },
      {
        name: useFormatMessage('Activation.tabs.positions'),
        isActive: false,
        component: Positions,
        props: {},
      },
      {
        name: 'Submitted',
        isActive: false,
        component: ActivationSubmittedDetails,
        props: {},
      },
    ],
  });

  const currentTabIsNotLastTab = () => {
    const lastTabIndex = tabs.length - 1;
    return currentTabIndex !== lastTabIndex;
  };

  const currentTabIsNotFirstTab = () => {
    return currentTabIndex !== 0;
  };

  const handleNav = (value) => {
    const navigate = () => {
      const nextOrPrevIndex = currentTabIndex + value;
      setTabs(({ tabs }) => ({
        currentTabIndex: nextOrPrevIndex,
        tabs: tabs.map((t, i) => ({ ...t, isActive: i === nextOrPrevIndex })),
      }));
    };
    if (value === 1 && currentTabIsNotLastTab()) {
      navigate();
    } else if (value === -1 && currentTabIsNotFirstTab()) {
      navigate();
    }
  };
  const redirect = !authorizeCRUD && <Redirect to={paths.ROOT} />;

  return (
    <>
      {redirect}
      <div className="page-padding">
        <TimeLine steps={tabs} />
        <div className="">
          <div className="w-full px-16">
            <Tabs
              activation={activation}
              setActivation={setActivation}
              isEditing={isEditing}
              tabs={tabs}
              hideNav
              next={() => handleNav(1)}
              previous={() => handleNav(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Activation;
