import React, { useState, useEffect } from 'react';
import { createGame, modifyGame, generateID } from 'state/actions/games';
import GameForm from 'components/GameForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

import { useFormatMessage } from 'hooks';

const Game = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.games.success,
    }),
    shallowEqual
  );
  const [game, setGame] = useState({
    name: '',
    idNum: '',
    disabled: false,
    imageUrl: '',
    marks: [],
  });

  useEffect(() => {
    const fetchGameData = async () => {
      const response = await firebase
        .firestore()
        .collection('games')
        .doc(id)
        .get();
      return response;
    };

    generateID().then((idNum) => {
      if (id) {
        fetchGameData()
          .then((gameData) => {
            setGame({
              ...gameData.data(),
              id,
            });
          })
          .catch(() => {
            setGame({ error: true });
          });
      } else {
        setGame({ ...game, idNum });
      }
    });
  }, [id]);

  const isEditing = !!id;

  const gameForm =
    (!game.name && id) || (!game.idNum && !id) ? (
      <ClipLoader />
    ) : (
      <GameForm
        isEditing={isEditing}
        gameData={game}
        action={isEditing ? modifyGame : createGame}
      />
    );

  const redirect = (game.error || success) && <Redirect to={paths.GAMES} />;

  return (
    <>
      {redirect}
      <PageHeader title={useFormatMessage('Game.title')} hideButton />
      <div className="page-padding">{gameForm}</div>
    </>
  );
};

export default Game;
