import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
import firebase from 'firebase.js';

/* eslint-disable camelcase */
import Controller from 'controllers/Controller';

export const PRO_CATEGORIES_FETCH_DATA_INIT = createAction(
  'PRO_CATEGORIES_FETCH_DATA_INIT'
);
export const PRO_CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'PRO_CATEGORIES_FETCH_DATA_SUCCESS'
);
export const PRO_CATEGORIES_FETCH_DATA_FAIL = createAction(
  'PRO_CATEGORIES_FETCH_DATA_FAIL'
);
export const PRO_CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'PRO_CATEGORIES_CREATE_CATEGORY_INIT'
);
export const PRO_CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'PRO_CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const PRO_CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'PRO_CATEGORIES_CREATE_CATEGORY_FAIL'
);
export const PRO_CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'PRO_CATEGORIES_DELETE_PRO_CATEGORIES_INIT'
);
export const PRO_CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'PRO_CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const PRO_CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'PRO_CATEGORIESS_DELETE_PRO_CATEGORIES_FAIL'
);
export const PRO_CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'PRO_CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const PRO_CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'PRO_CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const PRO_CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'PRO_CATEGORIES_MODIFY_CATEGORY_FAIL'
);

export const PRO_CATEGORIES_CLEAR_DATA = createAction(
  'PRO_CATEGORIES_CLEAR_DATA'
);

export const PRO_CATEGORIES_CLEAN_UP = createAction('PRO_CATEGORIES_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('proCategories', 'proCategory');

/**
 * Action Fetch proCategories
 * @return dispatch
 */

export const fetchProCategories = () => {
  return async (dispatch) => {
    dispatch(PRO_CATEGORIES_FETCH_DATA_INIT());
    let proCategories;
    try {
      proCategories = await gc.fetch();
    } catch (error) {
      toastr.error('Catègorie', 'Operation a échoué');
      return dispatch(PRO_CATEGORIES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      PRO_CATEGORIES_FETCH_DATA_SUCCESS({
        proCategories,
      })
    );
  };
};

/**
 * Action Create new proCategory
 * @param {string} name Name of proCategory
 * @return dispatch
 */

export const createProCategory = ({ name }) => {
  return async (dispatch, getState) => {
    dispatch(PRO_CATEGORIES_CREATE_CATEGORY_INIT());
    const { locale } = getState().preferences;
    let proCategory;
    try {
      const displayName = displayText(name);
      proCategory = await gc.create({ name, displayName }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'la création a échoué');
      return dispatch(
        PRO_CATEGORIES_CREATE_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'créé avec succès');
    return dispatch(PRO_CATEGORIES_CREATE_CATEGORY_SUCCESS({ proCategory }));
  };
};

/**
 * Action Delete proCategory
 * @param {string} id ID of proCategory
 * @return dispatch
 */

export const deleteProCategory = (id) => {
  return async (dispatch, getState) => {
    dispatch(PRO_CATEGORIES_DELETE_CATEGORY_INIT());
    const { locale } = getState().preferences;
    try {
      const products = await firebase
        .firestore()
        .collection('products')
        .where('categoryId', '==', id)
        .get();
      if (products.size > 0) {
        const err = new Error('category already used');
        err.code = 401;
        throw err;
      }
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'la suppression a échoué');
      return dispatch(
        PRO_CATEGORIES_DELETE_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'Supprimé avec succès');
    return dispatch(PRO_CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

/**
 * Action Update proCategory
 * @param {string} name Name of proCategory
 * @return dispatch
 */
export const modifyProCategory = ({ name, id }) => {
  return async (dispatch, getState) => {
    dispatch(PRO_CATEGORIES_MODIFY_CATEGORY_INIT());
    const { locale } = getState().preferences;
    let proCategory;
    try {
      const displayName = displayText(name);
      proCategory = await gc.update(id, { name, displayName }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Catègorie', 'mise à jour a échoué');
      return dispatch(
        PRO_CATEGORIES_MODIFY_CATEGORY_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Catègorie', 'mis à jour avec succès');
    return dispatch(PRO_CATEGORIES_MODIFY_CATEGORY_SUCCESS({ proCategory }));
  };
};

export const proCategoriesCleanUp = () => (dispatch) =>
  dispatch(PRO_CATEGORIES_CLEAN_UP());

export const clearProCategoriesData = () => {
  return (dispatch) => {
    dispatch(PRO_CATEGORIES_CLEAR_DATA());
  };
};
