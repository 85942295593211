import { createReducer } from 'redux-act';
import {
  REPORTINGS_FETCH_DATA_INIT,
  REPORTINGS_FETCH_DATA_SUCCESS,
  REPORTINGS_FETCH_DATA_FAIL,
  REPORTINGS_CLEAN_UP,
  REPORTINGS_CLEAR_DATA,
} from 'state/actions/reporting';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const reportingsReducer = createReducer(
  {
    [REPORTINGS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [REPORTINGS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.reportings,
      loading: false,
      error: null,
    }),
    [REPORTINGS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [REPORTINGS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [REPORTINGS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
