import { createReducer } from 'redux-act';
import {
  ALATIGES_FETCH_DATA_INIT,
  ALATIGES_FETCH_DATA_SUCCESS,
  ALATIGES_FETCH_DATA_FAIL,
  ALATIGES_CREATE_ALATIGE_INIT,
  ALATIGES_CREATE_ALATIGE_SUCCESS,
  ALATIGES_CREATE_ALATIGE_FAIL,
  ALATIGES_DELETE_ALATIGE_INIT,
  ALATIGES_DELETE_ALATIGE_SUCCESS,
  ALATIGES_DELETE_ALATIGE_FAIL,
  ALATIGES_MODIFY_ALATIGE_INIT,
  ALATIGES_MODIFY_ALATIGE_SUCCESS,
  ALATIGES_MODIFY_ALATIGE_FAIL,
  ALATIGES_CLEAN_UP,
  ALATIGES_CLEAR_DATA,
} from 'state/actions/alatige';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const alatigesReducer = createReducer(
  {
    [ALATIGES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ALATIGES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.alatiges,
      loading: false,
      error: null,
    }),
    [ALATIGES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ALATIGES_CREATE_ALATIGE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ALATIGES_CREATE_ALATIGE_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.alatige, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [ALATIGES_CREATE_ALATIGE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ALATIGES_DELETE_ALATIGE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ALATIGES_DELETE_ALATIGE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [ALATIGES_DELETE_ALATIGE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ALATIGES_MODIFY_ALATIGE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ALATIGES_MODIFY_ALATIGE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: [
          ...state.data.map((elem) => {
            if (elem.id === payload.alatige.id) {
              return {
                pdfUrl: payload.alatige.pdfUrl,
                isActive: payload.alatige.isActive,
                id: payload.id,
                createdAt: payload.alatige.createdAt,
              };
            }
            return elem;
          }),
        ],
        loading: false,
        error: null,
        success: true,
      };
    },
    [ALATIGES_MODIFY_ALATIGE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ALATIGES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ALATIGES_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
