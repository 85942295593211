import { createReducer } from 'redux-act';
import {
  AGENCIES_FETCH_DATA_INIT,
  AGENCIES_FETCH_DATA_SUCCESS,
  AGENCIES_FETCH_DATA_FAIL,
  AGENCIES_CLEAN_UP,
  AGENCIES_CREATE_AGENCY_INIT,
  AGENCIES_CREATE_AGENCY_SUCCESS,
  AGENCIES_CREATE_AGENCY_FAIL,
  AGENCIES_MODIFY_AGENCY_INIT,
  AGENCIES_MODIFY_AGENCY_SUCCESS,
  AGENCIES_MODIFY_AGENCY_FAIL,
  AGENCIES_DELETE_AGENCY_INIT,
  AGENCIES_DELETE_AGENCY_SUCCESS,
  AGENCIES_DELETE_AGENCY_FAIL,
  AGENCIES_CLEAR_DATA,
} from 'state/actions/agencies';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const agenciesReducer = createReducer(
  {
    [AGENCIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AGENCIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.agencies,
      loading: false,
      error: null,
    }),
    [AGENCIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AGENCIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
    }),

    [AGENCIES_DELETE_AGENCY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AGENCIES_DELETE_AGENCY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [AGENCIES_DELETE_AGENCY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AGENCIES_CREATE_AGENCY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AGENCIES_CREATE_AGENCY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.agency),
      loading: false,
      error: null,
      success: true,
    }),
    [AGENCIES_CREATE_AGENCY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AGENCIES_MODIFY_AGENCY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AGENCIES_MODIFY_AGENCY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map((elem) => {
        if (elem.id === payload.id) {
          return {
            name: payload.agency.name,
            location: payload.agency.location,
            id: payload.id,
            logoUrl: payload.agency.logoUrl,
            createdAt: payload.agency.createdAt,
            email: elem.email,
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true,
    }),
    [AGENCIES_MODIFY_AGENCY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AGENCIES_CLEAR_DATA]: () => ({
      ...initialState,
    }),
  },
  initialState
);
