import { createReducer } from 'redux-act';
import {
  SUPPORTS_FETCH_DATA_INIT,
  SUPPORTS_FETCH_DATA_SUCCESS,
  SUPPORTS_FETCH_DATA_FAIL,
  SUPPORTS_CREATE_INIT,
  SUPPORTS_CREATE_SUCCESS,
  SUPPORTS_CREATE_FAIL,
  SUPPORTS_CLEAN_UP,
  SUPPORTS_CLEAR_DATA,
  SUPPORTS_MODIFY_INIT,
  SUPPORTS_MODIFY_SUCCESS,
  SUPPORTS_MODIFY_FAIL,
  SUPPORTS_DELETE_INIT,
  SUPPORTS_DELETE_SUCCESS,
  SUPPORTS_DELETE_FAIL,
} from 'state/actions/supports';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const supportsReducer = createReducer(
  {
    [SUPPORTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SUPPORTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.supports,
      loading: false,
      error: null,
    }),
    [SUPPORTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SUPPORTS_CREATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SUPPORTS_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      data: [...state.data, { ...payload.support, id: state.id }],
      loading: false,
      error: null,
      success: true,
    }),
    [SUPPORTS_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SUPPORTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [SUPPORTS_DELETE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SUPPORTS_DELETE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: state.data.filter((elem) => elem.id !== payload.id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [SUPPORTS_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SUPPORTS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
    [SUPPORTS_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SUPPORTS_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.support.id) {
            return {
              ...payload.support,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [SUPPORTS_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
