import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFormatMessage, useCan } from 'hooks';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import Button from 'components/Common/Button';
import { fetchUsers } from 'state/actions/users';
import { rolesName } from 'utils';
import { generateTeamId } from 'state/actions/teams';

const TeamForm = ({ isEditing, teamData, action }) => {
  const { usersList, loading } = useSelector(
    (state) => ({
      usersList: state.users.data,
      loading: state.users.loading,
    }),
    shallowEqual
  );

  const [supervisors, setSupervisors] = useState([]);
  const [animators, setAnimators] = useState([]);
  const [name, setName] = useState(teamData.name);
  const [groups, setGroups] = useState(teamData.groups);
  const [teamId, setTeamId] = useState(teamData.teamId);

  const dispatch = useDispatch();

  const authorize = useCan(['view-members', 'view-users']);

  const filterRole = (role) => {
    return usersList
      .filter((u) => {
        if (u.role) {
          return !isEditing
            ? u.role.name === role && (u.teamId === '' || !u.teamId)
            : u.role.name === role;
        }
      })
      .map((usr) => ({
        value: usr.id,
        label: usr.name,
      }));
  };

  useEffect(() => {
    setName(teamData.name);
    setGroups(teamData.groups);
    setTeamId(teamData.teamId);
  }, [teamData]);

  useEffect(() => {
    const generateId = async () => {
      const retId = await generateTeamId();
      return retId;
    };
    if (!isEditing) {
      generateId().then(setTeamId);
    }
  }, []);

  useEffect(() => {
    if (authorize) {
      dispatch(fetchUsers());
    }
    if (usersList.length > 0 && !loading) {
      setSupervisors(filterRole(rolesName.supervisor));
      setAnimators(filterRole(rolesName.editor));
    }
  }, [dispatch, loading]);

  const supervisorsOpt = supervisors.filter((sup) =>
    groups.every((g) => sup.value !== g.supervisor)
  );

  const animatorsOpt = animators.filter((anim) =>
    groups.every((g) => !g.animators.some((an) => an === anim.value))
  );

  const handleSupervisorChange = (index, v) => {
    if (v) {
      const ugrps = groups.map((grp, ind) => {
        if (ind === index) grp.supervisor = v.value;
        return grp;
      });
      setGroups([...ugrps]);
    }
  };

  const handleAnimatorChange = (v, { action, removedValue }) => {
    if (v) {
      const ugrps = groups.map((grp, ind) => {
        if (v.length < 5) grp.animators = [...v.map((val) => val.value)];
        return grp;
      });
      setGroups([...ugrps]);
    }
  };

  const selectSupervisorValue = (spvId) =>
    supervisors.find((spv) => spv.value === spvId);

  const selectAnimatorValue = (anms) => {
    return anms.map((anmId) => animators.find((anm) => anm.value === anmId));
  };

  const canSubmit =
    name && groups.every((g) => g.supervisor && g.animators.length > 0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataFrom = isEditing
      ? { idTeam: teamData.id, name, groups, teamId }
      : { name, groups, teamId };
    dispatch(action(dataFrom));
  };

  const isLastOneAndIsEven = (groupIndex) => {
    return groupIndex === groups.length - 1 && groupIndex % 2 === 0;
  };

  const addGroup = (e) => {
    e.preventDefault();
    const index = groups.length - 1;
    if (
      supervisorsOpt.length > 0 &&
      animatorsOpt.length > 0 &&
      groups[index].supervisor &&
      groups[index].animators.length > 0
    ) {
      setGroups([
        ...groups,
        {
          supervisor: '',
          animators: [],
        },
      ]);
    }
  };

  const removeGroup = (index) => {
    groups.splice(index, 1);
    setGroups([...groups]);
  };

  const teamFormAdd = useFormatMessage('Team.teamForm.add');
  const teamFormSupervisor = useFormatMessage('Team.teamForm.supervisor');
  const teamFormAnimators = useFormatMessage('Team.teamForm.animators');

  return (
    <div className="">
      <form
        onSubmit={handleSubmit}
        className="flex p-2 rounded-lg border border-brand-lighterBlue max-w-6xl"
      >
        {/* div fields  */}
        <div className="flex-auto flex flex-wrap">
          <div className="w-full">
            {/* name  */}
            <NormalInput
              onChange={(e) => setName(e.target.value)}
              name="name"
              value={name}
              label={useFormatMessage('Team.teamForm.name')}
            />
            {/* team id  */}
            <NormalInput
              label="ID"
              name="teamId"
              placeholder={teamId}
              readOnly
            />
          </div>
          {groups.map((g, i) => (
            <div
              key={i}
              className={`transition-all duration-500 p-1 ${
                isLastOneAndIsEven(i) ? 'w-full' : 'w-1/2'
              }`}
            >
              <div className="rounded-lg border border-brand-lighterBlue p-2 relative">
                {i > 0 && (
                  <button
                    onClick={() => removeGroup(i)}
                    className="text-sm font-semibold text-blue-300 hover:text-brand-lightBlue absolute right-0 top-0 mx-2 my-1"
                  >
                    X
                  </button>
                )}
                <SelectField
                  type="form"
                  onChange={(value) => handleSupervisorChange(i, value)}
                  options={supervisorsOpt}
                  value={selectSupervisorValue(groups[i].supervisor)}
                  label={teamFormSupervisor}
                />
                <SelectField
                  type="form"
                  onChange={handleAnimatorChange}
                  options={animatorsOpt}
                  isMulti
                  value={selectAnimatorValue(groups[i].animators)}
                  label={teamFormAnimators}
                />
              </div>
            </div>
          ))}
          <div className="w-full">
            <button
              onClick={addGroup}
              className="bg-brand-lighterBlue border border-brand-lightBlue text-brand-lightBlue mx-auto w-full max-w-xs h-10 flex items-center justify-center rounded-lg"
            >
              <span className="icon">
                <i className="mdi mdi-24px mdi-account-multiple-plus" />
              </span>
            </button>
          </div>
        </div>
        {/* submit button  */}
        <div className=" lg:ml-16 self-end">
          <Button
            type="submit"
            text={teamFormAdd}
            color="green"
            size="large"
            icon="mdi-plus-circle"
            disabled={!canSubmit}
          />
        </div>
      </form>
    </div>
  );
};
export default TeamForm;
