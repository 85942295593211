import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import { useFormatMessage } from 'hooks';

export const LEVELS_FETCH_DATA_INIT = createAction('LEVELS_FETCH_DATA_INIT');
export const LEVELS_FETCH_DATA_SUCCESS = createAction(
  'LEVELS_FETCH_DATA_SUCCESS'
);
export const LEVELS_FETCH_DATA_FAIL = createAction('LEVELS_FETCH_DATA_FAIL');
export const LEVELS_CREATE_INIT = createAction('LEVELS_CREATE_INIT');
export const LEVELS_CREATE_SUCCESS = createAction('LEVELS_CREATE_SUCCESS');
export const LEVELS_CREATE_FAIL = createAction('LEVELS_CREATE_FAIL');

export const LEVELS_MODIFY_INIT = createAction('LEVELS_MODIFY_INIT');
export const LEVELS_MODIFY_SUCCESS = createAction('LEVELS_MODIFY_SUCCESS');
export const LEVELS_MODIFY_FAIL = createAction('LEVELS_MODIFY_FAIL');

export const LEVELS_DELETE_INIT = createAction('LEVELS_DELETE_INIT');
export const LEVELS_DELETE_SUCCESS = createAction('LEVELS_DELETE_SUCCESS');
export const LEVELS_DELETE_FAIL = createAction('LEVELS_DELETE_FAIL');

export const LEVELS_CLEAR_DATA = createAction('LEVELS_CLEAR_DATA');
export const LEVELS_CLEAN_UP = createAction('LEVELS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('levels', 'level');

/**
 * Action Fetch levels
 * @return dispatch
 */
export const fetchLevels = () => {
  return async (dispatch) => {
    dispatch(LEVELS_FETCH_DATA_INIT());
    let levels;
    try {
      levels = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(LEVELS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      LEVELS_FETCH_DATA_SUCCESS({
        levels,
      })
    );
  };
};

/**
 * Action Create new level
 * @return dispatch
 */

export const createlevel = ({
  name,
  desc,
  value,
  criterias,
  disabled,
  purchaseNumber,
}) => {
  return async (dispatch, getState) => {
    dispatch(LEVELS_CREATE_INIT());
    const { locale } = getState().preferences;
    let level;
    try {
      const displayName = displayText(name);
      level = await gc.create(
        {
          name,
          displayName,
          desc,
          value: +displayName.split('-')[1],
          criterias,
          disabled,
          purchaseNumber,
        },
        'displayName'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Niveau', 'la création a échoué');
      return dispatch(LEVELS_DELETE_FAIL({ error: errorMessage }));
    }
    toastr.success('Niveau', 'créé avec succès');
    return dispatch(LEVELS_CREATE_SUCCESS({ level }));
  };
};

/**
 * Action Delete level
 * @param {string} id ID of level
 * @return dispatch
 */
export const deleteLevel = (id) => {
  return async (dispatch, getState) => {
    dispatch(LEVELS_DELETE_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Niveau', 'la suppression a échoué');
      return dispatch(LEVELS_DELETE_FAIL({ error: errorMessage }));
    }
    toastr.success('Niveau', 'Supprimé avec succès');
    return dispatch(LEVELS_DELETE_SUCCESS({ id }));
  };
};

/**
 * Action Update level
 * @return dispatch
 */

export const modifyLevel = ({
  name,
  desc,
  value,
  criterias,
  disabled,
  purchaseNumber,
  id,
}) => {
  return async (dispatch, getState) => {
    dispatch(LEVELS_MODIFY_INIT());
    const { locale } = getState().preferences;
    let level;
    try {
      const displayName = displayText(name);
      level = await gc.update(
        id,
        {
          name,
          displayName,
          desc,
          value: +displayName.split('-')[1],
          criterias,
          disabled,
          purchaseNumber,
        },
        'displayName'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Niveau', 'mise à jour a échoué');
      return dispatch(LEVELS_MODIFY_FAIL({ error: errorMessage }));
    }
    toastr.success('Niveau', 'mis à jour avec succès');
    return dispatch(LEVELS_MODIFY_SUCCESS({ level }));
  };
};

export const levelsCleanUp = () => (dispatch) => dispatch(LEVELS_CLEAN_UP());

export const clearLevelsData = () => {
  return (dispatch) => {
    dispatch(LEVELS_CLEAR_DATA());
  };
};
