import React from 'react';
import Button from 'components/Common/Button';

const PageHeader = ({ title, hideButton, to }) => {
  /**
   * author issam@abweb.ma
   *@param {String} title, text to be shown on the left
   *@param {Boolean} hideButton, to hide the button on the right
   */
  return (
    <section className="hero is-hero-bar">
      <div className="hero-body">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="text-brand-darkBlue text-3xl font-semibold">
                {title}
              </h1>
            </div>
          </div>
          {!hideButton && (
            <div className="level-right">
              <div className="level-item">
                <Button
                  to={to}
                  text="Nouvelle"
                  color="blue"
                  size="medium"
                  icon="mdi-plus-box-multiple"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PageHeader;
