import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ActivationDetailsCard from 'components/Common/ActivationDetailsCard';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import { useFormatMessage } from 'hooks';
import { fetchActivation } from 'state/actions/activations';

const ActivationDetails = () => {
  const { id } = useParams();
  const { isAdmin, activation, loading } = useSelector((state) => {
    return {
      activation: state.activations.activation,
      loading: state.activations.loading,
      isAdmin: state.auth.userData.isAdmin,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id && id !== 'new' && id !== 'undefined') {
      dispatch(fetchActivation({}, id));
    }
  }, [id]);

  const columns = [
    {
      Header: useFormatMessage('ActivationsDetails.table.name'),
      accessor: 'name',
      Cell: ({ row }) =>
        row.original.agency ? row.original.agency.name : '----',
    },
    {
      Header: useFormatMessage('ActivationsDetails.table.positionNumber'),
      accessor: 'initPositions',
    },
    {
      Header: useFormatMessage('ActivationsDetails.table.initialStock'),
      accessor: 'initProductsVolume',
    },
    {
      Header: useFormatMessage('ActivationsDetails.table.productsEcoule'),
      accessor: 'totalProducts',
    },
    {
      Header: useFormatMessage('ActivationsDetails.table.activationsRealized'),
      accessor: 'totalContacts',
    },
    {
      Header: useFormatMessage('ActivationsDetails.table.successfullContacts'),
      accessor: 'totalSuccessContacts',
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) =>
        row.original.agency && row.original.agency.id ? (
          <div className="flex justify-center">
            <Button
              to={`/activations/${id}/${row.original.agency.id}/details/agency`}
              isIconOnly
              className="mx-2"
              text="Consulter"
              icon="mdi-chevron-right"
              color="blue"
              size="small"
            />
          </div>
        ) : (
          '-----'
        ),
    },
  ];
  return (
    <div className="page-padding">
      <div className="p-2">
        <ActivationDetailsCard
          loading={loading}
          activation={{ ...activation }}
        />
      </div>
      <div className="">
        <div className="p-2">
          <h1 className="text-2xl font-semibold text-brand-darkBlue">
            {/* {useFormatMessage('ActivationsDetails.title')} */}
          </h1>
        </div>
        <div className="p-2">
          {/* you can pass a loading state to table loading={loading} */}
          {isAdmin ? (
            <Table
              loading={loading}
              data={activation.agencies || []}
              columns={columns}
            />
          ) : (
            <Table
              loading={loading}
              data={activation.products || []}
              columns={columns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivationDetails;
