import React, { useState, useEffect } from 'react';
import {
  createActCategory,
  modifyActCategory,
} from 'state/actions/actCategories';
import ActivationsCategoryForm from 'components/ActivationsCategoryForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

import { useFormatMessage } from 'hooks';

const ActivationsCategory = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.actCategories.success,
    }),
    shallowEqual
  );
  const [actCategory, setActCategory] = useState({ name: '', marks: [] });

  useEffect(() => {
    const fetchActCategoryData = async () => {
      const response = await firebase
        .firestore()
        .collection('actCategories')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchActCategoryData()
        .then((actCategoryData) => {
          setActCategory({
            ...actCategoryData.data(),
            id,
          });
        })
        .catch(() => {
          setActCategory({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const activationsCategoryForm =
    !actCategory.name && id ? (
      <ClipLoader />
    ) : (
      <ActivationsCategoryForm
        isEditing={isEditing}
        actCategoryData={actCategory}
        action={isEditing ? modifyActCategory : createActCategory}
      />
    );

  const redirect = (actCategory.error || success) && (
    <Redirect to={paths.ACTIVATIONS_CATEGORIES} />
  );
  return (
    <>
      {redirect}
      <PageHeader
        title={useFormatMessage('ActivationsCategory.title')}
        hideButton
      />
      <section className="page-padding">{activationsCategoryForm}</section>
    </>
  );
};

export default ActivationsCategory;
