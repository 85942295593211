import firebase from 'firebase.js';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
/* eslint-disable camelcase */

export const KPIS_FETCH_DATA_INIT = createAction('KPIS_FETCH_DATA_INIT');
export const KPIS_FETCH_DATA_SUCCESS = createAction('KPIS_FETCH_DATA_SUCCESS');
export const KPIS_FETCH_DATA_FAIL = createAction('KPIS_FETCH_DATA_FAIL');
export const KPIS_CLEAR_DATA = createAction('KPIS_CLEAR_DATA');
export const KPIS_CLEAN_UP = createAction('KPIS_CLEAN_UP');

/**
 * Action kpis
 * @return dispatch
 */
export const fetchKpis = (agencyId, actId) => {
  return async (dispatch) => {
    dispatch(KPIS_FETCH_DATA_INIT());
    try {
      const { data } = await firebase.functions().httpsCallable('httpsKpis')({
        agencyId,
        actId,
      });
      return dispatch(KPIS_FETCH_DATA_SUCCESS({ kpis: data }));
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(KPIS_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const kpisCleanUp = () => (dispatch) => dispatch(KPIS_CLEAN_UP());

export const clearKpisData = () => {
  return (dispatch) => {
    dispatch(KPIS_CLEAR_DATA());
  };
};
