import React, { useState, useEffect } from 'react';
import SelectField from 'components/Common/SelectField';
import NormalInput from 'components/Common/NormalInput';
import Button from 'components/Common/Button';
import { useChangeHandler } from 'hooks';
import { useSelector, shallowEqual } from 'react-redux';
import {
  updateActivationProduct,
  createProductNotification,
} from 'state/actions/activations';

import './StockModal.scss';

const StockModal = ({
  isActive,
  updateProduct,
  onCancel,
  activation,
  product,
}) => {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.userData,
    }),
    shallowEqual
  );
  const [stockInfo, setStockInfo] = useState({
    quantityRecieved: 0,
    differenceReason: null,
    discrepancyExist: false,
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (product) {
      setStockInfo({
        quantityRecieved: 0,
        differenceReason: null,
        activation,
        product,
      });
    }
  }, [activation, product]);

  useEffect(() => {
    if (product) {
      setStockInfo((prevState) => ({
        ...prevState,
        discrepancyExist: product.quantity > stockInfo.quantityRecieved,
      }));
    }
  }, [product, stockInfo.quantityRecieved]);

  const modifiers = isActive && 'is-active';
  const loadingModifier = isLoading && 'is-loading';

  const onReasonChange = ({ value }) => {
    setStockInfo((prevState) => ({
      ...prevState,
      differenceReason: value,
    }));
  };

  const onChangeHandler = useChangeHandler(setStockInfo);

  const diferenceReason = [
    {
      value: 'Stock remis par l’agence insuffisant',
      label: 'Stock remis par l’agence insuffisant',
    },
    {
      value: 'Manquant constaté lors de l’ouverture du colis',
      label: 'Manquant constaté lors de l’ouverture du colis',
    },
    {
      value: 'Article défaillant',
      label: 'Article défaillant',
    },
  ];

  const handleClose = () => {
    setStockInfo({
      quantityRecieved: 0,
      differenceReason: null,
      discrepancyExist: false,
    });
    setLoading(false);
    onCancel();
  };

  const submitHandler = async () => {
    setLoading(true);
    if (stockInfo.discrepancyExist) {
      const notificationData = {
        activationName: activation.name,
        agency: activation.agency.id,
        createdAt: new Date(),
        from: user.id,
        idProduit: product.id,
        message: stockInfo.differenceReason,
        notificationType: 'product',
        positionLicence: '',
        productName: product.name,
        qtyInitial: product.quantity,
        qtyRecieved: +stockInfo.quantityRecieved,
        taken: false,
        to: 'admin',
        userName: user.name,
      };
      await createProductNotification(notificationData);
    }
    updateActivationProduct(activation.id, product.id, activation.agency.id, {
      quantity: product.quantity,
      quantityReceived: +stockInfo.quantityRecieved,
    }).then((result) => {
      if (result) {
        updateProduct({
          id: product.id,
          name: product.name,
          quantity: product.quantity,
          quantityReceived: +stockInfo.quantityRecieved,
        });
        handleClose();
      }
    });
  };

  return (
    <div className={`modal ${modifiers}`}>
      <div
        className="modal-background"
        onClick={!isLoading ? onCancel : undefined}
      />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">État du stock</p>
        </header>
        <section className="modal-card-body">
          <div className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap">
            <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
              <div className="lg:max-w-xl w-full lg:w-5/6">
                <NormalInput
                  className="flex-1 max-w-sm"
                  label="Quantité reçue"
                  type="number"
                  required
                  value={stockInfo.quantityRecieved}
                  name="quantityRecieved"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            {stockInfo.discrepancyExist && (
              <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
                <div className=" lg:max-w-xl w-full lg:w-5/6">
                  <SelectField
                    disabled
                    options={diferenceReason}
                    label="Raison de la différence"
                    type="form"
                    name="reason"
                    placeholder="Select Role"
                    onChange={onReasonChange}
                  />
                </div>
              </div>
            )}
            <Button
              className={`mt-6 mx-4 ${loadingModifier}`}
              text="Enregistrer"
              icon="mdi-plus"
              color="green"
              size="large"
              onClick={submitHandler}
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button"
            onClick={handleClose}
            disabled={isLoading}
          >
            Annuler
          </button>
        </footer>
      </div>
    </div>
  );
};

export default StockModal;
