/* eslint-disable camelcase */
import {
  createAction
} from 'redux-act';
import {
  toastr
} from 'react-redux-toastr';
import {
  firebaseError
} from 'utils';
import ActivationController from 'controllers/ActivationController';
import firebase from 'firebase.js';

export const ACTIVATIONS_FETCH_DATA_INIT = createAction(
  'ACTIVATIONS_FETCH_DATA_INIT'
);
export const ACTIVATIONS_FETCH_DATA_SUCCESS = createAction(
  'ACTIVATIONS_FETCH_DATA_SUCCESS'
);
export const ACTIVATIONS_FETCH_DATA_FAIL = createAction(
  'ACTIVATIONS_FETCH_DATA_FAIL'
);
export const MEMBER_FETCH_DATA_INIT = createAction('MEMBER_FETCH_DATA_INIT');
export const MEMBER_FETCH_DATA_SUCCESS = createAction(
  'MEMBER_FETCH_DATA_SUCCESS'
);
export const MEMBER_FETCH_DATA_FAIL = createAction('MEMBER_FETCH_DATA_FAIL');
export const ACTIVATION_FETCH_DATA_INIT = createAction(
  'ACTIVATION_FETCH_DATA_INIT'
);
export const ACTIVATION_FETCH_DATA_SUCCESS = createAction(
  'ACTIVATION_FETCH_DATA_SUCCESS'
);
export const ACTIVATION_FETCH_DATA_FAIL = createAction(
  'ACTIVATION_FETCH_DATA_FAIL'
);
export const ACTIVATION_POS_FETCH_DATA_INIT = createAction(
  'ACTIVATION_POS_FETCH_DATA_INIT'
);
export const ACTIVATION_POS_FETCH_DATA_SUCCESS = createAction(
  'ACTIVATION_POS_FETCH_DATA_SUCCESS'
);
export const ACTIVATION_POS_FETCH_DATA_FAIL = createAction(
  'ACTIVATION_POS_FETCH_DATA_FAIL'
);
export const ACTIVATIONS_CREATE_ACTIVATION_INIT = createAction(
  'ACTIVATIONS_CREATE_ACTIVATION_INIT'
);
export const ACTIVATIONS_CREATE_ACTIVATION_SUCCESS = createAction(
  'ACTIVATIONS_CREATE_ACTIVATION_SUCCESS'
);
export const ACTIVATIONS_CREATE_ACTIVATION_FAIL = createAction(
  'ACTIVATIONS_CREATE_ACTIVATION_FAIL'
);
export const ACTIVATIONS_CLEAR_DATA = createAction('ACTIVATIONS_CLEAR_DATA');
export const ACTIVATIONS_DELETE_ACTIVATION_INIT = createAction(
  'ACTIVATIONS_DELETE_ACTIVATIONS_INIT'
);
export const ACTIVATIONS_DELETE_ACTIVATION_SUCCESS = createAction(
  'ACTIVATIONS_DELETE_ACTIVATION_SUCCESS'
);
export const ACTIVATIONS_DELETE_ACTIVATION_FAIL = createAction(
  'ACTIVATIONSS_DELETE_ACTIVATIONS_FAIL'
);
export const ACTIVATIONS_MODIFY_ACTIVATION_INIT = createAction(
  'ACTIVATIONS_MODIFY_ACTIVATION_INIT'
);
export const ACTIVATIONS_MODIFY_ACTIVATION_SUCCESS = createAction(
  'ACTIVATIONS_MODIFY_ACTIVATION_SUCCESS'
);
export const ACTIVATIONS_MODIFY_ACTIVATION_FAIL = createAction(
  'ACTIVATIONS_MODIFY_ACTIVATION_FAIL'
);
export const ACTIVATIONS_CLEAN_UP = createAction('ACTIVATIONS_CLEAN_UP');

const ac = new ActivationController();

export const fetchActivations = (agencyId, history, date) => {
  return async (dispatch) => {
    dispatch(ACTIVATIONS_FETCH_DATA_INIT());
    let activations;
    try {
      activations = await ac.fetch(agencyId, history, date);
    } catch (error) {

      toastr.error('Activations', 'Operation a échoué');
      return dispatch(ACTIVATIONS_FETCH_DATA_FAIL({
        error
      }));
    }
    return dispatch(
      ACTIVATIONS_FETCH_DATA_SUCCESS({
        activations: activations.filter((act) => act),
      })
    );
  };
};


export const fetchMemberAnimations = (activationId, memberId, type) => {
  return async (dispatch) => {
    dispatch(MEMBER_FETCH_DATA_INIT());
    let animations;
    try {
      animations = await ac.fetchAnimations(activationId, memberId, type);
    } catch (error) {
      toastr.error('animations', 'Operation a échoué');
      return dispatch(MEMBER_FETCH_DATA_FAIL({
        error
      }));
    }
    return dispatch(
      MEMBER_FETCH_DATA_SUCCESS({
        animations: animations.filter((act) => act),
      })
    );
  };
};

export const fetchActivation = (act, actId, agId = null) => {
  return async (dispatch) => {
    dispatch(ACTIVATION_FETCH_DATA_INIT());
    let activation;
    try {
      activation = !agId ?
        await ac.fetchData(act, actId) :
        await ac.fetchAgency(act, actId, agId);
    } catch (error) {
      toastr.error('Activation', 'Operation a échoué');
      return dispatch(ACTIVATION_FETCH_DATA_FAIL({
        error
      }));
    }
    return dispatch(ACTIVATION_FETCH_DATA_SUCCESS({
      activation
    }));
  };
};

export const fetchActivationPositions = ({
  actId,
  periodStart,
  periodEnd
}) => {
  return async (dispatch) => {
    dispatch(ACTIVATION_POS_FETCH_DATA_INIT());
    let actPositions;
    try {
      actPositions = await ac.fetchActivationPositions({
        actId,
        periodStart,
        periodEnd,
      });
    } catch (error) {
      console.log('error Activation', error);

      toastr.error('Activation', 'Operation a échoué');
      return dispatch(ACTIVATION_POS_FETCH_DATA_FAIL({
        error
      }));
    }
    return dispatch(ACTIVATION_POS_FETCH_DATA_SUCCESS({
      actPositions
    }));
  };
};

export const createActivation = (data) => {
  return async (dispatch) => {
    dispatch(ACTIVATIONS_CREATE_ACTIVATION_INIT());
    let activation;
    try {
      activation = await ac.create(data);
    } catch (error) {
      toastr.error('Activation', 'la création a échoué');
      return dispatch(ACTIVATIONS_CREATE_ACTIVATION_FAIL({
        error
      }));
    }
    toastr.success('Activation', 'créé avec succès');
    return dispatch(ACTIVATIONS_CREATE_ACTIVATION_SUCCESS({
      activation
    }));
  };
};

export const deleteActivation = (id) => {
  return async (dispatch, getState) => {
    dispatch(ACTIVATIONS_DELETE_ACTIVATION_INIT());
    const {
      locale
    } = getState().preferences;
    try {
      await ac.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Activation', 'la suppression a échoué');
      return dispatch(
        ACTIVATIONS_DELETE_ACTIVATION_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('Activation', 'Supprimé avec succès');
    return dispatch(ACTIVATIONS_DELETE_ACTIVATION_SUCCESS({
      id
    }));
  };
};

export const modifyActivation = (data) => {
  return async (dispatch, getState) => {
    dispatch(ACTIVATIONS_MODIFY_ACTIVATION_INIT());
    const {
      locale
    } = getState().preferences;
    let team;
    try {
      team = await ac.update(data);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Activation', 'mise à jour a échoué');
      return dispatch(
        ACTIVATIONS_MODIFY_ACTIVATION_SUCCESS({
          error: errorMessage
        })
      );
    }
    toastr.success('Activation', 'mis à jour avec succès');
    return dispatch(ACTIVATIONS_MODIFY_ACTIVATION_FAIL({
      team
    }));
  };
};

export const updateActivationProduct = async (
  activationId,
  productId,
  agencyId,
  productData
) => {
  try {
    await firebase
      .firestore()
      .collection('activations')
      .doc(activationId)
      .collection('agencies')
      .doc(agencyId)
      .collection('products')
      .doc(productId)
      .update({
        ...productData
      });

    return true;
  } catch (error) {
    return false;
  }
};

export const createProductNotification = async (notification) => {
  const result = await firebase
    .firestore()
    .collection('notifications')
    .add(notification);
  return result;
};

export const updatePlanning = (activationId, positions, planTeams) => {
  return async (dispatch, getState) => {
    dispatch(ACTIVATIONS_MODIFY_ACTIVATION_INIT());
    let planning;
    try {
      planning = await ac.updatePlanning(activationId, positions, planTeams);
      toastr.success('Activation', 'mis à jour avec succès');
    } catch (error) {
      toastr.error('Activation', 'mise à jour a échoué');
    }
    return dispatch(ACTIVATIONS_MODIFY_ACTIVATION_FAIL({
      planning
    }));
  };
};

export const enableActivation = (id, option) => ac.enable(id, option);

export const activationsCleanUp = () => (dispatch) =>
  dispatch(ACTIVATIONS_CLEAN_UP());

export const clearActivationsData = () => {
  return (dispatch) => {
    dispatch(ACTIVATIONS_CLEAR_DATA());
  };
};