/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { useFormatMessage, useChangeHandler } from 'hooks';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import Button from 'components/Common/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import Table from 'components/Table';
import { fetchProducts } from 'state/actions/products';
import { fetchLevels } from 'state/actions/levels';
import NormalCheckbox from 'components/Common/NormalCheckbox';

const Produits = ({ next, previous, setActivation, activation }) => {
  const dispatch = useDispatch();

  const {
    loading,
    actProducts,
    actProductsLoading,
    actLevels,
    actLevelsLoading,
    gameCats,
  } = useSelector(
    (state) => ({
      loading: state.activations.loading,
      actProducts: state.products.data,
      actProductsLoading: state.products.loading,
      actLevels: state.levels.data,
      actLevelsLoading: state.levels.loading,
      gameCats: state.games.data[4].categories,
    }),
    shallowEqual
  );
  const [products, setProducts] = useState(activation.products);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [product, setProduct] = useState({
    productName: '',
    productId: '',
    levelName: '',
    levelId: '',
    quantity: 0,
    price: 0,
    isbigwin: false,
    categories: '',
  });

  const selectProdOptions = actProducts.map((actProd) => ({
    value: actProd.id,
    label: actProd.name,
  }));
  const selectGameCat = gameCats.map((gameCat) => ({
    value: gameCat,
    label: gameCat.toUpperCase(),
  }));
  const selectLevelOptions = actLevels.map((actLevel) => ({
    value: actLevel.id,
    label: actLevel.name,
  }));

  useEffect(() => {
    dispatch(fetchLevels());
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    // console.log(isbigwin);

    console.log('product', product);

    setActivation({ ...activation, initProducts: products.length, products });
  }, [products, product]);

  const onChangeHandler = useChangeHandler(setProduct);

  const onChangeSelect = (type, opt) => {
    const setOpt =
      type === 'product'
        ? { productName: opt.label, productId: opt.value }
        : { levelName: opt.label, levelId: opt.value };
    setProduct((prod) => ({ ...prod, ...setOpt }));
  };

  const selectValue = (type, selectId) => {
    let selct;
    if (type === 'product') {
      selct = !actProductsLoading
        ? selectProdOptions.find((prod) => prod.id === selectId)
        : null;
    } else {
      selct = !actLevelsLoading
        ? selectLevelOptions.find((lvl) => lvl.id === selectId)
        : null;
    }
    if (selct) return { value: selct.value, label: selct.label };
  };

  const addProduct = (e) => {
    e.preventDefault();
    if (!products.some((p) => p.productId === product.productId))
      setProducts([
        ...products,
        {
          ...product,
          quantity: parseInt(product.quantity),
          price: parseInt(product.price),
        },
      ]);
  };

  const goPositions = (e) => {
    e.preventDefault();
    next();
  };

  const canAddProd =
    product.productId &&
    product.quantity > 0 &&
    // product.levelId &&
    product.price > 0;

  const canSubmit = products.length > 0;

  const columns = [
    {
      Header: useFormatMessage('Activation.productsTab.table.name'),
      accessor: 'productName',
    },
    {
      Header: useFormatMessage('Activation.productsTab.table.quantity'),
      accessor: 'quantity',
    },
    // {
    //   Header: useFormatMessage('Activation.productsTab.table.levelName'),
    //   accessor: 'levelName',
    // },
    {
      Header: useFormatMessage('Activation.productsTab.table.price'),
      accessor: 'price',
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: ({ row }) => (
        <Button
          className="mx-auto"
          text={useFormatMessage('Activation.productsTab.table.action.delete')}
          size="small"
          icon="mdi-delete"
          color="red"
          onClick={(e) => {
            e.preventDefault();
            products.splice(row.index, 1);
            if (row.original.id)
              setActivation({
                ...activation,
                deletedProducts: [
                  ...activation.deletedProducts,
                  row.original.id,
                ],
              });
            setProducts([...products]);
          }}
        />
      ),
    },
  ];
  const deleteMessage = useFormatMessage('Activation.productsTab.delete');
  const confirmMessage = useFormatMessage('Activation.productsTab.confirm');
  const permDeleteMessage = useFormatMessage(
    'Activation.productsTab.permDelete'
  );
  const cancelMessage = useFormatMessage('Activation.productsTab.cancel');

  // const onCancelActivationHandler = () => {
  //   window.location.reload();
  // };
  const [ishidden, setishidden] = useState('hidden');
  const [techenology, settechenology] = useState('technology');
  const onchangeCheckbox = (cb) => {
    console.log(cb.target.checked);
    product.isbigwin = !product.isbigwin;

    if (cb.target.checked) {
      product.categories = techenology;
      setishidden('');
    } else {
      setishidden('hidden');
      product.categories = '';
    }

    console.log('categories  :', product.categories);
  };
  const onchangeCategory = (value) => {
    product.categories = value.value;
    console.log('categories  :', value.value);
  };

  const onCloseModalHandler = () => {
    setIsModalOpen(false);
  };
  const onCancelButtonClickHandler = () => {
    setIsModalOpen((prevState) => !prevState.isOpen);
  };
  return (
    <>
      {isModalOpen && (
        <ConfirmationModal
          isActive={isModalOpen}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          // onConfirmation={onCancelActivationHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <div className="">
        <h1 className="text-2xl font-semibold text-brand-darkBlue py-3">
          {useFormatMessage('Activation.productsTab.title1')}
        </h1>
        <form className="">
          <div className="flex px-4 py-6 border border-brand-lighterBlue rounded-lg">
            <div className="w-2/3 flex flex-wrap">
              <div className="w-1/2">
                <SelectField
                  isLoading={actProductsLoading}
                  isDisabled={actProductsLoading}
                  type="form"
                  label={useFormatMessage('Activation.productsTab.product')}
                  onChange={(value) => onChangeSelect('product', value)}
                  options={selectProdOptions}
                  value={selectValue('product', product.productId)}
                />
              </div>
              <div className="w-1/2">
                <NormalInput
                  label={useFormatMessage(
                    'Activation.productsTab.amountAllocated'
                  )}
                  onChange={onChangeHandler}
                  type="text"
                  required
                  name="quantity"
                  value={product.quantity}
                />
              </div>

              <div className="w-1/2">
                <NormalInput
                  label={useFormatMessage(
                    'Activation.productsTab.winningPrice'
                  )}
                  onChange={onChangeHandler}
                  type="text"
                  required
                  name="price"
                  value={product.price}
                />
              </div>
              <div className="w-1/2 p-2">
                <NormalCheckbox
                  label={useFormatMessage('Activation.animationsTab.bigwin')}
                  // eslint-disable-next-line no-return-assign
                  // product.isbigwin = !product.isbigwin
                  // (e) =>
                  onChange={(e) => onchangeCheckbox(e)}
                  // name="price"
                  // value={product.price}
                />
              </div>
              <div className={`w-full ${ishidden}`}>
                <SelectField
                  isLoading={actLevelsLoading}
                  isDisabled={actLevelsLoading}
                  type="form"
                  required
                  label={useFormatMessage('Activation.productsTab.gainLevel')}
                  onChange={(value) => onchangeCategory(value)}
                  options={selectGameCat}
                  value={selectValue('level', product.levelId)}
                />
              </div>
            </div>
            <div className="ml-auto self-end">
              <Button
                className="mb-2"
                size="large"
                icon="mdi-plus-circle"
                color="green"
                text={useFormatMessage('Activation.productsTab.add')}
                disabled={!canAddProd}
                onClick={addProduct}
              />
            </div>
          </div>
          <div className="mt-4">
            <h1 className="text-2xl font-semibold text-brand-darkBlue py-3">
              {useFormatMessage('Activation.productsTab.title2')}
            </h1>
            <Table data={products} columns={columns} loading={loading} />
          </div>
          <div className="w-full py-8 px-4 flex justify-between">
            <Button
              className=""
              size="medium"
              icon="mdi-chevron-left"
              color="blue"
              text={useFormatMessage('Activation.productsTab.previous')}
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
            />
            <Button
              className=""
              size="medium"
              icon="mdi-chevron-right"
              color="blue"
              text={useFormatMessage('Activation.productsTab.next')}
              disabled={!canSubmit}
              onClick={goPositions}
            />
            <Button
              className="text-gray-500 text-sm"
              text={useFormatMessage('Activation.productsTab.cancelCreation')}
              onClick={() => onCancelButtonClickHandler()}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Produits;
