import React, { useState, useEffect } from 'react';
import {
  createProCategory,
  modifyProCategory,
} from 'state/actions/proCategories';
import ProductsCategoryForm from 'components/ProductsCategoryForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

const ProductsCategory = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.proCategories.success,
    }),
    shallowEqual
  );
  const [proCategory, setProCategory] = useState({ name: '' });

  useEffect(() => {
    const fetchPosCategoryData = async () => {
      const response = await firebase
        .firestore()
        .collection('proCategories')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchPosCategoryData()
        .then((posCategoryData) => {
          setProCategory({
            ...posCategoryData.data(),
            id,
          });
        })
        .catch(() => {
          setProCategory({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const productsCategoryForm =
    !proCategory.name && id ? (
      <ClipLoader />
    ) : (
      <ProductsCategoryForm
        isEditing={isEditing}
        proCategoryData={proCategory}
        action={isEditing ? modifyProCategory : createProCategory}
      />
    );

  const redirect = (proCategory.error || success) && (
    <Redirect to={paths.PRODUCTS_CATEGORIES} />
  );

  return (
    <>
      {redirect}
      <PageHeader title="Nature" hideButton />
      <section className="page-padding">
        {productsCategoryForm}
      </section>
    </>
  );
};

export default ProductsCategory;
