/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFormatMessage, useChangeHandler } from 'hooks';
import NormalInput from 'components/Common/NormalInput';
import FileInput from 'components/Common/FileInput';
import Button from 'components/Common/Button';

const BrandForm = ({ isEditing, brandData, action }) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.brands.loading,
    }),
    shallowEqual
  );
  const [brand, setBrand] = useState(brandData);
  const onChangeHandler = useChangeHandler(setBrand);
  const dispatch = useDispatch();

  const imagePreviewUrl = !brand.imageUrl
    ? brand.file && URL.createObjectURL(brand.file)
    : brand.imageUrl;

  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setBrand((prevState) => ({ ...prevState, file, imageUrl: null }));
  };
  const brandFormSubmit = useFormatMessage('BrandForm.submit');
  const brandFormUpdate = useFormatMessage('BrandForm.update');
  // const canSubmit = !!brand.name && !!brand.file;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(brand));
  };

  return (
    <>
      <h1 className="text-2xl mb-2 text-brand-darkBlue font-semibold">
        {useFormatMessage('Brands.name')}
      </h1>
      <form
        onSubmit={onSubmitHandler}
        className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
      >
        {imagePreviewUrl && (
          <div className="w-full flex-none pb-4">
            <div className="is-user-avatar image has-max-width is-aligned-center">
              <img
                className="user-avatar"
                src={imagePreviewUrl}
                alt="User profile logo preview"
              />
            </div>
          </div>
        )}
        <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-1/2">
          <div className=" lg:max-w-sm lg:w-1/2">
            <NormalInput
              label={useFormatMessage('BrandForm.name')}
              id="name"
              type="text"
              required
              name="name"
              value={brand.name}
              onChange={onChangeHandler}
            />
          </div>
          <div className=" lg:max-w-sm lg:w-1/2">
            <FileInput
              isForm
              label="Image"
              type="file"
              accept="image/*"
              file={brand.file}
              className={`${loading && 'is-loading'}`}
              disabled={loading}
              onChange={onFileChangedHandler}
            />
          </div>
        </div>
        <div className=" lg:max-w-sm lg:w-1/2 flex items-end py-3 px-2">
          <Button
            type="submit"
            className={`${loading && 'is-loading'}`}
            disabled={loading}
            color="green"
            size="large"
            icon="mdi-plus-circle"
            text={isEditing ? brandFormUpdate : brandFormSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default BrandForm;
