import firebase from 'firebase.js';
import Controller from './Controller';
import AgencyController from './AgencyController';
import PositionController from './PositionController';
import ProductController from './ProductController';
// import LevelController from './LevelController';
import MarkController from './MarksController';
import GameController from './GameController';
import AwardController from './AwardController';
import UserController from './UserController';
import TeamController from './TeamController';

export default class RelationController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'activations';
    this.item = 'activation';
  }

  getCategory = (categoryCollection, categoryId) =>
    this.getDoc(categoryId, categoryCollection).then((categorySnap) => {
      if (categorySnap.exists) {
        return {
          id: categorySnap.id,
          ...categorySnap.data(),
        };
      }
    });

  /**
   * fetch activation agencies from (positions) subcollection
   * data related to (agency) collection, and get data of each agency
   * @param {Array} actMarksIds array of activation marks ids
   * @returns {Array} list of marks data
   */
  getActivationMarks = (actMarksIds) => {
    const mc = new MarkController();
    const promises = (actMarksIds || []).map((markId) => mc.fetchById(markId));
    return Promise.all(promises.filter((promise) => promise));
  };

  /**
   * fetch activation agencies from (positions) subcollection
   * data related to (agency) collection, and get data of each agency
   * @param {String} activationId if of activation
   * @returns {Array} list of agencies
   */
  getActivationAgencies = (activationId) => {
    const ac = new AgencyController();
    return this.fetchSubColl(activationId, 'positions').then(
      (positionSnaps) => {
        if (positionSnaps.size > 0) {
          let agencies = [];
          positionSnaps.docs.forEach((pos) => {
            // filter agencies on positions collection
            if (
              !agencies.find(
                (agenciesItem) => agenciesItem.agencyId === pos.data().agencyId
              )
            )
              agencies = [...agencies, { ...pos.data() }];
          });
          const promises = agencies.map(async (agency) => ({
            ...agency,
            agency: await ac.fetchById(agency.agencyId),
          }));
          return Promise.all(promises.filter((promise) => promise));
        }
        return [];
      }
    );
  };

  getActivationPositions = (activationId, agencyId = null) => {
    const pc = new PositionController();
    const ac = new AgencyController();
    const prc = new ProductController();
    const tc = new TeamController();

    return (agencyId
      ? firebase
          .firestore()
          .collection('activations')
          .doc(activationId)
          .collection('positions')
          .where('agencyId', '==', agencyId)
          .get()
      : this.fetchSubColl(activationId, 'positions')
    ).then((positionSnaps) => {
      if (positionSnaps.size > 0) {
        let positions = [];
        positionSnaps.docs.forEach((pos) => {
          if (
            !positions.find(
              (positionsItem) =>
                positionsItem.positionId === pos.data().positionId
            )
          )
            positions = [...positions, { id: pos.id, ...pos.data() }];
        });
        const promises = positions.map(async (position) => ({
          ...position,
          position: await pc.fetchById(String(position.positionId)),
          team: await tc.fetchById(position.agencyId, position.teamId),
          agency: await ac.fetchById(position.agencyId),
          product: await prc.fetchById(position.productId),
          // TODO
          contacts: [],
        }));
        return Promise.all(promises.filter((promise) => promise));
      }
      return [];
    });
  };

  getActivationProducts = (activationId, agencyId) => {
    const pc = new ProductController();
    return (agencyId
      ? firebase
          .firestore()
          .collection('activations')
          .doc(activationId)
          .collection('positions')
          .where('agencyId', '==', agencyId)
          .get()
      : this.fetchSubColl(activationId, 'positions')
    ).then((productSnaps) => {
      if (productSnaps.size > 0) {
        const promises = productSnaps.docs.map(async (product) => ({
          ...product.data(),
          product: await pc.fetchById(product.data().productId),
        }));
        return Promise.all(promises.filter((promise) => promise));
      }
      return [];
    });
  };

  getActivationAnimations = (activationId) => {
    const pc = new ProductController();
    const gc = new GameController();
    const ac = new AwardController();
    const mc = new MarkController();
    return this.fetchSubColl(activationId, 'games').then((gameSnaps) => {
      if (gameSnaps.size > 0) {
        const promises = gameSnaps.docs.map(async (game) => ({
          ...game.data(),
          product: await pc.fetchById(game.data().productId),
          game: await gc.fetchById(game.data().gameId),
          award: await ac.fetchById(game.data().awardId),
          mark: await mc.fetchById(game.data().markId),
        }));
        return Promise.all(promises.filter((promise) => promise));
      }
      return [];
    });
  };

  getTeams = (agencyId) => {
    const uc = new UserController();
    return firebase
      .firestore()
      .collection('users')
      .doc(agencyId)
      .collection('teams')
      .get()
      .then((teamSnaps) => {
        if (teamSnaps.size > 0) {
          const promises = teamSnaps.docs.map(async (team) => {
            const groups = team.data().groups.map(async (group) => {
              const supervisor = await uc.fetchById(group.supervisor);
              const animators = group.animators.map((animator) =>
                uc.fetchById(animator)
              );
              return { supervisor, animators: await Promise.all(animators) };
            });
            return {
              id: team.id,
              ...team.data(),
              groups: await Promise.all(groups),
            };
          });
          return Promise.all(promises);
        }
        return [];
      });
  };

  getActivationContacts = (activationId, agencyId, positionId) => {
    const pc = new PositionController();
    const ac = new AgencyController();
    let contactRef = firebase
      .firestore()
      .collection('contacts')
      .where('activationId', '==', activationId);
    if (agencyId) contactRef = contactRef.where('agencyId', '==', agencyId);
    if (positionId)
      contactRef = contactRef.where('positionId', '==', positionId);

    return contactRef.get().then((contactSnaps) => {
      if (contactSnaps.size > 0) {
        const promises = contactSnaps.docs.map((doc) => ({
          id: doc.if,
          ...doc.data(),
          agency: ac.fetchById(doc.data().agencyId),
          position: pc.fetchById(doc.data().positionId),
        }));
        return Promise.all(promises);
      }
      return [];
    });
  };
}
