import { createReducer } from 'redux-act';
import {
  MULTIMARKS_FETCH_DATA_INIT,
  MULTIMARKS_FETCH_DATA_SUCCESS,
  MULTIMARKS_FETCH_DATA_FAIL,
  MULTIMARKS_CREATE_MULTIMARK_INIT,
  MULTIMARKS_CREATE_MULTIMARK_SUCCESS,
  MULTIMARKS_CREATE_MULTIMARK_FAIL,
  MULTIMARKS_DELETE_MULTIMARK_INIT,
  MULTIMARKS_DELETE_MULTIMARK_SUCCESS,
  MULTIMARKS_DELETE_MULTIMARK_FAIL,
  MULTIMARKS_MODIFY_MULTIMARK_INIT,
  MULTIMARKS_MODIFY_MULTIMARK_SUCCESS,
  MULTIMARKS_MODIFY_MULTIMARK_FAIL,
  MULTIMARKS_CLEAN_UP,
  MULTIMARKS_CLEAR_DATA,
} from 'state/actions/multimarks';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const multimarksReducer = createReducer(
  {
    [MULTIMARKS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MULTIMARKS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.multimarks,
      loading: false,
      error: null,
    }),
    [MULTIMARKS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MULTIMARKS_CREATE_MULTIMARK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MULTIMARKS_CREATE_MULTIMARK_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.multimark, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [MULTIMARKS_CREATE_MULTIMARK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MULTIMARKS_DELETE_MULTIMARK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MULTIMARKS_DELETE_MULTIMARK_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [MULTIMARKS_DELETE_MULTIMARK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MULTIMARKS_MODIFY_MULTIMARK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MULTIMARKS_MODIFY_MULTIMARK_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: [
          ...state.data.map((elem) => {
            if (elem.id === payload.multimark.id) {
              return {
                pdfUrl: payload.multimark.pdfUrl,
                isActive: payload.multimark.isActive,
                id: payload.id,
                createdAt: payload.multimark.createdAt,
              };
            }
            return elem;
          }),
        ],
        loading: false,
        error: null,
        success: true,
      };
    },
    [MULTIMARKS_MODIFY_MULTIMARK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MULTIMARKS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [MULTIMARKS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
