import React, { useState, useEffect } from 'react';
import { createMark, modifyMark } from 'state/actions/marks';
import MarkForm from 'components/MarkForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

import { useFormatMessage } from 'hooks';

const Mark = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.marks.success,
    }),
    shallowEqual
  );
  const [mark, setMark] = useState({
    name: '',
    isFavorite: false,
    imageUrl: '',
    pdfUrl: '',
    source: '',
  });

  useEffect(() => {
    const fetchMarkData = async () => {
      const response = await firebase
        .firestore()
        .collection('marks')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchMarkData()
        .then((markData) => {
          setMark({
            ...markData.data(),
            id,
          });
        })
        .catch(() => {
          setMark({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const markForm =
    !mark.name && id ? (
      <ClipLoader />
    ) : (
      <MarkForm
        isEditing={isEditing}
        markData={mark}
        action={isEditing ? modifyMark : createMark}
      />
    );

  const redirect = (mark.error || success) && <Redirect to={paths.MARKS} />;

  return (
    <>
      {redirect}
      <PageHeader title={useFormatMessage('Mark.title')} hideButton />
      <div className="page-padding">{markForm}</div>
    </>
  );
};

export default Mark;
