import Moment from 'moment';
import firebase from 'firebase.js';
const getDateYearArray = (agenciesList) => {
  var newList = [];
  agenciesList.map((item) => {
    if (!newList.includes(new Date(item.createdAt).getFullYear()))
      newList.push(new Date(item.createdAt).getFullYear());
  });
  return newList.map((item) => {
    return { label: item, accessor: 'createdAt' };
  });
};

const getOptions = (activations, index) => {
  var newList = [];
  activations.map((item) => {
    if (!newList.includes(item[index])) newList.push(item[index]);
  });
  return newList.map((item) => {
    return { label: item, accessor: index };
  });
};

const isDateBetween = (givingDate, startDate, endDate) =>
  (Moment(new Date(givingDate)).isBefore(new Date(endDate)) ||
    Moment(new Date(givingDate)).isSame(new Date(endDate))) &&
  (Moment(new Date(givingDate)).isAfter(new Date(startDate)) ||
    Moment(new Date(givingDate)).isSame(new Date(startDate)));

const getDatesBorder = (dates) => {
  const newList = dates.sort(
    (a, b) => new Moment(new Date(a.label)) - new Moment(new Date(b.label))
  );
  return {
    first:
      newList.length && newList[0] && newList[0].label
        ? newList[0].label
        : new Date(),
    last:
      newList.length &&
      newList[newList.length - 1] &&
      newList[newList.length - 1].label
        ? newList[newList.length - 1].label
        : new Date(),
  };
};

const getMarkName = (marks, docId) => {
  const mark = marks.find((item) => item.id === docId);
  return mark ? mark.name : '';
};
const dataTronsform = (activations, marks) =>
  activations.map((item, index) => {
    return {
      animation: item.category.name,
      marque: item.marks.length
        ? getMarkName(marks, item.marks[0])
        : '',
      agence: item.agencies.length ? item.agencies[0].agency.name : '',
      region: item.region || '',
      // zone: '',
      // secteur: '',
      date: item.createdAt || '',
    };
  });

export {
  getDateYearArray,
  getOptions,
  isDateBetween,
  getDatesBorder,
  getMarkName,
  dataTronsform,
};
