import React, { useState, useEffect } from 'react';
import { createAlatige, modifyAlatige } from 'state/actions/alatige';
import AlatigeForm from 'components/alatigeForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

const Alatige = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.alatiges.success,
    }),
    shallowEqual
  );
  const [alatige, setAlatige] = useState({
    isActive: false,
    pdfUrl: '',
  });

  useEffect(() => {
    const fetchAlatgieData = async () => {
      const response = await firebase
        .firestore()
        .collection('alatiges')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchAlatgieData()
        .then((alatigeData) => {
          setAlatige({
            ...alatigeData.data(),
            id,
          });
        })
        .catch(() => {
          setAlatige({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const alatigeForm =
    !alatige.pdfUrl && id ? (
      <ClipLoader />
    ) : (
      <AlatigeForm
        isEditing={isEditing}
        alatigeData={alatige}
        action={isEditing ? modifyAlatige : createAlatige}
      />
    );

  const redirect = (alatige.error || success) && (
    <Redirect to={paths.ALATIGES} />
  );

  return (
    <>
      {redirect}
      <PageHeader title="A la tige" hideButton />
      <div className="page-padding">{alatigeForm}</div>
    </>
  );
};

export default Alatige;
