import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import PageHeader from 'components/PageHeader';
import { useFormatMessage, useFormatDate } from 'hooks';
import FilterSearch from 'components/Common/FilterSearch';
import {
  fetchReferences,
  deleteReference,
  clearReferecesData,
} from 'state/actions/references';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import useFilterSearch from 'hooks/useFilterSearch';
import ConfirmationModal from 'components/ConfirmationModal';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import sortByDate from 'utils/sortByDate';

const References = () => {
  const { referencesList, loading, deleted } = useSelector(
    (state) => ({
      loading: state.references.loading,
      referencesList: state.references.data,
      deleted: state.references.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    referenceId: null,
    imageUrl: null,
    argUrl: null,
    brand: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReferences());
    return () => dispatch(fetchReferences());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        referenceId: null,
        imageUrl: null,
        argUrl: null,
        brand: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearReferecesData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (referenceId, imageUrl, argUrl, brand) => {
    setDeleteModal((prevState) => ({
      referenceId,
      imageUrl,
      argUrl,
      brand,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({
      referenceId: null,
      imageUrl: null,
      argUrl: null,
      brand: null,
      isOpen: false,
    });
  };

  const onDeletePreferenceHandler = () => {
    dispatch(deleteReference({ id: deleteModal.referenceId, ...deleteModal }));
  };

  const columns = [
    {
      Header: 'Nome de référence',
      accessor: 'name',
    },
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Image',
      accessor: 'imageLink',
      Cell({ row }) {
        return (
          <div className="flex justify-center">
            <div className="w-10 h-10 bg-gray-400 rounded-full">
              <img
                className="rounded-full"
                src={row.original.imageUrl}
                alt=""
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Activé',
      accessor: 'active',
      Cell({
        row: {
          original: { active },
        },
      }) {
        return (
          <div className="flex justify-center">
            <span className="icon">
              <i
                className={
                  active
                    ? 'mdi mdi-check text-green-600'
                    : 'mdi mdi-close text-red-600'
                }
              />
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Multimarque',
      accessor: 'multiMark',
      Cell({
        row: {
          original: { isMultiMark },
        },
      }) {
        return (
          <div className="flex justify-center">
            <span className="icon">
              <i
                className={
                  isMultiMark
                    ? 'mdi mdi-check text-green-600'
                    : 'mdi mdi-close text-red-600'
                }
              />
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <div className="text-brand-darkBlue">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </div>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell({ row }) {
        return (
          <div className="flex flex-wrap justify-center">
            <Button
              to={`/references/${row.original.id}`}
              className="mx-1"
              text="Modifier"
              isIconOnly
              size="small"
              icon="mdi-pencil"
              color="blue"
            />
            <Button
              className="mx-1"
              text="Supprimer"
              isIconOnly
              size="small"
              icon="mdi-delete"
              color="red"
              onClick={() =>
                onRemoveButtonClickHandler(
                  row.original.id,
                  row.original.imageUrl,
                  row.original.argUrl,
                  row.original.brand
                )
              }
            />
          </div>
        );
      },
    },
  ];

  const deleteMessage = useFormatMessage('Permissions.delete');
  const confirmMessage = useFormatMessage('Permissions.confirm');
  const permDeleteMessage = useFormatMessage('Permissions.permDelete');
  const cancelMessage = useFormatMessage('Permissions.cancel');

  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(referencesList);
  // end filter logic

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeletePreferenceHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Référence" to={paths.ADD_REFERENCE} />
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            hideSelect
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default References;
