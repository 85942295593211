import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import { ClipLoader } from 'react-spinners';
import paths from 'pages/Router/paths';
import {
  fetchPermissions,
  deletePermission,
  clearPermissionsData,
} from 'state/actions/permissions';
import ConfirmationModal from 'components/ConfirmationModal';
import useFilterSearch from 'hooks/useFilterSearch';
import PageHeader from 'components/PageHeader';
import FilterSearch from 'components/Common/FilterSearch';
import Button from 'components/Common/Button';
import sortByDate from 'utils/sortByDate';

const Permissions = () => {
  /**
   * Get Permission Data from states
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   * @param {Array} permissionsList list of permissions data
   * @param {boolean} isAdmin get if authenticated user is Admin
   * @param {string} error fetch parmissions error
   * @param {boolean} leading fetch parmissions leading
   * @param {boolean} deleted parmissions deleted
   */
  const { permissionsList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      permissionsList: state.permissions.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.permissions.error,
      loading: state.permissions.loading,
      deleted: state.permissions.deleted,
    }),
    shallowEqual
  );
  /**
   * Initialize States
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const [search, setSearch] = useState('');
  const [deleteModal, setDeleteModal] = useState({
    permissionId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  /**
   * If Auth is admin fetch data if not clear data
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchPermissions());
    }
    return () => dispatch(clearPermissionsData());
  }, [dispatch, isAdmin]);

  /**
   * Close Modal if Permission deleted or page loading
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        permissionId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  /**
   * Redirect To Root if not Admin
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const redirect = !isAdmin && <Redirect to={paths.ROOT} />;

  /**
   * Show Modal on Delete button clicked
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const onRemoveButtonClickHandler = (permissionId) => {
    setDeleteModal((prevState) => ({
      permissionId,
      isOpen: !prevState.isOpen,
    }));
  };

  /**
   * Close Modal on Close button clicked
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const onCloseModalHandler = () => {
    setDeleteModal({ permissionId: null, isOpen: false });
  };

  /**
   * Show Modal on Delete button clicked
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const onDeletePermissionHandler = () => {
    dispatch(deletePermission(deleteModal.permissionId));
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Dsiplay name',
      accessor: 'displayName',
    },
    {
      /**
       * useFormatMessage from multilange labels
       * Author Ennaim Yassine <ennaimyassine@gmail.com>
       */
      Header: useFormatMessage('Permissions.created'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {
            /**
             * useFormatDate transfer String date to Format
             * Author Ennaim Yassine <ennaimyassine@gmail.com>
             */
            useFormatDate(row.original.createdAt, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })
          }
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            isIconOnly
            className="mx-1"
            to={`/permissions/${row.original.id}`}
            text="modifer"
            color="blue"
            size="small"
            icon="mdi-pencil"
          />

          <Button
            isIconOnly
            className="mx-1"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
            text="modifer"
            color="red"
            size="small"
            icon="mdi-delete"
          />
        </div>
      ),
      disableSortBy: true,
    },
  ];
  /**
   * Search in all Permission object fields
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(permissionsList);

  // end filter logic

  /**
   * Get Messages text
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const deleteMessage = useFormatMessage('Permissions.delete');

  const confirmMessage = useFormatMessage('Permissions.confirm');

  const permDeleteMessage = useFormatMessage('Permissions.permDelete');

  const cancelMessage = useFormatMessage('Permissions.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeletePermissionHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Permissions" to={paths.ADD_PERMISSION} />
      <div className="page-padding">
        <div className="px-2">
          <FilterSearch
            loading={loading}
            hideSelect
            selectProps={selectProps}
            searchProps={searchProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Permissions;
