import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import firebase from 'firebase.js';

import { supportsCleanUp } from 'state/actions/supports';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler } from 'hooks';
import FileInput from 'components/Common/FileInput';

import classnames from 'classnames';
import Preview from 'components/Common/Preview';

const SupportForm = ({ supportData, action, isEditing }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state) => ({
      loading: state.supports.loading,
    }),
    shallowEqual
  );
  const [support, setSupport] = useState(supportData);
  const [categoriesList, setCategoriesList] = useState([]);
  const [editingState, setEditingState] = useState(isEditing);

  useEffect(() => {
    return () => {
      dispatch(supportsCleanUp());
      setSupport({ name: '' });
      setEditingState(false);
    };
  }, [dispatch]);

  useEffect(() => {
    // get categories
    firebase
      .firestore()
      .collection('proCategories')
      .get()
      .then((categoryRef) => {
        const categories = categoryRef.docs.map((categorySnap) => {
          return {
            value: categorySnap.id,
            label: categorySnap.data().name,
          };
        });
        setCategoriesList(categories);
      });
  }, []);

  const onChangeHandler = useChangeHandler(setSupport);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(support));
  };

  const onCategoryChange = ({ value, label }) => {
    setSupport((prevState) => ({
      ...prevState,
      categoryId: value,
      categorie: label,
    }));
  };

  const defaultCategory = isEditing
    ? {
        value: support.category.id,
        label: support.category.name,
      }
    : {};

  const canSubmit =
    support.name && support.categoryId && (support.link || support.file);
  //video uploading logic
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setSupport((prevState) => ({ ...prevState, file, link: null }));
  };
  // Added 3 fields according to SMT-I79
  // please update the logic
  // niveau de gain, prix , telecharger une  photo
  return (
    <>
      <form onSubmit={onSubmitHandler}>
        {/* form  */}
        <div className="flex items-start rounded-lg border border-brand-lighterBlue py-2">
          <div className="flex flex-wrap">
            {/* top  */}
            <div className="w-full flex">
              {/* name  */}
              <NormalInput
                className="flex-1"
                label="Nom"
                value={support.name}
                name="name"
                required
                onChange={onChangeHandler}
              />
              {/* category  */}
              <div className="flex-1">
                <SelectField
                  type="form"
                  label="categorie"
                  options={categoriesList}
                  isLoading={!categoriesList}
                  defaultValue={defaultCategory}
                  onChange={onCategoryChange}
                />
              </div>
            </div>
            {/* bottom  */}
            <div className="w-full flex">
              {/* photo upload */}
              <div className="flex-1">
                <FileInput
                  isForm
                  className={classnames({
                    'is-loading': loading,
                  })}
                  label="Télécharger une photo"
                  accept="image/*"
                  file={support.file}
                  link={support.link}
                  disabled={loading}
                  onChange={onFileChangedHandler}
                />
              </div>
            </div>
          </div>
          <Button
            className={`mt-auto mb-4 mx-8 ${loading && 'is-loading'}`}
            disabled={loading || !canSubmit}
            text="Ajouter"
            icon="mdi-plus-circle"
            color="green"
            size="large"
          />
        </div>
      </form>
      <div className="p-2">
        <Preview file={support.file} link={support.link} />
      </div>
    </>
  );
};

export default SupportForm;
