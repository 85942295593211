import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import FilterSelect from 'components/Common/FilterSelect';
import { useDispatch } from 'react-redux';
import DateRange from 'components/Common/DateRange';
import { useFormatMessage } from 'hooks';
import { fetchActivations } from 'state/actions/activations';
import './Dash.css';
import { getOptions, getDatesBorder, isDateBetween } from './util';

import { columns, fakeData } from './constants';

const DataTable = (data, loading) => {
  console.log('data :', data, 'loading :', loading);
  const [tableData, setTableData] = useState(fakeData);
  const [animation, setAnimation] = useState({ label: 'Animations' });
  const [marque, setMarque] = useState({ label: 'Marques' });
  const [agence, setAgence] = useState({ label: 'Agences' });
  const [zone, setZone] = useState({ label: 'Zone' });
  const [secteur, setSecteur] = useState({ label: 'Secteur' });
  const [region, setRegion] = useState({ label: 'Regions' });
  const selectField = (field) => getOptions(tableData, field);
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(getDatesBorder(selectField('date')).first),
    periodEnd: new Date(getDatesBorder(selectField('date')).last),
  });

  const [filters, setFilters] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(loading);
    const newFilters = filters.filter((item) => item.id !== 'date');
    const date = new Date(dateSearch.periodStart);
    dispatch(fetchActivations(null, null, date));

    setFilters([
      ...newFilters,
      {
        id: 'date',
        value: {
          start: dateSearch.periodStart,
          end: dateSearch.periodEnd,
        },
      },
    ]);
    updateData([
      ...newFilters,
      {
        id: 'date',
        value: {
          start: dateSearch.periodStart,
          end: dateSearch.periodEnd,
        },
      },
    ]);
  }, [dateSearch]);

  const updateData = (myFilters) => {
    let newData = fakeData;
    myFilters.forEach((filterItem) => {
      if (filterItem.id !== 'date') {
        newData = newData.filter(
          (item) => item[filterItem.id] === filterItem.value
        );
      } else {
        newData = newData.filter((item) =>
          isDateBetween(
            item[filterItem.id],
            dateSearch.periodStart,
            dateSearch.periodEnd
          )
        );
      }
    });
    setTableData(newData);
  };

  const selectFieldsProps = (field, label, options, setField, value) => {
    return {
      options: [{ label }, ...options],
      getOptionLabel: (op) => op.label,
      getOptionValue: (op) => op.label,
      onChange: (op) => {
        setField({ label: op.label });
        if (op.label === label) {
          setFilters(filters.filter((item) => item.id !== field));
          updateData(filters.filter((item) => item.id !== field));
          return;
        }
        const newFilters = filters.filter((item) => item.id !== field);
        setFilters([...newFilters, { id: field, value: op.label }]);
        updateData([...newFilters, { id: field, value: op.label }]);
      },
      value,
      placeholder: label,
    };
  };

  return (
    <>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-1.4/12 m-1">
          <FilterSelect
            selectProps={selectFieldsProps(
              'animation',
              'toutes les animations',
              selectField('animation'),
              setAnimation,
              animation
            )}
          />
        </div>
        <div className="w-1.4/12 m-1">
          <FilterSelect
            style={{ width: '200px' }}
            selectProps={selectFieldsProps(
              'marque',
              'toutes les marques',
              selectField('marque'),
              setMarque,
              marque
            )}
          />
        </div>
        <div className="w-1.4/12 m-1">
          <FilterSelect
            selectProps={selectFieldsProps(
              'agence',
              'toutes les agences',
              selectField('agence'),
              setAgence,
              agence
            )}
          />
        </div>
        <div className="w-1.4/12 m-1">
          <FilterSelect
            style={{ width: '200px' }}
            selectProps={selectFieldsProps(
              'region',
              'toutes les region',
              selectField('region'),
              setRegion,
              region
            )}
          />
        </div>
        <div className="w-1.4/12 m-1">
          <FilterSelect
            style={{ width: '200px' }}
            selectProps={selectFieldsProps(
              'zone',
              'toutes les zone',
              selectField('zone'),
              setZone,
              zone
            )}
          />
        </div>
        <div className="w-1.4/12 m-1">
          <FilterSelect
            style={{ width: '200px' }}
            selectProps={selectFieldsProps(
              'secteur',
              'toutes les sectreurs',
              selectField('secteur'),
              setSecteur,
              secteur
            )}
          />
        </div>
        <div className="w-1.4/12 m-1">
          <DateRange
            className="w-full"
            fromText={useFormatMessage('Activation.informationsTab.from')}
            fromProps={{
              name: 'periodStart',
              setState: setDateSearch,
              date: new Date(dateSearch.periodStart),
            }}
            toText={useFormatMessage('Activation.informationsTab.to')}
            toProps={{
              name: 'periodEnd',
              setState: setDateSearch,
              date: new Date(dateSearch.periodEnd),
            }}
          />
        </div>

        <div className="w-full overflow-hidden">
          <Table columns={columns} data={data.data} loading={false} />
        </div>
      </div>
    </>
  );
};
export default DataTable;
