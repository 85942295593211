import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
import firebase from 'firebase.js';

/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';

export const REFERENCES_FETCH_DATA_INIT = createAction(
  'REFERENCES_FETCH_DATA_INIT'
);
export const REFERENCES_FETCH_DATA_SUCCESS = createAction(
  'REFERENCES_FETCH_DATA_SUCCESS'
);
export const REFERENCES_FETCH_DATA_FAIL = createAction(
  'REFERENCES_FETCH_DATA_FAIL'
);
export const REFERENCES_CREATE_REFERENCE_INIT = createAction(
  'REFERENCES_CREATE_REFERENCE_INIT'
);
export const REFERENCES_CREATE_REFERENCE_SUCCESS = createAction(
  'REFERENCES_CREATE_REFERENCE_SUCCESS'
);
export const REFERENCES_CREATE_REFERENCE_FAIL = createAction(
  'REFERENCES_CREATE_REFERENCE_FAIL'
);
export const REFERENCES_CLEAR_DATA = createAction('REFERENCES_CLEAR_DATA');
export const REFERENCES_DELETE_REFERENCE_INIT = createAction(
  'REFERENCES_DELETE_REFERENCES_INIT'
);
export const REFERENCES_DELETE_REFERENCE_SUCCESS = createAction(
  'REFERENCES_DELETE_REFERENCE_SUCCESS'
);
export const REFERENCES_DELETE_REFERENCE_FAIL = createAction(
  'GAMESS_DELETE_REFERENCES_FAIL'
);
export const REFERENCES_MODIFY_REFERENCE_INIT = createAction(
  'REFERENCES_MODIFY_REFERENCE_INIT'
);
export const REFERENCES_MODIFY_REFERENCE_SUCCESS = createAction(
  'REFERENCES_MODIFY_REFERENCE_SUCCESS'
);
export const REFERENCES_MODIFY_REFERENCE_FAIL = createAction(
  'REFERENCES_MODIFY_REFERENCE_FAIL'
);
export const REFERENCES_CLEAN_UP = createAction('REFERENCES_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const c = new Controller('references', 'reference', 'displayName');
const cUpload = new ControllerWithFileUpload(
  'references',
  'reference',
  'displayName'
);

const updateBrandWithReference = async (brand, operation) => {
  if (typeof brand === 'string') {
    const brandRef = await firebase.firestore().collection('marks').doc(brand);
    const brandSnap = await brandRef.get();
    let refNumber;

    let brandData;
    if (brandSnap) {
      brandData = brandSnap.data();
    }
    if (operation !== 'add') {
      refNumber =
        brandData.referencesNumber === 0 ? 0 : brandData.referencesNumber - 1;
    }
    if (brandData) {
      brandRef.update({
        ...brandData,
        referencesNumber:
          operation === 'add' ? brandData.referencesNumber + 1 : refNumber,
      });
    }
  }
  if (typeof brand === 'object') {
    let brandData;
    const brandRefs = await firebase
      .firestore()
      .collection('marks')
      .where('displayName', '==', brand.displayName);
    let refNumber;

    const brandSnap = await brandRefs.get();

    if (brandSnap) {
      brandData = brandSnap.docs[0].data();
    }
    if (operation !== 'add') {
      refNumber =
        brandData.referencesNumber === 0 ? 0 : brandData.referencesNumber - 1;
    }
    if (brandData) {
      const currentBrandRef = await firebase
        .firestore()
        .collection('marks')
        .doc(brandSnap.docs[0].id);
      currentBrandRef.update({
        ...brandData,
        brand: brand.id,
        referencesNumber:
          operation === 'add' ? brandData.referencesNumber + 1 : refNumber,
      });
    }
  }
};

const isBrandChanged = (brandOne, brandTwo) => {
  if (typeof brandOne === 'string' && typeof brandTwo === 'string')
    return brandOne !== brandTwo;
  if (typeof brandOne === 'object' && typeof brandTwo === 'object') {
    if (brandOne.id && brandTwo.id) return brandOne.id !== brandTwo.id;
    return brandOne.displayName !== brandTwo.displayName;
  }
  if (typeof brandOne === 'object') return brandOne.id !== brandTwo;
  if (typeof brandTwo === 'object') return brandTwo.id !== brandOne;
};

/**
 * Action fetch references
 * @return dispatch
 */
export const fetchReferences = () => {
  return async (dispatch) => {
    dispatch(REFERENCES_FETCH_DATA_INIT());
    let references;
    try {
      references = await c.fetch();
    } catch (error) {
      toastr.error('Références', 'Operation a échoué');
      return dispatch(REFERENCES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(REFERENCES_FETCH_DATA_SUCCESS({ references }));
  };
};

/**
 * Action Create new reference
 * @param {string} name Name of the reference
 * @param {file} image Image of the reference
 * @param {file} arg Arguments of the reference
 * @param {string} brand Id of the reference brand
 * @param {babel} active State of the reference
 * @return dispatch
 */

export const createReference = ({
  name,
  image,
  arg,
  brand,
  active,
  isMultiMark,
  source,
}) => {
  return async (dispatch, getState) => {
    dispatch(REFERENCES_CREATE_REFERENCE_INIT());
    const { locale } = getState().preferences;
    let referenceRef;
    let reference;
    try {
      const displayName = displayText(name);
      referenceRef = await firebase.firestore().collection('references').add({
        name,
        displayName,
        isMultiMark,
        source,
        createdAt: new Date().toGMTString(),
        updatedAt: new Date().toGMTString(),
      });
      await cUpload.uploadFile(referenceRef.id, image);
      if (arg) await cUpload.uploadFile(referenceRef.id, arg);

      const imageUrl = cUpload.getFileUrl(referenceRef.id, image);
      let argUrl = '';
      if (arg) argUrl = cUpload.getFileUrl(referenceRef.id, arg);

      reference = await referenceRef
        .update({
          name,
          displayName,
          brand,
          active,
          source,
          imageUrl,
          argUrl,
        })
        .then(() => referenceRef.get().then((snap) => snap.data()));
      await updateBrandWithReference(brand, 'add');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Référence', 'la création a échoué');
      return dispatch(
        REFERENCES_CREATE_REFERENCE_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Référence', 'créé avec succès');
    return dispatch(REFERENCES_CREATE_REFERENCE_SUCCESS({ reference }));
  };
};

/**
 * Action Delete a reference
 * @param {string} id ID of the reference
 * @param {string} imageUrl Image url of the reference
 * @param {string} argUrl  Arguments url of the reference
 * @param {string || object} brand  Reference brand
 * @return dispatch
 */
export const deleteReference = ({ id, imageUrl, argUrl, brand }) => {
  return async (dispatch, getState) => {
    dispatch(REFERENCES_DELETE_REFERENCE_INIT());
    const { locale } = getState().preferences;
    try {
      await c.destroy(id);
      await cUpload.deleteFile(imageUrl);
      await cUpload.deleteFile(argUrl);
      await updateBrandWithReference(brand, 'delete');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Référence', 'la suppression a échoué');
      return dispatch(
        REFERENCES_DELETE_REFERENCE_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Référence', 'Supprimé avec succès');
    return dispatch(REFERENCES_DELETE_REFERENCE_SUCCESS({ id }));
  };
};

/**
 * Action Update new reference
 * @param {string} name Name of the reference
 * @param {file} image Image of the reference
 * @param {file} arg Arguments of the reference
 * @param {string} imageUrl Image url of the reference
 * @param {string} argUrl  Arguments url of the reference
 * @param {string} brand Id of the reference brand
 * @param {babel} active State of the reference
 * @return dispatch
 */

export const modifyReference = ({
  id,
  name,
  image,
  arg,
  brand,
  active,
  isMultiMark,
  imageUrl,
  argUrl,
  source,
}) => {
  return async (dispatch, getState) => {
    dispatch(REFERENCES_MODIFY_REFERENCE_INIT());
    const { locale } = getState().preferences;
    let reference;

    const displayName = displayText(name);

    try {
      if (!(await cUpload.exists({ displayName }))) {
        let oldImage = imageUrl;
        let oldArg = argUrl;
        const referenceRef = await firebase
          .firestore()
          .collection('references')
          .doc(id);
        const oldReference = await referenceRef.get();

        if (image) {
          await cUpload.deleteFile(imageUrl);
          await cUpload.uploadFile(id, image);
          oldImage = cUpload.getFileUrl(id, image);
        }
        if (arg) {
          await cUpload.deleteFile(argUrl);
          await cUpload.uploadFile(id, arg);
          oldArg = cUpload.getFileUrl(id, arg);
        }

        reference = await referenceRef
          .update({
            name,
            displayName,
            imageUrl: oldImage,
            argUrl: oldArg,
            active,
            brand: brand.id,
            isMultiMark,
            source,
            updatedAt: new Date().toGMTString(),
          })
          .then(() =>
            referenceRef.get().then((snap) => ({ id: snap.id, ...snap.data() }))
          );

        if (isBrandChanged(brand, oldReference.data().brand)) {
          await updateBrandWithReference(brand, 'add');
          await updateBrandWithReference(oldReference.data().brand, 'delete');
        }
      } else {
        toastr.error('Référence', 'Cette article existe déjà!');
        return dispatch(
          REFERENCES_MODIFY_REFERENCE_INIT({
            error: `This reference is already exists!`,
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Référence', 'mise à jour a échoué');
      return dispatch(
        REFERENCES_MODIFY_REFERENCE_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Référence', 'mis à jour avec succès');
    return dispatch(REFERENCES_MODIFY_REFERENCE_SUCCESS({ reference }));
  };
};

export const referencesCleanUp = () => (dispatch) =>
  dispatch(REFERENCES_CLEAN_UP());

export const clearReferecesData = () => {
  return (dispatch) => {
    dispatch(REFERENCES_CLEAR_DATA());
  };
};
