import React, { useState } from 'react';

import Table from 'components/Table';

import { CSVLink } from 'react-csv';

import DateRange from 'components/Common/DateRange';
import { useFormatMessage, useFormatDate } from 'hooks';
import Button from 'components/Common/Button';

const ReportingPerformancesAnimatrice = () => {
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(),
    periodEnd: new Date(),
  });

  const data = [
    {
      agence: 'BLS',
      idAnimatrice: 'iJCW9B',
      tempConnexionParjour: '3467',
      horaireDemarragemoyen: 'November 1, 2021 at 12:00:00 AM',
      horaireDernierContact: 'November 1, 2021 at 18:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: 'abagency',
      idAnimatrice: 'QHPKG',
      tempConnexionParjour: '3647',
      horaireDemarragemoyen: 'October 25, 2021 at 12:00:00 AM',
      horaireDernierContact: 'October 25, 2021 at 18:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: 'abagency',
      idAnimatrice: 'T7DN6',
      tempConnexionParjour: '8723',
      horaireDemarragemoyen: 'Juillet 23, 2021 at 12:00:00 AM',
      horaireDernierContact: 'Juillet 23, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: 'BLS',
      idAnimatrice: 'QHPRG',
      tempConnexionParjour: '6753',
      horaireDemarragemoyen: 'Juillet 1, 2021 at 12:00:00 AM',
      horaireDernierContact: 'Juillet 1, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: 'DEMO agency',
      idAnimatrice: '7M0M1',
      tempConnexionParjour: '7546',
      horaireDemarragemoyen: 'November 1, 2021 at 12:00:00 AM',
      horaireDernierContact: 'November 1, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: '121 agency',
      idAnimatrice: 'HTDZA',
      tempConnexionParjour: '6467',
      horaireDemarragemoyen: 'November 10, 2021 at 12:00:00 AM',
      horaireDernierContact: 'November 10, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: 'LK',
      idAnimatrice: '95ME1',
      tempConnexionParjour: '7678',
      horaireDemarragemoyen: 'November 13, 2021 at 12:00:00 AM',
      horaireDernierContact: 'November 13, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: '121 agency',
      idAnimatrice: 'iLCP9M',
      tempConnexionParjour: '5645',
      horaireDemarragemoyen: 'October 13, 2021 at 12:00:00 AM',
      horaireDernierContact: 'October 13, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: '121-AG',
      idAnimatrice: 'PF7GX',
      tempConnexionParjour: '3467',
      horaireDemarragemoyen: 'October 10, 2021 at 12:00:00 AM',
      horaireDernierContact: 'October 10, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: 'LK',
      idAnimatrice: 'S7YUS',
      tempConnexionParjour: '8654',
      horaireDemarragemoyen: 'Septembre 1, 2021 at 12:00:00 AM',
      horaireDernierContact: 'Septembre 1, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: '121 agency',
      idAnimatrice: 'MZU4H',
      tempConnexionParjour: '5654',
      horaireDemarragemoyen: 'Septembre 13, 2021 at 12:00:00 AM',
      horaireDernierContact: 'Septembre 13, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
    {
      agence: 'agence name',
      idAnimatrice: 'id animatrice',
      tempConnexionParjour: 'tempConnexionParjour',
      horaireDemarragemoyen: 'November 1, 2021 at 12:00:00 AM',
      horaireDernierContact: 'November 1, 2021 at 12:00:00 AM',
      nbrContactMoyenParJour: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
      nbrVenteReussiMoyen: Math.floor(Math.random() * 30),
    },
  ];

  const columns = [
    {
      Header: 'AGENCE',
      accessor: 'agence',
    },
    {
      Header: 'ID ANIMATRICE',
      accessor: 'idAnimatrice',
    },
    {
      Header: 'Temps de connexion moyen/jour',
      accessor: 'tempConnexionParjour',
    },
    {
      Header: 'Horaire démarrage moyen',
      accessor: 'horaireDemarragemoyen',
    },
    {
      Header: 'Horaire dernier contact',
      accessor: 'horaireDernierContact',
    },

    {
      Header: 'NBR CONTACT MOYEN/JOUR',
      accessor: 'nbrContactMoyenParJour',
    },
    {
      Header: 'NBR CONTACT REUSSI MOYEN/JOUR ',
      accessor: 'nbrContactReussiMoyen',
    },
    {
      Header: 'NBR vente MOYEN/JOUR/reference marque ',
      accessor: 'nbrVenteReussiMoyen',
    },
  ];

  return (
    <div className="page-padding" style={{ overflow: 'auto' }}>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="text-brand-darkBlue text-3xl font-semibold">
                  Performances par Equipe
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="flex">
          <DateRange
            label={useFormatMessage('ActivationsDetails.card.period')}
            isForm
            fromText={useFormatMessage('Activation.informationsTab.from')}
            fromProps={{
              name: 'periodStart',
              setState: setDateSearch,
              date: new Date(dateSearch.periodStart),
            }}
            toText={useFormatMessage('Activation.informationsTab.to')}
            toProps={{
              name: 'periodEnd',
              setState: setDateSearch,
              date: new Date(dateSearch.periodEnd),
            }}
          />
          <Button
            style={{ height: 'max-content', marginTop: '38px' }}
            size="small"
            color="blue"
            text="Afficher Reporting"
            // onClick={searchReporting}
          />
          <CSVLink
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              background: '#3273dc',
              color: '#fff',
              borderRadius: 9999,
              display: 'inline-block',
              padding: 3,
              backgroundColor: '#2699fb',
              marginLeft: 50,
              height: 'max-content',
              marginTop: '38px',
            }}
            data={data}
          >
            Télécharger Reporting
          </CSVLink>
        </div>
      </section>
      <div className="flex">
        {/* left */}
        <div className="flex flex-col p-3">
          <div className="w-full reporting-table ">
            <Table
              // style={{ width: '1200px' }}
              tailwind="w-64 bg-black"
              columns={columns}
              data={data || []}
              loading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingPerformancesAnimatrice;
