import React from 'react';
import Table from 'components/Table';
import NormalInput from 'components/Common/NormalInput';
import { useSecondToHours } from 'hooks';
//
const moment = require('moment');

const PositionsDetails = ({ activation, loading }) => {
  const columns = [
    {
      Header: 'Position',
      accessor: 'Position',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {(row.original.position || {}).name}
        </div>
      ),
    },
    {
      Header: 'Region',
      accessor: 'Rrgion',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {(row.original.position || {}).region}
        </div>
      ),
    },
    {
      Header: 'Zone',
      Cell: ({ row }) => (row.original.position || {}).zone || '-----',
    },
    {
      Header: 'Secteur',
      Cell: ({ row }) => (row.original.position || {}).sector || '-----',
    },
    {
      Header: 'Adresse',
      Cell: ({ row }) => (row.original.position || {}).address || '-----',
    },
    {
      Header: 'Horaire démarrage',
      accessor: 'Horaire_démarrage',
      Cell: ({ row }) => row.original.startHour || '00:00',
    },
    {
      Header: 'nombre heure travaillés',
      accessor: 'nombre_heure_travaillés',
      Cell: ({ row }) => (
        <div>
          {moment('2015-01-01')
            .startOf('day')
            .seconds(row.original.workTime || 0)
            .format('H:mm:ss') || '00 min'}
        </div>
      ),
    },
    {
      Header: 'Nombre contact',
      accessor: 'Nombre_contact',
      Cell: ({ row }) => row.original.totalContacts || 0,
    },
    {
      Header: 'contacts réussis',
      accessor: 'contacts_réussis',
      Cell: ({ row }) => row.original.totalSuccessContacts || 0,
    },
  ];
  return (
    <div className="p-4 border">
      {/* table  */}
      <div className="pb-6">
        {/* title */}
        <div className="pb-2 flex justify-between">
          <h1 className="text-2xl text-brand-darkBlue font-semibold">
            Detail par position à la date indiqué
          </h1>
          <NormalInput placeholder="Date" isFilter icon="mdi-calendar" />
        </div>
        <div className="">
          <Table
            loading={loading}
            data={
              (activation.positions || []).sort(function (a, b) {
                return a.date.toDate() - b.date.toDate();
              }) || []
            }
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};
//
export default PositionsDetails;
