import React, { useState, useEffect } from 'react';
import { createCriteria, modifyCriteria } from 'state/actions/criterias';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import PageHeader from 'components/PageHeader';
import CriteriaForm from 'components/CriteriaForm';
import firebase from 'firebase.js';
import paths from 'pages/Router/paths';

const Criteria = () => {
  const { id } = useParams();
  const [criteria, setCriteria] = useState({
    name: '',
    brands: [],
    ageRanges: [],
    purchases: [],
    buyCount: [],
    smoker: false,
  });
  const { success } = useSelector(
    (state) => ({
      success: state.criterias.success,
    }),
    shallowEqual
  );

  useEffect(() => {
    const fetchCriteriasData = async () => {
      const response = await firebase
        .firestore()
        .collection('criterias')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchCriteriasData()
        .then(async (criteriaData) => {
          const brandsPromises = criteriaData
            .data()
            .brands.map(async (brand) => {
              const brandRef = await firebase
                .firestore()
                .collection('marks')
                .doc(brand)
                .get();
              return {
                label: brandRef.data().name,
                value: brandRef.id,
              };
            });
          const brands = await Promise.all(brandsPromises);
          setCriteria({
            ...criteriaData.data(),
            brands,
            id,
          });
        })
        .catch(() => {
          setCriteria({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const criteriaForm =
    !criteria.name && id ? (
      <ClipLoader />
    ) : (
      <CriteriaForm
        isEditing={isEditing}
        criteriaData={criteria}
        action={isEditing ? modifyCriteria : createCriteria}
      />
    );

  const redirect = (criteria.error || success) && (
    <Redirect to={paths.CRITERIAS} />
  );

  return (
    <>
      {redirect}
      <PageHeader hideButton title="Critère" />
      <div className="page-padding">{criteriaForm}</div>
    </>
  );
};

export default Criteria;
