import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import transformArrayToObject from 'utils/transformArrayToObject';

import {
  fetchLevels,
  deleteLevel,
  clearLevelsData,
} from 'state/actions/levels';
import { fetchCriterias } from 'state/actions/criterias';

import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const Levels = () => {
  const { LevelsList, loading, deleted, criteriasList } = useSelector(
    (state) => ({
      success: state.levels.success,
      LevelsList: state.levels.data,
      isAdmin: state.auth.userData.isAdmin,
      loading: state.levels.loading,
      deleted: state.levels.deleted,
      criteriasList: state.criterias.data,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    levelId: null,
    isOpen: false,
  });
  const dispatch = useDispatch();

  const [criteriasIndex, setCriteriasIndex] = useState(null);

  useEffect(() => {
    setCriteriasIndex(transformArrayToObject(criteriasList));
  }, [criteriasList]);

  const getCriteriaName = (id) => {
    try {
      return criteriasIndex[`${id}`].name;
    } catch (e) {
      return 'id invalide';
    }
  };

  useEffect(() => {
    dispatch(fetchLevels());
    dispatch(fetchCriterias());
    return () => dispatch(clearLevelsData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        levelId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearLevelsData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (levelId) => {
    setDeleteModal((prevState) => ({
      levelId,
      isOpen: !prevState.isOpen,
    }));
  };
  const onCloseModalHandler = () => {
    setDeleteModal({ levelId: null, isOpen: false });
  };
  const onDeleteLevelHandler = () => {
    dispatch(deleteLevel(deleteModal.levelId));
  };

  const deleteMessage = useFormatMessage('Levels.delete');
  const confirmMessage = useFormatMessage('Levels.confirm');
  const permDeleteMessage = useFormatMessage('Levels.permDelete');
  const cancelMessage = useFormatMessage('Levels.cancel');
  const statusActive = useFormatMessage('Levels.table.status.activated');
  const statusDisabled = useFormatMessage('Levels.table.status.disabled');
  const columns = [
    {
      Header: useFormatMessage('Levels.table.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Levels.table.description'),
      accessor: 'desc',
    },
    {
      Header: useFormatMessage('Levels.table.criterias'),
      accessor: 'criterias',
      Cell({
        row: {
          original: { criterias },
        },
      }) {
        if (criterias && criteriasIndex) {
          return (
            <div className="flex flex-wrap justify-center">
              {criterias.map((criteriaId) => (
                <span className="green-pill" key={criteriaId}>
                  {getCriteriaName(criteriaId)}
                </span>
              ))}
            </div>
          );
        } else {
          return <span>N/A</span>;
        }
      },
    },
    {
      Header: useFormatMessage('Levels.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Levels.table.status'),
      accessor: 'disabled',
      Cell({
        row: {
          original: { disabled },
        },
      }) {
        if (disabled) {
          return <span className="text-brand-red">{statusDisabled}</span>;
        } else {
          return <span className="text-brand-green">{statusActive}</span>;
        }
      },
    },
    {
      Header: useFormatMessage('Levels.table.action'),
      accessor: 'Actions',
      Cell({ row }) {
        // Button accepts a prop named "to" for  navigating to another route
        return (
          <div className="flex items-center justify-center">
            <Button
              to={`/levels/${row.original.id}`}
              isIconOnly
              color="blue"
              icon="mdi-pencil"
              text={useFormatMessage('Levels.table.action.edit')}
              className="mx-1"
            />
            <Button
              isIconOnly
              color="red"
              icon="mdi-delete"
              text={useFormatMessage('Levels.table.action.delete')}
              className="mx-1"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            />
          </div>
        );
      },
    },
  ];

  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(LevelsList);

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteLevelHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader
        title={useFormatMessage('Levels.title')}
        to={paths.ADD_LEVEL}
      />
      <div className="page-padding">
        {/* filter  */}
        <div className="flex py-2">
          <FilterSearch
            loading={loading}
            hideSelect
            selectProps={selectProps}
            searchProps={searchProps}
          />
        </div>
        <Table data={result} columns={columns} loading={loading} />
      </div>
    </>
  );
};

export default Levels;
