import React from 'react';

export const columns = [
  {
    Header: 'Animations',
    accessor: 'animation',
  },
  {
    Header: 'Marques',
    accessor: 'marque',
  },
  {
    Header: 'Agences',
    accessor: 'agence',
  },
  {
    Header: 'Regions',
    accessor: 'region',
  },
  {
    Header: 'Zone',
    accessor: 'zone',
  },
  {
    Header: 'Secteur',
    accessor: 'secteur',
  },
  {
    Header: 'Date de création',
    accessor: 'date',
    sortType: '',
    Cell: ({ row }) => (
      <div className="text-brand-darkBlue"> {row.original.date} </div>
    ),
  },
];
const animation = ['POS', 'Horeca', 'Moussem', 'Souk', 'Event'];
const secteurs = ['Rabat Temara', 'Tanger Détroit', 'Oulfa', 'Zaers', 'Agdal'];
const regions = ['Region Nord', 'Region Sud', 'Region West', 'Region Ouest'];
const dates = [
  'Wed, 10 Feb 2021 11:27:50 GMT',
  'Tue, 09 Mar 2021 13:48:39 GMT',
  'Fri, 01 Jan 2021 13:48:39 GMT',
  'Fri, 12 Mar 2021 13:48:39 GMT',
  'Fri, 12 Mar 2021 13:48:39 GMT',
];
const marques = ['Gob', 'Davidoff', 'Chesterfield', 'MonteCarlo', 'MQS'];
const agences = ['121 agency', 'BLS', 'LK', 'DEMO agency', 'abagency'];
const zones = [
  'Zone Sbata',
  'Zone Rabat Salé',
  'Zone Agadir Sahara',
  'Zone Mazagan',
  'Zone Khouribga Beni Mellal',
];

export const fakeData = new Array(5775).fill({}).map((val, index) => {
  return {
    animation: animation[Math.floor(Math.random() * 5)],
    marque: marques[Math.floor(Math.random() * 5)],
    agence: agences[Math.floor(Math.random() * 5)],
    // region: regions[Math.floor(Math.random() * 4)],
    // zone: zones[Math.floor(Math.random() * 5)],
    // secteur: secteurs[Math.floor(Math.random() * 5)],
    // date: dates[Math.floor(Math.random() * 4)],
  };
});

export const filtersId = {
  marque: 'marque',
  animation: 'animation',
};
