import React, { useState, useEffect } from 'react';
import { createMultimark, modifyMultimark } from 'state/actions/multimarks';
import MultimarkForm from 'components/multimarkForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

import { useFormatMessage } from 'hooks';

const Multimark = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.multimarks.success,
    }),
    shallowEqual
  );
  const [multimark, setMultimark] = useState({
    isActive: false,
    pdfUrl: '',
  });

  useEffect(() => {
    const fetchMultimarkData = async () => {
      const response = await firebase
        .firestore()
        .collection('multimarks')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchMultimarkData()
        .then((multimarkData) => {
          setMultimark({
            ...multimarkData.data(),
            id,
          });
        })
        .catch(() => {
          setMultimark({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const multimarkForm =
    !multimark.pdfUrl && id ? (
      <ClipLoader />
    ) : (
      <MultimarkForm
        isEditing={isEditing}
        multimarkData={multimark}
        action={isEditing ? modifyMultimark : createMultimark}
      />
    );

  const redirect = (multimark.error || success) && (
    <Redirect to={paths.MULTIMARKS} />
  );

  return (
    <>
      {redirect}
      <PageHeader title={useFormatMessage('Multimark.title')} hideButton />
      <div className="page-padding">{multimarkForm}</div>
    </>
  );
};

export default Multimark;
