
const sortByDate =  (
    { original: { createdAt: createdAtA } },
    { original: { createdAt: createdAtB } }
) => {
    try {
        if (
            new Date(createdAtA).getTime() >= new Date(createdAtB).getTime()
        ) {
            return -1;
        } else {
            return 1;
        }
    } catch (e) {
        console.log("something went wrong when ordering by date", e)
        return 1;
    }
}

export default sortByDate