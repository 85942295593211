import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCan } from 'hooks';
import { Link } from 'react-router-dom';
import paths from 'pages/Router/paths';
import NavLink from '../Link';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { app } from 'firebase';

import { fetchAppName } from '../../../state/actions/appName';
import { path } from '@amcharts/amcharts4/core';

// begin routes objects list
const routes = [
  // home
  {
    path: paths.ROOT,
    name: 'Dashboard',
    icon: 'mdi-home',
    // permissions: ['view-dashboard'],
  },
  // users
  {
    path: paths.USERS,
    name: 'Utilisateurs',
    icon: 'mdi-account-supervisor',
    permissions: ['view-members'],
    children: [
      {
        path: paths.ROLES,
        name: 'Roles',
        icon: 'mdi-account-star',
        permissions: ['view-roles'],
      },
      {
        path: paths.PERMISSIONS,
        name: 'Permissions',
        icon: 'mdi-toggle-switch',
        permissions: ['view-permissions'],
      },
    ],
  },
  // activations
  {
    path: paths.ACTIVATIONS,
    name: 'Activations',
    icon: 'mdi-check-all',
    children: [
      {
        path: paths.ADD_ACTIVATION,
        name: 'Nouvelle',
        icon: 'mdi-plus-circle',
        permissions: ['create-activation'],
      },
      {
        path: paths.ACTIVATIONS_PARTICIPANTS,
        name: 'Participants',
        icon: 'mdi-share-variant',
        permissions: ['view-contacts'],
      },
      {
        path: paths.ACTIVATIONS_CATEGORIES,
        name: 'Catégories',
        icon: 'mdi-briefcase',
        permissions: ['view-categories'],
      },
      {
        path: paths.ACTIVATIONS_HISTORY,
        name: 'Historique',
        icon: 'mdi-history',
        permissions: ['view-history'],
      },
      {
        path: paths.ACTIVATIONS_VIDEOS,
        name: 'Vidèos',
        icon: 'mdi-video',
        permissions: ['view-videos'],
      },
    ],
  },
  // products
  {
    path: paths.PRODUCTS,
    name: 'Gratuité',
    icon: 'mdi-package-variant',
    permissions: ['view-products'],
    children: [
      {
        path: paths.ADD_PRODUCT,
        name: 'Nouvelle',
        icon: 'mdi-plus-circle',
      },
      {
        path: paths.SUPPORTS,
        name: 'Support',
        icon: 'mdi-help-circle',
      },
      {
        path: paths.PRODUCTS_CATEGORIES,
        name: 'Catégories',
        icon: 'mdi-briefcase',
      },
    ],
  },
  // teams
  {
    path: paths.TEAMS,
    name: 'Equipes',
    icon: 'mdi-account-group',
    permissions: ['view-teams'],
    children: [
      {
        path: paths.ADD_TEAM,
        name: 'Nouvelle',
        icon: 'mdi-plus-circle',
        permissions: ['create-team'],
      },
    ],
  },
  // positions
  {
    path: paths.POSITIONS,
    name: 'Positions',
    icon: 'mdi-map-marker',
    permissions: ['view-positions', 'view-agency-positions'],
    children: [
      {
        path: paths.ADD_POSITION,
        name: 'Nouvelle',
        icon: 'mdi-map-marker-plus',
        permissions: ['create-position'],
      },
      {
        path: paths.POSITIONS_CATEGORIES,
        name: 'Catégories',
        icon: 'mdi-briefcase',
        permissions: ['create-category'],
      },
    ],
  },
  // video
  {
    path: '/videos',
    name: 'video',
    icon: 'mdi-video ',
    permissions: ['view-positions', 'view-agency-positions'],
    children: [
      {
        path: paths.ADD_VIDEO,
        name: 'Nouvelle video',
        icon: 'mdi-plus',
        permissions: ['create-position'],
      },
    ],
  },
  // levels
  {
    path: paths.LEVELS,
    name: 'Niveaux',
    icon: 'mdi-signal',
    permissions: ['view-levels'],
    children: [
      {
        path: paths.ADD_LEVEL,
        name: 'Nouvelle',
        icon: 'mdi-plus-circle',
      },
      {
        path: paths.CRITERIAS,
        name: 'Critères',
        icon: 'mdi-card-text-outline',
      },
    ],
  },
  {
    path: paths.MARKS,
    name: 'Marques',
    icon: 'mdi-copyright',
    permissions: ['view-marks'],
    children: [
      {
        path: paths.REFERENCES,
        name: 'Références',
        icon: 'mdi-arrow-bottom-right',
        permissions: ['view-references'],
      },
      {
        path: paths.MULTIMARKS,
        name: 'Mutlimarque',
        icon: 'mdi-border-all',
        permissions: ['view-references'],
      },
      {
        path: paths.ALATIGES,
        name: 'A la tige',
        icon: 'mdi-border-none',
        permissions: ['view-references'],
      },
    ],
  },
  // Jeux
  {
    path: paths.GAMES,
    name: 'Jeux',
    icon: 'mdi-gamepad',
    permissions: ['view-games'],
  },

  // Agences
  {
    path: paths.AGENCIES,
    name: 'Agences',
    icon: 'mdi-home-group',
    permissions: ['view-games'],
  },
  // Gains
  {
    path: paths.AWARDS,
    name: 'Gains',
    icon: 'mdi-trophy',
    permissions: ['view-awards'],
  },
  // reporting
  {
    path: paths.REPORTING,
    name: 'Reporting',
    icon: 'mdi-chart-line',
    permissions: ['view-reporting'],
  },
  // parameters
  {
    path: paths.PARAMETERS,
    name: 'Paramétres',
    icon: 'mdi-settings',
    permissions: ['view-parametre'],
  },
];
// end routes objects list

//begin SubMenu Comp
export const SubMenu = ({ label, icon, to, children, permissions = [] }) => {
  const [active, setActive] = useState(false);
  const authorize = useCan(permissions);
  return (
    authorize && (
      <li className={classNames('tw-dropdown', { 'is-active': active })}>
        <div
          exact-active-class="is-active"
          className="has-icon has-dropdown-icon label-link-container"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <NavLink to={to} className="label-link">
            <span className="icon">
              <i className={`mdi ${icon || 'mdi-view-list'}`} />
            </span>
            <span className="menu-item-label">{label}</span>
          </NavLink>
          <div className="dropdown-icon" onClick={() => setActive(!active)}>
            <span className="icon">
              <i
                className={classNames(
                  'mdi',
                  { 'mdi-minus': active },
                  { 'mdi-plus': !active }
                )}
              />
            </span>
          </div>
        </div>
        <ul className="tw-dropdown-items">{children}</ul>
      </li>
    )
  );
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string,
};
// end SubMenu comp

//  begin ComplexLink Comp
const ComplexLink = ({ path, name, icon, permissions = [], onClick }) => {
  const authorize = useCan(permissions);
  return (
    authorize && (
      <li>
        <NavLink to={path} className="has-icon" onClick={onClick}>
          <span className="icon">
            <i className={classNames('mdi', icon)} />
          </span>
          <span className="menu-item-label">{name}</span>
        </NavLink>
      </li>
    )
  );
};

//  end ComplexLink Comp

// begin Aside comp
const Aside = ({ handleMobileToggle }) => {
  const { userPermissions, isAdmin, appNames } = useSelector(
    (state) => ({
      userPermissions: state.auth.userPermissions,
      isAdmin: state.auth.userData.isAdmin,
      appNames: state.appNames.data,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const linksList = routes.map(
    ({ path, name, icon, children, permissions }, i) => {
      // hide teams links on SMT / for admins
      if (isAdmin && path.includes('team')) {
        return null;
      }
      // construct a dropdown if route has children & at user is permitted to see at least one
      const isPermittedToSeeAtLeastOneChild = !isAdmin
        ? children &&
          children.some((route) => {
            return userPermissions.some(
              (perm) => perm == route.permissions && route.permissions[0]
            );
          })
        : true;
      //
      if (children && isPermittedToSeeAtLeastOneChild) {
        return (
          <SubMenu
            key={path}
            to={path}
            label={name}
            icon={icon}
            permissions={permissions}
          >
            {children.map(({ path, name, icon, permissions }, i) => (
              <ComplexLink
                key={path}
                path={path}
                name={name}
                icon={icon}
                permissions={permissions}
                onClick={handleMobileToggle}
              />
            ))}
          </SubMenu>
        );
      }
      // else if route has no children
      // construct a single link
      else {
        return (
          <ComplexLink
            key={path}
            path={path}
            name={name}
            icon={icon}
            permissions={permissions}
            onClick={handleMobileToggle}
          />
        );
      }
    }
  );

  // fetch app name
  useEffect(() => {
    dispatch(fetchAppName());
  }, [dispatch]);
  const appName = appNames[0] && appNames[0].name;
  return (
    <aside className="aside is-placed-left is-expanded">
      {/* logo  */}
      <Link to={paths.ROOT} className="aside-tools">
        <div className="aside-tools-label">
          <span>{appName}</span>
        </div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">{linksList}</ul>
      </div>
    </aside>
  );
};

Aside.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
};
// end Aside comp
export default Aside;
