import { createReducer } from 'redux-act';
import {
  BRANDS_FETCH_DATA_INIT,
  BRANDS_FETCH_DATA_SUCCESS,
  BRANDS_FETCH_DATA_FAIL,
  BRANDS_CREATE_INIT,
  BRANDS_CREATE_SUCCESS,
  BRANDS_CREATE_FAIL,
  BRANDS_DELETE_INIT,
  BRANDS_DELETE_SUCCESS,
  BRANDS_DELETE_FAIL,
  BRANDS_MODIFY_INIT,
  BRANDS_MODIFY_SUCCESS,
  BRANDS_MODIFY_FAIL,
  BRANDS_CLEAN_UP,
  BRANDS_CLEAR_DATA,
} from 'state/actions/brands';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const brandsReducer = createReducer(
  {
    [BRANDS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [BRANDS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.brands,
      loading: false,
      error: null,
    }),
    [BRANDS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [BRANDS_CREATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [BRANDS_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.brand, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [BRANDS_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [BRANDS_DELETE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [BRANDS_DELETE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [BRANDS_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [BRANDS_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [BRANDS_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.brand.id) {
            return {
              name: payload.brand.name,
              imageUrl: payload.brand.imageUrl,
              displayName: payload.brand.displayName,
              id: payload.id,
              createdAt: payload.brand.createdAt,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [BRANDS_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [BRANDS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [BRANDS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
