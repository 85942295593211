import React, { useState, useEffect } from 'react';
import { fetchMarks, deleteMark, clearMarkesData } from 'state/actions/marks';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import paths from 'pages/Router/paths';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const Marks = () => {
  const { marksList, error, loading, deleted } = useSelector(
    (state) => ({
      success: state.marks.success,
      marksList: state.marks.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.marks.error,
      loading: state.marks.loading,
      deleted: state.marks.deleted,
      pageCount: state.marks.pageCount,
    }),
    shallowEqual
  );

  const [search, setSearch] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    markId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMarks());
    return () => dispatch(clearMarkesData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        markId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearMarkesData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (markId) => {
    setDeleteModal((prevState) => ({
      markId,
      isOpen: !prevState.isOpen,
    }));
  };
  const onCloseModalHandler = () => {
    setDeleteModal({ markId: null, isOpen: false });
  };
  const onDeleteMarkHandler = () => {
    dispatch(deleteMark(deleteModal.markId));
  };

  const deleteMessage = useFormatMessage('Marks.delete');
  const confirmMessage = useFormatMessage('Marks.confirm');
  const permDeleteMessage = useFormatMessage('Marks.permDelete');
  const cancelMessage = useFormatMessage('Marks.cancel');

  const columns = [
    {
      Header: useFormatMessage('Marks.table.name'),
      accessor: 'name',
    },
    // newly added
    {
      Header: 'Nombre de références',
      accessor: '',
      Cell({ row }) {
        return (
          <div className="">
            {!row.original.referencesNumber ? 0 : row.original.referencesNumber}
          </div>
        );
      },
    },
    //
    {
      Header: useFormatMessage('Marks.table.prefered'),
      accessor: 'isFavorite',
      Cell({ row }) {
        if (row.original.isFavorite) {
          return (
            <span className="icon text-brand-green">
              <i className="mdi mdi-check"></i>
            </span>
          );
        } else {
          return (
            <span className="icon text-brand-red">
              <i className="mdi mdi-close"></i>
            </span>
          );
        }
      },
    },
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: useFormatMessage('Marks.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            to={`/marks/${row.original.id}`}
            isIconOnly
            text={useFormatMessage('Marks.table.action.edit')}
            icon="mdi-pencil"
            color="blue"
            size="small"
          />
          <Button
            isIconOnly
            className="mx-2"
            text={useFormatMessage('Marks.table.action.delete')}
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];

  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(marksList);

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteMarkHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title={useFormatMessage('Marks.title')} to={paths.ADD_MARK} />
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            loading={loading}
            hideSelect
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Marks;
