/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { Link } from 'react-router-dom';
import SelectField from 'components/Common/SelectField';
import { rolesCleanUp } from 'state/actions/roles';
import { fetchPermissions } from 'state/actions/permissions';
import paths from 'pages/Router/paths';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';

const RoleForm = ({ roleData, action }) => {
  const dispatch = useDispatch();

  const { loading, permissions, permissionsLoading } = useSelector(
    (state) => ({
      loading: state.roles.loading,
      permissions: state.permissions.data,
      permissionsLoading: state.permissions.loading,
    }),
    shallowEqual
  );
  const [role, setRole] = useState(roleData);

  const goBackMessage = useFormatMessage('UserForm.goBack');


  useEffect(() => {
    dispatch(fetchPermissions());

    return () => dispatch(rolesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setRole);

  const canSubmit = role.name && role.permissions.length;

  const onSubmitHandler = (event) => {
    event.preventDefault();

    dispatch(action(role));
  };
  const selectOptions = permissions.map((per) => ({
    value: per.id,
    label: per.displayName,
  }));

  const onChangeSelect = (opts) => {
    const perms = (opts || []).map((opt) => opt.value);
    setRole((per) => ({ ...per, permissions: perms || '' }));
  };

  const selectValue = (perms) => {
    if (!permissionsLoading)
      return perms.map((perId) => {
        const p = permissions.find((per) => per.id === perId);
        if (p) return { value: p.id, label: p.displayName };
      });
  };

  return (
    <form
      onSubmit={onSubmitHandler}
      className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
    >
      {/* normal field  */}
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        {/* name  */}
        <div className="lg:max-w-sm lg:w-1/2">
          <NormalInput
            label="Nom"
            type="text"
            name="name"
            value={role.name}
            onChange={onChangeHandler}
          />
        </div>
        {/* permissions  */}
        <div className="lg:max-w-sm lg:w-1/2">
          <SelectField
            label="Permissions"
            type="form"
            isLoading={permissionsLoading}
            isDisabled={permissionsLoading}
            placeholder="Selectioner"
            onChange={(value) => onChangeSelect(value)}
            isMulti
            options={selectOptions}
            value={selectValue(role.permissions)}
          />
        </div>
      </div>
      {/* actions  */}
      <div className="flex flex-wrap w-full max-w-md lg:w-1/3 lg:items-end lg:justify-end items-center justify-end">
           {/* go back */}
          <div className="">
            <Button
              className="m-3 pr-4"
              icon="mdi-arrow-left"
              to={paths.ROLES}
              text={goBackMessage}
            />
          </div>
         {/* end go back */}
        <div className="">
          {/* submit  */}
          <Button
            type="submit"
            className={`${loading && 'is-loading'}`}
            disabled={!canSubmit || loading}
            color="green"
            size="large"
            icon="mdi-plus-circle"
            text={useFormatMessage('UserForm.submit')}
          />
          {/* end submit  */}
        </div>
      </div>
      {/* end controls  */}
    </form>
  );
};

export default RoleForm;
