import React, { useState } from 'react';
import AgencyDetailsCard from 'components/Common/AgencyDetailsCard';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import StockModal from 'components/StockModal';
import { useSelector, shallowEqual } from 'react-redux';

const InformationsDetails = ({ activation, loading }) => {
  const { isAdmin } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual
  );

  const [stockModal, setStockModal] = useState({
    isOpen: false,
    product: null,
    activation: null,
  });

  const [productsList, setProductsList] = useState(
    (activation.agencyStock || {}).products || []
  );

  const onStockModalClose = () => {
    setStockModal({
      isOpen: false,
      product: null,
      activation: null,
    });
  };

  const onProductConsult = (productData) => {
    setStockModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
      product: productData,
      activation,
    }));
  };

  const updateProduct = (newProduct) => {
    const newProductsList = productsList.map((product) => {
      if (newProduct.id === product.id) {
        return newProduct;
      }
      return product;
    });
    setProductsList(newProductsList);
  };
  const columns = [
    {
      Header: 'Gratuité',
      accessor: 'Gratuité',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">{row.original.name}</div>
      ),
    },
    {
      Header: 'Stock initial (Par POS)',
      accessor: 'Stock_initial',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">{row.original.quantity}</div>
      ),
    },
    {
      Header: 'Stock initial total',
      accessor: 'Stock_restants',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {row.original.quantity * activation.agencyStock.initPositions}
        </div>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <Button
          // to={`/productdetails/${row.original.id}/${activation.id}`}
          isIconOnly
          className="mx-auto"
          text="Consulter"
          icon="mdi-chevron-right"
          color="blue"
          size="small"
          onClick={() => !isAdmin && onProductConsult(row.original)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="p-4 border">
        <div className="pb-6">
          <AgencyDetailsCard loading={loading} activation={activation} />
        </div>
        <StockModal
          onCancel={onStockModalClose}
          isActive={stockModal.isOpen}
          activation={stockModal.activation}
          product={stockModal.product}
          updateProduct={updateProduct}
        />
        {/* table  */}
        <div className="pb-6">
          {/* title */}
          <h1 className="text-2xl text-brand-darkBlue font-semibold pb-2">
            Etat du stock global
          </h1>
          <div className="">
            <Table
              loading={loading}
              data={(activation.agencyStock || {}).products || []}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationsDetails;
