import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

// end catalog import
import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
// permissions module pages
import Permissions from 'pages/Permissions';
import Permission from 'pages/Permission';
// end permissions module pages
// roles module pages
import Roles from 'pages/Roles';
import Role from 'pages/Role';
// end roles module pages
import Positions from 'pages/Positions';
import PositionsAll from 'pages/PositionsAll';
import Position from 'pages/Position';
import PositionDetails from 'pages/PositionDetails';
import PosCategories from 'pages/PosCategories';
import PosCategory from 'pages/PosCategory';
// start produits
import Products from 'pages/Products';
import Product from 'pages/Product';
import ProductsCategories from 'pages/ProductsCategories';
import ProductsCategory from 'pages/ProductsCategory';
// end produits
// start activations
import Activations from 'pages/Activations';
import Activation from 'pages/Activation';
import ActivationDetails from 'pages/ActivationDetails';
import ActivationAgencyDetails from 'pages/ActivationAgencyDetails';
import ActivationsParticipants from 'pages/ActivationsParticipants';
import ActivationsHistory from 'pages/ActivationsHistory';
import ActivationsCategories from 'pages/ActivationsCategories';
import ActivationsVideos from 'pages/ActivationsVideos';
import ActivationsCategory from 'pages/ActivationsCategory';
import ActivationPlanning from 'pages/ActivationPlanning';
import ActivationReporting from 'pages/ActivationReporting';
import ActivationGraphs from 'pages/ActivationGraphs';
import MemberDetails from 'pages/MemberDetails';
// end activations
// start games
import Games from 'pages/Games';
import Game from 'pages/Game';
// end games
// start awards
import Awards from 'pages/Awards';
import Award from 'pages/Award';
// end awards
// start marks
import Marks from 'pages/Marks';
import Mark from 'pages/Mark';
// end marks
// start References
import References from 'pages/References';
import Reference from 'pages/Reference';
// end References
// start contacts
import Contact from 'pages/Contact';
import Contacts from 'pages/Contacts';
// end contacts
// start teams
import Teams from 'pages/Teams';
import Team from 'pages/Team';
// end teams
// start levels
import Levels from 'pages/Levels';
import Level from 'pages/Level';
// end levels
// start parameters
import Parameters from 'pages/Parameters';
// Jeux
import Jeux from 'pages/Jeux';
// Reporting
import Reporting from 'pages/Reporting';
import CanceledPositions from 'pages/ReportingPosition';

import ReportingParticipants from 'pages/ReportingParticipants';
import ReportingAgences from 'pages/ReportingAgences';
import ReportingPerformancesPosition from 'pages/ReportingPerformances';
import ReportingPerformancesSecteur from 'pages/ReportingPerformances/PerformancesSecteur';
import ReportingPerformancesZone from 'pages/ReportingPerformances/PerformancesZone';
// agencies
import Agencies from 'pages/Agencies';
import Agency from 'pages/Agency';
// criteria
import Criterias from 'pages/Criterias';
import Criteria from 'pages/Criteria';
// supports
import Supports from 'pages/Supports';
import Support from 'pages/Support';

// product details
import ProductDetails from 'pages/ProductDetails';

// please dont remove this comment to generate auto module Imports
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Section from 'pages/Section';
import Submenu from 'pages/Submenu';
import ActivationsVideo from 'pages/ActivationVideo';

import Multimarks from 'pages/Multimarks';
import Multimark from 'pages/Multimark';

import Alatiges from 'pages/alatiges';
import Alatige from 'pages/alatige';
// videos
import Videos from 'pages/video';
import AddVideo from 'pages/videoAdd';

// end videos
import ReportingPerformancesEquipe from 'pages/ReportingPerformances/PerformancesEquipes';
import ReportingPerformancesAnimatrice from 'pages/ReportingPerformances/PerformancesAnimatrices';
import Detaildecalage from 'pages/ReportingAgences/DetailDecalage';
import PrivateRoute from './PrivateRoute';
import paths from './paths';

const routes = [
  // A la tige
  {
    path: paths.ADD_ALATIGE,
    component: Alatige,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_ALATIGE,
    component: Alatige,
    isPrivate: true,
  },
  {
    path: paths.ALATIGES,
    component: Alatiges,
    isPrivate: true,
  },
  // parameters
  {
    path: paths.PARAMETERS,
    component: Parameters,
    isPrivate: true,
  },
  // contacts
  {
    path: paths.ADD_CONTACT,
    component: Contact,
    isPrivate: true,
  },
  {
    path: paths.CONTACTS,
    component: Contacts,
    isPrivate: true,
  },
  // supports
  {
    path: paths.ADD_SUPPORT,
    component: Support,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_SUPPORT,
    component: Support,
    isPrivate: true,
  },
  {
    path: paths.SUPPORTS,
    component: Supports,
    isPrivate: true,
  },
  // games
  {
    path: paths.ADD_GAME,
    component: Game,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_GAME,
    component: Game,
    isPrivate: true,
  },
  {
    path: paths.GAMES,
    component: Games,
    isPrivate: true,
  },
  // multimarks
  {
    path: paths.ADD_MULTIMARK,
    component: Multimark,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_MULTIMARK,
    component: Multimark,
    isPrivate: true,
  },
  {
    path: paths.MULTIMARKS,
    component: Multimarks,
    isPrivate: true,
  },
  {
    path: paths.PRODUCT_DETAILS,
    component: ProductDetails,
    isPrivate: true,
  },
  // awards
  {
    path: paths.ADD_AWARD,
    component: Award,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_AWARD,
    component: Award,
    isPrivate: true,
  },
  {
    path: paths.AWARDS,
    component: Awards,
    isPrivate: true,
  },
  // marks
  {
    path: paths.ADD_MARK,
    component: Mark,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_MARK,
    component: Mark,
    isPrivate: true,
  },
  {
    path: paths.MARKS,
    component: Marks,
    isPrivate: true,
  },
  {
    path: paths.ADD_REFERENCE,
    component: Reference,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_REFERENCE,
    component: Reference,
    isPrivate: true,
  },
  {
    path: paths.REFERENCES,
    component: References,
    isPrivate: true,
  },
  // password & login
  {
    path: paths.LOGIN,
    component: Login,
    isPrivate: false,
  },
  {
    path: paths.RESET_PASSWORD,
    component: ResetPassword,
    isPrivate: false,
  },
  // users
  {
    path: paths.ADD_USER,
    component: User,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_USER,
    component: User,
    isPrivate: true,
  },
  {
    path: paths.USERS,
    component: Users,
    isPrivate: true,
  },
  // permissions
  {
    path: paths.ADD_PERMISSION,
    component: Permission,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_PERMISSION,
    component: Permission,
    isPrivate: true,
  },
  {
    path: paths.PERMISSIONS,
    component: Permissions,
    isPrivate: true,
  },
  // roles
  {
    path: paths.ADD_ROLE,
    component: Role,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_ROLE,
    component: Role,
    isPrivate: true,
  },
  {
    path: paths.ROLES,
    component: Roles,
    isPrivate: true,
  },
  // positions
  // ** categorie
  {
    path: paths.ADD_POSITIONS_CATEGORY,
    component: PosCategory,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_POSITIONS_CATEGORY,
    component: PosCategory,
    isPrivate: true,
  },
  {
    path: paths.POSITIONS_CATEGORIES,
    component: PosCategories,
    isPrivate: true,
  },
  // ** positions
  {
    path: paths.ADD_POSITION,
    component: Position,
    isPrivate: true,
  },
  {
    path: paths.POSITIONS_ALL,
    component: PositionsAll,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.POSITION_DETAILS,
    component: PositionDetails,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_POSITION,
    component: Position,
    isPrivate: true,
  },
  {
    path: paths.POSITIONS,
    component: Positions,
    isPrivate: true,
  },
  // products
  {
    path: paths.ADD_PRODUCTS_CATEGORY,
    component: ProductsCategory,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_PRODUCTS_CATEGORY,
    component: ProductsCategory,
    isPrivate: true,
  },
  {
    path: paths.PRODUCTS_CATEGORIES,
    component: ProductsCategories,
    isPrivate: true,
  },
  {
    path: paths.ADD_PRODUCT,
    component: Product,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_PRODUCT,
    component: Product,
    isPrivate: true,
  },
  {
    path: paths.PRODUCTS,
    component: Products,
    isPrivate: true,
  },
  // activations
  // activations videos
  {
    path: paths.ADD_ACTIVATIONS_VIDEO,
    component: ActivationsVideo,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_ACTIVATIONS_VIDEO,
    component: ActivationsVideo,
    isPrivate: true,
  },
  {
    path: paths.ACTIVATIONS_VIDEOS,
    component: ActivationsVideos,
    isPrivate: true,
  },
  // activations categories
  {
    path: paths.ADD_ACTIVATIONS_CATEGORY,
    component: ActivationsCategory,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_ACTIVATIONS_CATEGORY,
    component: ActivationsCategory,
    isPrivate: true,
  },
  {
    path: paths.ACTIVATIONS_CATEGORIES,
    component: ActivationsCategories,
    isPrivate: true,
  },
  // activations history
  {
    path: paths.ACTIVATIONS_HISTORY,
    component: ActivationsHistory,
    isPrivate: true,
  },
  // activations participants
  {
    path: paths.ACTIVATIONS_PARTICIPANTS,
    component: ActivationsParticipants,
    isPrivate: true,
  },
  // activation plan
  {
    path: paths.ADD_ACTIVATION_PLAN,
    component: ActivationPlanning,
    isPrivate: true,
  },
  {
    path: paths.ACTIVATIONS_REPORTING,
    component: ActivationReporting,
    isPrivate: true,
  },
  {
    path: paths.ACTIVATIONS_GRAPHS,
    component: ActivationGraphs,
    isPrivate: true,
  },
  {
    path: paths.MEMBER_DETAILS,
    component: MemberDetails,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_ACTIVATION_PLAN,
    component: ActivationPlanning,
    isPrivate: true,
  },
  // activations details
  {
    path: paths.ACTIVATION_DETAILS_AGENCY_DEATILS,
    component: ActivationAgencyDetails,
    isPrivate: true,
  },
  {
    path: paths.ACTIVATION_DETAILS,
    component: ActivationDetails,
    isPrivate: true,
  },
  // activation form
  {
    path: paths.MODIFY_ACTIVATION,
    component: Activation,
    isPrivate: true,
  },
  {
    path: paths.ADD_ACTIVATION,
    component: Activation,
    isPrivate: true,
  },
  // activations list
  {
    path: paths.ACTIVATIONS,
    component: Activations,
    isPrivate: true,
  },
  // teams
  {
    path: paths.ADD_TEAM,
    component: Team,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_TEAM,
    component: Team,
    isPrivate: true,
  },
  {
    path: paths.TEAMS,
    component: Teams,
    isPrivate: true,
  },
  // levels
  {
    path: paths.ADD_LEVEL,
    component: Level,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_LEVEL,
    component: Level,
    isPrivate: true,
  },
  {
    path: paths.LEVELS,
    component: Levels,
    isPrivate: true,
  },
  // Criteria
  {
    path: paths.ADD_CRITERIA,
    component: Criteria,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_CRITERIA,
    component: Criteria,
    isPrivate: true,
  },
  {
    path: paths.CRITERIAS,
    component: Criterias,
    isPrivate: true,
  },
  // Jeux
  {
    path: paths.JEUX,
    component: Jeux,
    isPrivate: true,
  },
  // Agencies
  {
    path: paths.ADD_AGENCY,
    component: Agency,
    isPrivate: true,
  },
  {
    path: paths.MODIFY_AGENCY,
    component: Agency,
    isPrivate: true,
  },
  {
    path: paths.AGENCIES,
    component: Agencies,
    isPrivate: true,
  },
  // videos
  {
    path: paths.VIDEOS,
    component: Videos,
    isPrivate: true,
    exact: true,
  },
  {
    path: paths.ADD_VIDEO,
    component: AddVideo,
    isPrivate: true,
    exact: true,
  },
  // Reporting
  {
    path: paths.REPORTING,
    component: Reporting,
    exact: true,
    isPrivate: true,
  },

  {
    path: paths.CANCELED_POSITIONS,
    component: CanceledPositions,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.PARTICIPANT_DATA,
    component: ReportingParticipants,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.STOCK_AGENCY,
    component: ReportingAgences,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.DETAIL_DECALGE,
    component: Detaildecalage,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.PERFORMANCE_POSITION,
    component: ReportingPerformancesPosition,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.PERFORMANCE_SECTEUR,
    component: ReportingPerformancesSecteur,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.PERFORMANCE_Equipes,
    component: ReportingPerformancesEquipe,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.PERFORMANCE_Animatrices,
    component: ReportingPerformancesAnimatrice,
    exact: true,
    isPrivate: true,
  },
  {
    path: paths.PERFORMANCE_ZONE,
    component: ReportingPerformancesZone,
    exact: true,
    isPrivate: true,
  },

  // profile
  {
    path: paths.PROFILE,
    component: Profile,
    isPrivate: true,
  },
  {
    path: paths.SECTION,
    component: Section,
    isPrivate: true,
  },
  {
    path: paths.SUBMENU_1,
    component: Submenu,
    isPrivate: true,
  },
  {
    path: paths.SUBMENU_2,
    component: Submenu,
    isPrivate: true,
  },
  {
    path: '/',
    component: Home,
    isPrivate: true,
  },
];

const RouterComponent = () => {
  const routesList = routes.map((route, i) => {
    if (route.isPrivate) {
      return <PrivateRoute key="key" {...route} />;
    }
    return <Route key="key" exact {...route} />;
  });

  return (
    <BrowserRouter>
      <Switch>
        {' '}
        {routesList} <Route component={NotFound} />{' '}
      </Switch>{' '}
    </BrowserRouter>
  );
};

export default RouterComponent;
