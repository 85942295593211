import React, { useState, useEffect } from 'react';
import { fetchContacts, clearContactsData } from 'state/actions/contacts';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Table from 'components/Table';
import { useParams } from 'react-router-dom';
// import Button from 'components/Common/Button';
// import paths from 'pages/Router/paths';
import FilterSearch from 'components/Common/FilterSearch';
// import PageHeader from 'components/PageHeader';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const Contacts = () => {
  const { actId } = useParams();

  const { contactsList, error, loading } = useSelector(
    (state) => ({
      success: state.contacts.success,
      contactsList: state.contacts.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.contacts.error,
      loading: state.contacts.loading,
      pageCount: state.contacts.pageCount,
    }),
    shallowEqual
  );

  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContacts(actId));
    return () => dispatch(clearContactsData());
  }, [dispatch]);

  const columns = [
    {
      Header: useFormatMessage('Contacts.table.name'),
      accessor: 'name',
    },
    // {
    //   Header: useFormatMessage('Contacts.table.email'),
    //   Cell: ({ row }) => row.original.email || '-----',
    // },
    // {
    //   Header: useFormatMessage('Contacts.table.phone'),
    //   Cell: ({ row }) => row.original.phone || '-----',
    // },
    // {
    //   Header: useFormatMessage('Contacts.table.sexe'),
    //   Cell: ({ row }) => row.original.sexe || '-----',
    // },
    {
      Header: useFormatMessage('Contacts.table.age'),
      Cell: ({ row }) => row.original.age || '-----',
    },
    {
      Header: useFormatMessage('Contacts.table.product'),
      Cell: ({ row }) => row.original.productName || '-----',
    },
    // newly added
    {
      Header: 'Références',
      accessor: '',
      Cell({ row }) {
        return (
          <div className="">
            {row.original.references ? (
              <div className="flex flex-wrap justify-center">
                {row.original.references.map((ref) => (
                  <span className="green-pill" key={ref}>
                    {ref}
                  </span>
                ))}
              </div>
            ) : (
              '-----'
            )}
          </div>
        );
      },
    },
    //
    {
      Header: 'Type',
      Cell({ row }) {
        let result = [];
        if (row.original.isAcceptContacted) {
          result = [
            ...result,
            <span
              style={{
                display: 'block',
                margin: 5,
                background: '#ccc',
                padding: 3,
                color: '#fff',
                borderRadius: 5,
              }}
            >
              AcceptContacted
            </span>,
          ];
        }
        if (row.original.isAcceptGame) {
          result = [
            ...result,
            <span
              style={{
                display: 'block',
                margin: 5,
                background: '#ccc',
                padding: 3,
                color: '#fff',
                borderRadius: 5,
              }}
            >
              AcceptGame
            </span>,
          ];
        }
        if (row.original.isConsumer) {
          result = [
            ...result,
            <span
              style={{
                display: 'block',
                margin: 5,
                background: '#ccc',
                padding: 3,
                color: '#fff',
                borderRadius: 5,
              }}
            >
              Consumer
            </span>,
          ];
        }
        if (row.original.isLoyal) {
          result = [
            ...result,
            <span
              style={{
                display: 'block',
                margin: 5,
                background: '#ccc',
                padding: 3,
                color: '#fff',
                borderRadius: 5,
              }}
            >
              Loyal
            </span>,
          ];
        }
        if (row.original.isMultiBrands) {
          result = [
            ...result,
            <span
              style={{
                display: 'block',
                margin: 5,
                background: '#ccc',
                padding: 3,
                color: '#fff',
                borderRadius: 5,
              }}
            >
              MultiBrands
            </span>,
          ];
        }
        if (row.original.isTige) {
          result = [
            ...result,
            <span
              style={{
                display: 'block',
                margin: 5,
                background: '#ccc',
                padding: 3,
                color: '#fff',
                borderRadius: 5,
              }}
            >
              Tige
            </span>,
          ];
        }
        return result.map((r) => r);
      },
    },
    {
      Header: useFormatMessage('Contacts.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
  ];

  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(contactsList);

  // end filter logic
  return (
    <>
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            loading={loading}
            hideSelect
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Contacts;
