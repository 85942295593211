import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import { ClipLoader } from 'react-spinners';
import paths from 'pages/Router/paths';
import { fetchRoles, deleteRole, clearRolesData } from 'state/actions/roles';
import ConfirmationModal from 'components/ConfirmationModal';
import Button from 'components/Common/Button';
import PageHeader from 'components/PageHeader';
import FilterSearch from 'components/Common/FilterSearch';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const Roles = () => {
  /**
   * Get Roles Data from states
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   * @param {Array} rolesList list of roles data
   * @param {boolean} isAdmin get if authenticated user is Admin
   * @param {string} error fetch roles error
   * @param {boolean} leading fetch roles leading
   * @param {boolean} deleted roles deleted
   */
  const { rolesList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      rolesList: state.roles.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.roles.error,
      loading: state.roles.loading,
      deleted: state.roles.deleted,
    }),
    shallowEqual
  );
  /**
   * Initialize States
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const [search, setSearch] = useState('');
  const [deleteModal, setDeleteModal] = useState({
    roleId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  /**
   * If Auth is admin fetch data if not clear data
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchRoles());
    }
    return () => dispatch(clearRolesData());
  }, [dispatch, isAdmin]);

  /**
   * Close Modal if Role deleted or page loading
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        roleId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  /**
   * Redirect To Root if not Admin
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const redirect = !isAdmin && <Redirect to={paths.ROOT} />;

  /**
   * Show Modal on Delete button clicked
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const onRemoveButtonClickHandler = (roleId) => {
    setDeleteModal((prevState) => ({
      roleId,
      isOpen: !prevState.isOpen,
    }));
  };

  /**
   * Close Modal on Close button clicked
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const onCloseModalHandler = () => {
    setDeleteModal({ roleId: null, isOpen: false });
  };

  /**
   * Show Modal on Delete button clicked
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const onDeleteRoleHandler = () => {
    dispatch(deleteRole(deleteModal.roleId));
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Dsiplay name',
      accessor: 'displayName',
    },
    {
      /**
       * useFormatMessage from multilange labels
       * Author Ennaim Yassine <ennaimyassine@gmail.com>
       */
      Header: useFormatMessage('Roles.created'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {
            /**
             * useFormatDate transfer String date to Format
             * Author Ennaim Yassine <ennaimyassine@gmail.com>
             */
            useFormatDate(row.original.createdAt, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })
          }
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          <div className="flex justify-center">
            {/**
             * Link to edit Role
             * Author Ennaim Yassine <ennaimyassine@gmail.com>
             */}
            <Button
              to={`/roles/${row.original.id}`}
              isIconOnly
              className="mx-1"
              text="modifer"
              color="blue"
              size="small"
              icon="mdi-pencil"
            />
            {/**
             * Button to Show Modal then Delete
             * Author Ennaim Yassine <ennaimyassine@gmail.com>
             */}

            <Button
              isIconOnly
              className="mx-1"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
              text="modifer"
              color="red"
              size="small"
              icon="mdi-delete"
            />
          </div>
        </>
      ),
      disableSortBy: true,
    },
  ];
  /**
   * Search in all roles object fields
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  // filter logic
  /**
   * updated by issam
   */
  const { result: data, searchProps, selectProps } = useFilterSearch(rolesList);

  // end filter logic

  /**
   * Get Messages text
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   */
  const deleteMessage = useFormatMessage('Roles.delete');

  const confirmMessage = useFormatMessage('Roles.confirm');

  const permDeleteMessage = useFormatMessage('Roles.permDelete');

  const cancelMessage = useFormatMessage('Roles.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteRoleHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Roles" to={paths.ADD_ROLE} />

      <div className="page-padding">
        <div className="px-2">
          <FilterSearch
            hideSelect
            loading={loading}
            selectProps={selectProps}
            searchProps={searchProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={data} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Roles;
