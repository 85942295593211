import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  clearProductDetailsData,
  fetchProductDetails,
} from 'state/actions/productDetails';
import PageHeader from 'components/PageHeader';

const ProductDetails = () => {
  const { productDetails, error, loading } = useSelector((state) => {
    return {
      productDetails: state.productDetails.data,
      error: state.activations.error,
      loading: state.productDetails.loading,
    };
  }, shallowEqual);

  const { productId, activationId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // const agencyId = authorizeView && !isAdmin ? userID : null;
    dispatch(fetchProductDetails());
    return () => dispatch(clearProductDetailsData());
  }, [dispatch]);

  let columns = [
    {
      Header: 'Postion (Licence)',
      accessor: 'positionLicence',
    },
    {
      Header: 'Animateur',
      accessor: 'animator',
    },
    {
      Header: 'Quantité distribuée',
      accessor: 'qtyDistributed',
    },
  ];

  return (
    <>
      {loading ? <ClipLoader /> : <PageHeader title="Historique" hideButton />}
      <div className="page-padding">
        <div className="p-2">
          {/* <div className="flex justify-between px-2">
            <FilterSearch
              loading={loading}
              searchProps={searchProps}
              selectProps={selectProps}
            />
          </div>
          <div className="p-2">
            <Table columns={columns} data={result} loading={loading} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
