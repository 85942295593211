import React, { useState, useEffect } from 'react';
import { createRole, modifyRole } from 'state/actions/roles';
import RoleForm from 'components/RoleForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

const Role = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.roles.success,
    }),
    shallowEqual
  );

  const [role, setRole] = useState({
    name: '',
    permissions: [],
  });

  useEffect(() => {
    const fetchRoleData = async () => {
      const response = await firebase
        .firestore()
        .collection('roles')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchRoleData()
        .then((roleData) => {
          setRole({
            ...roleData.data(),
            id,
          });
        })
        .catch(() => {
          setRole({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const roleForm =
    !role.name && id ? (
      <ClipLoader />
    ) : (
      <RoleForm
        isEditing={isEditing}
        roleData={role}
        action={isEditing ? modifyRole : createRole}
      />
    );

  const redirect = (role.error || success) && <Redirect to={paths.ROLES} />;

  return (
    <>
      {redirect}
      <PageHeader title="Rôle" hideButton />
      <div className="page-padding">{roleForm}</div>
    </>
  );
};

export default Role;
