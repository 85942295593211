import { useEffect, useMemo, useState } from 'react';
const searchString = (target, searchQuery) => {
  try {
    return target.toString().toLowerCase().includes(searchQuery.toLowerCase());
  } catch (e) {
    return false;
  }
};
const indexStrings = (dataArray = []) => {
  const result = [];

  const isObject = (e) => typeof e === 'object';
  const isArray = (e) => typeof e === 'array';
  const isString = (e) => typeof e === 'string';

  const handleString = (result, string) => {
    return result + ' ' + string;
  };
  const handleObject = (objOrArray, string) => {
    let result = string;
    const values = isArray(objOrArray) ? objOrArray : Object.values(objOrArray);
    values.forEach((value) => {
      if (value && isObject(value)) {
        result = handleObject(value, result);
      } else if (value && isArray(value)) {
        result = handleObject(value, result);
      } else if (value && isString(value)) {
        result = handleString(value, result);
      }
    });
    return result;
  };

  dataArray.forEach((e, i) => {
    let allStringsConcatenated = '';
    if (e && isObject(e)) {
      const values = Object.values(e);
      values.forEach((value) => {
        if (value && isString(value)) {
          allStringsConcatenated = handleString(allStringsConcatenated, value);
        } else if (value && (isObject(value) || isArray(value))) {
          allStringsConcatenated = handleObject(value, allStringsConcatenated);
        }
      });
    }
    result.push(allStringsConcatenated);
  });
  return result;
};

/**
 *
 * @param {array} data an array of objects that will be filtered
 * @param {array} options an array of of objects {label: String, accessor: String, getValue: Function}
 * label will be the display name of the filter,
 * accessor will be the key of the property where searching will happen if data is simple/no inner objects
 * getValue is a function that will receive a data piece and should return the value of the target property that the filter is about
 * getValue can be used even when data is simple
 * they will be used for the dropdown
 */
const useFilterSearch = (data = [], options = [], onlyDp = false) => {
  // state
  const memoizedData = useMemo(() => data, [data]);
  const indexedStrings = useMemo(() => indexStrings(data), [data]);
  const [result, setResult] = useState(memoizedData);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState(options.filter((f) => f));
  const [filter, setFilter] = useState({ label: 'Tous' });

  // utilities
  /**
   *
   * @param {Object} el a data item
   * @param {String} searchQuery the search value
   * @return {Boolean}, returns true or false after searching through all the filters
   */
  const searchAll = (el, searchQuery, index) => {
    const indexedStringsOfTheElment = indexedStrings[index];
    return (
      el &&
      indexedStringsOfTheElment &&
      indexedStringsOfTheElment
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };
  /**
   *
   * @param {Object} el a data item
   * @param {String} searchQuery the search value
   * @return {Boolean}, returns true or false after searching through all the selected filter
   */

  const searchBySelectedFilter = (el, searchQuery) => {
    try {
      // if data is simple and an accessor was passed to for filters
      const { accessor } = filter;
      if (accessor) {
        // if accessor is complex like "category.name"
        if (accessor.includes('.')) {
          const handleComplexAccessor = () => {
            let value = el;
            const keys = accessor.split('.');
            keys.forEach((k) => (value = value[k]));
            return searchString(value, searchQuery);
          };
          return handleComplexAccessor();
        }
        // end complex accessor handleing
        // if accessor is simple
        return searchString(el[accessor], searchQuery);
      }
      // else if data is complexe and a getValue func was passed for filter && no accessor was provided
      return searchString(filter.getValue(el), searchQuery);
    } catch (e) {
      return false;
    }
  };
  // state change handlers for dropdown & search
  useEffect(() => {
    const noFilterSelected = filter.label === 'Tous';
    const searchQueryIsEmpty = searchQuery === '';
    // else if searchQuery not empty
    setResult((prevState) => {
      return memoizedData.filter((el, i) => {
        // return true to keep the data as is if search bar is empty
        if (searchQueryIsEmpty) {
          return true;
        }
        // else check if no filter is active to search everything
        if (noFilterSelected) {
          return searchAll(el, searchQuery, i);
        }
        // otherwise search only the selected filter
        return searchBySelectedFilter(el, searchQuery);
      });
    });
  }, [searchQuery, filter, memoizedData]);

  // handlers for FilterSearch component
  // this is to simplify the api of the hook and the component
  // to avoid repetition
  const selectProps = {
    options: [{ label: 'Tous' }, ...filters],
    getOptionLabel: (op) => op.label,
    getOptionValue: (op) => op.label,
    onChange: (op) => {
      
      if (onlyDp) setSearchQuery(`${op.label}`);

      if (op.label === 'Tous') {
        setSearchQuery(``);
        return setFilter({ label: 'Tous' });
      }
      return setFilter(op);
    },
    value: filter,
  };
  const searchProps = {
    value: searchQuery,
    onChange: (e) => setSearchQuery(e.target.value),
  };

  return {
    selectProps,
    searchProps,
    result,
  };
};

export default useFilterSearch;
