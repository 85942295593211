import { createAction } from 'redux-act';
import { displayText } from 'utils';
/* eslint-disable camelcase */
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';

export const ACT_VIDEOS_FETCH_DATA_INIT = createAction(
  'ACT_VIDEOS_FETCH_DATA_INIT'
);
export const ACT_VIDEOS_FETCH_DATA_SUCCESS = createAction(
  'ACT_VIDEOS_FETCH_DATA_SUCCESS'
);
export const ACT_VIDEOS_FETCH_DATA_FAIL = createAction(
  'ACT_VIDEOS_FETCH_DATA_FAIL'
);
export const ACT_VIDEOS_CREATE_VIDEO_INIT = createAction(
  'ACT_VIDEOS_CREATE_VIDEO_INIT'
);
export const ACT_VIDEOS_CREATE_VIDEO_SUCCESS = createAction(
  'ACT_VIDEOS_CREATE_VIDEO_SUCCESS'
);
export const ACT_VIDEOS_CREATE_VIDEO_FAIL = createAction(
  'ACT_VIDEOS_CREATE_VIDEO_FAIL'
);
export const ACT_VIDEOS_CLEAR_DATA = createAction('ACT_VIDEOS_CLEAR_DATA');
export const ACT_VIDEOS_DELETE_VIDEO_INIT = createAction(
  'ACT_VIDEOS_DELETE_ACT_VIDEOS_INIT'
);
export const ACT_VIDEOS_DELETE_VIDEO_SUCCESS = createAction(
  'ACT_VIDEOS_DELETE_VIDEO_SUCCESS'
);
export const ACT_VIDEOS_DELETE_VIDEO_FAIL = createAction(
  'ACT_VIDEOSS_DELETE_ACT_VIDEOS_FAIL'
);
export const ACT_VIDEOS_MODIFY_VIDEO_INIT = createAction(
  'ACT_VIDEOS_MODIFY_VIDEO_INIT'
);
export const ACT_VIDEOS_MODIFY_VIDEO_SUCCESS = createAction(
  'ACT_VIDEOS_MODIFY_VIDEO_SUCCESS'
);
export const ACT_VIDEOS_MODIFY_VIDEO_FAIL = createAction(
  'ACT_VIDEOS_MODIFY_VIDEO_FAIL'
);
export const ACT_VIDEOS_CLEAN_UP = createAction('ACT_VIDEOS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const c = new ControllerWithFileUpload('actVideos', 'actVideo', 'displayName');

/**
 * Action Fetch videos
 * @return dispatch
 */
export const fetchActVideos = () =>
  c.fetch(
    ACT_VIDEOS_FETCH_DATA_INIT,
    ACT_VIDEOS_FETCH_DATA_SUCCESS,
    ACT_VIDEOS_FETCH_DATA_FAIL
  );

/**
 * Action Create new video
 * @param {string} name Name of video
 * @param {string} link link the video
 * @return dispatch
 */
export const createActVideo = ({ name, file }) => {
  const displayName = displayText(name);
  return c.create(
    { name, displayName },
    ACT_VIDEOS_CREATE_VIDEO_INIT,
    ACT_VIDEOS_CREATE_VIDEO_SUCCESS,
    ACT_VIDEOS_CREATE_VIDEO_FAIL,
    file
  );
};

/**
 * Action Delete video
 * @param {string} id ID of video
 * @return dispatch
 */
export const deleteActVideo = (id, link) =>
  c.destroy(
    id,
    ACT_VIDEOS_DELETE_VIDEO_INIT,
    ACT_VIDEOS_DELETE_VIDEO_FAIL,
    ACT_VIDEOS_DELETE_VIDEO_SUCCESS,
    link
  );

/**
 * Action Update video
 * @param {string} name Name of video
 * @return dispatch
 */
export const modifyActVideo = ({ name, id, link, file }) => {
  const displayName = displayText(name);
  return c.update(
    id,
    { name, displayName, link },
    ACT_VIDEOS_MODIFY_VIDEO_INIT,
    ACT_VIDEOS_MODIFY_VIDEO_SUCCESS,
    ACT_VIDEOS_MODIFY_VIDEO_FAIL,
    file
  );
};

export const actVideosCleanUp = () => (dispatch) =>
  dispatch(ACT_VIDEOS_CLEAN_UP());

export const clearActVideosData = () => {
  return (dispatch) => {
    dispatch(ACT_VIDEOS_CLEAR_DATA());
  };
};
