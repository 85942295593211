import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError } from 'utils';
import firebase from 'firebase.js';

import UserController from 'controllers/UserController';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CLEAR_DATA = createAction('USERS_CLEAR_DATA');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

const uc = new UserController();

export const fetchUsers = () => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());
    dispatch(USERS_FETCH_DATA_INIT());
    const { id, isAdmin } = getState().auth.userData;
    let users;
    try {
      users = isAdmin ? await uc.fetch() : await uc.fetch(id);
    } catch (error) {
      toastr.error('Utilisateurs', 'Operation a échoué');
      return dispatch(USERS_CREATE_USER_FAIL({ error }));
    }
    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        users: users
      })
    );
  };
};

export const generateID = () => uc.genIdNum();

export const fetchRoleUsers = (roleId) => uc.roleUsers(roleId);

export const createUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    const { locale } = getState().preferences;
    const { id, isAdmin: admin } = getState().auth.userData;

    let user;
    try {
      user = await uc.create(id, admin, data);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Utilisateur', 'la création a échoué');
      return dispatch(USERS_CREATE_USER_FAIL({ error: errorMessage }));
    }
    toastr.success('Utilisateur', 'créé avec succès');
    return dispatch(USERS_CREATE_USER_SUCCESS({ user }));
  };
};

export const modifyUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
    const { id, isProfile, isEditing } = data;
    // Update Logo

    let user;
    try {
      const { logoUrl } = isProfile
        ? getState().auth.userData
        : getState()
            .users.data.filter((usr) => usr.id === id)
            .pop();
      const { uid } = firebase.auth().currentUser;

      user = await uc.update(data, logoUrl);
      if (id === uid) {
        dispatch(AUTH_UPDATE_USER_DATA({ ...user, id }));
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Utilisateur', 'la suppression a échoué');
      return dispatch(USERS_MODIFY_USER_FAIL({ error: errorMessage }));
    }

    // show seccuss message
    if (isProfile) {
      toastr.success('Profile', 'mis à jour avec succès');
    } else if (isEditing) {
      toastr.success('Utilisateur', 'mis à jour avec succès');
    }
    return dispatch(USERS_MODIFY_USER_SUCCESS({ user }));
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_DELETE_USER_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .users.data.filter((user) => user.id === id)
      .pop();
    try {
      await uc.delete(id, logoUrl);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Utilisateur', 'mise à jour a échoué');
      return dispatch(USERS_DELETE_USER_FAIL({ error: errorMessage }));
    }

    toastr.success('Utilisateur', 'Supprimé avec succès');
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());

export const clearUsersData = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA());
  };
};
