/* eslint-disable camelcase */
import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import Controller from 'controllers/Controller';
import firebase from 'firebase.js';
import { firebaseError, displayText } from 'utils';
import { useFormatMessage } from 'hooks';

export const PERMISSIONS_FETCH_DATA_INIT = createAction(
  'PERMISSIONS_FETCH_DATA_INIT'
);
export const PERMISSIONS_FETCH_DATA_SUCCESS = createAction(
  'PERMISSIONS_FETCH_DATA_SUCCESS'
);
export const PERMISSIONS_FETCH_DATA_FAIL = createAction(
  'PERMISSIONS_FETCH_DATA_FAIL'
);
export const PERMISSIONS_CREATE_PERMISSION_INIT = createAction(
  'PERMISSIONS_CREATE_PERMISSION_INIT'
);
export const PERMISSIONS_CREATE_PERMISSION_SUCCESS = createAction(
  'PERMISSIONS_CREATE_PERMISSION_SUCCESS'
);
export const PERMISSIONS_CREATE_PERMISSION_FAIL = createAction(
  'PERMISSIONS_CREATE_PERMISSION_FAIL'
);
export const PERMISSIONS_CLEAR_DATA = createAction('PERMISSIONS_CLEAR_DATA');
export const PERMISSIONS_DELETE_PERMISSION_INIT = createAction(
  'PERMISSIONS_DELETE_PERMISSIONS_INIT'
);
export const PERMISSIONS_DELETE_PERMISSION_SUCCESS = createAction(
  'PERMISSIONS_DELETE_PERMISSION_SUCCESS'
);
export const PERMISSIONS_DELETE_PERMISSION_FAIL = createAction(
  'PERMISSIONSS_DELETE_PERMISSIONS_FAIL'
);
export const PERMISSIONS_MODIFY_PERMISSION_INIT = createAction(
  'PERMISSIONS_MODIFY_PERMISSION_INIT'
);
export const PERMISSIONS_MODIFY_PERMISSION_SUCCESS = createAction(
  'PERMISSIONS_MODIFY_PERMISSION_SUCCESS'
);
export const PERMISSIONS_MODIFY_PERMISSION_FAIL = createAction(
  'PERMISSIONS_MODIFY_PERMISSION_FAIL'
);
export const PERMISSIONS_CLEAN_UP = createAction('PERMISSIONS_CLEAN_UP');

const gc = new Controller('permissions', 'permission');

export const fetchPermissions = () => {
  return async (dispatch) => {
    dispatch(PERMISSIONS_FETCH_DATA_INIT());
    let permissions;
    try {
      permissions = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(PERMISSIONS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      PERMISSIONS_FETCH_DATA_SUCCESS({
        permissions,
      })
    );
  };
};

export const createPermission = ({ name }) => {
  return async (dispatch, getState) => {
    dispatch(PERMISSIONS_CREATE_PERMISSION_INIT());
    const { locale } = getState().preferences;
    let permission;
    try {
      const displayName = displayText(name);
      permission = await gc.create({ name, displayName }, 'displayName');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Permission', 'la création a échoué');
      return dispatch(
        PERMISSIONS_CREATE_PERMISSION_FAIL({
          error: errorMessage,
        })
      );
    }
    toastr.success('Permission', 'créé avec succès');
    return dispatch(PERMISSIONS_CREATE_PERMISSION_SUCCESS({ permission }));
  };
};

export const deletePermission = (id) => {
  return async (dispatch, getState) => {
    dispatch(PERMISSIONS_DELETE_PERMISSION_INIT());
    const { locale } = getState().preferences;
    try {
      const users = await firebase
        .firestore()
        .collection('roles')
        .where('permissions', 'array-contains', id)
        .get();
      if (users.size > 0) {
        const err = new Error('permissions already used');
        err.code = 401;
        throw err;
      }
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Permission', 'la suppression a échoué');
      return dispatch(
        PERMISSIONS_DELETE_PERMISSION_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('Permission', 'Supprimé avec succès');
    return dispatch(PERMISSIONS_DELETE_PERMISSION_SUCCESS({ id }));
  };
};

export const modifyPermission = ({ name, id }) => {
  return async (dispatch, getState) => {
    dispatch(PERMISSIONS_MODIFY_PERMISSION_INIT());
    const { locale } = getState().preferences;
    let permission;
    try {
      const displayName = displayText(name);
      permission = gc.update(id, { name, displayName }, 'displayName');
      toastr.success('Permission', 'mis à jour avec succès');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Permission', 'mise à jour a échoué');
      return dispatch(
        PERMISSIONS_MODIFY_PERMISSION_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('Permission', 'mis à jour avec succès');
    return dispatch(PERMISSIONS_MODIFY_PERMISSION_SUCCESS({ permission }));
  };
};

export const permissionsCleanUp = () => (dispatch) =>
  dispatch(PERMISSIONS_CLEAN_UP());

export const clearPermissionsData = () => {
  return (dispatch) => {
    dispatch(PERMISSIONS_CLEAR_DATA());
  };
};
