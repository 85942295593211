import React from 'react';
import classNames from 'classnames';

const NormalCheckbox = ({ label, className, isFilter, ...props }) => {
  if (isFilter) {
    return (
      <label className={classNames("flex items-center p-2 mx-1 rounded-full", className)}>
        <input className="form-checkbox w-6 h-6 mx-1" type="checkbox" {...props} />
        <span className="mx-1 text-gray-500">{label}</span>
      </label>
    );
  }
  return (
    <label
      className={classNames('flex-1 flex items-center p-2 pt-8', className)}
    >
      <input
        className="form-checkbox border-blue-300 bg-brand-lighterBlue w-6 h-6"
        type="checkbox"
        {...props}
      />
      <span className="font-semibold text-brand-lightBlue mx-2">{label}</span>
    </label>
  );
};

export default NormalCheckbox;
