import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';

export const CRITERIAS_FETCH_DATA_INIT = createAction(
  'CRITERIAS_FETCH_DATA_INIT'
);
export const CRITERIAS_FETCH_DATA_SUCCESS = createAction(
  'CRITERIAS_FETCH_DATA_SUCCESS'
);
export const CRITERIAS_FETCH_DATA_FAIL = createAction(
  'CRITERIAS_FETCH_DATA_FAIL'
);
export const CRITERIAS_CREATE_CRITERIA_INIT = createAction(
  'CRITERIAS_CREATE_CRITERIA_INIT'
);
export const CRITERIAS_CREATE_CRITERIA_SUCCESS = createAction(
  'CRITERIAS_CREATE_CRITERIA_SUCCESS'
);
export const CRITERIAS_CREATE_CRITERIA_FAIL = createAction(
  'CRITERIAS_CREATE_CRITERIA_FAIL'
);
export const CRITERIAS_CLEAR_DATA = createAction('CRITERIAS_CLEAR_DATA');
export const CRITERIAS_DELETE_CRITERIA_INIT = createAction(
  'CRITERIAS_DELETE_CRITERIAS_INIT'
);
export const CRITERIAS_DELETE_CRITERIA_SUCCESS = createAction(
  'CRITERIAS_DELETE_CRITERIA_SUCCESS'
);
export const CRITERIAS_DELETE_CRITERIA_FAIL = createAction(
  'GAMESS_DELETE_CRITERIAS_FAIL'
);
export const CRITERIAS_MODIFY_CRITERIA_INIT = createAction(
  'CRITERIAS_MODIFY_CRITERIA_INIT'
);
export const CRITERIAS_MODIFY_CRITERIA_SUCCESS = createAction(
  'CRITERIAS_MODIFY_CRITERIA_SUCCESS'
);
export const CRITERIAS_MODIFY_CRITERIA_FAIL = createAction(
  'CRITERIAS_MODIFY_CRITERIA_FAIL'
);
export const CRITERIAS_CLEAN_UP = createAction('CRITERIAS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('criterias', 'criteria');

/**
 * Action Fetch Ctirerias
 * @return dispatch
 */
export const fetchCriterias = () => {
  return async (dispatch) => {
    dispatch(CRITERIAS_FETCH_DATA_INIT());
    let criterias;
    try {
      criterias = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', "Operation a échoué"
);
      return dispatch(CRITERIAS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      CRITERIAS_FETCH_DATA_SUCCESS({
        criterias,
      })
    );
  };
};

/**
 * Action Create new criteria
 * @param {string} name Name of the criteria
 * @param {array} brands list of brands
 * @param {array} purchases list of purchases
 * @param {array} ageRanges list of ageRanges
 * @param {array} buyCount list of buyCounts
 * @param {boolean} smoker Is somker or not
 * @return dispatch
 */
export const createCriteria = ({
  name,
  brands,
  ageRanges,
  purchases,
  smoker,
  buyCount
}) => {
  return async (dispatch, getState) => {
    dispatch(CRITERIAS_CREATE_CRITERIA_INIT());
    const { locale } = getState().preferences;
    let criteria;
    try {
      const displayName = displayText(name);
      criteria = await gc.create(
        { name, displayName, brands, ageRanges, purchases, smoker, buyCount },
        'displayName'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Critère', "la création a échoué"
);
      return dispatch(CRITERIAS_CREATE_CRITERIA_FAIL({ error: errorMessage }));
    }
    toastr.success('Critère', "créé avec succès");
    return dispatch(CRITERIAS_CREATE_CRITERIA_SUCCESS({ criteria }));
  };
};

/**
 * Action Delete a criteria
 * @param {string} id ID of the criteria
 * @return dispatch
 */
export const deleteCriteria = (id) => {
  return async (dispatch, getState) => {
    dispatch(CRITERIAS_DELETE_CRITERIA_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Critère', "la suppression a échoué"
);
      return dispatch(CRITERIAS_DELETE_CRITERIA_FAIL({ error: errorMessage }));
    }
    toastr.success('Critère', "Supprimé avec succès");
    return dispatch(CRITERIAS_DELETE_CRITERIA_SUCCESS({ id }));
  };
};

/**
 * Action Update a criteria
 * @param {string} name Name of the criteria
 * @param {array} brands list of brands
 * @param {array} purchases list of purchases
 * @param {array} ageRanges list of ageRanges
 * @param {boolean} smoker Is somker or not
 * @return dispatch
 */
export const modifyCriteria = ({
  name,
  brands,
  ageRanges,
  purchases,
  smoker,
  buyCount,
  id,
}) => {
  return async (dispatch, getState) => {
    dispatch(CRITERIAS_MODIFY_CRITERIA_INIT());
    const { locale } = getState().preferences;
    let criteria;
    try {
      const displayName = displayText(name);
      criteria = gc.update(
        id,
        { name, displayName, brands, ageRanges, purchases, smoker, buyCount },
        'displayName'
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Critère', "mise à jour a échoué"
);
      return dispatch(CRITERIAS_MODIFY_CRITERIA_FAIL({ error: errorMessage }));
    }
    toastr.success('Critère', "mis à jour avec succès");
    return dispatch(CRITERIAS_MODIFY_CRITERIA_SUCCESS({ criteria }));
  };
};

export const criteriaCleanUp = () => (dispatch) =>
  dispatch(CRITERIAS_CLEAN_UP());

export const clearCriteriasData = () => {
  return (dispatch) => {
    dispatch(CRITERIAS_CLEAR_DATA());
  };
};
