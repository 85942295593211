import firebase from 'firebase.js';
import Controller from './Controller';

export default class RoleController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'roles';
    this.item = 'role';
    this.unique = 'displayName';
  }

  /**
   * Get Role Id by name
   * @param {string} name Role name
   */
  getRoleId = (name) =>
    firebase
      .firestore()
      .collection(this.collection)
      .where('name', '==', name)
      .get()
      .then((roleSnp) => roleSnp.docs[0].id);

  /**
   * Get Role Name by Id
   * @param {string} roleId Role Id
   */
  fetchById = (roleId) =>
    roleId
      ? this.getDoc(roleId).then((roleSnap) => {
          if (roleSnap.exists) {
            return {
              id: roleSnap.id,
              ...roleSnap.data(),
            };
          }
          return null;
        })
      : null;
}
