/* eslint-disable camelcase */
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
import Controller from 'controllers/Controller';
import firebase from 'firebase.js';

export const PRODUCTS_FETCH_DATA_INIT = createAction(
  'PRODUCTS_FETCH_DATA_INIT'
);
export const PRODUCTS_FETCH_DATA_SUCCESS = createAction(
  'PRODUCTS_FETCH_DATA_SUCCESS'
);
export const PRODUCTS_FETCH_DATA_FAIL = createAction(
  'PRODUCTS_FETCH_DATA_FAIL'
);
export const PRODUCTS_CREATE_INIT = createAction('PRODUCTS_CREATE_INIT');
export const PRODUCTS_CREATE_SUCCESS = createAction('PRODUCTS_CREATE_SUCCESS');
export const PRODUCTS_CREATE_FAIL = createAction('PRODUCTS_CREATE_FAIL');

export const PRODUCTS_MODIFY_INIT = createAction('PRRODUCTS_MODIFY_INIT');
export const PRODUCTS_MODIFY_SUCCESS = createAction('PRRODUCTS_MODIFY_SUCCESS');
export const PRODUCTS_MODIFY_FAIL = createAction('PRRODUCTS_MODIFY_FAIL');

export const PRODUCTS_DELETE_INIT = createAction('PRRODUCTS_DELETE_INIT');
export const PRODUCTS_DELETE_SUCCESS = createAction('PRRODUCTS_DELETE_SUCCESS');
export const PRODUCTS_DELETE_FAIL = createAction('PRRODUCTS_DELETE_FAIL');

export const PRODUCTS_CLEAR_DATA = createAction('PRODUCTS_CLEAR_DATA');
export const PRODUCTS_CLEAN_UP = createAction('PRODUCTS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const pc = new Controller('products', 'product');

/**
 * Action Fetch prodcuts
 * @return dispatch
 */

export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch(PRODUCTS_FETCH_DATA_INIT());
    try {
      const products = await pc.fetch();
      const proCategories = products.map(async (product) => {
        const proRef = await firebase
          .firestore()
          .collection('proCategories')
          .doc(product.categoryId)
          .get();
        const category = proRef.data();
        return {
          ...product,
          category,
        };
      });
      const productsList = await Promise.all(proCategories);
      return dispatch(PRODUCTS_FETCH_DATA_SUCCESS({ products: productsList }));
    } catch (error) {
      toastr.error('Produits', 'Operation a échoué');
      return dispatch(PRODUCTS_FETCH_DATA_FAIL({ error }));
    }
  };
};

/**
 * Action Fetch a specific product
 * @param {string} ID ID of the product
 */

export async function fetchProductData(id) {
  const productData = await firebase
    .firestore()
    .collection('products')
    .doc(id)
    .get()
    .then(async (productSnap) => {
      const productInfo = productSnap.data();
      const combinedData = await firebase
        .firestore()
        .collection('proCategories')
        .doc(productInfo.categoryId)
        .get()
        .then((categorySnap) => {
          const categoryInfo = categorySnap.data();
          return {
            id: productSnap.id,
            ...productInfo,
            category: {
              id: categorySnap.id,
              ...categoryInfo,
            },
          };
        });
      const combinedDataWithLevelData = firebase
        .firestore()
        .collection('levels')
        .doc(productInfo.levelId)
        .get()
        .then((levelSnap) => {
          const levelInfo = levelSnap.data();
          return {
            ...combinedData,
            level: {
              id: levelSnap.id,
              ...levelInfo,
            },
          };
        });
      return combinedDataWithLevelData;
    });

  return productData;
}

/**
 * Action Create new product
 * @param {string} name Name of product and category ID
 * @return dispatch
 */

export const createProduct = ({ name, categoryId, price, levels, file }) => {
  return async (dispatch, getState) => {
    dispatch(PRODUCTS_CREATE_INIT());
    let product;
    try {
      const displayName = displayText(name);
      product = await pc.create(
        { name, displayName, categoryId, price, levels },
        'displayName'
      );
      if (file) {
        await pc.uploadFile(product.id, file);
        const link = pc.getFileUrl(product.id, file);
        product = await pc.update(product.id, {
          link,
          updatedAt: new Date().toGMTString(),
        });
      }
    } catch (error) {
      toastr.error('Produit', 'la création a échoué');
      return dispatch(PRODUCTS_CREATE_FAIL({ error }));
    }
    toastr.success('Produit', 'créé avec succès');
    return dispatch(PRODUCTS_CREATE_SUCCESS({ product }));
  };
};

/**
 * Action Update Product
 * @param {string} name Name of the product
 * @param {string} id product ID
 * @param {string} categoryId the Category ID
 * @return dispatch
 */
export const modifyProduct = ({
  name,
  id,
  categoryId,
  link,
  price,
  levels,
  file,
}) => {
  return async (dispatch, getState) => {
    dispatch(PRODUCTS_MODIFY_INIT());
    const { locale } = getState().preferences;
    let product;
    try {
      const displayName = displayText(name);
      product = await pc.update(
        id,
        { name, displayName, categoryId, price, levels },
        'displayName'
      );
      if (file) {
        if (link) await pc.deleteFile(link);
        await pc.uploadFile(id, file);
        const newlink = pc.getFileUrl(product.id, file);
        product = await pc.update(product.id, {
          link: newlink,
          updatedAt: new Date().toGMTString(),
        });
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Produit', 'mise à jour a échoué');
      return dispatch(PRODUCTS_MODIFY_FAIL({ error: errorMessage }));
    }
    toastr.success('Produit', 'mis à jour avec succès');
    return dispatch(PRODUCTS_MODIFY_SUCCESS({ product }));
  };
};

/**
 * Action Delete Product
 * @param {string} id ID of the product
 * @return dispatch
 */

export const deleteProduct = (id) => {
  return async (dispatch, getState) => {
    dispatch(PRODUCTS_DELETE_INIT());
    const { locale } = getState().preferences;
    try {
      await pc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Produit', 'la suppression a échoué');
      return dispatch(PRODUCTS_DELETE_FAIL({ error: errorMessage }));
    }
    toastr.success('Produit', 'Supprimé avec succès');
    return dispatch(PRODUCTS_DELETE_SUCCESS({ id }));
  };
};

export const productsCleanUp = () => (dispatch) =>
  dispatch(PRODUCTS_CLEAN_UP());

export const clearProductsData = () => {
  return (dispatch) => {
    dispatch(PRODUCTS_CLEAR_DATA());
  };
};
