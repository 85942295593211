import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

import { authReducer } from './auth';
import { usersReducer } from './users';

import { teamsReducer } from './teams';

import { preferencesReducer } from './preferences';
// for permissions module
import { permissionsReducer } from './permissions';
import { activationsReducer } from './activations';
// for roles module
import { rolesReducer } from './roles';

import { positionsReducer } from './positions';
import { posCategoriesReducer } from './posCategories';
// please dont remove this comment to generate auto module Reducer Imports

import { productsReducer } from './products';
import { proCategoriesReducer } from './proCategories';

import { actCategoriesReducer } from './actCategories';

import { levelsReducer } from './levels';

import { gamesReducer } from './games';

import { awardsReducer } from './awards';

import { actVideosReducer } from './actVideos';

import { marksReducer } from './marks';

import { kpisReducer } from './kpis';

import { contactsReducer } from './contacts';

import { brandsReducer } from './brands';

import { appNameReducer } from './appName';

import { referencesReducer } from './references';

import { agenciesReducer } from './agencies';

import { criteriasReducer } from './criterias';

import { reportingsReducer } from './reportings';

import { multimarksReducer } from './multimarks';

import { supportsReducer } from './supports';

import { paramVideosReducer } from './paramVideos';

import { productDetailsReducer } from './productDetails';

import { alatigesReducer } from './alatiges';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    {
      key: 'preferences',
      storage: new CookieStorage(Cookies),
    },
    preferencesReducer
  ),
  activations: persistReducer(
    {
      key: 'activations',
      storage,
      blacklist: ['error', 'loading'],
    },
    activationsReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  teams: persistReducer(
    {
      key: 'teams',
      storage,
      blacklist: ['error', 'loading'],
    },
    teamsReducer
  ),
  permissions: persistReducer(
    {
      key: 'permissions',
      storage,
      blacklist: ['error', 'loading'],
    },
    permissionsReducer
  ),
  roles: persistReducer(
    {
      key: 'roles',
      storage,
      blacklist: ['error', 'loading'],
    },
    rolesReducer
  ),
  positions: persistReducer(
    {
      key: 'positions',
      storage,
      blacklist: ['error', 'loading'],
    },
    positionsReducer
  ),
  posCategories: persistReducer(
    {
      key: 'posCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    posCategoriesReducer
  ),
  proCategories: persistReducer(
    {
      key: 'proCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    proCategoriesReducer
  ),
  actCategories: persistReducer(
    {
      key: 'proCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    actCategoriesReducer
  ),
  products: persistReducer(
    {
      key: 'products',
      storage,
      blacklist: ['error', 'loading'],
    },
    productsReducer
  ),
  levels: persistReducer(
    {
      key: 'levels',
      storage,
      blacklist: ['error', 'loading'],
    },
    levelsReducer
  ),
  games: persistReducer(
    {
      key: 'games',
      storage,
      blacklist: ['error', 'loading'],
    },
    gamesReducer
  ),
  awards: persistReducer(
    {
      key: 'awards',
      storage,
      blacklist: ['error', 'loading'],
    },
    awardsReducer
  ),
  actVideos: persistReducer(
    {
      key: 'actVideos',
      storage,
      blacklist: ['error', 'loading'],
    },
    actVideosReducer
  ),
  marks: persistReducer(
    {
      key: 'marks',
      storage,
      blacklist: ['error', 'loading'],
    },
    marksReducer
  ),
  kpis: persistReducer(
    {
      key: 'kpis',
      storage,
      blacklist: ['error', 'loading'],
    },
    kpisReducer
  ),
  contacts: persistReducer(
    {
      key: 'contacts',
      storage,
      blacklist: ['error', 'loading'],
    },
    contactsReducer
  ),
  brands: persistReducer(
    {
      key: 'brands',
      storage,
      blacklist: ['error', 'loading'],
    },
    brandsReducer
  ),
  appNames: persistReducer(
    {
      key: 'appNames',
      storage,
      blacklist: ['error', 'loading'],
    },
    appNameReducer
  ),
  references: persistReducer(
    {
      key: 'references',
      storage,
      blacklist: ['error', 'loading'],
    },
    referencesReducer
  ),
  agencies: persistReducer(
    {
      key: 'agencies',
      storage,
      blacklist: ['error', 'loading'],
    },
    agenciesReducer
  ),

  criterias: persistReducer(
    {
      key: 'criterias',
      storage,
      blacklist: ['error', 'loading'],
    },
    criteriasReducer
  ),
  reportings: persistReducer(
    {
      key: 'reportings',
      storage,
      blacklist: ['error', 'loading'],
    },
    reportingsReducer
  ),
  multimarks: persistReducer(
    {
      key: 'multimarks',
      storage,
      blacklist: ['error', 'loading'],
    },
    multimarksReducer
  ),
  supports: persistReducer(
    {
      key: 'supports',
      storage,
      blacklist: ['error', 'loading'],
    },
    supportsReducer
  ),
  paramVideos: persistReducer(
    {
      key: 'paramVideos',
      storage,
      blacklist: ['error', 'loading'],
    },
    paramVideosReducer
  ),
  productDetails: persistReducer(
    {
      key: 'productDetails',
      storage,
      blacklist: ['error', 'loading'],
    },
    productDetailsReducer
  ),
  alatiges: persistReducer(
    {
      key: 'alatiges',
      storage,
      blacklist: ['error', 'loading'],
    },
    alatigesReducer
  ),
  // please dont remove this comment to generate auto module Reducer Script
  toastr: toastrReducer,
});
