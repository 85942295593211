import Controller from './Controller';

export default class ReferenceController extends Controller {
  constructor(collection, item) {
    super(collection, item);
    this.collection = 'references';
    this.item = 'reference';
  }

  /**
   * Get Reference data
   * @param {string} referenceId Reference ID
   * @returns {Object} object of Reference data or false
   */
  fetchById = async (referenceId) =>
    referenceId
      ? this.getDoc(referenceId).then((referenceSnap) => {
          if (referenceSnap.exists) {
            return {
              id: referenceSnap.id,
              ...referenceSnap.data(),
            };
          }
          return null;
        })
      : null;
}
