import { createReducer } from 'redux-act';
import {
  TEAMS_FETCH_DATA_INIT,
  TEAMS_FETCH_DATA_SUCCESS,
  TEAMS_FETCH_DATA_FAIL,
  TEAMS_CREATE_TEAM_INIT,
  TEAMS_CREATE_TEAM_SUCCESS,
  TEAMS_CREATE_TEAM_FAIL,
  TEAMS_DELETE_TEAM_INIT,
  TEAMS_DELETE_TEAM_SUCCESS,
  TEAMS_DELETE_TEAM_FAIL,
  TEAMS_MODIFY_TEAM_INIT,
  TEAMS_MODIFY_TEAM_SUCCESS,
  TEAMS_MODIFY_TEAM_FAIL,
  TEAMS_CLEAN_UP,
  TEAMS_CLEAR_DATA,
} from 'state/actions/teams';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const teamsReducer = createReducer(
  {
    // for fetching teams
    [TEAMS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [TEAMS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.teams,
      loading: false,
      error: null,
    }),
    [TEAMS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEAMS_CREATE_TEAM_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TEAMS_CREATE_TEAM_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat({ id: state.id }).concat(payload.team),
      loading: false,
      error: null,
      success: true,
    }),
    [TEAMS_CREATE_TEAM_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEAMS_DELETE_TEAM_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TEAMS_DELETE_TEAM_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.idTeam),
      loading: false,
      error: null,
      deleted: true,
    }),
    [TEAMS_DELETE_TEAM_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEAMS_MODIFY_TEAM_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TEAMS_MODIFY_TEAM_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map((elem) => {
        if (elem.id === payload.id) {
          return {
            name: payload.team.name,
            groups: payload.team.groups,
            id: payload.id,
            createdAt: payload.team.createdAt,
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true,
    }),
    [TEAMS_MODIFY_TEAM_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TEAMS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [TEAMS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
