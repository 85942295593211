import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Tabs from 'components/Common/Tabs';
import InformationsDetails from 'components/ActivationDetailsParts/InformationsDetails';
import PositionsDetails from 'components/ActivationDetailsParts/PositionsDetails';
import TeamDetails from 'components/ActivationDetailsParts/TeamDetails';
import PlanningDetails from 'components/ActivationDetailsParts/PlanningDetails';
import { fetchActivation } from 'state/actions/activations';

const ActivationAgencyDetails = () => {
  const { activationId, agencyId } = useParams();

  const { isAdmin, userId, loading, activation } = useSelector(
    (state) => ({
      activation: state.activations.activation,
      loading: state.activations.loading,
      isAdmin: state.auth.userData.isAdmin,
      userId: state.auth.userData.id,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      activationId &&
      activationId !== 'new' &&
      activationId !== 'undefined' &&
      agencyId &&
      agencyId !== 'new' &&
      agencyId !== 'undefined'
    ) {
      dispatch(fetchActivation({}, activationId, agencyId));
    }
  }, [activationId]);

  const [tabs, setTabs] = useState(
    [
      {
        name: 'Information',
        isActive: true,
        component: InformationsDetails,
        props: {},
      },
      {
        name: 'Positions',
        isActive: false,
        component: PositionsDetails,
        props: {},
      },
      {
        name: 'Equipe',
        isActive: false,
        component: TeamDetails,
        props: {},
      },
      !isAdmin && {
        name: 'Planning',
        isActive: false,
        component: PlanningDetails,
        props: {},
      },
    ].filter((tab) => tab)
  );

  return (
    <div className="page-padding">
      <Tabs loading={loading} tabs={tabs} activation={activation} />
    </div>
  );
};

export default ActivationAgencyDetails;
