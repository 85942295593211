import { createReducer } from 'redux-act';
import {
  ROLES_FETCH_DATA_INIT,
  ROLES_FETCH_DATA_SUCCESS,
  ROLES_FETCH_DATA_FAIL,
  ROLES_CREATE_ROLE_INIT,
  ROLES_CREATE_ROLE_SUCCESS,
  ROLES_CREATE_ROLE_FAIL,
  ROLES_DELETE_ROLE_INIT,
  ROLES_DELETE_ROLE_SUCCESS,
  ROLES_DELETE_ROLE_FAIL,
  ROLES_MODIFY_ROLE_INIT,
  ROLES_MODIFY_ROLE_SUCCESS,
  ROLES_MODIFY_ROLE_FAIL,
  ROLES_CLEAN_UP,
  ROLES_CLEAR_DATA,
} from 'state/actions/roles';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const rolesReducer = createReducer(
  {
    // for fetching permissions
    [ROLES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ROLES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.roles,
      loading: false,
      error: null,
    }),
    [ROLES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_CREATE_ROLE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ROLES_CREATE_ROLE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat({ id: state.id }).concat(payload.role),
      loading: false,
      error: null,
      success: true,
    }),
    [ROLES_CREATE_ROLE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_DELETE_ROLE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ROLES_DELETE_ROLE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [ROLES_DELETE_ROLE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_MODIFY_ROLE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ROLES_MODIFY_ROLE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map((elem) => {
        if (elem.id === payload.id) {
          return {
            name: payload.role.name,
            displayName: payload.role.displayName,
            permissions: payload.role.permissions,
            id: payload.id,
            createdAt: payload.role.createdAt,
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true,
    }),
    [ROLES_MODIFY_ROLE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ROLES_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
