import React, { useState, useEffect } from 'react';
import TeamForm from 'components/TeamForm';
import { createTeam, modifyTeam } from 'state/actions/teams';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';
import paths from 'pages/Router/paths';

import { useFormatMessage } from 'hooks';

const Team = () => {
  const { id } = useParams();

  const { agencyId, success } = useSelector(
    (state) => ({
      success: state.teams.success,
      agencyId: state.auth.userData.id,
    }),
    shallowEqual
  );

  const [team, setTeam] = useState({
    name: '',
    teamId: '',
    groups: [
      {
        supervisor: '',
        animators: [],
      },
    ],
  });

  useEffect(() => {
    const fetchTeamData = async () => {
      const response = await firebase
        .firestore()
        .collection('users')
        .doc(agencyId)
        .collection('teams')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchTeamData()
        .then((teamData) => {
          setTeam({
            ...teamData.data(),
            id,
          });
        })
        .catch(() => {
          setTeam({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;
  const teamForm =
    !team.name && !team.groups && id ? (
      <ClipLoader />
    ) : (
      <TeamForm
        isEditing={isEditing}
        teamData={team}
        action={isEditing ? modifyTeam : createTeam}
      />
    );
  const redirect = (team.error || success) && <Redirect to={paths.TEAMS} />;

  return (
    <>
      {redirect}
      <PageHeader title={useFormatMessage('Team.title')} hideButton />
      <div className="page-padding">{teamForm}</div>
    </>
  );
};

export default Team;
