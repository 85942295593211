import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';
import firebase from 'firebase.js';

export const MULTIMARKS_FETCH_DATA_INIT = createAction(
  'MULTIMARKS_FETCH_DATA_INIT'
);
export const MULTIMARKS_FETCH_DATA_SUCCESS = createAction(
  'MULTIMARKS_FETCH_DATA_SUCCESS'
);
export const MULTIMARKS_FETCH_DATA_FAIL = createAction(
  'MULTIMARKS_FETCH_DATA_FAIL'
);
export const MULTIMARKS_CREATE_MULTIMARK_INIT = createAction(
  'MULTIMARKS_CREATE_MULTIMARK_INIT'
);
export const MULTIMARKS_CREATE_MULTIMARK_SUCCESS = createAction(
  'MULTIMARKS_CREATE_MULTIMARK_SUCCESS'
);
export const MULTIMARKS_CREATE_MULTIMARK_FAIL = createAction(
  'MULTIMARKS_CREATE_MULTIMARK_FAIL'
);
export const MULTIMARKS_CLEAR_DATA = createAction('MULTIMARKS_CLEAR_DATA');
export const MULTIMARKS_DELETE_MULTIMARK_INIT = createAction(
  'MULTIMARKS_DELETE_MULTIMARKS_INIT'
);
export const MULTIMARKS_DELETE_MULTIMARK_SUCCESS = createAction(
  'MULTIMARKS_DELETE_MULTIMARK_SUCCESS'
);
export const MULTIMARKS_DELETE_MULTIMARK_FAIL = createAction(
  'GAMESS_DELETE_MULTIMARKS_FAIL'
);
export const MULTIMARKS_MODIFY_MULTIMARK_INIT = createAction(
  'MULTIMARKS_MODIFY_MULTIMARK_INIT'
);
export const MULTIMARKS_MODIFY_MULTIMARK_SUCCESS = createAction(
  'MULTIMARKS_MODIFY_MULTIMARK_SUCCESS'
);
export const MULTIMARKS_MODIFY_MULTIMARK_FAIL = createAction(
  'MULTIMARKS_MODIFY_MULTIMARK_FAIL'
);
export const MULTIMARKS_CLEAN_UP = createAction('MULTIMARKS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('multimarks', 'multimark');
const cUpload = new ControllerWithFileUpload(
  'multimarks',
  'multimark',
  'displayName'
);

/**
 * Action multimarks
 * @return dispatch
 */
export const fetchMultimarks = () => {
  return async (dispatch) => {
    dispatch(MULTIMARKS_FETCH_DATA_INIT());
    let multimarks;
    try {
      multimarks = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(MULTIMARKS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(MULTIMARKS_FETCH_DATA_SUCCESS({ multimarks }));
  };
};

/**
 * Action Create new multimark
 * @param {file} pdf multimark argument
 * @param {boolean} isActive Is the multimark favorite
 * @return dispatch
 */
export const createMultimark = ({ pdf, isActive }) => {
  return async (dispatch, getState) => {
    dispatch(MULTIMARKS_CREATE_MULTIMARK_INIT());
    const { locale } = getState().preferences;
    let pdfUrl;
    let multimark;

    try {
      const multimarkReference = await firebase
        .firestore()
        .collection('multimarks')
        .add({
          isActive,
          createdAt: new Date().toGMTString(),
          updatedAt: new Date().toGMTString(),
        });

      if (pdf) {
        await cUpload.uploadFile(multimarkReference.id, pdf);
        pdfUrl = cUpload.getFileUrl(multimarkReference.id, pdf);
        await cUpload.update(multimarkReference.id, {
          pdfUrl,
          updatedAt: new Date().toGMTString(),
        });
      }

      multimark = await multimarkReference
        .update({
          isActive,
          pdfUrl,
        })
        .then(() => multimarkReference.get().then((snap) => snap.data()));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Multimarque', 'la création a échoué');
      return dispatch(
        MULTIMARKS_CREATE_MULTIMARK_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Multimarque', 'créé avec succès');
    return dispatch(MULTIMARKS_CREATE_MULTIMARK_SUCCESS({ multimark }));
  };
};

/**
 * Action Delete a multimark
 * @param {string} id ID of the multimark
 * @return dispatch
 */
export const deletemMultimark = (id) => {
  return async (dispatch, getState) => {
    dispatch(MULTIMARKS_DELETE_MULTIMARK_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Multimarque', 'la suppression a échoué');
      return dispatch(
        MULTIMARKS_DELETE_MULTIMARK_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Multimarque', 'Supprimé avec succès');
    return dispatch(MULTIMARKS_DELETE_MULTIMARK_SUCCESS({ id }));
  };
};

/**
 * Action Update Multimark
 * @param {file} pdf multimark argument
 * @param {boolean} isActive Is the multimark favorite
 * @return dispatch
 */
export const modifyMultimark = ({ isActive, id, pdfUrl, pdf }) => {
  return async (dispatch, getState) => {
    dispatch(MULTIMARKS_MODIFY_MULTIMARK_INIT());
    const { locale } = getState().preferences;
    let multimark;

    try {
      let oldpdf = pdfUrl;
      const marksReference = await firebase
        .firestore()
        .collection('multimarks')
        .doc(id);

      if (pdf) {
        await cUpload.deleteFile(pdfUrl);
        await cUpload.uploadFile(id, pdf);
        oldpdf = cUpload.getFileUrl(id, pdf);
      }

      multimark = await marksReference
        .update({
          pdfUrl: oldpdf,
          isActive,
          updatedAt: new Date().toGMTString(),
        })
        .then(() =>
          marksReference.get().then((snap) => ({ id: snap.id, ...snap.data() }))
        );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Multimarque', 'mise à jour a échoué');
      return dispatch(
        MULTIMARKS_MODIFY_MULTIMARK_FAIL({ error: errorMessage })
      );
    }
    toastr.success('Multimarque', 'mis à jour avec succès');
    return dispatch(MULTIMARKS_MODIFY_MULTIMARK_SUCCESS({ multimark }));
  };
};

export const multimarksCleanUp = () => (dispatch) =>
  dispatch(MULTIMARKS_CLEAN_UP());

export const clearMultiMarkesData = () => {
  return (dispatch) => {
    dispatch(MULTIMARKS_CLEAR_DATA());
  };
};
