import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import FilterSearch from 'components/Common/FilterSearch';
import useFilterSearch from 'hooks/useFilterSearch';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import ConfirmationModal from 'components/ConfirmationModal';

import { useCan, useFormatMessage } from 'hooks';
import {
  clearActivationsData,
  deleteActivation,
  fetchActivations,
} from 'state/actions/activations';

const Activations = () => {
  const authorizeView = useCan(['view-agency-activations']);
  const authorizeCRUD = useCan([
    'view-activation',
    'create-activation',
    'edit-activation',
    'delete-activation',
  ]);

  const {
    isAdmin,
    userID,
    activationsList,
    error,
    loading,
    deleted,
  } = useSelector((state) => {
    return {
      success: state.activations.success,
      activationsList: state.activations.data,
      isAdmin: state.auth.userData.isAdmin,
      userID: state.auth.userData.id,
      error: state.activations.error,
      loading: state.activations.loading,
      pageCount: state.activations.pageCount,
      deleted: state.activations.deleted,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    const agencyId = authorizeView && !isAdmin ? userID : null;
    dispatch(fetchActivations(agencyId));
    return () => dispatch(clearActivationsData());
  }, [dispatch]);

  const [deleteModal, setDeleteModal] = useState({
    activationId: null,
    isOpen: false,
  });

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        activationId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (activationId) => {
    setDeleteModal((prevState) => ({
      activationId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ activationId: null, isOpen: false });
  };

  const onDeleteActivationHandler = () => {
    dispatch(deleteActivation(deleteModal.activationId));
  };

  let columns = [
    {
      Header: 'Nom',
      accessor: 'name',
      Cell: ({ row }) => {
        const name = row.original && row.original.name;
        return <div className="text-brand-lightBlue">{name}</div>;
      },
    },
    {
      Header: 'Status',
      Cell: ({ row }) => {
        return row.original.periodStart > new Date()
          ? 'Programmée'
          : 'En cours';
      },
    },
    {
      Header: 'Région',
      Cell: ({ row }) => {
        const region = row.original && row.original.region;
        return region || 'Tout';
      },
    },
    {
      Header: 'Catégorie',
      accessor: 'category',
      hideForAgency: true,
      Cell: ({ row }) =>
        row.original.category ? row.original.category.name : '---',
    },
    {
      Header: useFormatMessage('Activations.table.ProductsCount'),
      accessor: 'initProducts',
      hideForAgency: true,
    },
    {
      Header: useFormatMessage('Activations.table.ProductsVolume'),
      accessor: 'initProductsVolume',
      hideForAgency: true,
    },
    {
      Header: useFormatMessage('Activations.table.PositionsCount'),
      accessor: 'initPositions',
      hideForAgency: true,
    },
    {
      Header: "Nombre d'agence",
      accessor: 'initAgencies',
      hideForAgency: true,
      Permission: authorizeCRUD,
    },
    {
      Header: 'Produits Ecoule',
      accessor: 'totalProducts',
    },
    // {
    //   Header: 'Status',
    //   accessor: 'activationStatus',
    //   Cell: ({ row }) => {
    //     const currentDate = new Date();
    //     const startDate = new Date(row.original.periodStart);
    //     const endDate = new Date(row.original.periodEnd);

    //     if (startDate <= currentDate && endDate > currentDate)
    //       return 'En cours';
    //     if (startDate > currentDate) return 'Programmée';
    //     return '---';
    //   },
    // },
    {
      Header: 'Total contacts',
      accessor: 'totalContacts',
    },
    {
      Header: 'Total contact réussis',
      accessor: 'totalSuccessContacts',
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row: { index, ...row } }) => {
        const id = row.original && row.original.id;
        return (
          <div className="flex justify-center">
            <Button
              to={
                isAdmin
                  ? `/activations/${id}/details`
                  : `/activations/${id}/${userID}/details/agency`
              }
              isIconOnly
              className="mx-2"
              text="Consulter"
              icon="mdi-chevron-right"
              color="blue"
              size="medium"
            />
            {isAdmin && (
              <Button
                isIconOnly
                className="mx-1"
                onClick={() => onRemoveButtonClickHandler(id)}
                text="modifer"
                color="red"
                size="small"
                icon="mdi-delete"
              />
            )}
          </div>
        );
      },
    },
  ];

  if (!isAdmin) {
    // getting rid of cols that shouldn't appear on agency area
    columns = columns.filter((col) => !col.hideForAgency);
  }

  const { result, searchProps, selectProps } = useFilterSearch(
    activationsList,
    [
      isAdmin && {
        label: 'Catégories',
        accessor: 'category.name',
      },
    ]
  );

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage="Supprimer"
          title="Supprimer Activation"
          body="voulez-vous vraiment supprimer ?"
          cancelButtonMessage="Annuler"
          onConfirmation={onDeleteActivationHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <div className="page-padding">
        <div className="p-2">
          <div className="flex justify-between px-2">
            <h1 className="text-brand-darkBlue text-2xl font-semibold">
              Récapitulatif des activations
            </h1>
            {/* <FilterSearch loading={loading} hideSelect={!isAdmin} searchProps={searchProps} selectProps={selectProps} /> */}
          </div>
          <div className="p-2">
            <Table columns={columns} data={result || []} loading={loading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Activations;
