import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { shallowEqual, useSelector } from 'react-redux';
import classnames from 'classnames';

import './DatePicker.scss';
import { fr } from 'date-fns/locale';

registerLocale('en', en);
registerLocale('es', es);
registerLocale('fr', fr);

const dateFormat = (locale) => {
  switch (locale) {
    case 'en':
      return 'MM-dd-yy';
    case 'es':
      return 'dd/MM/yy';
    default:
      return 'MM-dd-yy';
  }
};

const DatePickerStyled = ({
  name,
  date,
  setState,
  isForm,
  isFilter,
  ...props
}) => {
  const onDateChangedHandler = (value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const { locale } = useSelector(
    (state) => ({
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

    // return the form or filter version 
    // if isForm or isFilter evaluates to true
  if (isForm || isFilter) {
    return (
      <div
        className={classnames({
          'date-picker-form': isForm,
          'date-picker-filter': isFilter,
        })}
      >
        <DatePicker
          {...props}
          locale={locale}
          dateFormat={dateFormat(locale)}
          selected={date}
          onChange={onDateChangedHandler}
        />
        <div className="icon">
          <i className="mdi mdi-calendar-search mdi-24px"></i>
        </div>
      </div>
    );
  }

  return (
    <DatePicker
      {...props}
      locale={locale}
      dateFormat={dateFormat(locale)}
      selected={date}
      onChange={onDateChangedHandler}
    />
  );
};

DatePickerStyled.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  setState: PropTypes.func.isRequired,
};

export default DatePickerStyled;
