import React, { useState, useEffect } from 'react';
import paths from 'pages/Router/paths';
import Table from 'components/Table';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import Button from 'components/Common/Button';
import {
  fetchPositions,
  clearPositionsData,
  deletePosition,
} from 'state/actions/positions';
import { useCan, useFormatMessage } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import useFilterSearch from 'hooks/useFilterSearch';
import classnames from 'classnames';

const moment = require('moment');

const Positions = () => {
  const authorizeCRUD = useCan([
    'create-position',
    'edit-position',
    'delete-position',
  ]);

  const { isAdmin, userID, positionsList, loading, deleted } = useSelector(
    (state) => ({
      success: state.positions.success,
      positionsList: state.positions.data,
      isAdmin: state.auth.userData.isAdmin,
      userID: state.auth.userData.id,
      error: state.positions.error,
      loading: state.positions.loading,
      deleted: state.positions.deleted,
    }),
    shallowEqual
  );
  const [deleteModal, setDeleteModal] = useState({
    positionId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const agencyId = !isAdmin ? userID : null;
    dispatch(fetchPositions());
    return () => dispatch(clearPositionsData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        positionId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearPositionsData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (positionId) => {
    setDeleteModal((prevState) => ({
      positionId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ positionId: null, isOpen: false });
  };

  const onDeletePositionHandler = () => {
    dispatch(deletePosition(deleteModal.positionId));
  };

  const deleteMessage = useFormatMessage('Positions.delete');
  const confirmMessage = useFormatMessage('Positions.confirm');
  const permDeleteMessage = useFormatMessage('Positions.permDelete');
  const cancelMessage = useFormatMessage('Positions.cancel');
  let columns = [
    {
      Header: 'Nom',
      Cell: ({ row }) => row.original.name || '------',
    },
    // {
    //   Header: 'Catégorie',
    //   Cell: ({ row }) =>
    //     (isAdmin
    //       ? row.original.category
    //       : (row.original.position || {}).category) || '------',
    // },
    {
      Header: 'Région',
      Cell: ({ row }) => row.original.region || '------',
    },
    {
      Header: 'Zone',
      Cell: ({ row }) => row.original.zone || '------',
    },
    {
      Header: 'Secteur',
      Cell: ({ row }) => row.original.sector || '------',
    },
    {
      Header: 'Horaire de démarrage',
      Cell: ({ row }) => row.original.startHour || ' 00:00',
    },
    {
      Header: 'nombre heure travaillés',
      Cell: ({ row }) => (
        <div>
          {moment('2015-01-01')
            .startOf('day')
            .seconds(row.original.workTime || 0)
            .format('H:mm:ss') || '00:00'}
        </div>
      ),
    },
    {
      Header: 'Localization',
      Cell: ({ row }) => (
        <div className="">
          {row.original.city || '------'}
          <span className="icon text-brand-lightBlue">
            <i className="mdi mdi-map-marker" />
          </span>
        </div>
      ),
    },
    {
      Header: 'Matricule',
      Cell: ({ row }) => row.original.matricule || '-----',
    },
    {
      Header: 'Status',
      Cell({ row }) {
        const isActive = isAdmin
          ? row.original.status === 'VALIDATED'
          : (row.original.position || {}).status === 'VALIDATED' || false;
        return (
          <div className="flex justify-center items-center">
            <div
              className={classnames('w-3 h-3 bg-gray-400 rounded-full', {
                'bg-brand-lightGreen': isActive,
              })}
            />
          </div>
        );
      },
    },
  ];
  // clean columns from falsy values
  columns = columns.filter((col) => col);
  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(positionsList, [
    {
      // for smt & agency.
      label: 'Region',
      accessor: 'region',
    },
    {
      // for smt & agency.
      label: 'Ville',
      accessor: 'city',
    },
    {
      // for smt only.
      label: 'Secteur',
      accessor: 'sector',
    },
    {
      // for smt only.
      label: 'Zone',
      accessor: 'zone',
    },
    {
      label: 'Matricule',
      accessor: 'matricule',
    },
  ]);

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeletePositionHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader
        title="Positions en cours"
        to={paths.ADD_POSITION}
        hideButton={!authorizeCRUD}
      />
      <div className="page-padding">
        {/* title & active rate  */}
        <div className="flex justify-between">
          {/* this part is hidden until it becomes dynamic  */}
          <div className="p-2 hidden">
            <span className="text-4xl text-brand-lightGreen">225</span>
            <span className="p-2 text-gray-500 text-lg">active sur</span>
            <span className="text-lg">245</span>
          </div>
          {/* end */}
        </div>
        {/* table and filters  */}
        {/* filters  */}
        <div className="flex items-center justify-between px-2">
          <FilterSearch
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        {/* table  */}
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default Positions;
