import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import Button from 'components/Common/Button';
// import FilterSearch from 'components/Common/FilterSearch';

import {
  fetchProducts,
  clearProductsData,
  deleteProduct,
} from 'state/actions/products';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import paths from 'pages/Router/paths';
import PageHeader from 'components/PageHeader';
import FilterSearch from 'components/Common/FilterSearch';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const Produits = () => {
  const { productsList, error, loading, deleted } = useSelector(
    (state) => ({
      success: state.products.success,
      productsList: state.products.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.products.error,
      loading: state.products.loading,
      deleted: state.products.deleted,
    }),
    shallowEqual
  );
  const [search, setSearch] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    productId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
    return () => dispatch(clearProductsData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        productId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearProductsData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (productId) => {
    setDeleteModal((prevState) => ({
      productId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ productId: null, isOpen: false });
  };

  const onDeleteProductHandler = () => {
    dispatch(deleteProduct(deleteModal.productId));
  };

  const deleteMessage = useFormatMessage('Permissions.delete');
  const confirmMessage = useFormatMessage('Permissions.confirm');
  const permDeleteMessage = useFormatMessage('Permissions.permDelete');
  const cancelMessage = useFormatMessage('Permissions.cancel');

  const columns = [
    {
      Header: 'Noms',
      accessor: 'name',
    },
    {
      Header: 'Categorie',
      accessor: 'categorie',
      Cell: ({ row }) => {
       if (row.original.category) return row.original.category.name;
       return '';
      },
    },
    {
      Header: "Date de création",
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <div className="text-brand-darkBlue">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </div>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            isIconOnly
            to={`/produits/${row.original.id}`}
            className="mx-2"
            text="Modifier"
            icon="mdi-pencil"
            color="blue"
            size="small"
          />
          <Button
            isIconOnly
            className="mx-2"
            text="Supprimer"
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];

  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(productsList, [
    {
      label: 'Catégories',
      accessor: 'category.name',
    },
  ]);
  // end filter logic

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteProductHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Gartuités" to={paths.ADD_PRODUCT} />
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default Produits;
