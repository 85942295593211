/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useFormatDate } from 'hooks';
import firebase from 'firebase.js';

const ActivationSubmittedDetails = ({ activation }) => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [productVolume, setProductVolume] = useState(0);
  const [totalPositions, setTotalPositions] = useState(0);
  const [totalAgencies, setTotalAgencies] = useState([]);

  const periodStart = useFormatDate(activation.periodStart, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const periodEnd = useFormatDate(activation.periodEnd, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  useEffect(() => {
    let agcArr = [];
    const getCities = async (agencies) => {
      const promises = agencies.map((agId) =>
        firebase
          .firestore()
          .collection('users')
          .doc(agId)
          .get()
          .then((agSnap) => {
            if (agSnap.exists) return agSnap.data().location;
          })
      );
      const response = await Promise.all(promises);
      return response;
    };
    activation.positions.map((pos) => {
      if (!agcArr.includes(pos.agencyId)) agcArr = [...agcArr, pos.agencyId];
    });
    setTotalPositions(activation.initPositions);
    getCities(agcArr).then((cities) => {
      setTotalAgencies(cities);
    });
    setTotalProducts(activation.initProducts);
    setProductVolume(activation.initProductsVolume);
  });

  return (
    <div className="p-2 max-w-4xl mx-auto">
      {/* card contains the entries of the activation form  */}
      <h1 className="text-2xl font-semibold text-brand-lightGreen mb-3">
        Felicitation votre activation est en linge !
      </h1>
      <div className="p-3 rounded-lg border border-blue-300 bg-brand-veryLightBlue">
        {/* nom, periode, ville  */}
        <div className="flex ">
          <div className="flex-1 p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Nome de l'activation
            </h1>
            <p className="font-semibold text-gray-800">{activation.name}</p>
          </div>
          <div className="flex-1 p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">Periode</h1>
            <p className="text-gray-800">
              Du {periodStart} au {periodEnd}
            </p>
          </div>
          <div className="flex-1 p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">Villes</h1>
            <p className="text-gray-800">
              {totalAgencies.map((agencyName) => (
                <span>{agencyName} , </span>
              ))}
            </p>
          </div>
        </div>
        {/* description  */}
        <div className="flex">
          <div className="flex-1 p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Description
            </h1>
            <div className="bg-white rounded-lg p-2">
              <p className="text-gray-800 text-sm p-1 max-w-sm">
                {activation.description}
              </p>
            </div>
          </div>
          {/* <div className="flex-1 p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Animation
            </h1>
            <p className="text-gray-800">
              {activation.games.map((game) => (
                <span>{game.gameName} , </span>
              ))}
            </p>
            <p className="text-gray-800">{activation.video.name}</p>
          </div> */}
          {/* <div className="flex-1 p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Video
            </h1>
            <p className="text-gray-800">{activation.video.name}</p>
          </div> */}
        </div>
        <hr className="border-t border-gray-500 w-5/6" />
        {/* nombre de positions, d'agences, de produits, et volume de de produits  */}
        <div className="flex">
          <div className="text-center p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Nombre de positions
            </h1>
            <p className="text-gray-800 text-lg font-medium p-1">
              {totalPositions}
            </p>
          </div>
          <div className="flex-1 text-center p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Nombre d'agences
            </h1>
            <p className="text-gray-800 text-lg font-medium p-1">
              {totalAgencies.length}
            </p>
          </div>
          <div className="flex-1 text-center p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Nombre de produits
            </h1>
            <p className="text-gray-800 text-lg font-medium p-1">
              {totalProducts}
            </p>
          </div>
          <div className="flex-1 text-center p-2">
            <h1 className="font-semibold text-brand-lightBlue mb-2">
              Volume des produits
            </h1>
            <p className="text-gray-800 text-lg font-medium p-1">
              {productVolume}
            </p>
          </div>
        </div>
      </div>
      {/* link on the right bottom */}
      <div className="text-right p-2">
        <a className="text-brand-lightBlue underline" href="/activations">
          Revenir a la liste des activations
        </a>
      </div>
    </div>
  );
};

export default ActivationSubmittedDetails;
