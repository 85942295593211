/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState } from 'react';

import Table from 'components/Table';

import DateRange from 'components/Common/DateRange';
import { useFormatMessage } from 'hooks';
import Button from 'components/Common/Button';

import SelectField from 'components/Common/SelectField';

const ReportingAgences = () => {
  const [selectedAgencie, setselectedAgencie] = useState('121 agency');
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(),
    periodEnd: new Date(),
  });

  const rawData = [
    {
      nomGoodies: 'Power Bank',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2FUpywepgpvSXrzG8AlLDP_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'ECOUTEUR TRIANGLE',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2FcMn0NyuoOdilSd3BeyJl_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'Serviette plage S',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2F6cl4kMj535QNKaXEga7z_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'SAC EN POLYESTER',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2FYawa2H04WUICXcZeXsRh_200x200.JPG?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'ECOUTEUR AVEC POCHETTE',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2FlbtDdwBaBJjNwMVZ2bIE_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'SELFIE STICK',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2Fst9Q7mOEgwXi7Pk0x3I8_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'coffee Mug en silicone',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2FYGz6rPSmHYO3lacOTn6g_200x200.png?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'SUPPORT VENTOUSE TELEPHONE ',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2FsnLJNpgKdoE8sdmxxYMI_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'CABLE LUMINEUX',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2Fz0YTUlSV3iY21ca1q0Sq_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
    {
      nomGoodies: 'BRIQUET JEEP ',
      photoGoodies:
        'https://firebasestorage.googleapis.com/v0/b/smt-activations.appspot.com/o/products%2FClF8YBE7TKbwy3brPc75_200x200.jpg?alt=media',
      quantite: Math.floor(Math.random() * 30),
      valeur: Math.floor(Math.random() * 30),
      agence: Math.floor(Math.random() * 100),
      superviseurs: Math.floor(Math.random() * 100),
      animatrices: Math.floor(Math.random() * 100),
      activaton: Math.floor(Math.random() * 100),
    },
  ];
  const data = rawData.slice(Math.random() * 4, Math.random() * 10 + 5);

  const columns = [
    {
      Header: 'NOM GOODIES & PHOTO',
      accessor: 'photoGoodies',
      Cell: (tableProps) => (
        <div>
          <span className="class-for-name">
            {tableProps.row.original.nomGoodies}
          </span>
          <center>
            <img
              src={tableProps.row.original.photoGoodies}
              width={60}
              alt="Player"
            />
          </center>
        </div>
      ),
    },

    {
      Header: 'STOCK GLOBAL ATTRIBUE PAR SMT EN QUANTITE',
      accessor: 'quantite',
    },
    {
      Header: 'STOCK GLOBAL ATTRIBUE PAR SMT EN VALEURE',
      accessor: 'valeur',
    },
    {
      Header: 'STOCK CONFIRME PAR AGENCE',
      accessor: 'agence',
    },
    {
      Header: 'STOCK CONFIRME PAR SUPERVISEURS',
      accessor: 'superviseurs',
    },
    {
      Header: 'STOCK CONFIRME PAR ANIMATRICES ',
      accessor: 'animatrices',
    },
    {
      Header: 'SORTIE STOCK ACTIVATION ',
      accessor: 'activaton',
    },
  ];

  const agencies = [
    {
      value: '1',
      label: '121 agency',
    },
    {
      value: '2',
      label: 'DEMO AGENCY',
    },
    {
      value: '3',
      label: 'lk agence',
    },
    {
      value: '4',
      label: 'ab-web-test',
    },
    {
      value: '5',
      label: 'BLS',
    },
    {
      value: '6',
      label: 'MEHDI OUADIYE',
    },
    {
      value: '7',
      label: 'bls-agency',
    },
    {
      value: '8',
      label: '121-AG',
    },
  ];

  const onChangeactivation = (value) => {
    setselectedAgencie(value.value);
    console.log(selectedAgencie);
  };

  return (
    <div className="page-padding" style={{ overflow: 'auto' }}>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="text-brand-darkBlue text-3xl font-semibold">
                  Etat de stock par agence
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="flex flex-wrap place-items-center activation-filters">
          <div className="w-1/2 overflow-hidden lg:w-1/3">
            <DateRange
              label={useFormatMessage('ActivationsDetails.card.period')}
              isForm
              fromText={useFormatMessage('Activation.informationsTab.from')}
              fromProps={{
                name: 'periodStart',
                setState: setDateSearch,
                date: new Date(dateSearch.periodStart),
              }}
              toText={useFormatMessage('Activation.informationsTab.to')}
              toProps={{
                name: 'periodEnd',
                setState: setDateSearch,
                date: new Date(dateSearch.periodEnd),
              }}
            />
          </div>

          <div className=" w-1/2 mt-5  select-activations ">
            <SelectField
              placeholder={selectedAgencie}
              type="form"
              onChange={onChangeactivation}
              options={agencies}
            />
          </div>
        </div>
      </section>
      <div className="flex">
        <div className="flex flex-col p-3">
          <div className="w-full reporting-table ">
            <Table
              tailwind="w-64 bg-black"
              columns={columns}
              data={data || []}
              loading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingAgences;
