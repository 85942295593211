import { createReducer } from 'redux-act';
import {
  FETCH_APP_NAME_INIT,
  FETCH_APP_NAME_SUCCESS,
  FETCH_APP_NAME_FAIL,
  CREATE_APP_NAME_INIT,
  CREATE_APP_NAME_SUCCESS,
  CREATE_APP_NAME_FAIL,
  MODIFY_APP_NAME_INIT,
  MODIFY_APP_NAME_SUCCESS,
  MODIFY_APP_NAME_FAIL,
} from 'state/actions/appName';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const appNameReducer = createReducer(
  {
    [FETCH_APP_NAME_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [FETCH_APP_NAME_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: payload.appNames,
        loading: false,
        error: null,
      };
    },
    [FETCH_APP_NAME_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CREATE_APP_NAME_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CREATE_APP_NAME_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.appName, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [CREATE_APP_NAME_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MODIFY_APP_NAME_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MODIFY_APP_NAME_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.appName.id) {
            return {
              name: payload.appName.name,
              displayName: payload.appName.displayName,
              id: payload.id,
              createdAt: payload.appName.createdAt,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [MODIFY_APP_NAME_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
