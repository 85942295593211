/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { Link } from 'react-router-dom';
import { permissionsCleanUp } from 'state/actions/permissions';
import paths from 'pages/Router/paths';
import './PermissionForm.scss';
import NormalInput from 'components/Common/NormalInput';
import Button from 'components/Common/Button';

const PermissionForm = ({ permissionData, action }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => ({
    loading: state.permissions.loading,
  }));

  useEffect(() => {
    return () => dispatch(permissionsCleanUp());
  }, [dispatch]);

  const [permission, setPermission] = useState(permissionData);

  const onChangeHandler = useChangeHandler(setPermission);

  const canSubmit = permission.name;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(permission));
  };
  return (
    <form
      onSubmit={onSubmitHandler}
      className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
    >
      {/* normal field  */}
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        {/* name  */}
        <div className="lg:max-w-sm lg:w-1/2">
          <NormalInput
            label="Nom"
            type="text"
            name="name"
            value={permission.name}
            onChange={onChangeHandler}
          />
        </div>
      </div>
      {/* actions  */}
      <div className="flex flex-wrap w-full max-w-md lg:w-1/3 lg:items-end lg:justify-end items-center justify-end">
        {/* go back */}
        <div className="">
          <Button
            className="m-3 pr-4"
            icon="mdi-arrow-left"
            to={paths.PERMISSIONS}
            text={useFormatMessage('UserForm.goBack')}
          />
        </div>
        {/* end go back */}
        <div className="">
          {/* submit  */}
          <Button
            type="submit"
            className={`${loading && 'is-loading'}`}
            disabled={!canSubmit || loading}
            color="green"
            size="large"
            icon="mdi-plus-circle"
            text={useFormatMessage('UserForm.submit')}
          />
          {/* end submit  */}
        </div>
      </div>
      {/* end controls  */}
    </form>
  );
};

export default PermissionForm;
