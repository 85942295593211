import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';
import firebase from 'firebase.js';

export const MARKS_FETCH_DATA_INIT = createAction('MARKS_FETCH_DATA_INIT');
export const MARKS_FETCH_DATA_SUCCESS = createAction(
  'MARKS_FETCH_DATA_SUCCESS'
);
export const MARKS_FETCH_DATA_FAIL = createAction('MARKS_FETCH_DATA_FAIL');
export const MARKS_CREATE_MARK_INIT = createAction('MARKS_CREATE_MARK_INIT');
export const MARKS_CREATE_MARK_SUCCESS = createAction(
  'MARKS_CREATE_MARK_SUCCESS'
);
export const MARKS_CREATE_MARK_FAIL = createAction('MARKS_CREATE_MARK_FAIL');
export const MARKS_CLEAR_DATA = createAction('MARKS_CLEAR_DATA');
export const MARKS_DELETE_MARK_INIT = createAction('MARKS_DELETE_MARKS_INIT');
export const MARKS_DELETE_MARK_SUCCESS = createAction(
  'MARKS_DELETE_MARK_SUCCESS'
);
export const MARKS_DELETE_MARK_FAIL = createAction('GAMESS_DELETE_MARKS_FAIL');
export const MARKS_MODIFY_MARK_INIT = createAction('MARKS_MODIFY_MARK_INIT');
export const MARKS_MODIFY_MARK_SUCCESS = createAction(
  'MARKS_MODIFY_MARK_SUCCESS'
);
export const MARKS_MODIFY_MARK_FAIL = createAction('MARKS_MODIFY_MARK_FAIL');
export const MARKS_CLEAN_UP = createAction('MARKS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('marks', 'mark');
const cUpload = new ControllerWithFileUpload('marks', 'mark', 'displayName');
/**
 * Action marks
 * @return dispatch
 */
export const fetchMarks = () => {
  return async (dispatch) => {
    dispatch(MARKS_FETCH_DATA_INIT());
    let marks;
    try {
      marks = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(MARKS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(MARKS_FETCH_DATA_SUCCESS({ marks }));
  };
};

/**
 * Action Create new mark
 * @param {string} name Name of the mark
 * @param {boolean} isFavorite Is the mark favorite
 * @param {boolean} isMultiMark Is the mark in the multiMark group
 * @return dispatch
 */
export const createMark = ({ name, isFavorite, image, pdf, source }) => {
  return async (dispatch, getState) => {
    dispatch(MARKS_CREATE_MARK_INIT());
    const { locale } = getState().preferences;
    let mark;
    let imageUrl = '';
    let pdfUrl = '';

    try {
      const displayName = displayText(name);
      const markReference = await firebase.firestore().collection('marks').add({
        name,
        displayName,
        isFavorite,
        source,
        createdAt: new Date().toGMTString(),
        updatedAt: new Date().toGMTString(),
        referencesNumber: 0,
      });

      if (image) {
        await cUpload.uploadFile(markReference.id, image);
        imageUrl = cUpload.getFileUrl(markReference.id, image);
        await cUpload.update(markReference.id, {
          imageUrl,
          updatedAt: new Date().toGMTString(),
        });
      }
      if (pdf) {
        await cUpload.uploadFile(markReference.id, pdf);
        pdfUrl = cUpload.getFileUrl(markReference.id, pdf);
        await cUpload.update(markReference.id, {
          pdfUrl,
          updatedAt: new Date().toGMTString(),
        });
      }

      mark = await markReference
        .update({
          name,
          displayName,
          isFavorite,
          referencesNumber: 0,
          imageUrl,
          pdfUrl,
          source,
        })
        .then(() => markReference.get().then((snap) => snap.data()));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Marque', 'la création a échoué');
      return dispatch(MARKS_CREATE_MARK_FAIL({ error: errorMessage }));
    }
    toastr.success('Marque', 'créé avec succès');
    return dispatch(MARKS_CREATE_MARK_SUCCESS({ mark }));
  };
};

/**
 * Action Delete a mark
 * @param {string} id ID of the mark
 * @return dispatch
 */
export const deleteMark = (id) => {
  return async (dispatch, getState) => {
    dispatch(MARKS_DELETE_MARK_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Marque', 'la suppression a échoué');
      return dispatch(MARKS_DELETE_MARK_FAIL({ error: errorMessage }));
    }
    toastr.success('Marque', 'Supprimé avec succès');
    return dispatch(MARKS_DELETE_MARK_SUCCESS({ id }));
  };
};

/**
 * Action Update mark
 * @param {string} name Name of the game
 * @param {boolean} isFavorite Is the mark favorite
 * @param {boolean} isMultiMark Is the mark in the multiMark group
 * @return dispatch
 */
export const modifyMark = ({
  name,
  isFavorite,
  id,
  image,
  imageUrl,
  pdfUrl,
  pdf,
  source,
}) => {
  return async (dispatch, getState) => {
    dispatch(MARKS_MODIFY_MARK_INIT());
    const { locale } = getState().preferences;
    let mark;
    const displayName = displayText(name);

    try {
      if (!(await cUpload.exists({ displayName }))) {
        let oldImage = imageUrl;
        let oldpdf = pdfUrl;
        const marksReference = await firebase
          .firestore()
          .collection('marks')
          .doc(id);

        if (image) {
          await cUpload.deleteFile(imageUrl);
          await cUpload.uploadFile(id, image);
          oldImage = cUpload.getFileUrl(id, image);
        }
        if (pdf) {
          await cUpload.deleteFile(pdfUrl);
          await cUpload.uploadFile(id, pdf);
          oldpdf = cUpload.getFileUrl(id, pdf);
        }

        mark = await marksReference
          .update({
            name,
            displayName,
            imageUrl: oldImage,
            pdfUrl: oldpdf,
            isFavorite,
            source,
            updatedAt: new Date().toGMTString(),
          })
          .then(() =>
            marksReference
              .get()
              .then((snap) => ({ id: snap.id, ...snap.data() }))
          );
      } else {
        toastr.error('Marque', 'Cette article existe déjà!');
        return dispatch(
          MARKS_MODIFY_MARK_INIT({
            error: `This ,ark is already exists!`,
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Marque', 'mise à jour a échoué');
      return dispatch(MARKS_MODIFY_MARK_FAIL({ error: errorMessage }));
    }
    toastr.success('Marque', 'mis à jour avec succès');
    return dispatch(MARKS_MODIFY_MARK_SUCCESS({ mark }));
  };
};

export const marksCleanUp = () => (dispatch) => dispatch(MARKS_CLEAN_UP());

export const clearMarkesData = () => {
  return (dispatch) => {
    dispatch(MARKS_CLEAR_DATA());
  };
};
