import { createReducer } from 'redux-act';
import {
  ACT_VIDEOS_FETCH_DATA_INIT,
  ACT_VIDEOS_FETCH_DATA_SUCCESS,
  ACT_VIDEOS_FETCH_DATA_FAIL,
  ACT_VIDEOS_CREATE_VIDEO_INIT,
  ACT_VIDEOS_CREATE_VIDEO_SUCCESS,
  ACT_VIDEOS_CREATE_VIDEO_FAIL,
  ACT_VIDEOS_DELETE_VIDEO_INIT,
  ACT_VIDEOS_DELETE_VIDEO_SUCCESS,
  ACT_VIDEOS_DELETE_VIDEO_FAIL,
  ACT_VIDEOS_MODIFY_VIDEO_INIT,
  ACT_VIDEOS_MODIFY_VIDEO_SUCCESS,
  ACT_VIDEOS_MODIFY_VIDEO_FAIL,
  ACT_VIDEOS_CLEAN_UP,
  ACT_VIDEOS_CLEAR_DATA,
} from 'state/actions/actVideos';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const actVideosReducer = createReducer(
  {
    [ACT_VIDEOS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ACT_VIDEOS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.actVideos,
      loading: false,
      error: null,
    }),
    [ACT_VIDEOS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACT_VIDEOS_CREATE_VIDEO_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ACT_VIDEOS_CREATE_VIDEO_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.actVideo, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [ACT_VIDEOS_CREATE_VIDEO_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACT_VIDEOS_DELETE_VIDEO_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ACT_VIDEOS_DELETE_VIDEO_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [ACT_VIDEOS_DELETE_VIDEO_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACT_VIDEOS_MODIFY_VIDEO_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ACT_VIDEOS_MODIFY_VIDEO_SUCCESS]: (state, payload) => ({
      ...state,
      data: [
        ...state.data.map((elem) => {
          if (elem.id === payload.actVideo.id) {
            return {
              name: payload.actVideo.name,
              link: payload.actVideo.link,
              displayName: payload.actVideo.displayName,
              id: payload.id,
              createdAt: payload.actVideo.createdAt,
            };
          }
          return elem;
        }),
      ],
      loading: false,
      error: null,
      success: true,
    }),
    [ACT_VIDEOS_MODIFY_VIDEO_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ACT_VIDEOS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ACT_VIDEOS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
