/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import Button from 'components/Common/Button';

const moment = extendMoment(originalMoment);

const DateRange = (props) => {
  const today = moment();

  // const lastMonth = moment()
  //   .subtract(1, 'month')
  //   .startOf('month')
  //   .format('MMMM');

  // const thisMonth = today.toLocaleString('default', { month: 'long' });

  // const startOfMonth = moment()
  //   .month(selectedrange)
  //   .startOf('month')
  //   .toDate();
  // const endOfMonth = moment().month(selectedrange).endOf('month').toDate();

  const [isCustum, setisCustum] = useState(false);
  const [selectedrange, setselectedrange] = useState('novembre');

  const [isOpen, setisOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, 'days'), today.clone())
  );

  useEffect(() => {
    if (props.range === '1') {
      setselectedrange('Octobre');
      setisCustum(false);
    } else if (props.range === '2') {
      setselectedrange('novembre');
      setisCustum(false);
    } else if (props.range === '3') {
      setisCustum(true);
      // console.log('select number :', props.range);
      // console.log('isCustum :', isCustum);
    }

    const startOfMonth = moment()
      .month(selectedrange)
      .startOf('month')
      .toDate();
    const endOfMonth = moment().month(selectedrange).endOf('month').toDate();

    setValue(moment.range(startOfMonth, endOfMonth));
    setisOpen(props.isShown);
    // console.log(props.isShown);
  }, [props.isShown, props.range]);

  const renderSelectionValue = () => {
    return (
      <div>
        {' La date sélectionnée : du  '}
        {value.start.format('DD-MMMM-YYYY')}
        {' au '}
        {value.end.format('DD-MMMM-YYYY')}
      </div>
    );
  };
  const onSelect = (valuee) => {
    setValue(valuee);
  };

  return (
    <>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-full overflow-hidden font-semibold m-1 p-4 xl:w-4/6 bg-blue-100">
          {' '}
          {renderSelectionValue()}
        </div>
        {isCustum && (
          <>
            <input
              className="w-full overflow-hidden font-semibold m-1 xl:w-1/5 bg-green-200"
              type="button"
              value="Edit"
              onClick={(e) => {
                setisOpen(!isOpen);
              }}
            />
          </>
        )}
        {isOpen && (
          <div className=" w-min absolute mt-12 ml-1 bg-blue-100 z-50">
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={setisOpen}
            />
            <input
              className=" text-white overflow-hidden font-semibold m-1 xl:w-1/5 bg-red-600"
              type="button"
              value="Appliquer"
              onClick={(e) => {
                setisOpen(!isOpen);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DateRange;
