import React from 'react';
import PlanningForm from 'components/PlanningForm';

const PlanningDetails = ({ activation, loading }) => {
  return (
    <div className="border pt-6 pb-32">
      <PlanningForm activation={activation} loading={loading} />
    </div>
  );
};

export default PlanningDetails;
