import React from 'react';
import { useFormatMessage, useFormatDate } from 'hooks';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import CenteredLoader from './Loader';

const AgencyDetailsCard = ({ activation, loading }) => {
  const { isAdmin } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual
  );
  const periodStart = useFormatDate((activation.date || {}).periodStart, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const periodEnd = useFormatDate((activation.date || {}).periodEnd, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const achivedDays = () => {
    if (activation.date) {
      if (
        activation.date.periodStart < new Date() &&
        activation.date.periodEnd > new Date()
      ) {
        const a = moment(activation.date.periodStart);
        const b = moment(new Date());
        return b.diff(a, 'days');
      }
      const a = moment(activation.date.periodStart);
      const b = moment(activation.date.periodEnd);
      return b.diff(a, 'days');
    }
    return '---';
  };
  if (loading) {
    return <CenteredLoader />;
  }
  const SMT = (
    <div className=" rounded-lg bg-brand-veryLightBlue border border-blue-300 p-3">
      {/* top row  */}
      <div className="flex">
        {/* item  */}
        <div className="flex w-3/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">Agence</h1>
          <p className="text-gray-700 p-2">{(activation.agency || {}).name}</p>
        </div>
        {/* item  */}
        <div className="flex w-4/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Nombre de positions
          </h1>
          <p className="text-gray-700 p-2">
            {(activation.agencyStock || {}).initPositions}
          </p>
        </div>
        {/* item  */}
        <div className="flex w-5/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">Periode</h1>
          <p className="text-gray-700 p-2">
            Du {periodStart} au {periodEnd}
          </p>
        </div>
      </div>
      {/* separater  */}
      <hr className="border-b-0 border-solid border-blue-300 m-3" />
      {/* middle row  */}
      <div className="flex">
        {/* item  */}
        {/* item  */}
        <div className="flex w-4/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Nombre de contacts
          </h1>
          <p className="text-gray-700 p-2">
            {(activation.agencyStock || {}).totalContacts}
          </p>
        </div>
        {/* item  */}
        <div className="flex w-3/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Produits ecoules
          </h1>
          <p className="text-brand-lightGreen font-semibold p-2">
            {(activation.agencyStock || {}).totalProducts}
          </p>
        </div>
        <div className="flex w-4/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Contacts réussis
          </h1>
          <p className="text-brand-lightGreen font-semibold p-2">
            {(activation.agencyStock || {}).totalSuccessContacts}
          </p>
          {/* <a
            href="#"
            className="text-gray-500 font-semibold underline text-sm p-2"
          >
            Telecharger les contacts
          </a> */}
        </div>
      </div>
      {/* separater  */}
      <hr className="border-b-0 border-solid border-blue-300 m-3" />
      {/* bottom row  */}
      <div className="flex">
        {/* item  */}
        <div className="flex w-3/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Nombre de jours acheves
          </h1>
          <p className="text-brand-lightGreen font-semibold p-2">
            {achivedDays()}
          </p>
        </div>
      </div>
    </div>
  );
  const Agency = (
    <div className=" rounded-lg bg-brand-veryLightBlue border border-blue-300 p-3">
      {/* top row  */}
      <div className="flex">
        {/* item  */}
        <div className="flex w-3/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">Activation</h1>
          <p className="text-gray-700 p-2">{activation.name}</p>
        </div>
        {/* item  */}
        <div className="flex w-5/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">Periode</h1>
          <p className="text-gray-700 p-2">
            {`Du ${periodStart} au ${periodEnd}`}
          </p>
        </div>
      </div>
      <hr className="border-b-0 border-solid border-blue-300 m-3" />
      {/* bottom row  */}
      <div className="flex">
        {/* item  */}
        <div className="flex w-3/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Nombre de jours acheves
          </h1>
          <p className="text-brand-lightGreen font-semibold p-2">
            {achivedDays()}
          </p>
        </div>
        {/* item  */}
        <div className="flex w-4/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Produits ecoules
          </h1>
          <p className="text-brand-lightGreen font-semibold p-2">
            {(activation.agencyStock || {}).totalProducts}
          </p>
        </div>
        {/* item  */}
        <div className="flex w-5/12">
          <h1 className="font-semibold text-brand-lightBlue p-2">
            Contacts réussis
          </h1>
          <p className="text-brand-lightGreen font-semibold p-2">
            {(activation.agencyStock || {}).totalSuccessContacts}
          </p>
          {/* <a
            href="#"
            className="text-gray-500 font-semibold underline text-sm p-2"
          >
            Telecharger les contacts
          </a> */}
        </div>
      </div>
    </div>
  );
  return <div className="">{isAdmin ? SMT : Agency}</div>;
};

export default AgencyDetailsCard;
