import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const useChangeHandler = (setState) => {
  const onChangeHandler = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setState((prevState) => ({ ...prevState, [`${name}`]: value }));
  };

  return onChangeHandler;
};

const useFormatMessage = (
  id,
  values = {},
  defaultMessage = '',
  description = ''
) => {
  const intl = useIntl();
  return intl.formatMessage({ id, defaultMessage, description }, values);
};

const useFormatDate = (value, options = {}) => {
  const intl = useIntl();
  return intl.formatDate(value, options);
};

const useSecondToHours = (sc) => {
  const value = Number(sc);
  const h = Math.floor(value / 3600);
  const m = Math.floor((value % 3600) / 60);
  const s = Math.floor((value % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' h, ' : ' h, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' min, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' s' : ' s') : '';
  if (hDisplay + mDisplay + sDisplay === '') return '00 s';
  return hDisplay + mDisplay + sDisplay;
};

const useCan = (perms) => {
  const { userPermissions, isAdmin, error, loading } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
      userPermissions: state.auth.userPermissions,
      error: state.auth.error,
      loading: state.auth.loading,
    }),
    shallowEqual
  );

  if (!error && !loading) {
    return (
      !perms.length || perms.some((p) => userPermissions.includes(p)) || isAdmin
    );
  }
  return false;
};
export {
  useChangeHandler,
  useFormatMessage,
  useFormatDate,
  useSecondToHours,
  useCan,
};
