import React, { useEffect, useState, useMemo } from 'react';
import Table from 'components/Table';
import useFilterSearch from 'hooks/useFilterSearch';
import FilterSearch from 'components/Common/FilterSearch';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  fetchAgencies,
  deleteAgency,
  agenciesCleanUp,
} from 'state/actions/agencies';
import ConfirmationModal from 'components/ConfirmationModal';
import PageHeader from 'components/PageHeader';
import Button from 'components/Common/Button';
import paths from 'pages/Router/paths';
import { getDateYearArray } from './util';
import { useFormatMessage } from 'hooks';
import sortByDate from 'utils/sortByDate';

const Agences = () => {
  const { agenciesList, loading, deleted } = useSelector(
    (state) => ({
      success: state.agencies.success,
      agenciesList: state.agencies.data,
      isAdmin: state.auth.userData.isAdmin,
      loading: state.agencies.loading,
      deleted: state.agencies.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAgencies());
    return () => dispatch(agenciesCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteAgencyHandler = () => {
    dispatch(deleteAgency(deleteModal.userId));
  };

  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');

  const columns = [
    {
      Header: 'ID',
      accessor: 'idNum',
    },
    {
      Header: 'Nom',
      accessor: 'name',
    },
    {
      Header: 'Date de création',
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <div className="text-brand-darkBlue">{row.original.createdAt}</div>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            isIconOnly
            className="mx-1"
            to={`/agencies/${row.original.id}`}
            text="Modifier"
            color="blue"
            size="small"
            icon="mdi-pencil"
          />

          <Button
            isIconOnly
            className="mx-1"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
            text="Supprimer"
            color="red"
            size="small"
            icon="mdi-delete"
          />
        </div>
      ),
    },
  ];
  const selectDate = useMemo(() => {
    return getDateYearArray(agenciesList);
  }, [agenciesList]);
  const { result, searchProps, selectProps } = useFilterSearch(
    agenciesList,
    selectDate,
    true
  );
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteAgencyHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Agences" to={paths.ADD_AGENCY} />

      {/* table */}
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            hideSelect
            loading={loading}
            searchProps={searchProps}
          />
          <FilterSearch hideInput selectProps={selectProps} />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default Agences;
