/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearKpisData, fetchKpis } from 'state/actions/kpis';
import { useParams, Redirect } from 'react-router-dom';
import SelectField from 'components/Common/SelectField';
import Table from 'components/Table';
import { fetchProducts } from 'state/actions/products';
import { fetchPositions } from 'state/actions/positions';
import { PropagateLoader } from 'react-spinners';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const ActivationGraphs = () => {
  const { actId } = useParams();
  const { activationsList, loading } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
      userID: state.auth.userData.id,
      activationsList: state.kpis.data,
      productsList: state.products.data,
      productsLoading: state.products.loading,
      positionsCount: state.positions.data.length,
      positionsLoading: state.positions.loading,
      error: state.kpis.error,
      loading: state.kpis.loading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const [groupBy, setGroupBy] = useState({ label: 'Ville', value: 'city' });
  const [groupPositions, setGroupPositions] = useState([]);
  const [positions, setPositions] = useState([]);

  const chart = useRef(null);

  useEffect(() => {
    dispatch(fetchKpis(null, actId));
    dispatch(fetchPositions());
    dispatch(fetchProducts());
    return () => dispatch(clearKpisData());
  }, [dispatch]);

  useEffect(() => {
    if (
      !loading &&
      activationsList.positions &&
      activationsList.positions.length > 0
    ) {
      const reduced = activationsList.positions
        .reduce((m, pos) => {
          const existsPos = m && m.find((f) => f.date === pos.date);
          if (!existsPos) {
            return [
              ...m,
              {
                date: pos.date,
                totalProducts: pos.totalProducts || 0,
                totalContacts: pos.totalContacts || 0,
                totalSuccessContacts: pos.totalSuccessContacts || 0,
                count: 1,
              },
            ];
          }
          existsPos.totalProducts += pos.totalProducts;
          existsPos.totalContacts += pos.totalContacts;
          existsPos.totalSuccessContacts += pos.totalSuccessContacts;
          existsPos.count += 1;
          return m;
        }, [])
        .sort(function (a, b) {
          return Date.parse(a.date) - Date.parse(b.date);
        });
      setPositions(reduced);
    }
  }, [loading, activationsList, groupBy]);

  useEffect(() => {
    if (
      !loading &&
      activationsList.positions &&
      activationsList.positions.length > 0
    ) {
      if (groupBy.value) {
        const reduced = activationsList.positions.reduce((m, pos) => {
          const p = pos.position;
          if (p) {
            const existsPos = m && m.find((f) => f.name === p[groupBy.value]);
            if (!existsPos) {
              return [
                ...m,
                {
                  name: p[groupBy.value],
                  totalProducts: pos.totalProducts || 0,
                  totalContacts: pos.totalContacts || 0,
                  totalSuccessContacts: pos.totalSuccessContacts || 0,
                  count: 1,
                },
              ];
            }
            existsPos.totalProducts += pos.totalProducts;
            existsPos.totalContacts += pos.totalContacts;
            existsPos.totalSuccessContacts += pos.totalSuccessContacts;
            existsPos.count += 1;
            return m;
          }
          return m;
        }, []);
        setGroupPositions(reduced);
      }
    }
  }, [loading, activationsList, groupBy]);

  useLayoutEffect(() => {
    if (groupPositions.length > 0) {
      let chart = am4core.create('chartdiv2', am4charts.XYChart);
      chart.colors.step = 3;

      // Add data
      chart.data = groupPositions.map((gp) => ({
        group: gp.name,
        totalProducts: gp.totalProducts,
        totalContacts: gp.totalContacts,
        totalSuccessContacts: gp.totalSuccessContacts,
        count: gp.count,
      }));

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'group';
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.min = 0;

      // Create series
      function createSeries(field, name) {
        // Set up series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = 'group';
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';

        // Add label
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{valueY}';
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;

        return series;
      }

      createSeries('totalProducts', 'Ventes');
      createSeries('totalContacts', 'Contacts');
      createSeries('totalSuccessContacts', 'Succ. Contacts');
      createSeries('count', 'Animations');

      // Legend
      chart.legend = new am4charts.Legend();
      return () => {
        chart.dispose();
      };
    }
  }, [groupPositions, groupBy]);

  useLayoutEffect(() => {
    if (positions.length > 0) {
      let chart = am4core.create('chartdiv', am4charts.XYChart);

      //

      // Increase contrast by taking evey second color
      chart.colors.step = 2;

      // Add data
      chart.data = generateChartData();

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;

      // Create series
      function createAxisAndSeries(field, name, opposite, bullet) {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) != 0) {
          valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = 'date';
        series.strokeWidth = 2;
        series.yAxis = valueAxis;
        series.name = name;
        series.tooltipText = '{name}: [bold]{valueY}[/]';
        series.tensionX = 0.8;
        series.showOnInit = true;

        let interfaceColors = new am4core.InterfaceColorSet();

        switch (bullet) {
          case 'triangle':
            let bullet = series.bullets.push(new am4charts.Bullet());
            bullet.width = 12;
            bullet.height = 12;
            bullet.horizontalCenter = 'middle';
            bullet.verticalCenter = 'middle';

            let triangle = bullet.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor('background');
            triangle.strokeWidth = 2;
            triangle.direction = 'top';
            triangle.width = 12;
            triangle.height = 12;
            break;
          case 'rectangle':
            let bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = 'middle';
            bullet1.verticalCenter = 'middle';

            let rectangle = bullet1.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor('background');
            rectangle.strokeWidth = 2;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            let bullet2 = series.bullets.push(new am4charts.CircleBullet());
            bullet2.circle.stroke = interfaceColors.getFor('background');
            bullet2.circle.strokeWidth = 2;
            break;
        }

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = series.stroke;
        valueAxis.renderer.labels.template.fill = series.stroke;
        valueAxis.renderer.opposite = opposite;
      }

      createAxisAndSeries('count', 'Animations', false, 'circle');
      createAxisAndSeries('totalProducts', 'Ventes', false, 'circle');
      createAxisAndSeries('totalContacts', 'Contacts', true, 'triangle');
      createAxisAndSeries(
        'totalSuccessContacts',
        'succ. Contacts',
        true,
        'rectangle'
      );

      // Add legend
      chart.legend = new am4charts.Legend();

      // Add cursor
      chart.cursor = new am4charts.XYCursor();

      // generate some random data, quite different range
      function generateChartData() {
        let chartData = [];
        let firstDate = new Date();
        firstDate.setDate(firstDate.getDate() - 100);
        firstDate.setHours(0, 0, 0, 0);

        let count = 160;
        let totalProducts = 1600;
        let totalContacts = 2900;
        let totalSuccessContacts = 8700;

        for (var i = 0; i < positions.length; i++) {
          // we create date objects here. In your data, you can have date strings
          // and then set format of your dates using chart.dataDateFormat property,
          // however when possible, use date objects, as this will speed up chart rendering.
          const pos = positions[i];
          let newDate = new Date(pos.date);
          newDate.setDate(newDate.getDate());

          count = pos.count;
          totalProducts = pos.totalProducts;
          totalContacts = pos.totalContacts;
          totalSuccessContacts = pos.totalSuccessContacts;

          chartData.push({
            date: newDate,
            count,
            totalProducts,
            totalContacts,
            totalSuccessContacts,
          });
        }
        return chartData;
      }

      chart.current = chart;

      return () => {
        chart.dispose();
      };
    }
  }, [positions]);
  if (loading) {
    // return loader comp
    return (
      <div className="py-10 flex justify-center">
        <div className="flex">
          <div className="p-2 pb-4 flex items-center">
            <h3 className="text-brand-darkBlue text-2xl font-semibold">
              Chargement du graphs
            </h3>
            <PropagateLoader color="#90cdf4" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="page-padding">
      <div className="flex">
        <h3 className="text-brand-darkBlue text-2xl font-semibold">
          Statistics par Date
        </h3>
      </div>
      <div className="flex">
        <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
      </div>
      <div className="flex">
        <h3 className="text-brand-darkBlue text-2xl font-semibold">
          Statistics par Location
        </h3>
      </div>
      <div className="flex">
        <div className="p-2 pb-4 flex items-center">
          <label className="pr-2 text-xl text-brand-darkBlue">FILTER PAR</label>
          <SelectField
            placeholder="Selectioner"
            className="tw-filter-search-select"
            type="filter"
            onChange={(g) => setGroupBy(g)}
            options={[
              { label: 'Ville', value: 'city' },
              { label: 'Secteur', value: 'sector' },
              { label: 'Zone', value: 'zone' },
              { label: 'Region', value: 'region' },
            ]}
            value={groupBy}
          />
        </div>
      </div>
      <div className="flex">
        <div id="chartdiv2" style={{ width: '100%', height: '500px' }}></div>
      </div>
    </div>
  );
};

export default ActivationGraphs;
