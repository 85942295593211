import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

const Tabs = ({
  loading,
  setActivation,
  activation,
  isEditing,
  tabs = [],
  hideNav = false,
  next,
  previous,
}) => {
  /**
   * @param {Boolean} hideNav, to hide nav or not to hide it
   * @param {Array of Objecs} tabs, Each Object should contain :
   * * @param {String} name, for the navigation links
   * * @param {Boolean} isActive, to define which tab to show
   * * @param {Component} component, your tab component
   * * @param {Object} props, props for each tab
   */

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    tabs.forEach((t, i) => {
      if (t.isActive) {
        setCurrentTabIndex(i);
      }
    });
  }, [tabs]);

  // get the active tab
  const { component: Tab } = tabs[currentTabIndex];

  // nav part
  const TabsNavigation = (
    <div className="flex">
      {tabs.map((t, i) => (
        <button
          key={i}
          className={classnames(
            'px-10 py-3 border-r-4 border-white transition-colors duration-200 rounded-md rounded-b-none',
            {
              'bg-brand-lighterBlue text-brand-lightBlue':
                currentTabIndex !== i,
              'bg-brand-lightBlue text-white': currentTabIndex === i,
            }
          )}
          onClick={() => setCurrentTabIndex(i)}
        >
          {t.name}
        </button>
      ))}
    </div>
  );

  // body part
  const TabsBody = (
    <div className="">
      <SwitchTransition>
        <CSSTransition
          key={currentTabIndex}
          mode="out-in"
          classNames="fade"
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
        >
          <Tab
            next={next}
            previous={previous}
            setActivation={setActivation}
            activation={activation}
            isEditing={isEditing}
            loading={loading}
          />
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
  return (
    <div className="">
      {/* tabs links  */}
      {!hideNav && TabsNavigation}
      {/* tabs  */}
      {TabsBody}
    </div>
  );
};

export default Tabs;
