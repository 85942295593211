import React, { useState, useEffect } from 'react';
import { fetchGames, deleteGame, clearGamesData } from 'state/actions/games';
import { fetchMarks } from 'state/actions/marks';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import paths from 'pages/Router/paths';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';
import transformArrayToObject from 'utils/transformArrayToObject';

const Games = () => {
  const { gamesList, error, loading, deleted, marksList } = useSelector(
    (state) => ({
      success: state.games.success,
      gamesList: state.games.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.games.error,
      loading: state.games.loading,
      deleted: state.games.deleted,
      pageCount: state.games.pageCount,
      marksList: state.marks.data,
    }),
    shallowEqual
  );

  const [search, setSearch] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    gameId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();
  const [marksIndex, setmarksIndex] = useState(null);

  useEffect(() => {
    dispatch(fetchGames());
    dispatch(fetchMarks());
    return () => dispatch(clearGamesData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        gameId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearGamesData());
    }
  }, [deleted, loading]);

  useEffect(() => {
    setmarksIndex(transformArrayToObject(marksList));
  }, [marksList]);

  const getCriteriaName = (id) => {
    try {
      return marksIndex[`${id}`].name;
    } catch (e) {
      return 'id invalide';
    }
  };

  const onRemoveButtonClickHandler = (gameId) => {
    setDeleteModal((prevState) => ({
      gameId,
      isOpen: !prevState.isOpen,
    }));
  };
  const onCloseModalHandler = () => {
    setDeleteModal({ gameId: null, isOpen: false });
  };
  const onDeleteGameHandler = () => {
    dispatch(deleteGame(deleteModal.gameId));
  };

  const deleteMessage = useFormatMessage('Games.delete');
  const confirmMessage = useFormatMessage('Games.confirm');
  const permDeleteMessage = useFormatMessage('Games.permDelete');
  const cancelMessage = useFormatMessage('Games.cancel');

  const columns = [
    {
      Header: 'ID',
      accessor: 'idNum',
    },
    {
      Header: useFormatMessage('Games.table.name'),
      accessor: 'name',
    },
    {
      Header: 'Image',
      accessor: 'imageLink',
      Cell({ row }) {
        return (
          <div className="flex justify-center">
            <div className="w-10 h-10 bg-gray-400 rounded-full">
              <img
                className="w-10 h-10 bg-gray-400 rounded-full"
                src={row.original.imageUrl}
                alt=""
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Marques',
      accessor: 'marks',
      Cell({
        row: {
          original: { marks },
        },
      }) {
        if (marks && marksIndex) {
          return (
            <div className="flex flex-wrap justify-center">
              {marks.map((markId) => (
                <span className="green-pill" key={markId}>
                  {getCriteriaName(markId)}
                </span>
              ))}
            </div>
          );
        } else {
          return <span>N/A</span>;
        }
      },
    },
    {
      Header: 'Active',
      accessor: 'disabled',
      Cell({ row }) {
        if (!row.original.disabled) {
          return (
            <span className="icon text-brand-green">
              <i className="mdi mdi-check"></i>
            </span>
          );
        } else {
          return (
            <span className="icon text-brand-red">
              <i className="mdi mdi-close"></i>
            </span>
          );
        }
      },
    },
    {
      Header: useFormatMessage('Games.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            to={`/games/${row.original.id}`}
            isIconOnly
            text={useFormatMessage('Games.table.action.edit')}
            icon="mdi-pencil"
            color="blue"
            size="small"
          />
          <Button
            isIconOnly
            className="mx-2"
            text={useFormatMessage('Games.table.action.delete')}
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];

  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(gamesList);

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteGameHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title={useFormatMessage('Games.title')} to={paths.ADD_GAME} />
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Games;
