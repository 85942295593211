import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const options = {
  tooltips: {
    enabled: false,
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let sum = 0;
        const dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map((data) => {
          sum += data;
        });
        const percentage = ` ${ctx.chart.data.labels[ctx.dataIndex]} :  ${(
          (value * 100) /
          sum
        ).toFixed(2)}%`;
        return percentage;
      },
      color: 'black',
    },
  },
};
const PieChart = (props) => (
  <>
    <Pie data={props.data} plugins={[ChartDataLabels]} options={options} />
  </>
);

export default PieChart;
