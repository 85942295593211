/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearKpisData } from 'state/actions/kpis';
import { useParams } from 'react-router-dom';
import Table from 'components/Table';
import { fetchProducts } from 'state/actions/products';
import { fetchActivationPositions } from 'state/actions/activations';
import { fetchContacts } from 'state/actions/contacts';
import { isArray } from '@amcharts/amcharts4/core';
import { CSVLink } from 'react-csv';
import FilterSearch from 'components/Common/FilterSearch';
import DateRange from 'components/Common/DateRange';
import useFilterSearch from 'hooks/useFilterSearch';
import { useFormatMessage, useFormatDate } from 'hooks';
import Button from 'components/Common/Button';

const CanceledPositions = () => {
  const { actId } = useParams();
  const {
    actPositions,
    loading,
    productsList,
    productsLoading,
    contacts,
    contactsLoading,
  } = useSelector(
    (state) => ({
      actPositions: state.activations.actPositions,
      productsList: state.products.data,
      productsLoading: state.products.loading,
      contacts: state.contacts.data,
      contactsLoading: state.contacts.loading,
      error: state.kpis.error,
      loading: state.activations.loading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(),
    periodEnd: new Date(),
  });
  const [groupBy, setGroupBy] = useState({ label: 'Nom', value: 'name' });
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    dispatch(fetchActivationPositions({ actId }));
    dispatch(fetchProducts());
    dispatch(fetchContacts(actId));
    return () => dispatch(clearKpisData());
  }, [dispatch]);

  const marks = [
    'MARLBORO',
    'WINSTON',
    'CAMEL',
    'MQS',
    'GAULOISE',
    'MONTE CARLO',
    'CHESTERFIELD',
    'ROTHMANS',
    'MARQUISE',
    'L&M',
    'VICEROY',
  ];

  const contactsGroupBy = (posId) => {
    const nContacs = contacts.filter((c) => c.idPosition === posId);
    const age1 = nContacs.filter((c) => c.age === 'Entre 18 – 24 ans');
    const age2 = nContacs.filter((c) => c.age === 'Entre 24 – 35 ans');
    const age3 = nContacs.filter((c) => c.age === '35 et plus');
    const s_age1 = age1.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const s_age2 = age2.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const s_age3 = age3.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const multimarque = nContacs.filter((c) => c.isMultiBrands);
    const s_multimarque = multimarque.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const n_refus = nContacs.reduce((acc, c) => {
      const ref = c.brands.every((b) => b.selectedPack === 0);
      if (ref) {
        acc = acc + 1;
      }
      return acc;
    }, 0);
    const n_gouloise_ff = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === '3gkBxhfkICJb3wSaYaIO'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const n_gouloise_light = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === 'PX2brvhcsmURoBE1IiPZ'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const n_gouloise_red_mix = nContacs.reduce((acc, c) => {
      const ref = c.brands.find(
        (b) => b.referenceDocId === 'JInFNEeIfapF6NiTz1qp'
      );
      if (ref) return acc + ref.selectedPack;
      return acc;
    }, 0);
    const autres = nContacs.filter(
      (c) =>
        !c.isMultiBrands &&
        marks.every((m) => !c.references.find((r) => r && r.includes(m)))
    );
    const s_autres = autres.filter(
      (c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0
    );
    const refs = {};
    marks.forEach((m) => {
      refs[m.toLowerCase().replace(' ', '_')] = nContacs.filter(
        (c) =>
          !c.isMultiBrands &&
          c.references.findIndex((r) => r && r.includes(m)) >= 0
      );
    });
    const srefs = {};
    marks.forEach((m) => {
      srefs[`s_${m.toLowerCase().replace(' ', '_')}`] = refs[
        m.toLowerCase().replace(' ', '_')
      ].filter((c) => c.brands.findIndex((b) => b.selectedPack > 0) >= 0);
    });
    const response = {
      age1: age1.length,
      age2: age2.length,
      age3: age3.length,
      s_age1: s_age1.length,
      s_age2: s_age2.length,
      s_age3: s_age3.length,
      multimarque: multimarque.length,
      s_multimarque: s_multimarque.length,
      n_refus,
      n_gouloise_ff,
      n_gouloise_light,
      n_gouloise_red_mix,
      autres: autres.length,
      s_autres: s_autres.length,
    };
    Object.keys(refs).forEach((r) => {
      response[r] = refs[r].length;
    });
    Object.keys(srefs).forEach((sr) => {
      response[sr] = srefs[sr].length;
    });
    return response;
  };

  useEffect(() => {
    if (!loading && actPositions.positions) {
      const posFormat = actPositions.positions
        .filter(
          (pos) =>
            (pos.positionBackup && pos.type === 'INITIAL') ||
            (pos.positionBackup === '' && pos.type === 'INITIAL')
        )
        .map((pos) => {
          const posAgency = actPositions.agencies.find(
            (ag) => ag.id === pos.agencyId
          );
          const backupPos =
            pos.positionBackup !== ''
              ? actPositions.positions.find((p) => p.id === pos.positionBackup)
              : null;
          const setBackPos =
            pos.status === 'CANCELED' && backupPos ? backupPos : null;
          const nPos = {
            date: new Date(pos.date),
            agency: posAgency ? (posAgency.agency || {}).name : '-----',
            init_licence: pos.position.matricule,
            init_status:
              pos.status !== 'INITIAL'
                ? pos.status === 'VALIDATED'
                  ? 'ACTIVEE'
                  : 'ANNULEE'
                : '----',
            back_licence: backupPos ? backupPos.position.matricule : '----',
            back_status: backupPos
              ? backupPos.status !== 'INITIAL'
                ? backupPos.status === 'VALIDATED'
                  ? 'ACTIVEE'
                  : 'ANNULEE'
                : '----'
              : '-----',
            idNum: (setBackPos || pos).animator.idNum,
            startHour: (setBackPos || pos).animator.startHour,
            connextionTime: (setBackPos || pos).animator.connextionTime,
            region:
              (setBackPos || pos).position.region === ''
                ? '-----'
                : pos.position.region,
            zone: (setBackPos || pos).position.zone,
            totalContacts: (setBackPos || pos).totalContacts,
            products: (setBackPos || pos).products,
            totalSuccessContacts: (setBackPos || pos).totalSuccessContacts,
            ...contactsGroupBy((setBackPos || pos).id),
          };
          productsList.forEach((p) => {
            nPos[p.name] =
              (
                (setBackPos || pos).products.find((actp) => actp.id === p.id) ||
                {}
              ).totalProducts || 0;
          });
          return nPos;
        });
      console.log('posFormat', posFormat);
      setPositions(posFormat);
    }
  }, [loading, actPositions, groupBy]);
  const data = [
    {
      city: 'casa',
      licence: 'Position 13222401',
      region: 'Region Ouest',
      zone: 'Zone Sbata',
      secteur: 'Hay Mohammadi',
      localization: 'Bd NIL N°201 CITE JAMAA',
      citybackup: 'CASABLANCA',
      licenceBackup: 'Position 13222274',
      regionBackup: 'Region Ouest',
      zoneBackup: 'Zone Sbata',
      secteurBackup: 'Hay Mohammadi',
      localizationBackup: '----',
    },
    {
      city: 'AGADIR',
      licence: 'Position 13200012',
      region: 'Region Sud',
      zone: 'Zone Agadir Souss',
      secteur: 'Agadir ',
      localization: 'BD HASSAN II AGADIR',
      citybackup: 'AGADIR',
      licenceBackup: '13200018',
      regionBackup: 'Region Sud ',
      zoneBackup: 'Zone Agadir Souss ',
      secteurBackup: 'Agadir Ouest',
      localizationBackup: 'IM 5 RESIDENCE ERRACHAD AGADIR',
    },
    {
      city: 'Bensergaou',
      licence: 'Position 13200097',
      region: 'Region Sud',
      zone: 'Zone Agadir Souss',
      secteur: 'Agadir ',
      localization: 'RUE DE MEKNESS Q.I AGADIR N° 117',
      citybackup: 'Dchira',
      licenceBackup: 'Position 13200100',
      regionBackup: 'Region Sud',
      zoneBackup: 'Zone Agadir Souss',
      secteurBackup: 'Agadir ',
      localizationBackup: 'PLACE SALAM Imm BAKRIM Q.I AGADIR',
    },
    {
      city: 'Tine mansour',
      licence: 'Position 13200503 ',
      region: 'Region Sud',
      zone: 'Zone Agadir Sahara',
      secteur: 'Tiznit',
      localization: 'SK LARBAA AIT BOUTAYEB CNE INCHADEN',
      citybackup: 'Massa',
      licenceBackup: 'Position 13200512 ',
      regionBackup: 'Region ',
      zoneBackup: 'Zone ',
      secteurBackup: 'Tiznit',
      localizationBackup: 'DOUR EL MERS MASSA',
    },
    {
      city: 'Bensergaou',
      licence: '1602 ',
      region: 'Region ',
      zone: 'Zone Agadir Sahara',
      secteur: 'Inzegane',
      localization: '1602 PERGOLA DCHEIRA INEZGANE',
      citybackup: 'Dchira',
      licenceBackup: 'Position 13200602',
      regionBackup: 'Region Sud',
      zoneBackup: 'Zone Agadir Sahara  ',
      secteurBackup: 'Inzegane',
      localizationBackup: '1602 PERGOLA DCHEIRA INEZGANE',
    },
    {
      city: 'KLEAA',
      licence: 'Position 13200947',
      region: 'Region Sud',
      zone: 'Zone Agadir Sahara ',
      secteur: 'Chtouka',
      localization: 'DR LAARAB KOLEAA',
      citybackup: 'KLEAA',
      licenceBackup: 'Position 13200953',
      regionBackup: 'Region',
      zoneBackup: 'Zone Agadir Sahara',
      secteurBackup: 'Chtouka',
      localizationBackup: 'N°2632 CENTRE EL AIN EL KLIAA AIT M',
    },
    {
      city: 'OULAD TEIMA',
      licence: 'Position 13201032',
      region: 'Region Sud',
      zone: 'Zone Agadir Souss',
      secteur: 'Taroudant',
      localization: '----',
      citybackup: 'OULAD TEIMA',
      licenceBackup: 'Position 13201037',
      regionBackup: 'Region Sud',
      zoneBackup: 'Zone Agadir Souss',
      secteurBackup: 'Taroudant',
      localizationBackup: 'N° 596BD MED V LOT MBARKA OULED TE',
    },
    {
      city: 'Temsia',
      licence: 'Position 13201090',
      region: 'Region Sud',
      zone: 'Zone Agadir Souss',
      secteur: 'Taroudant',
      localization: 'Dr TAMSIA',
      citybackup: 'Temsia',
      licenceBackup: 'Position 13201090',
      regionBackup: 'Region Sud',
      zoneBackup: 'ZONE Agadir Souss',
      secteurBackup: 'Taroudant',
      localizationBackup: '----',
    },
    {
      city: 'FES',
      licence: 'Position 13201899',
      region: 'Region Nord',
      zone: 'Zone Fes',
      secteur: 'Fes Ouest',
      localization: '----',
      citybackup: 'FES',
      licenceBackup: 'Position 13201877',
      regionBackup: 'Region Nord',
      zoneBackup: 'Zone Fes',
      secteurBackup: 'Taounate',
      localizationBackup: '----',
    },
    {
      city: 'Fes',
      licence: 'Position 13202377',
      region: 'Region Nord',
      zone: 'Zone Fes',
      secteur: 'Fes Medina',
      localization: 'N°19 NEKHALINE HADDADINE FES MEDINA',
      citybackup: 'Fes',
      licenceBackup: 'Position 13202387',
      regionBackup: 'Region Nord',
      zoneBackup: 'Zone Fes',
      secteurBackup: 'Fes Ouest',
      localizationBackup: 'SUIKAT BEN SAFI',
    },
    {
      city: 'Essaouira',
      licence: 'Position 13207346',
      region: 'Region Sud',
      zone: 'Zone Agadir Souss',
      secteur: 'Essaouira',
      localization: 'AV.OKBA IBN NAFAA ESSAOUIRA',
      citybackup: 'ESSAOUIRA',
      licenceBackup: 'Position 13207350',
      regionBackup: 'Region Sud',
      zoneBackup: 'Zone Agadir Souss',
      secteurBackup: 'Essaouira',
      localizationBackup: '16 BD MLY YOUSSEF ESSAOUIRA',
    },
    {
      city: 'Rabat',
      licence: 'Position 13210723',
      region: '---',
      zone: 'Zone Rabat Salé',
      secteur: 'Zaers',
      localization: 'N°10 GROUPE ZAITOUNE BLOC 14 TAKKAD',
      citybackup: 'Rabat',
      licenceBackup: 'Position 13210727',
      regionBackup: 'Region Ouest',
      zoneBackup: 'Zone Rabat Salé',
      secteurBackup: 'Agdal',
      localizationBackup: 'N°54 AV OKBA AGDAL',
    },
  ];

  const columns = [
    {
      Header: 'Positions annulées',
      columns: [
        {
          Header: 'Licence',
          accessor: 'licence',
        },
        {
          Header: 'Ville',
          accessor: 'city',
        },
        {
          Header: 'Région',
          accessor: 'region',
        },
        {
          Header: 'Zone',
          accessor: 'zone',
        },
        {
          Header: 'Secteur',
          accessor: 'secteur',
        },
        {
          Header: 'Localization',
          accessor: 'localization',
        },
      ],
    },
    {
      Header: 'position Backup',
      columns: [
        {
          Header: 'Licence',
          accessor: 'licenceBackup',
        },
        {
          Header: 'Ville',
          accessor: 'citybackup',
        },
        {
          Header: 'Région',
          accessor: 'regionBackup',
        },
        {
          Header: 'Zone',
          accessor: 'zoneBackup',
        },
        {
          Header: 'Secteur',
          accessor: 'secteurBackup',
        },
        {
          Header: 'Localization',
          accessor: 'localizationBackup',
        },
      ],
    },
  ];

  //   columns = [
  //     ...columns,
  //     ...(!productsLoading
  //       ? productsList.map((p) => ({
  //           Header: p.name,
  //           accessor: p.name,
  //         }))
  //       : []),
  //   ];
  const { result, searchProps, selectProps } = useFilterSearch(positions);

  return (
    <div className="page-padding" style={{ overflow: 'auto' }}>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="text-brand-darkBlue text-3xl font-semibold"></h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="flex">
          <DateRange
            label={useFormatMessage('ActivationsDetails.card.period')}
            isForm
            fromText={useFormatMessage('Activation.informationsTab.from')}
            fromProps={{
              name: 'periodStart',
              setState: setDateSearch,
              date: new Date(dateSearch.periodStart),
            }}
            toText={useFormatMessage('Activation.informationsTab.to')}
            toProps={{
              name: 'periodEnd',
              setState: setDateSearch,
              date: new Date(dateSearch.periodEnd),
            }}
          />
          <Button
            style={{ height: 'max-content', marginTop: '38px' }}
            size="small"
            color="blue"
            text="Afficher Reporting"
            // onClick={searchReporting}
          />
          <CSVLink
            style={{
              padding: '3px',
              height: '30px',
              marginTop: '38px',
              color: 'white',
              fontWeight: 'bold',
              marginLeft: '10px',
              borderRadius: '55px',
              background: '#2699fb',
            }}
            size="small"
            data={data}
          >
            Télécharger Reporting
          </CSVLink>
        </div>
      </section>
      <div className="flex">
        {/* left */}
        <div className="flex flex-col p-3">
          <div className="w-full reporting-table ">
            <Table
              // style={{ width: '1200px' }}
              tailwind="w-64 bg-black"
              columns={columns}
              data={data || []}
              loading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledPositions;
