/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { fetchMarks } from 'state/actions/marks';
import NormalCheckbox from 'components/Common/NormalCheckbox';
import SelectField from 'components/Common/SelectField';

import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import FileInput from 'components/Common/FileInput';

import { gamesCleanUp } from 'state/actions/games';

const GameForm = ({ gameData, action }) => {
  const dispatch = useDispatch();

  const { loading, marksList, marksLoading } = useSelector(
    (state) => ({
      loading: state.games.loading,
      marksList: state.marks.data,
      marksLoading: state.marks.loading,
    }),
    shallowEqual
  );
  const [game, setGame] = useState(gameData);

  useEffect(() => {
    dispatch(fetchMarks());
    return () => dispatch(gamesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setGame);

  const canSubmit = game.name;

  const selectOptions = marksList.map((mark) => ({
    value: mark.id,
    label: mark.displayName,
  }));

  const onChangeSelect = (opts) => {
    const marks = (opts || []).map((opt) => opt.value);
    setGame((prevState) => ({ ...prevState, marks: marks || '' }));
  };

  const selectValue = (marks) => {
    if (!marksLoading)
      return marks.map((perId) => {
        const p = marksList.find((per) => per.id === perId);
        if (p) return { value: p.id, label: p.displayName };
      });
  };

  // Start change handlers
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (event.target) {
      setGame((prevState) => ({
        ...prevState,
        [name]: file,
        imageUrl: name === 'image' ? '' : prevState.imageUrl,
      }));
    }
  };

  const imagePreviewUrl = !game.imageUrl
    ? game.image && URL.createObjectURL(game.image)
    : game.imageUrl;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(game));
  };

  return (
    <>
      {/* logo preview  */}
      {imagePreviewUrl && (
        <div className="w-full flex-none pb-4">
          <div className="is-user-avatar image has-max-width is-aligned-center">
            <img
              className="user-avatar"
              src={imagePreviewUrl}
              alt="User profile logo preview"
            />
          </div>
        </div>
      )}
      <form
        className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
        onSubmit={onSubmitHandler}
      >
        <div className=" lg:max-w-sm lg:w-1/2">
          <NormalInput
            label="ID"
            id="idNum"
            type="text"
            required
            disabled
            name="idNum"
            value={game.idNum}
          />
        </div>
        <div className=" lg:max-w-sm lg:w-1/2">
          <NormalInput
            className="flex-1 max-w-sm"
            label={useFormatMessage('Game.gameForm.name')}
            required
            name="name"
            value={game.name}
            onChange={onChangeHandler}
          />
        </div>
        {/* image upload  */}
        <div className=" lg:max-w-sm lg:w-1/2">
          <FileInput
            isForm
            label="Image"
            name="image"
            type="file"
            accept="image/*"
            file={game.image}
            className={`${loading && 'is-loading'}`}
            disabled={loading}
            onChange={onFileChangedHandler}
          />
        </div>
        <div className="lg:max-w-sm lg:w-1/2">
          <SelectField
            label="Marques"
            type="form"
            isLoading={marksLoading}
            isDisabled={marksLoading}
            placeholder="Selectioner"
            onChange={(value) => onChangeSelect(value)}
            isMulti
            options={selectOptions}
            value={selectValue(game.marks)}
          />
        </div>
        {/* // disable checkbox */}
        <div className="lg:pt-3 lg:max-w-sm lg:w-1/2">
          <NormalCheckbox
            className="flex-1"
            label="Disable"
            type="checkbox"
            name="disabled"
            onChange={onChangeHandler}
            checked={game.disabled}
          />
        </div>
        <Button
          className={`mt-6 ${loading && 'is-loading'}`}
          text={useFormatMessage('Game.gameForm.save')}
          icon="mdi-plus-circle"
          color="green"
          size="large"
          disabled={!canSubmit}
          type="submit"
        />
      </form>
    </>
  );
};

export default GameForm;
