import { createAction } from 'redux-act';
import { displayText } from 'utils';
/* eslint-disable camelcase */
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';

export const PARAM_VIDEOS_FETCH_DATA_INIT = createAction(
  'PARAM_VIDEOS_FETCH_DATA_INIT'
);
export const PARAM_VIDEOS_FETCH_DATA_SUCCESS = createAction(
  'PARAM_VIDEOS_FETCH_DATA_SUCCESS'
);
export const PARAM_VIDEOS_FETCH_DATA_FAIL = createAction(
  'PARAM_VIDEOS_FETCH_DATA_FAIL'
);
export const PARAM_VIDEOS_CREATE_VIDEO_INIT = createAction(
  'PARAM_VIDEOS_CREATE_VIDEO_INIT'
);
export const PARAM_VIDEOS_CREATE_VIDEO_SUCCESS = createAction(
  'PARAM_VIDEOS_CREATE_VIDEO_SUCCESS'
);
export const PARAM_VIDEOS_CREATE_VIDEO_FAIL = createAction(
  'PARAM_VIDEOS_CREATE_VIDEO_FAIL'
);
export const PARAM_VIDEOS_CLEAR_DATA = createAction('PARAM_VIDEOS_CLEAR_DATA');
export const PARAM_VIDEOS_DELETE_VIDEO_INIT = createAction(
  'PARAM_VIDEOS_DELETE_PARAM_VIDEOS_INIT'
);
export const PARAM_VIDEOS_DELETE_VIDEO_SUCCESS = createAction(
  'PARAM_VIDEOS_DELETE_VIDEO_SUCCESS'
);
export const PARAM_VIDEOS_DELETE_VIDEO_FAIL = createAction(
  'PARAM_VIDEOSS_DELETE_PARAM_VIDEOS_FAIL'
);
export const PARAM_VIDEOS_MODIFY_VIDEO_INIT = createAction(
  'PARAM_VIDEOS_MODIFY_VIDEO_INIT'
);
export const PARAM_VIDEOS_MODIFY_VIDEO_SUCCESS = createAction(
  'PARAM_VIDEOS_MODIFY_VIDEO_SUCCESS'
);
export const PARAM_VIDEOS_MODIFY_VIDEO_FAIL = createAction(
  'PARAM_VIDEOS_MODIFY_VIDEO_FAIL'
);
export const PARAM_VIDEOS_CLEAN_UP = createAction('PARAM_VIDEOS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const c = new ControllerWithFileUpload(
  'paramVideos',
  'paramVideo',
  'displayName'
);

/**
 * Action Fetch videos
 * @return dispatch
 */
export const fetchParamVideos = () =>
  c.fetch(
    PARAM_VIDEOS_FETCH_DATA_INIT,
    PARAM_VIDEOS_FETCH_DATA_SUCCESS,
    PARAM_VIDEOS_FETCH_DATA_FAIL
  );

/**
 * Action Create new video
 * @param {string} name Name of video
 * @param {string} link link the video
 * @return dispatch
 */
export const createParamVideo = ({ name, file }) => {
  const displayName = displayText(name);
  return c.create(
    { name, displayName },
    PARAM_VIDEOS_CREATE_VIDEO_INIT,
    PARAM_VIDEOS_CREATE_VIDEO_SUCCESS,
    PARAM_VIDEOS_CREATE_VIDEO_FAIL,
    file
  );
};

/**
 * Action Delete video
 * @param {string} id ID of video
 * @return dispatch
 */
export const deleteParamVideo = (id, link) => {
  return c.destroy(
    id,
    PARAM_VIDEOS_DELETE_VIDEO_INIT,
    PARAM_VIDEOS_DELETE_VIDEO_FAIL,
    PARAM_VIDEOS_DELETE_VIDEO_SUCCESS,
    link
  );
};

/**
 * Action Update video
 * @param {string} name Name of video
 * @return dispatch
 */
export const modifyParamVideo = ({ name, id, link, file }) => {
  const displayName = displayText(name);
  return c.update(
    id,
    { name, displayName, link },
    PARAM_VIDEOS_MODIFY_VIDEO_INIT,
    PARAM_VIDEOS_MODIFY_VIDEO_SUCCESS,
    PARAM_VIDEOS_MODIFY_VIDEO_FAIL,
    file
  );
};

export const paramVideosCleanUp = () => (dispatch) =>
  dispatch(PARAM_VIDEOS_CLEAN_UP());

export const clearParamVideosData = () => {
  return (dispatch) => {
    dispatch(PARAM_VIDEOS_CLEAR_DATA());
  };
};
