import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import TextArea from 'components/Common/TextArea';
import Button from 'components/Common/Button';
import { useFormatMessage, useChangeHandler } from 'hooks';
import { fetchActCategories } from 'state/actions/actCategories';
import DateRange from 'components/Common/DateRange';
import { fetchPosCategories } from 'state/actions/posCategories';
// import DatePickerStyled from 'components/DatePicker';
import { fetchMarks } from 'state/actions/marks';
import { fetchGames } from 'state/actions/games';

const Informations = ({ next, setActivation, activation }) => {
  const dispatch = useDispatch();

  const {
    actCategories,
    actCategoriesLoading,
    posCategories,
    posCategoriesLoading,
    marks,
    marksLoading,
    gamesList,
    gamesLoading,
  } = useSelector(
    (state) => ({
      loading: state.activations.loading,
      actCategories: state.actCategories.data,
      actCategoriesLoading: state.actCategories.loading,
      posCategories: state.posCategories.data,
      posCategoriesLoading: state.posCategories.loading,
      marks: state.marks.data,
      marksLoading: state.marks.loading,
      gamesList: state.games.data,
      gamesLoading: state.games.loading,
    }),
    shallowEqual
  );

  const [marksOptions, setMarksOptions] = useState([]);
  /**
   * @param {string} catId on select category get this category makrs
   * then set those marks to maks options
   */
  const setMarks = (catId) => {
    if (!actCategoriesLoading) {
      const cat = actCategories.find((c) => c.id === catId);
      if (!marksLoading && cat) {
        const fmarks = [...marks.filter((mrk) => cat.marks.includes(mrk.id))];
        setMarksOptions([
          ...fmarks.map((m) => ({ value: m.id, label: m.name })),
        ]);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchMarks());
    dispatch(fetchGames());
    dispatch(fetchActCategories());
    dispatch(fetchPosCategories());
  }, [dispatch]);

  useEffect(() => {
    setMarks(activation.categoryId);
  }, [activation]);

  const gameSelectOptions = gamesList.map((game) => ({
    value: game.id,
    label: game.displayName,
  }));

  const onGameChangeSelect = (opts) => {
    const games = (opts || []).map((opt) => opt.value);
    setActivation((prevState) => ({ ...prevState, games: games || '' }));
  };

  const selectGameValue = (games) => {
    if (!gamesLoading)
      return games.map((perId) => {
        const p = gamesList.find((per) => per.id === perId);
        if (p) return { value: p.id, label: p.displayName };
      });
  };

  const onChangeHandler = useChangeHandler(setActivation);
  /**
   * get variables from activation and check validation
   * of values
   */
  const {
    name,
    agencyPrice,
    contactObjective,
    description,
    marks: marques,
    region,
    categoryId,
    activationType,
    games,
  } = activation;

  const canSubmit =
    name &&
    agencyPrice > 0 &&
    contactObjective > 0 &&
    description &&
    marques.length &&
    games.length &&
    region &&
    categoryId &&
    activationType;

  const selectOptions = actCategories.map((actCat) => ({
    value: actCat.id,
    label: actCat.name,
  }));
  const selectPosCategoriesOptions = posCategories.map((posCat) => ({
    value: posCat.id,
    label: posCat.name,
  }));

  /**
   * On change select fill the activation variable
   * @param {string} type get the select name
   * @param {object} opt get value of select
   */
  const onChangeSelect = (type, opt) => {
    if (type === 'category') {
      setActivation((act) => ({ ...act, categoryId: opt.value || '' }));
    } else if (type === 'activationType') {
      setActivation((act) => ({ ...act, activationType: opt.value || '' }));
    } else if (type === 'region') {
      setActivation((act) => ({ ...act, region: opt.value || '' }));
    } else {
      const values = opt ? opt.map((v) => v.value) : [];
      setActivation((act) => ({ ...act, marks: values }));
    }
  };
  /**
   * On select show the label of option
   * @param {string} type get the select name
   * @param {object} opt get value of select
   */
  const selectValue = (type, opId) => {
    if (type === 'category') {
      if (!actCategoriesLoading) {
        const cat = actCategories.find((c) => c.id === opId);
        if (cat) return { value: cat.id, label: cat.name };
      }
    } else if (type === 'activationType') {
      if (!posCategoriesLoading) {
        const cat = posCategories.find((c) => c.id === opId);
        if (cat) return { value: cat.id, label: cat.name };
      }
    } else if (type === 'marks' && !marksLoading) {
      return opId.map((op) => {
        const mrk = marks.find((m) => m.id === op || m.id === op.markId);
        if (mrk) return { value: mrk.id, label: mrk.name };
      });
    }
  };
  /**
   * Go to next assign products
   */
  const goProduct = (e) => {
    e.preventDefault();
    next();
  };

  return (
    <div className="">
      <h1 className="text-2xl font-semibold text-brand-darkBlue py-3">
        {useFormatMessage('Activation.informationsTab.title')}
      </h1>
      <form className="flex flex-wrap">
        <div className="w-1/2 flex flex-wrap">
          <div className="w-1/2">
            <NormalInput
              label="Nom"
              onChange={onChangeHandler}
              type={useFormatMessage('Activation.informationsTab.name')}
              required
              name="name"
              value={activation.name}
            />
          </div>
          <div className="w-1/2">
            <NormalInput
              label="Prix Agence"
              type="number"
              onChange={onChangeHandler}
              required
              name="agencyPrice"
              value={activation.agencyPrice}
            />
          </div>
          <div className="w-1/2">
            <NormalInput
              label="Objectif (Contact par POS)"
              onChange={onChangeHandler}
              placeholder="ex: 50"
              type="number"
              required
              name="contactObjective"
              value={activation.contactObjective}
            />
          </div>
          <div className="w-1/2">
            <SelectField
              type="form"
              label="Nature activation"
              isLoading={posCategoriesLoading}
              isDisabled={posCategoriesLoading}
              options={selectPosCategoriesOptions}
              onChange={(value) => onChangeSelect('activationType', value)}
              value={selectValue('activationType', activation.activationType)}
            />
          </div>
          <div className="w-1/2">
            <SelectField
              type="form"
              label={useFormatMessage('Activation.informationsTab.typology')}
              isLoading={actCategoriesLoading}
              isDisabled={actCategoriesLoading}
              onChange={(value) => onChangeSelect('category', value)}
              options={selectOptions}
              value={selectValue('category', activation.categoryId)}
            />
          </div>
          <div className="flex-1">
            <SelectField
              type="form"
              label={useFormatMessage('Activation.informationsTab.brands')}
              isMulti
              isLoading={marksLoading}
              isDisabled={marksLoading}
              onChange={(value) => onChangeSelect('mark', value)}
              options={marksOptions}
              value={selectValue('mark', activation.marks)}
            />
          </div>
          <div className="w-1/2">
            <SelectField
              type="form"
              label="Région"
              options={[
                { label: 'Tout les régions', value: 'Tout les régions' },
                { label: 'Nord', value: 'Nord' },
                { label: 'Sud', value: 'Sud' },
                { label: 'Ouest', value: 'Ouest' },
              ]}
              onChange={(value) => onChangeSelect('region', value)}
              value={selectValue('region', activation.region)}
            />
          </div>
          <div className="flex-1">
            <SelectField
              type="form"
              label="Jeux"
              isMulti
              isLoading={gamesLoading}
              isDisabled={gamesLoading}
              onChange={(value) => onGameChangeSelect(value)}
              options={gameSelectOptions}
              value={selectGameValue(activation.games)}
            />
          </div>
          <div className="w-full">
            <DateRange
              label={useFormatMessage('ActivationsDetails.card.period')}
              isForm
              fromText={useFormatMessage('Activation.informationsTab.from')}
              fromProps={{
                name: 'periodStart',
                setState: setActivation,
                date: new Date(activation.periodStart),
              }}
              toText={useFormatMessage('Activation.informationsTab.to')}
              toProps={{
                name: 'periodEnd',
                setState: setActivation,
                date: new Date(activation.periodEnd),
              }}
            />
          </div>
        </div>
        <div className="flex-auto">
          <TextArea
            rows="8"
            label={useFormatMessage('Activation.informationsTab.description')}
            onChange={onChangeHandler}
            type="text"
            required
            name="description"
            value={activation.description}
          />
        </div>
        <div className="w-full py-8 px-4 flex justify-between">
          <Button
            className=""
            size="medium"
            icon="mdi-chevron-right"
            color="blue"
            text={useFormatMessage('Activation.informationsTab.next')}
            onClick={goProduct}
            disabled={!canSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default Informations;
