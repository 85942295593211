export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2',
  // permissions
  PERMISSIONS: '/permissions',
  ADD_PERMISSION: '/permissions/new',
  MODIFY_PERMISSION: '/permissions/:id',
  // roles
  ROLES: '/roles',
  ADD_ROLE: '/roles/new',
  MODIFY_ROLE: '/roles/:id',
  // positions
  POSITIONS: '/positions',
  POSITIONS_ALL: '/positions/all',
  ADD_POSITION: '/positions/new',
  POSITION_DETAILS: '/positions/:id/details',
  MODIFY_POSITION: '/positions/:id',
  POSITIONS_CATEGORIES: '/positions/categories',
  ADD_POSITIONS_CATEGORY: '/positions/categories/new',
  MODIFY_POSITIONS_CATEGORY: '/positions/categories/:id',
  // produits
  PRODUCTS: '/produits',
  ADD_PRODUCT: '/produits/new',
  MODIFY_PRODUCT: '/produits/:id',
  PRODUCTS_CATEGORIES: '/produits/categories',
  ADD_PRODUCTS_CATEGORY: '/produits/categories/new',
  MODIFY_PRODUCTS_CATEGORY: '/produits/categories/:id',
  // activations
  ACTIVATIONS: '/activations',
  ADD_ACTIVATION: '/activations/new',
  MODIFY_ACTIVATION: '/activations/:id',
  ADD_ACTIVATION_PLAN: '/activations/plan/new/:id',
  MODIFY_ACTIVATION_PLAN: '/activations/plan/:id',
  ACTIVATION_DETAILS: '/activations/:id/details',
  ACTIVATION_DETAILS_AGENCY_DEATILS: '/activations/:activationId/:agencyId/details/agency',
  ACTIVATIONS_PARTICIPANTS: '/activations/participants',
  ACTIVATIONS_CATEGORIES: '/activations/categories',
  ACTIVATIONS_VIDEOS: '/activations/videos',
  ACTIVATIONS_HISTORY: '/activations/history',
  ACTIVATIONS_REPORTING: '/activation/reporting/:actId',
  ACTIVATIONS_GRAPHS: '/activation/graphs/:actId',
  ADD_ACTIVATIONS_VIDEO: '/activations/videos/new',
  MODIFY_ACTIVATIONS_VIDEO: '/activations/videos/:id',
  ADD_ACTIVATIONS_CATEGORY: '/activations/categories/new',
  MODIFY_ACTIVATIONS_CATEGORY: '/activations/categories/:id',
  MEMBER_DETAILS: '/member/:activationId/:memberId/:type',

  // games
  GAMES: '/games',
  ADD_GAME: '/games/new',
  MODIFY_GAME: '/games/:id',

  // awards
  AWARDS: '/awards',
  ADD_AWARD: '/awards/new',
  MODIFY_AWARD: '/awards/:id',

  // marks
  MARKS: '/marks',
  ADD_MARK: '/marks/new',
  MODIFY_MARK: '/marks/:id',

  // marks
  REFERENCES: '/references',
  ADD_REFERENCE: '/references/new',
  MODIFY_REFERENCE: '/references/:id',

  // contacts
  ADD_CONTACT: '/contacts/new',
  CONTACTS: '/activation/contacts/:actId',

  // equipes
  TEAMS: '/teams',
  ADD_TEAM: '/teams/new',
  MODIFY_TEAM: '/teams/:id',

  // levels
  LEVELS: '/levels',
  ADD_LEVEL: '/levels/new',
  MODIFY_LEVEL: '/levels/:id',

  // parameter
  PARAMETERS: '/parameters',
  JEUX: '/jeux',

  // Reporting
  REPORTING: '/reporting',
  CONTACT_REPORTING: '/reporting/contacts',
  CANCELED_POSITIONS: '/reporting/CanceledPositions',
  PARTICIPANT_DATA: '/reporting/participantData',
  STOCK_AGENCY: '/reporting/stockAgency',
  DETAIL_DECALGE: '/reporting/detailDecalage',
  PERFORMANCE_POSITION: '/reporting/performancePosition',
  PERFORMANCE_SECTEUR: '/reporting/performanceSecteur',
  PERFORMANCE_ZONE: '/reporting/performanceZone',
  PERFORMANCE_Animatrices: '/reporting/performanceAnimatrices',
  PERFORMANCE_Equipes: '/reporting/performanceEquipes',


  // videos
  VIDEOS: '/videos',
  ADD_VIDEO: '/videos/Ajouter',



  // agencies
  AGENCIES: '/agencies',
  ADD_AGENCY: '/agencies/new',
  MODIFY_AGENCY: '/agencies/:id',

  // criteria
  CRITERIAS: '/criteria',
  ADD_CRITERIA: '/criteria/new',
  MODIFY_CRITERIA: '/criteria/:id',

  // multiMark
  MULTIMARKS: '/multimarks',
  ADD_MULTIMARK: '/multimarks/new',
  MODIFY_MULTIMARK: '/multimarks/:id',

  // supports
  SUPPORTS: '/supports',
  ADD_SUPPORT: '/supports/new',
  MODIFY_SUPPORT: '/supports/:id',

  // product details
  PRODUCT_DETAILS: '/productdetails/:productId/:activationId',

  // alatiges
  ALATIGES: '/alatiges',
  ADD_ALATIGE: '/alatiges/new',
  MODIFY_ALATIGE: '/alatiges/:id',

  // please dont remove this comment to generate auto Module paths
};