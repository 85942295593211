import Controller from './Controller';

export default class AgencyController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'users';
    this.item = 'user';
  }

  /**
   * Get Agency data
   * @param {string} agencyId Agency ID
   * @returns {Object} object of Agency data or false
   */
  fetchById = async (agencyId) =>
    agencyId
      ? this.getDoc(agencyId).then((userSnap) => {
          if (userSnap.exists) {
            return {
              id: userSnap.id,
              ...userSnap.data(),
            };
          }
          return null;
        })
      : null;
}
