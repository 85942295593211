/* eslint-disable camelcase */
import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import TeamController from 'controllers/TeamController';

export const TEAMS_FETCH_DATA_INIT = createAction('TEAMS_FETCH_DATA_INIT');
export const TEAMS_FETCH_DATA_SUCCESS = createAction(
  'TEAMS_FETCH_DATA_SUCCESS'
);
export const TEAMS_FETCH_DATA_FAIL = createAction('TEAMS_FETCH_DATA_FAIL');
export const TEAMS_CREATE_TEAM_INIT = createAction('TEAMS_CREATE_TEAM_INIT');
export const TEAMS_CREATE_TEAM_SUCCESS = createAction(
  'TEAMS_CREATE_TEAM_SUCCESS'
);
export const TEAMS_CREATE_TEAM_FAIL = createAction('TEAMS_CREATE_TEAM_FAIL');
export const TEAMS_CLEAR_DATA = createAction('TEAMS_CLEAR_DATA');
export const TEAMS_DELETE_TEAM_INIT = createAction('TEAMS_DELETE_TEAMS_INIT');
export const TEAMS_DELETE_TEAM_SUCCESS = createAction(
  'TEAMS_DELETE_TEAM_SUCCESS'
);
export const TEAMS_DELETE_TEAM_FAIL = createAction('TEAMSS_DELETE_TEAMS_FAIL');
export const TEAMS_MODIFY_TEAM_INIT = createAction('TEAMS_MODIFY_TEAM_INIT');
export const TEAMS_MODIFY_TEAM_SUCCESS = createAction(
  'TEAMS_MODIFY_TEAM_SUCCESS'
);
export const TEAMS_MODIFY_TEAM_FAIL = createAction('TEAMS_MODIFY_TEAM_FAIL');
export const TEAMS_CLEAN_UP = createAction('TEAMS_CLEAN_UP');

const tc = new TeamController('teams', 'team');

export const fetchTeams = () => {
  return async (dispatch, getState) => {
    const { id } = getState().auth.userData;
    dispatch(TEAMS_FETCH_DATA_INIT());
    let teams;
    try {
      teams = await tc.fetch(id);
    } catch (error) {
      toastr.error('Equipes', 'Operation a échoué');
      return dispatch(TEAMS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(TEAMS_FETCH_DATA_SUCCESS({ teams }));
  };
};

export const createTeam = ({ name, groups, teamId }) => {
  return async (dispatch, getState) => {
    const { id } = getState().auth.userData;
    dispatch(TEAMS_CREATE_TEAM_INIT());
    const { locale } = getState().preferences;
    try {
      await tc.create(id, { name, groups, teamId });
    } catch (error) {
      toastr.error('Equipe', 'la création a échoué');
      return dispatch(TEAMS_CREATE_TEAM_FAIL({ error }));
    }
    toastr.success('Equipe', 'créé avec succès');
    return dispatch(
      TEAMS_CREATE_TEAM_SUCCESS({
        team: name,
        groups,
        teamId,
        createdAt: new Date().toGMTString(),
        updatedAt: new Date().toGMTString(),
      })
    );
  };
};

export const deleteTeam = (idTeam) => {
  return async (dispatch, getState) => {
    const { id } = getState().auth.userData;

    dispatch(TEAMS_DELETE_TEAM_INIT());
    const { locale } = getState().preferences;
    try {
      await tc.delete(id, idTeam);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Equipe', 'la suppression a échoué');
      return dispatch(TEAMS_DELETE_TEAM_FAIL({ error: errorMessage }));
    }

    toastr.success('Equipe', 'Supprimé avec succès');
    return dispatch(TEAMS_DELETE_TEAM_SUCCESS({ idTeam }));
  };
};

export const modifyTeam = ({ idTeam, name, groups }) => {
  return async (dispatch, getState) => {
    const { id } = getState().auth.userData;
    dispatch(TEAMS_MODIFY_TEAM_INIT());
    const { locale } = getState().preferences;
    try {
      await tc.update(id, idTeam, { name, groups });
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Equipe', 'mise à jour a échoué');
      return dispatch(TEAMS_MODIFY_TEAM_FAIL({ error: errorMessage }));
    }
    toastr.success('Equipe', 'mis à jour avec succès');
    return dispatch(TEAMS_MODIFY_TEAM_SUCCESS({ team: idTeam }));
  };
};

export const teamsCleanUp = () => (dispatch) => dispatch(TEAMS_CLEAN_UP());

export const clearTeamsData = () => {
  return (dispatch) => {
    dispatch(TEAMS_CLEAR_DATA());
  };
};

function makeId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function exists(value, key) {
  firebase
    .firestore()
    .collection('teams')
    .where(key, '==', value)
    .get()
    .then((snap) => snap.exists);
}

export const generateTeamId = async () => {
  const teamId = makeId(5);
  return exists(teamId, 'teamId') ? generateTeamId() : teamId;
};
