import React, { useState, useEffect } from 'react';
import {
  fetchMultimarks,
  deletemMultimark,
  clearMultiMarkesData,
} from 'state/actions/multimarks';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import paths from 'pages/Router/paths';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const Multimarks = () => {
  const { multimarksList, error, loading, deleted } = useSelector(
    (state) => ({
      success: state.multimarks.success,
      multimarksList: state.multimarks.data,
      error: state.multimarks.error,
      loading: state.multimarks.loading,
      deleted: state.multimarks.deleted,
      pageCount: state.multimarks.pageCount,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    multimarkId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMultimarks());
    return () => dispatch(clearMultiMarkesData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        multimarkId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearMultiMarkesData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (multimarkId) => {
    setDeleteModal((prevState) => ({
      multimarkId,
      isOpen: !prevState.isOpen,
    }));
  };
  const onCloseModalHandler = () => {
    setDeleteModal({ multimarkId: null, isOpen: false });
  };
  const onDeleteMarkHandler = () => {
    dispatch(deletemMultimark(deleteModal.multimarkId));
  };

  const deleteMessage = useFormatMessage('Marks.delete');
  const confirmMessage = useFormatMessage('Marks.confirm');
  const permDeleteMessage = useFormatMessage('Marks.permDelete');
  const cancelMessage = useFormatMessage('Marks.cancel');

  const columns = [
    {
      Header: useFormatMessage('Multimarks.table.link'),
      accessor: 'link',
      Cell: ({ row }) => (
        <a
          href={row.original.pdfUrl}
          className="px-2 font-semibold text-brand-lightBlue"
        >
          Fichier argumentaire
        </a>
      ),
    },
    //
    {
      Header: useFormatMessage('Multimarks.table.active'),
      accessor: 'isActive',
      Cell: ({ row }) => {
        if (row.original.isActive) {
          return (
            <span className="icon text-brand-green">
              <i className="mdi mdi-check"></i>
            </span>
          );
        } else {
          return (
            <span className="icon text-brand-red">
              <i className="mdi mdi-close"></i>
            </span>
          );
        }
      },
    },
    {
      Header: useFormatMessage('Marks.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            to={`/multimarks/${row.original.id}`}
            isIconOnly
            text={useFormatMessage('Marks.table.action.edit')}
            icon="mdi-pencil"
            color="blue"
            size="small"
          />
          <Button
            isIconOnly
            className="mx-2"
            text={useFormatMessage('Marks.table.action.delete')}
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];
  // filter logic
  const { result, searchProps, selectProps } = useFilterSearch(multimarksList);

  // end filter logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteMarkHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader
        title={useFormatMessage('Multimarks.title')}
        to={paths.ADD_MULTIMARK}
      />
      <div className="page-padding">
        <div className="flex items-center px-2">
          <FilterSearch
            loading={loading}
            hideSelect
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default Multimarks;
