import PageHeader from 'components/PageHeader';
import PlanningForm from 'components/PlanningForm';
import React from 'react';
import { useParams } from 'react-router-dom';

const ActivationPlanning = () => {
  const {id} = useParams();
  return (
    <>
      <PageHeader title="Planning" hideButton />
      <div className="page-padding">
        <PlanningForm activationId={id} />
      </div>
    </>
  );
};

export default ActivationPlanning;
