import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import paths from 'pages/Router/paths';

import {
  fetchActCategories,
  deleteActCategory,
  clearActCategoriesData,
} from 'state/actions/actCategories';
import useFilterSearch from 'hooks/useFilterSearch';
import PageHeader from 'components/PageHeader';
import FilterSearch from 'components/Common/FilterSearch';
import sortByDate from 'utils/sortByDate';

const ActivationsCategories = () => {
  const [search, setSearch] = useState('');
  const [deleteModal, setDeleteModal] = useState({
    actCatId: null,
    isOpen: false,
  });
  const dispatch = useDispatch();
  const { actCategoriesList, error, loading, deleted } = useSelector(
    (state) => ({
      success: state.actCategories.success,
      actCategoriesList: state.actCategories.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.actCategories.error,
      loading: state.actCategories.loading,
      deleted: state.actCategories.deleted,
    }),
    shallowEqual
  );

  // Effect handlers
  useEffect(() => {
    dispatch(fetchActCategories());
    return () => dispatch(clearActCategoriesData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        actCatId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  // Event handlers
  const onRemoveButtonClickHandler = (actCatId) => {
    setDeleteModal((prevState) => ({
      actCatId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ actCatId: null, isOpen: false });
  };

  const onDeleteActCategoryHandler = () => {
    dispatch(deleteActCategory(deleteModal.actCatId));
  };

  const deleteMessage = useFormatMessage('ActivationsCategories.delete');
  const confirmMessage = useFormatMessage('ActivationsCategories.confirm');
  const permDeleteMessage = useFormatMessage(
    'ActivationsCategories.permDelete'
  );
  const cancelMessage = useFormatMessage('ActivationsCategories.cancel');

  const columns = [
    {
      Header: useFormatMessage('ActivationsCategories.table.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('ActivationsCategories.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <div className="text-brand-darkBlue">
          {' '}
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </div>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            isIconOnly
            className="mx-1"
            text={useFormatMessage('ActivationsCategories.table.action.edit')}
            icon="mdi-pencil"
            color="blue"
            size="small"
            to={`/activations/categories/${row.original.id}`}
          />
          <Button
            isIconOnly
            className="mx-1"
            text={useFormatMessage('ActivationsCategories.table.action.delete')}
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];
  // filter search logic
  const { result, searchProps, selectProps } = useFilterSearch(
    actCategoriesList
  );

  // end filter search logic

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteActCategoryHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader
        title={useFormatMessage('ActivationsCategories.title')}
        to={paths.ADD_ACTIVATIONS_CATEGORY}
      />
      <div className="page-padding">
        {/* filter  */}
        <div className="flex justify-between px-2">
          <FilterSearch
            loading={loading}
            hideSelect
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        {/* table  */}
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
          {error && 'Show error'}
        </div>
      </div>
    </>
  );
};

export default ActivationsCategories;
