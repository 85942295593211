import React from 'react';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import paths from 'pages/Router/paths';
import {
  fetchActVideos,
  deleteActVideo,
  clearActVideosData,
} from 'state/actions/actVideos';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormatDate, useFormatMessage } from 'hooks';
import ConfirmationModal from 'components/ConfirmationModal';
import useFilterSearch from 'hooks/useFilterSearch';
import sortByDate from 'utils/sortByDate';

const Videos = () => {
  const { actVideosList, error, loading, deleted } = useSelector(
    (state) => ({
      success: state.actVideos.success,
      actVideosList: state.actVideos.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.actVideos.error,
      loading: state.actVideos.loading,
      deleted: state.actVideos.deleted,
      pageCount: state.actVideos.pageCount,
    }),
    shallowEqual
  );

  const [search, setSearch] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    actVidId: null,
    actVidlink: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActVideos());
    return () => dispatch(clearActVideosData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        actVidId: null,
        actVidlink: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearActVideosData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (actVidId, actVidlink) => {
    setDeleteModal((prevState) => ({
      actVidId,
      actVidlink,
      isOpen: !prevState.isOpen,
    }));
  };
  const onCloseModalHandler = () => {
    setDeleteModal({ actVidId: null, actVidlink: null, isOpen: false });
  };
  const onDeleteActVideoHandler = () => {
    dispatch(deleteActVideo(deleteModal.actVidId, deleteModal.actVidlink));
  };

  const deleteMessage = useFormatMessage('ActivationsVideos.delete');
  const confirmMessage = useFormatMessage('ActivationsVideos.confirm');
  const permDeleteMessage = useFormatMessage('ActivationsVideos.permDelete');
  const cancelMessage = useFormatMessage('ActivationsVideos.cancel');

  const columns = [
    {
      Header: useFormatMessage('ActivationsVideos.table.name'),
      accessor: 'name',
    },
    {
      Header: 'Marques',
      accessor: 'marques',
    },
    {
      Header: useFormatMessage('ActivationsVideos.table.date'),
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            to={`/activations/videos/${row.original.id}`}
            isIconOnly
            text={useFormatMessage('ActivationsVideos.table.action.edit')}
            icon="mdi-pencil"
            color="blue"
            size="small"
          />
          <Button
            isIconOnly
            className="mx-2"
            text={useFormatMessage('ActivationsVideos.table.action.delete')}
            icon="mdi-delete"
            color="red"
            size="small"
            onClick={() =>
              onRemoveButtonClickHandler(row.original.id, row.original.link)
            }
          />
        </div>
      ),
    },
  ];
  // filter search logic
  const { result, searchProps, selectProps } = useFilterSearch(actVideosList);

  // end filter search logic
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteActVideoHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader
        title={useFormatMessage('ActivationsVideos.title')}
        to={paths.ADD_VIDEO}
      />
      <div className="page-padding">
        {/* filter  */}
        <div className="flex px-2">
          <FilterSearch
            hideSelect
            loading={loading}
            selectProps={selectProps}
            searchProps={searchProps}
          />
        </div>
        {/* table */}
        <div className="p-2">
          <Table columns={columns} data={result} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default Videos;
