import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import FileInput from 'components/Common/FileInput';
import SelectField from 'components/Common/SelectField';
import classnames from 'classnames';
import { actVideosCleanUp } from 'state/actions/actVideos';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useChangeHandler, useFormatMessage } from 'hooks';

import Preview from 'components/Common/Preview';

const VideoForm = ({ actVideoData, action }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => ({
      loading: state.actVideos.loading,
    }),
    shallowEqual
  );
  const [Video, setVideo] = useState(actVideoData);

  useEffect(() => {
    return () => dispatch(actVideosCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setVideo);
  //
  const canSubmit = Video.name && Video.marque && (Video.file || Video.link);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(Video));
  };

  // video uploading logic

  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setVideo((prevState) => ({ ...prevState, file }));
  };
  // marks
  const {
    actCategories,
    actCategoriesLoading,
    posCategories,
    posCategoriesLoading,
    marks,
    marksLoading,
    gamesList,
    gamesLoading,
  } = useSelector(
    (state) => ({
      loading: state.activations.loading,
      actCategories: state.actCategories.data,
      actCategoriesLoading: state.actCategories.loading,
      posCategories: state.posCategories.data,
      posCategoriesLoading: state.posCategories.loading,
      marks: state.marks.data,
      marksLoading: state.marks.loading,
      gamesList: state.games.data,
      gamesLoading: state.games.loading,
    }),
    shallowEqual
  );
  // const [marksOptions, setMarksOptions] = useState([]);

  const marksOptions = marks.map((m) => ({ value: m.id, label: m.name }));

  // console.log('marque', marks[0].name);
  return (
    <>
      <form
        className="flex items-start rounded-lg border border-brand-lighterBlue py-2"
        onSubmit={onSubmitHandler}
      >
        <NormalInput
          className="flex-auto max-w-sm"
          label={useFormatMessage('ActivationsVideo.name')}
          required
          name="name"
          value={Video.name}
          onChange={onChangeHandler}
        />
        <div className="flex-auto ">
          <SelectField
            type="form"
            name="marque"
            label={useFormatMessage('Activation.informationsTab.brands')}
            isLoading={marksLoading}
            isDisabled={marksLoading}
            // onChange={(value) => onChangeSelect('mark', value)}
            options={marksOptions}
            // value={selectValue('mark', activation.marks)}
          />
        </div>
        <div className="flex-auto max-w-xs mt-8 p-1 mx-2">
          <FileInput
            className={classnames({
              'is-loading': loading,
            })}
            label={useFormatMessage('ActivationsVideo.downloadVideo')}
            accept="video/*"
            file={Video.file}
            link={Video.link}
            disabled={loading}
            onChange={onFileChangedHandler}
          />
        </div>
        <Button
          className={classnames('mt-8 mx-4 ml-auto', {
            'is-loading': loading,
          })}
          text={useFormatMessage('ActivationsVideo.add')}
          icon="mdi-plus-circle"
          color="green"
          size="large"
          disabled={!canSubmit || loading}
          type="submit"
        />
      </form>
      <div className="p-2">
        <Preview isVideo file={Video.file} link={Video.link} />
      </div>
    </>
  );
};

export default VideoForm;
