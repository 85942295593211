import React, { useState, useEffect } from 'react';
import {
  fetchCriterias,
  deleteCriteria,
  clearCriteriasData,
} from 'state/actions/criterias';
import { useFormatMessage, useFormatDate } from 'hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useFilterSearch from 'hooks/useFilterSearch';
import ConfirmationModal from 'components/ConfirmationModal';
import paths from 'pages/Router/paths';
import Button from 'components/Common/Button';
import FilterSearch from 'components/Common/FilterSearch';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import sortByDate from 'utils/sortByDate';

const Criterias = () => {
  const { criteriasList, error, loading, deleted } = useSelector(
    (state) => ({
      success: state.criterias.success,
      criteriasList: state.criterias.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.criterias.error,
      loading: state.criterias.loading,
      deleted: state.criterias.deleted,
    }),
    shallowEqual
  );
  const [deleteModal, setDeleteModal] = useState({
    criteriaId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCriterias());
    return () => dispatch(clearCriteriasData());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        criteriaId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearCriteriasData());
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (criteriaId) => {
    setDeleteModal((prevState) => ({
      criteriaId,
      isOpen: !prevState.isOpen,
    }));
  };
  const onCloseModalHandler = () => {
    setDeleteModal({ criteriaId: null, isOpen: false });
  };
  const onDeleteCriteriaHandler = () => {
    dispatch(deleteCriteria(deleteModal.criteriaId));
  };

  const columns = [
    {
      Header: 'Nom',
      accessor: 'name',
    },
    {
      Header: 'Date de création',
      accessor: 'createdAt',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </small>
      ),
    },
    // actions for smt only
    {
      Header: 'Actions',
      accessor: '',
      Cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            to={`/criteria/${row.original.id}`}
            className="mx-1"
            text="Modifier"
            isIconOnly
            size="small"
            icon="mdi-pencil"
            color="blue"
          />
          <Button
            className="mx-1"
            text="Supprimer"
            isIconOnly
            size="small"
            icon="mdi-delete"
            color="red"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          />
        </div>
      ),
    },
  ];

  const deleteMessage = useFormatMessage('Games.delete');
  const confirmMessage = useFormatMessage('Games.confirm');
  const permDeleteMessage = useFormatMessage('Games.permDelete');
  const cancelMessage = useFormatMessage('Games.cancel');

  const { result, searchProps, selectProps } = useFilterSearch(criteriasList);

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCriteriaHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <PageHeader title="Critères" to={paths.ADD_CRITERIA} />
      <div className="page-padding">
        {/* filters  */}
        <div className="flex items-center justify-between px-2">
          <FilterSearch
            loading={loading}
            searchProps={searchProps}
            selectProps={selectProps}
          />
        </div>
        {/* table  */}
        <div className="p-2">
          <Table loading={loading} columns={columns} data={result} />
        </div>
      </div>
    </>
  );
};
export default Criterias;
