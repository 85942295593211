import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

//this component is needed to make the Select component animated
const animatedCoponent = makeAnimated();

const SelectField = ({ label, type, className, ...props }) => {
  /**
   * this is based on react-select
   * to know more about this lib and how to use it
   * please visit react-select.com for the complete doc of its api
   */

  /**
   * @param {String} label, a label for the select component, displayed above it
   * @param {String} type, one of ['form', 'filter']
   * @param {String} className, classes for the react-select
   */

  //    for forms
  const FormSelect = (
    <div className="p-2">
      <label className="text-brand-lightBlue font-semibold">{label}</label>
      <Select
        {...props}
        components={animatedCoponent}
        className={`react-select-form-container  py-1 ${className || ''}`}
        classNamePrefix="react-select-form"
      />
    </div>
  );

  //   bordered, for table filters
  const FilterSelect = (
    <Select
      {...props}
      components={animatedCoponent}
      className={`react-select-filter-container ${className || ''}`}
      classNamePrefix="react-select-filter"
      // isMulti
    />
  );
  // has only a bordered bottom, for table filters
  const FilterMaterialSelect = (
    <Select
      {...props}
      components={animatedCoponent}
      className={`react-select-filter-container make-it-material ${
        className || ''
      }`}
      classNamePrefix="react-select-filter"
    />
  );

  return (
    <>
      {(type === 'form' && FormSelect) ||
        (type === 'filter' && FilterSelect) ||
        (type === 'filter-material' && FilterMaterialSelect) || (
          <Select {...props} />
        )}
    </>
  );
};

SelectField.propTypes = {
  type: propTypes.string.isRequired,
  label: propTypes.string,
};

export default React.memo(SelectField);
