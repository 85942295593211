import React, { useEffect, useState } from 'react';
import BrandForm from 'components/BrandForm';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { createBrand, modifyBrand, fetchBrands } from 'state/actions/brands';
import { useFormatMessage } from 'hooks';

import {
  fetchAppName,
  createAppName,
  modifyAppName,
} from 'state/actions/appName';
import {
  fetchParamVideos,
  createParamVideo,
  modifyParamVideo,
  deleteParamVideo,
} from 'state/actions/paramVideos';
import ClipLoader from 'react-spinners/ClipLoader';

import AppName from 'components/AppNameForm';
import PageHeader from 'components/PageHeader';
import VideoParametsForm from 'components/ParametrsVideoForm';

function Parameters() {
  const {
    brands,
    appNames,
    brandLoading,
    appNamesLoading,
    paramVideos,
    paramVideoLoading,
  } = useSelector(
    (state) => ({
      brands: state.brands.data,
      appNames: state.appNames.data,
      paramVideos: state.paramVideos.data,
      paramVideoLoading: state.paramVideos.loading,
      brandLoading: state.brands.loading,
      appNamesLoading: state.appNames.loading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchAppName());
    dispatch(fetchParamVideos());
  }, [dispatch]);

  const brandForm = !brands.length ? (
    <BrandForm
      action={createBrand}
      isEditing={false}
      brandData={{ imageUrl: '', name: '', link: '' }}
    />
  ) : (
    <BrandForm
      action={modifyBrand}
      brandData={{ ...brands[0], imageUrl: brands[0].link }}
      isEditing
    />
  );

  const appNameComponent = !appNames.length ? (
    <AppName
      action={createAppName}
      isEditing={false}
      appNameData={{ name: '' }}
    />
  ) : (
    <AppName action={modifyAppName} isEditing appNameData={appNames[0]} />
  );

  const parameterVideoComponent = !paramVideos.length ? (
    <VideoParametsForm
      action={createParamVideo}
      isEditing={false}
      paramVideoData={{ name: '', link: null }}
    />
  ) : (
    <VideoParametsForm
      action={modifyParamVideo}
      paramVideoData={paramVideos[0]}
      deleteAction={deleteParamVideo}
      isEditing
    />
  );

  const displayAppName = appNamesLoading ? <ClipLoader /> : appNameComponent;
  const displayBrandForm = brandLoading ? <ClipLoader /> : brandForm;
  const displayParameterVideo = paramVideoLoading ? (
    <ClipLoader />
  ) : (
    parameterVideoComponent
  );
  return (
    <>
      <PageHeader title={useFormatMessage('Parameters.name')} hideButton />
      <div className="page-padding">
        {displayBrandForm}
        <div className="py-4" />
        {displayAppName}
        <div className="py-4" />
        {displayParameterVideo}
      </div>
    </>
  );
}

export default Parameters;
