import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const options = {
  plugins: {
    datalabels: {
      borderRadius: 4,
      color: 'black',
      font: {
        weight: 'bold',
        size: '18',
      },
      // formatter: (value) => {
      //   return ` ${value} % `;
      // },
    },
  },
};
const DashChart = ({ data }) => (
  <>
    <Bar
      data={data}
      plugins={[ChartDataLabels]}
      options={options}
      // tooltipTemplate="<%= value  %>"
    />
  </>
);

export default DashChart;
