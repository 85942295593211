/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { changeUserPassword, authCleanUp } from 'state/actions/auth';
import { useChangeHandler, useFormatMessage } from 'hooks';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';

const ChangePasswordCard = () => {
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirmation: '',
  });

  const onChangeHandler = useChangeHandler(setPasswords);

  const { loading, changedPassword } = useSelector(
    (state) => ({
      loading: state.auth.loading,
      changedPassword: state.auth.changedPassword,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(authCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (changedPassword) {
      setPasswords({
        current: '',
        new: '',
        confirmation: '',
      });
    }
  }, [changedPassword, setPasswords]);

  let inputs = {
    new: {
      modifier: null,
      message: { modifier: null, content: null },
    },
    confirmation: {
      modifier: null,
      message: { modifier: null, content: null },
    },
  };

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  const isNewPasswordSecure = passwords.new && passwords.new.length >= 6;

  const safePasswordMessage = useFormatMessage(`ChangePassword.safePassword`);

  const insecurePasswordMessage = useFormatMessage(
    `ChangePassword.insecurePassword`
  );

  if (isNewPasswordSecure) {
    setInputs('new', {
      modifier: 'is-success',
      message: {
        modifier: 'is-success',
        content: safePasswordMessage,
      },
    });
  } else if (passwords.new) {
    setInputs('new', {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: insecurePasswordMessage,
      },
    });
  }

  const newPasswordsAreEqual =
    passwords.new &&
    passwords.confirmation &&
    passwords.new === passwords.confirmation;

  const passwordsMatchMessagge = useFormatMessage(
    `ChangePassword.matchPassword`
  );

  const notMatchPasswordMessage = useFormatMessage(
    `ChangePassword.notMatchPassword`
  );

  if (newPasswordsAreEqual) {
    setInputs('confirmation', {
      modifier: 'is-success',
      message: {
        modifier: 'is-success',
        content: passwordsMatchMessagge,
      },
    });
  } else if (passwords.confirmation) {
    setInputs('confirmation', {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: notMatchPasswordMessage,
      },
    });
  }

  const currentAndNewPasswordsEqual =
    passwords.new && passwords.current === passwords.new;

  const samePasswordMessage = useFormatMessage(`ChangePassword.samePassword`);

  const errorMessage = currentAndNewPasswordsEqual && samePasswordMessage;

  const canSubmit =
    isNewPasswordSecure && newPasswordsAreEqual && !currentAndNewPasswordsEqual;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(changeUserPassword(passwords.current, passwords.confirmation));
  };

  return (
    <div>
      <h1 className="text-brand-darkBlue text-xl font-semibold my-3">
        {useFormatMessage(`ChangePassword.changePassword`)}
      </h1>
      <form
        onSubmit={onSubmitHandler}
        className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
      >
        <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
          {/* current password  */}
          <div className="lg:max-w-sm lg:w-1/2">
            <NormalInput
              type="password"
              name="current"
              required
              label={useFormatMessage(`ChangePassword.currentPassword`)}
              value={passwords.current}
              onChange={onChangeHandler}
            />
          </div>
          {/* new password */}
          <div className="lg:max-w-sm lg:w-1/2">
            <NormalInput
              className={`${inputs.new.modifier}`}
              type="password"
              name="new"
              required
              label={useFormatMessage(`ChangePassword.newPassword`)}
              value={passwords.new}
              onChange={onChangeHandler}
            />
            {inputs.new.message.content ? (
              <p className={`px-3 help is-${inputs.new.message.modifier}`}>
                {inputs.new.message.content}
              </p>
            ) : null}
          </div>

          {/* confirm password  */}
          <div className="lg:max-w-sm lg:w-1/2">
            <NormalInput
              className={`${inputs.confirmation.modifier}`}
              type="password"
              name="confirmation"
              required
              label={useFormatMessage(`ChangePassword.confirmPassword`)}
              value={passwords.confirmation}
              onChange={onChangeHandler}
            />
            {inputs.confirmation.message.content && (
              <p className={`px-3 help is-${inputs.confirmation.message.modifier}`}>
                {inputs.confirmation.message.content}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap w-full max-w-md lg:w-1/3 lg:items-end lg:justify-end items-center justify-end">
          <div className="">
            {/* submit  */}
            <Button
              type="submit"
              className={`${loading && 'is-loading'}`}
              disabled={!canSubmit || loading}
              color="green"
              size="large"
              icon="mdi-plus-circle"
              text={useFormatMessage(`ChangePassword.submits`)}
            />
            {/* end submit  */}
          </div>
          {errorMessage && <p className="px-3 help is-danger">{errorMessage}</p>}
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordCard;
