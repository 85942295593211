/* eslint-disable camelcase */
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
import Controller from 'controllers/Controller';
import firebase from 'firebase.js';

export const SUPPORTS_FETCH_DATA_INIT = createAction(
  'SUPPORTS_FETCH_DATA_INIT'
);
export const SUPPORTS_FETCH_DATA_SUCCESS = createAction(
  'SUPPORTS_FETCH_DATA_SUCCESS'
);
export const SUPPORTS_FETCH_DATA_FAIL = createAction(
  'SUPPORTS_FETCH_DATA_FAIL'
);
export const SUPPORTS_CREATE_INIT = createAction('SUPPORTS_CREATE_INIT');
export const SUPPORTS_CREATE_SUCCESS = createAction('SUPPORTS_CREATE_SUCCESS');
export const SUPPORTS_CREATE_FAIL = createAction('SUPPORTS_CREATE_FAIL');

export const SUPPORTS_MODIFY_INIT = createAction('SUPPORTS_MODIFY_INIT');
export const SUPPORTS_MODIFY_SUCCESS = createAction('SUPPORTS_MODIFY_SUCCESS');
export const SUPPORTS_MODIFY_FAIL = createAction('SUPPORTS_MODIFY_FAIL');

export const SUPPORTS_DELETE_INIT = createAction('SUPPORTS_DELETE_INIT');
export const SUPPORTS_DELETE_SUCCESS = createAction('SUPPORTS_DELETE_SUCCESS');
export const SUPPORTS_DELETE_FAIL = createAction('SUPPORTS_DELETE_FAIL');

export const SUPPORTS_CLEAR_DATA = createAction('SUPPORTS_CLEAR_DATA');
export const SUPPORTS_CLEAN_UP = createAction('SUPPORTS_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const pc = new Controller('supports', 'support');

/**
 * Action Fetch supports
 * @return dispatch
 */

export const fetchSupports = () => {
  return async (dispatch) => {
    dispatch(SUPPORTS_FETCH_DATA_INIT());
    try {
      const supports = await pc.fetch();
      const proCategories = supports.map(async (product) => {
        const proRef = await firebase
          .firestore()
          .collection('proCategories')
          .doc(product.categoryId)
          .get();
        const category = proRef.data();
        return {
          ...product,
          category,
        };
      });
      const supportsList = await Promise.all(proCategories);
      return dispatch(SUPPORTS_FETCH_DATA_SUCCESS({ supports: supportsList }));
    } catch (error) {
      toastr.error('Supports', 'Operation a échoué');
      return dispatch(SUPPORTS_FETCH_DATA_FAIL({ error }));
    }
  };
};

/**
 * Action Fetch a specific support
 * @param {string} ID ID of the support
 */

export async function fetchSupportData(id) {
  const supportData = await firebase
    .firestore()
    .collection('supports')
    .doc(id)
    .get()
    .then(async (supportSnap) => {
      const supportInfo = supportSnap.data();
      const combinedData = await firebase
        .firestore()
        .collection('proCategories')
        .doc(supportInfo.categoryId)
        .get()
        .then((categorySnap) => {
          const categoryInfo = categorySnap.data();
          return {
            id: supportSnap.id,
            ...supportInfo,
            category: {
              id: categorySnap.id,
              ...categoryInfo,
            },
          };
        });
      return combinedData;
    });

  return supportData;
}

/**
 * Action Create new support
 * @param {string} name Name of support and category ID
 * @return dispatch
 */

export const createSupport = ({ name, categoryId, file }) => {
  return async (dispatch, getState) => {
    dispatch(SUPPORTS_CREATE_INIT());
    let support;
    try {
      const displayName = displayText(name);
      support = await pc.create(
        { name, displayName, categoryId },
        'displayName'
      );
      if (file) {
        await pc.uploadFile(support.id, file);
        const link = pc.getFileUrl(support.id, file);
        support = await pc.update(support.id, {
          link,
          updatedAt: new Date().toGMTString(),
        });
      }
    } catch (error) {
      toastr.error('Support', 'la création a échoué');
      return dispatch(SUPPORTS_CREATE_FAIL({ error }));
    }
    toastr.success('Support', 'créé avec succès');
    return dispatch(SUPPORTS_CREATE_SUCCESS({ support }));
  };
};

/**
 * Action Update Support
 * @param {string} name Name of the Support
 * @param {string} id Support ID
 * @param {string} categoryId the Category ID
 * @return dispatch
 */
export const modifySupport = ({ name, id, categoryId, link, file }) => {
  return async (dispatch, getState) => {
    dispatch(SUPPORTS_MODIFY_INIT());
    const { locale } = getState().preferences;
    let support;
    try {
      const displayName = displayText(name);
      support = await pc.update(
        id,
        { name, displayName, categoryId },
        'displayName'
      );

      if (file) {
        if (link) await pc.deleteFile(link);
        await pc.uploadFile(id, file);
        const newlink = pc.getFileUrl(support.id, file);
        support = await pc.update(support.id, {
          link: newlink,
          updatedAt: new Date().toGMTString(),
        });
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Support', 'mise à jour a échoué');
      return dispatch(SUPPORTS_MODIFY_FAIL({ error: errorMessage }));
    }
    toastr.success('Support', 'mis à jour avec succès');
    return dispatch(SUPPORTS_MODIFY_SUCCESS({ support }));
  };
};

/**
 * Action Delete Support
 * @param {string} id ID of the Support
 * @return dispatch
 */

export const deleteSupport = (id) => {
  return async (dispatch, getState) => {
    dispatch(SUPPORTS_DELETE_INIT());
    const { locale } = getState().preferences;
    try {
      await pc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Support', 'la suppression a échoué');
      return dispatch(SUPPORTS_DELETE_FAIL({ error: errorMessage }));
    }
    toastr.success('Support', 'Supprimé avec succès');
    return dispatch(SUPPORTS_DELETE_SUCCESS({ id }));
  };
};

export const supportsCleanUp = () => (dispatch) =>
  dispatch(SUPPORTS_CLEAN_UP());

export const clearSupportsData = () => {
  return (dispatch) => {
    dispatch(SUPPORTS_CLEAR_DATA());
  };
};
