import React, { useEffect, useState } from 'react';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import FileInput from 'components/Common/FileInput';
import classnames from 'classnames';
import { paramVideosCleanUp } from 'state/actions/paramVideos';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useChangeHandler, useFormatMessage } from 'hooks';
import Preview from 'components/Common/Preview';

const VideoParametsForm = ({
  paramVideoData,
  action,
  isEditing,
  deleteAction,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => ({
      loading: state.paramVideos.loading,
    }),
    shallowEqual
  );
  const [paramVideo, setParamVideo] = useState(paramVideoData);

  useEffect(() => {
    setParamVideo(paramVideoData);
  }, [dispatch, paramVideoData]);

  const onChangeHandler = useChangeHandler(setParamVideo);

  const canSubmit = paramVideo.name && (paramVideo.file || paramVideo.link);
  const submitTextLabel = useFormatMessage('ActivationsVideo.add');

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(paramVideo));
  };

  //video uploading logic
  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setParamVideo((prevState) => ({ ...prevState, file }));
  };

  return (
    <>
      <h1 className="text-2xl mb-2 text-brand-darkBlue font-semibold">Video</h1>

      <div className="flex items-start rounded-lg border border-brand-lighterBlue py-2">
        <form onSubmit={onSubmitHandler}>
          <NormalInput
            className="flex-auto max-w-sm"
            label={useFormatMessage('ActivationsVideo.name')}
            required
            name="name"
            value={paramVideo.name}
            onChange={onChangeHandler}
          />
          <div className="flex-auto max-w-xs mt-8 p-1 mx-2">
            <FileInput
              className={classnames({
                'is-loading': loading,
              })}
              label={useFormatMessage('ActivationsVideo.downloadVideo')}
              accept="video/*"
              file={paramVideo.file}
              link={paramVideo.link}
              disabled={loading}
              onChange={onFileChangedHandler}
            />
          </div>
          <Button
            className={classnames('mt-8 mx-4 ml-2', {
              'is-loading': loading,
            })}
            text={isEditing ? 'modifier' : submitTextLabel}
            icon="mdi-plus-circle"
            color="green"
            size="large"
            disabled={!canSubmit || loading}
            type="submit"
          />
        </form>
        {isEditing && (
          <Button
            className={classnames('mt-8 mx-4 ml-auto', {
              'is-loading': loading,
            })}
            text="Supprimer"
            icon="mdi-plus-circle"
            color="red"
            size="large"
            disabled={loading}
            onClick={() =>
              dispatch(deleteAction(paramVideo.id, paramVideo.link))
            }
          />
        )}
      </div>
      <div className="p-2" style={{ width: '700px' }}>
        <Preview isVideo file={paramVideo.file} link={paramVideo.link} />
      </div>
    </>
  );
};

export default VideoParametsForm;
