/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import DatePicker from 'components/DatePicker';
import NormalCheckbox from 'components/Common/NormalCheckbox';

import { useChangeHandler } from 'hooks';

import { contactsCleanUp, createContact } from 'state/actions/contacts';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

const ContactForm = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState({ date: new Date() });

  const { loading } = useSelector(
    (state) => ({
      loading: state.contacts.loading,
    }),
    shallowEqual
  );
  const [contact, setContact] = useState({
    name: '',
    positionId: '',
    ville: '',
    agenceId: '',
    activationId: '',
    connected: false,
  });

  useEffect(() => {
    return () => dispatch(contactsCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setContact);

  const handleCheckBoxChange = () => {
    setContact((prevState) => ({
      ...prevState,
      connected: !prevState.connected,
    }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(createContact({ ...contact, date: date.date }));
    setContact({
      name: '',
      positionId: '',
      ville: '',
      agenceId: '',
      activationId: '',
      connected: false,
    });
  };

  return (
    <form
      onSubmit={onSubmitHandler}
      className="flex p-2 rounded-lg border border-brand-lighterBlue"
    >
      {/* div fields  */}
      <div className="flex-auto flex flex-wrap">
        <div className="w-1/3">
          <NormalInput
            value={contact.name}
            label="Nom"
            name="name"
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label="Position ID"
            name="positionId"
            value={contact.positionId}
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label="Ville"
            name="ville"
            value={contact.ville}
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label="Agence ID"
            value={contact.agenceId}
            name="agenceId"
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <NormalInput
            label="Activation ID"
            name="activationId"
            value={contact.activationId}
            onChange={onChangeHandler}
          />
        </div>
        <div className="w-1/3">
          <div className="p-2">
            <label className="px-2 font-semibold text-brand-lightBlue">
              Date
            </label>
            <DatePicker
              isForm
              label="Matricule"
              name="date"
              setState={setDate}
              date={new Date(date.date)}
            />
          </div>
        </div>
        <div className="w-1/3">
          <div className="p-2">
            <NormalCheckbox
              checked={contact.connected}
              name="disabled"
              onChange={handleCheckBoxChange}
              label="Accepter être contacté"
            />
          </div>
        </div>
      </div>
      {/* submit button  */}
      <div className={`p-2 pt-8 ${loading && 'is-loading'}`}>
        <Button
          type="submit"
          text="Ajouter"
          color="green"
          size="large"
          icon="mdi-plus-circle"
        />
      </div>
    </form>
  );
};
export default ContactForm;
