import React, { useState } from 'react';

import Table from 'components/Table';

import { CSVLink } from 'react-csv';

import DateRange from 'components/Common/DateRange';
import { useFormatMessage, useFormatDate } from 'hooks';
import Button from 'components/Common/Button';

const ReportingPerformancesZone = () => {
  const [dateSearch, setDateSearch] = useState({
    periodStart: new Date(),
    periodEnd: new Date(),
  });

  const data = [
    {
      zone: 'Zone Sbata',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 90),
      nbrAnnulation: Math.floor(Math.random() * 10),
      horraireDemarrageMoyen: 'February 19, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Rabat Salé',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'January 12, 2021 at 10:21:17 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Agadir Sahara',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'October 27, 2021 at 10:55:19 AM ',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Kenitra',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'June 15, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Agadir Souss',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'January 14, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Rabat Salé',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'January 30, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Mazagan',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'March 8, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Kenitra',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'March 2, 2021 at 12:00:00 AM ',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Est',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'February 18, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Khouribga Beni Mellal',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'January 15, 2021 at 9:58:31 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone FES',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'June 15, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
    {
      zone: 'Zone Marrakech',
      nbrActivation: Math.floor(Math.random() * 100),
      nbrPosActives: Math.floor(Math.random() * 100),
      nbrMoyActivationPos: Math.floor(Math.random() * 30),
      nbrAnnulation: Math.floor(Math.random() * 100),
      horraireDemarrageMoyen: 'November 1, 2021 at 12:00:00 AM',
      nbrContactMoyen: Math.floor(Math.random() * 100),
      nbrContactReussiMoyen: Math.floor(Math.random() * 100),
    },
  ];

  const columns = [
    {
      Header: 'Zone',
      accessor: 'zone',
    },
    {
      Header: 'NBR D’ACTIVATION',
      accessor: 'nbrActivation',
    },
    {
      Header: 'NBR POS ACTIVES',
      accessor: 'nbrPosActives',
    },
    {
      Header: 'NBR MOYEN ACTIVATION POS',
      accessor: 'nbrMoyActivationPos',
    },
    {
      Header: 'NBR D’ANNULATION',
      accessor: 'nbrAnnulation',
    },
    {
      Header: 'HORRAIRE DEMARRAGE MOYEN',
      accessor: 'horraireDemarrageMoyen',
    },
    {
      Header: 'NBR CONTACT MOYEN',
      accessor: 'nbrContactMoyen',
    },
    {
      Header: 'NBR CONTACT REUSSI MOYEN ',
      accessor: 'nbrContactReussiMoyen',
    },
  ];

  return (
    <div className="page-padding" style={{ overflow: 'auto' }}>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="text-brand-darkBlue text-3xl font-semibold">
                  Performances par zone
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="flex">
          <DateRange
            label={useFormatMessage('ActivationsDetails.card.period')}
            isForm
            fromText={useFormatMessage('Activation.informationsTab.from')}
            fromProps={{
              name: 'periodStart',
              setState: setDateSearch,
              date: new Date(dateSearch.periodStart),
            }}
            toText={useFormatMessage('Activation.informationsTab.to')}
            toProps={{
              name: 'periodEnd',
              setState: setDateSearch,
              date: new Date(dateSearch.periodEnd),
            }}
          />
          <Button
            style={{ height: 'max-content', marginTop: '38px' }}
            size="small"
            color="blue"
            text="Afficher Reporting"
            // onClick={searchReporting}
          />
          <CSVLink
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              background: '#3273dc',
              color: '#fff',
              borderRadius: 9999,
              display: 'inline-block',
              padding: 3,
              backgroundColor: '#2699fb',
              marginLeft: 50,
              height: 'max-content',
              marginTop: '38px',
            }}
            data={data}
          >
            Télécharger Reporting
          </CSVLink>
        </div>
      </section>
      <div className="flex">
        {/* left */}
        <div className="flex flex-col p-3">
          <div className="w-full reporting-table ">
            <Table
              // style={{ width: '1200px' }}
              tailwind="w-64 bg-black"
              columns={columns}
              data={data || []}
              loading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingPerformancesZone;
