import React from 'react';
import classnames from 'classnames';
/**
 * @param {String} label text that will be a label
 * @param {String} isForm to get back the form version that has a label above
 * @param {String} file the file selected, to extract the name
 * @param {String} link link to the file
 * @param {String} isSmall for smaller size
 * @param {String} className classes for container
 * @param {*} * anything else will be passed to the file input
 */
const FileInput = ({
  label,
  isForm,
  file,
  link,
  isSmall,
  className,
  ...props
}) => {
  //file name
  const filename =
    (file && file.name) || (link && 'choisissez une autre fichier') || '';
  // the input
  const StyledFileInput = (
    <label
      className={classnames(
        'relative bg-brand-lightBlue flex items-center rounded-full overflow-hidden tw-file-input',
        className || '',
        { 'text-sm': isSmall }
      )}
    >
      {/* icon  */}
      <div className="bg-brand-blue text-white h-full absolute top-0 left-0 w-12 flex items-center justify-center tw-icon">
        <i className="mdi mdi-upload mdi-24px"></i>
      </div>
      {/* file input  */}
      <input type="file" className="file-input" {...props} />
      {/* input for file name or placeholder  */}
      <input
        readOnly
        className="py-2 pl-16 w-full font-semibold text-white bg-transparent placeholder-white"
        placeholder={label}
        value={filename}
        type="text"
      />
    </label>
  );
  // when form type is requested
  if (isForm) {
    return (
      <div className="p-2">
        <label className="text-brand-lightBlue font-semibold">{label}</label>
        <div className="py-1">{StyledFileInput}</div>
      </div>
    );
  }
  // the normal
  return StyledFileInput;
};

export default FileInput;
