import React, { useState, useEffect } from 'react';
import ProductForm from 'components/ProductForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';

import { createProduct, modifyProduct } from 'state/actions/products';
import PageHeader from 'components/PageHeader';

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({
    name: '',
    price: 0,
    link: null,
    levels: [],
  });

  const { success } = useSelector(
    (state) => ({
      success: state.products.success,
    }),
    shallowEqual
  );

  useEffect(() => {
    const fetchProductData = async () => {
      const response = await firebase
        .firestore()
        .collection('products')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchProductData(id)
        .then(async (productData) => {
          const fetchedProduct = productData.data();

          const productCategorySnap = await firebase
            .firestore()
            .collection('proCategories')
            .doc(fetchedProduct.categoryId)
            .get();
          const productCategoryInfo = productCategorySnap.data();

          setProduct({
            id: productData.id,
            ...fetchedProduct,
            category: {
              id: productCategorySnap.id,
              ...productCategoryInfo,
            },
          });
        })
        .catch((error) => {
          setProduct({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const productForm =
    !product.name && id ? (
      <ClipLoader />
    ) : (
      <ProductForm
        isEditing={isEditing}
        productData={product}
        action={isEditing ? modifyProduct : createProduct}
      />
    );

  const redirect = (product.error || success) && (
    <Redirect to={paths.PRODUCTS} />
  );

  return (
    <>
      {redirect}
      <PageHeader title="Gratuité" hideButton />
      <div className="page-padding">{productForm}</div>
    </>
  );
};
export default Product;
