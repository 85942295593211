/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler, useFormatMessage } from 'hooks';
import firebase from 'firebase.js';
import Button from 'components/Common/Button';
import NormalCheckbox from 'components/Common/NormalCheckbox';
import NormalInput from 'components/Common/NormalInput';
import SelectField from 'components/Common/SelectField';
import { criteriaCleanUp } from 'state/actions/criterias';

const CriteriaForm = ({ criteriaData, action, isEditing }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => ({
      loading: state.criterias.loading,
    }),
    shallowEqual
  );
  const [criteria, setCriteria] = useState(criteriaData);
  const [marksList, setMarksList] = useState([]);

  useEffect(() => {
    return () => dispatch(criteriaCleanUp());
  }, [dispatch]);

  useEffect(() => {
    firebase
      .firestore()
      .collection('marks')
      .get()
      .then((markRef) => {
        const marks = markRef.docs.map((markSnap) => {
          return {
            value: markSnap.id,
            label: markSnap.data().name,
          };
        });
        setMarksList(marks);
      });
  }, []);

  const onChangeHandler = useChangeHandler(setCriteria);

  const canSubmit =
    criteria.name &&
    criteria.ageRanges.length &&
    criteria.purchases.length &&
    criteria.brands.length;

  const defaultMark = isEditing ? criteria.brands : null;

  const defaultAgeRange = isEditing
    ? criteria.ageRanges.map((ageRange) => ({
        value: ageRange,
        label: ageRange,
      }))
    : null;

  const defaultPurchases = isEditing
    ? criteria.purchases.map((purchase) => ({
        value: purchase,
        label: purchase,
      }))
    : null;

  const defaultBuyCount = isEditing
    ? (criteria.buyCount || []).map((buyCount) => ({
        value: buyCount,
        label: buyCount,
      }))
    : null;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(criteria));
  };

  const handleCheckBoxChange = () => {
    setCriteria((prevState) => ({
      ...prevState,
      smoker: !prevState.smoker,
    }));
  };

  const onMarksChange = (options) => {
    if (options) {
      const brands = [];
      options.forEach((option) => {
        brands.push(option.value);
      });
      setCriteria((prevState) => ({
        ...prevState,
        brands,
      }));
    } else {
      setCriteria((prevState) => ({
        ...prevState,
        brands: [],
      }));
    }
  };

  const onAgeRangeChange = (options) => {
    if (options) {
      const ageRanges = [];
      options.forEach((option) => {
        ageRanges.push(option.value);
      });
      setCriteria((prevState) => ({
        ...prevState,
        ageRanges,
      }));
    } else {
      setCriteria((prevState) => ({
        ...prevState,
        ageRanges: [],
      }));
    }
  };

  const onPurchasesChange = (options) => {
    if (options) {
      const purchases = [];
      options.forEach((option) => {
        purchases.push(option.value);
      });
      setCriteria((prevState) => ({
        ...prevState,
        purchases,
      }));
    } else {
      setCriteria((prevState) => ({
        ...prevState,
        purchases: [],
      }));
    }
  };

  const onBuyCountChange = (options) => {
    if (options) {
      const buyCount = [];
      options.forEach((option) => {
        buyCount.push(option.value);
      });
      setCriteria((prevState) => ({
        ...prevState,
        buyCount,
      }));
    } else {
      setCriteria((prevState) => ({
        ...prevState,
        buyCount: [],
      }));
    }
  };

  const AgeRangeOptions = [
    {
      label: 'Age 18-24 ans',
      value: 'Age 18-24 ans',
    },
    {
      label: 'Age 24-35 ans',
      value: 'Age 24-35 ans',
    },
    {
      label: 'Age 35 ans et plus',
      value: 'Age 35 ans et plus',
    },
  ];
  const achatOptions = [
    { label: 'A la tige', value: 'A la tige' },
    { label: 'Achat plusieurs marques', value: 'Achat plusieurs marques' },
    { label: 'Achat Normal', value: 'Achat Normal' },
  ];

  // nombre d'achat
  const buyCountOptions = [
    { label: '1 paquet', value: '1 paquet' },
    { label: '2 paquets', value: '2 paquets' },
    { label: '3 paquets', value: '3 paquets' },
    { label: '1 cartouche', value: '1 cartouche' },
    { label: '2 cartouches', value: '2 cartouches' },
    { label: '3 cartouches', value: '3 cartouches' },
  ];

  const brandsOptions = [
    {
      label: 'Fumeur Chesterfield/Monte carlo/Rothmans',
      value: 'Fumeur Chesterfield/Monte carlo/Rothmans',
    },
    { label: 'Fumeur Gauloises', value: 'Fumeur Gauloises' },
    {
      label: 'Fumeur Marquise/ MQS/Marvel/ Casa',
      value: 'Fumeur Marquise/ MQS/Marvel/ Casa',
    },
    {
      label: 'Fumeur Marlboro/Winston/davidoff/Camel/L&M',
      value: 'Fumeur Marlboro/Winston/davidoff/Camel/L&M',
    },
    {
      label: 'Acheteur plusieurs marques',
      value: 'Acheteur plusieurs marques',
    },
    { label: 'Autres', value: 'Autres' },
  ];

  // const hasPickedAutres = data.favoriteBrands.some((e) =>
  //   e.toLowerCase().includes('autres')
  // );

  return (
    <form
      onSubmit={onSubmitHandler}
      className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
    >
      {/* fields  container */}
      <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/3">
        {/* name  */}
        <div className="lg:max-w-sm lg:w-1/2">
          <NormalInput
            label="Nom"
            type="text"
            name="name"
            value={criteria.name}
            onChange={onChangeHandler}
            required
          />
        </div>
        {/* Tranche d'age  */}
        <div className="lg:max-w-sm lg:w-1/2">
          <SelectField
            label="Tranche d'âge"
            type="form"
            placeholder="Selectioner"
            isMulti
            options={AgeRangeOptions}
            onChange={onAgeRangeChange}
            defaultValue={defaultAgeRange}
            // isLoading={}
            // isDisabled={}
          />
        </div>
        {/* Smoker or not  */}
        <div className="py-2 px-2 lg:max-w-sm lg:w-1/2">
          <NormalCheckbox
            className="flex-1"
            label="Fumeur Oui/Non"
            type="checkbox"
            name="smoker"
            checked={criteria.smoker}
            onChange={handleCheckBoxChange}
          />
        </div>
        {/* Achat  */}
        <div className="lg:max-w-sm lg:w-1/2">
          <SelectField
            label="Achat"
            type="form"
            placeholder="Selectioner"
            isMulti
            options={achatOptions}
            onChange={onPurchasesChange}
            defaultValue={defaultPurchases}
            // isLoading={}
            // isDisabled={}
          />
        </div>
        {/* favorite brands  */}
        <div className="lg:max-w-sm lg:w-1/2">
          <SelectField
            label="Marques préféres"
            type="form"
            placeholder="Selectioner"
            isMulti
            options={marksList}
            defaultValue={defaultMark}
            onChange={onMarksChange}
            // isLoading={}
            // isDisabled={}
          />
        </div>
        {/* specify brands (visible when user chooses "autres" in the input above)  */}
        {/* {hasPickedAutres && (
          <div className="lg:max-w-sm lg:w-1/2">
            <NormalInput label="Spécifier les autre marques" type="text" />
          </div>
        )} */}
        {/* "Nombre d'achats" */}
        <div className="lg:max-w-sm lg:w-1/2">
          <SelectField
            label="Nombre d'achats"
            type="form"
            placeholder="Selectioner"
            isMulti
            options={buyCountOptions}
            defaultValue={defaultBuyCount}
            onChange={onBuyCountChange}
            // isLoading={}
            // isDisabled={}
          />
        </div>
      </div>
      {/* actions  */}
      <div className="flex flex-wrap w-full max-w-md lg:w-1/3 lg:items-end lg:justify-end items-center justify-end">
        <div className="pb-2">
          {/* submit  */}
          <Button
            type="submit"
            className={`${loading && 'is-loading'}`}
            disabled={!canSubmit || loading}
            color="green"
            size="large"
            icon="mdi-plus-circle"
            text={useFormatMessage('UserForm.submit')}
          />
          {/* end submit  */}
        </div>
      </div>
      {/* end controls  */}
    </form>
  );
};

export default CriteriaForm;
