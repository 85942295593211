import firebase from 'firebase.js';
import Controller from './Controller';
import ActivationController from './ActivationController';
import RelationController from './RelationController';

export default class PositionController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'positions';
    this.item = 'position';
  }

  fetch = async (agencyId) => {
    const ac = new ActivationController();
    const rc = new RelationController();
    return !agencyId ?
      firebase
      .firestore()
      .collection(this.collection)
      .orderBy('createdAt', 'desc')
      .get()
      .then((posSnaps) => {
        if (posSnaps.size > 0) {
          const promises = posSnaps.docs.map(async (pos) => ({
            id: pos.id,
            ...pos.data(),
            category: pos.data().categoryId ?
              await rc.getCategory('posCategories', pos.data().categoryId) : null,
          }));
          return Promise.all(promises);
        }
        return [];
      }) :
      ac.getActPostionsByAgency(agencyId);
  };

  /**
   * Get position data with category By ID
   * @param {string} positionId position ID
   * @returns {Object} object of position data and category or False
   */
  fetchById = async (positionId) =>
    positionId ?
    this.getDoc(positionId)
    .then((positionSnap) => {
      if (positionSnap.exists) {
        return {
          id: positionSnap.id,
          ...positionSnap.data(),
        };
      }
      return null;
    })
    .then((positionData) => {
      if (positionData && positionData.categoryId)
        return this.getDoc(positionData.categoryId, 'posCategories').then(
          (categorySnap) => {
            if (categorySnap.exists) {
              return {
                ...positionData,
                category: {
                  id: categorySnap.id,
                  ...categorySnap.data(),
                },
              };
            }
            return positionData;
          }
        );
      return positionData;
    }) :
    null;
}