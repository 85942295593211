import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import { useFormatDate, useSecondToHours } from 'hooks';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NormalInput from 'components/Common/NormalInput';
import { fetchMemberAnimations } from 'state/actions/activations';
//
const moment = require('moment');

const MemberDetails = () => {
  const { activationId, memberId, type } = useParams();
  const dispatch = useDispatch();

  const { animations, loading } = useSelector(
    (state) => ({
      loading: state.activations.loading,
      animations: state.activations.animations,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchMemberAnimations(activationId, memberId, type));
  }, [dispatch]);

  const columns = [
    {
      Header: 'Position',
      accessor: 'Position',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {(row.original.position || {}).name}
        </div>
      ),
    },
    {
      Header: 'Image',
      Cell: ({ row }) => (
        <a href={row.original.animatorImageUrl || ''} target="_blank">
          <img
            src={row.original.animatorImageUrl || ''}
            style={{ width: 100, height: 100, margin: '0 auto' }}
          />
        </a>
      ),
    },
    {
      Header: 'Contacts',
      Cell: ({ row }) => row.original.contacts.length || 0,
    },
    {
      Header: 'Succ. Contacts',
      Cell: ({ row }) => row.original.successContacts.length || 0,
    },
    {
      Header: 'Temps travail',
      accessor: 'Temps_travail',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {moment('2015-01-01')
            .startOf('day')
            .seconds(row.original.workTime || 0)
            .format('H:mm:ss') || '00 min'}
        </div>
      ),
    },
    {
      Header: 'Heure début connexion',
      accessor: 'Heure_début_connexion',
      Cell: ({ row }) => (
        <div className="text-brand-lightBlue">
          {useFormatDate(row.original.startTime.toDate() || new Date(), {
            hour: 'numeric',
            minute: 'numeric',
          }) || '00:00'}
        </div>
      ),
    },
  ];
  return (
    <div className="p-4 border">
      {/* table  */}
      <div className="pb-6">
        {/* title */}
        <div className="pb-2 flex justify-between">
          <h1 className="text-2xl text-brand-darkBlue font-semibold">
            Detail par Member
          </h1>
          <NormalInput placeholder="Date" isFilter icon="mdi-calendar" />
        </div>
        <div className="">
          <Table loading={loading} data={animations} columns={columns} />
        </div>
      </div>
    </div>
  );
};
//
export default MemberDetails;
