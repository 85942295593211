import React from 'react';

import { useFormatMessage } from 'hooks';

const Preview = ({ isVideo, link = '', file }) => {
  // construct url
  const previewUrl = !link ? file && URL.createObjectURL(file) : link;
  //   construct video
  const video = (
    <video controls className="shadow-md border">
      <source src={previewUrl} />
    </video>
  );
  //construct image
  const image = (
    <img className="shadow-md border" src={previewUrl} alt="preview" />
  );
  //construct message for when there is no file & no link
  const noFileOrLinkMSg = <p>{useFormatMessage('Commnon.preview.noFile')}</p>;
  return (
    <div className="">
      <h1 className="text-brand-darkBlue text-2xl font-semibold mb-1">
        {useFormatMessage('Commnon.preview.title')}
      </h1>
      {previewUrl ? (isVideo ? video : image) : noFileOrLinkMSg}
    </div>
  );
};

export default Preview;
