
import { createReducer } from 'redux-act';
import {
  POSITIONS_FETCH_DATA_INIT,
  POSITIONS_FETCH_DATA_SUCCESS,
  POSITIONS_FETCH_DATA_FAIL,
  POSITIONS_CREATE_POSITION_INIT,
  POSITIONS_CREATE_POSITION_SUCCESS,
  POSITIONS_CREATE_POSITION_FAIL,
  POSITIONS_DELETE_POSITION_INIT,
  POSITIONS_DELETE_POSITION_SUCCESS,
  POSITIONS_DELETE_POSITION_FAIL,
  POSITIONS_MODIFY_POSITION_INIT,
  POSITIONS_MODIFY_POSITION_SUCCESS,
  POSITIONS_MODIFY_POSITION_FAIL,
  POSITIONS_CLEAN_UP,
  POSITIONS_CLEAR_DATA,
} from 'state/actions/positions';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const positionsReducer = createReducer(
  {
    // for fetching positions
    [POSITIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [POSITIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.positions,
      loading: false,
      error: null,
    }),
    [POSITIONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POSITIONS_CREATE_POSITION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [POSITIONS_CREATE_POSITION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat({ id: state.id }).concat(payload.position),
      loading: false,
      error: null,
      success: true,
    }),
    [POSITIONS_CREATE_POSITION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POSITIONS_DELETE_POSITION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [POSITIONS_DELETE_POSITION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [POSITIONS_DELETE_POSITION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POSITIONS_MODIFY_POSITION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [POSITIONS_MODIFY_POSITION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map((elem) => {
        if (elem.id === payload.id) {
          return {
            name: payload.position.name,
            displayName: payload.position.displayName,
            id: payload.id,
            createdAt: payload.position.createdAt,
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true,
    }),
    [POSITIONS_MODIFY_POSITION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POSITIONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [POSITIONS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);

