import firebase from 'firebase.js';
import { rolesName } from 'utils';
import Controller from './Controller';
import RoleController from './RoleController';
import TeamController from './TeamController';

const rc = new RoleController();
export default class UserController extends Controller {
  constructor(collection, item) {
    super(collection, item);
    this.collection = 'users';
    this.item = 'user';
  }

  /**
   * Fetch users
   */
  fetch = async (agencyId) => {
    const tc = new TeamController();
    const userRef = !agencyId
      ? firebase.firestore().collection(this.collection).get()
      : firebase
          .firestore()
          .collection(this.collection)
          .where('createdBy', '==', agencyId)
          .get();
    return userRef.then(async (usrsSnap) => {
      if (usrsSnap.size > 0) {
        const promises = usrsSnap.docs.map(async (doc) => {
          const usr = { id: doc.id, ...doc.data() };
          if (usr.roleId !== undefined && usr.roleId !== '') {
            const role = await rc.fetchById(usr.roleId);
            let user = { ...usr, role };
            if (role.name === rolesName.manager)
              user = { ...user, teams: await tc.fetch(doc.id) };
            return user;
          }
          return usr;
        });
        return Promise.all(promises);
      }
      return [];
    });
  };

  /**
   * Get users by Role
   * @param {string} roleId role ID
   * @returns {array} users name & id
   */
  roleUsers = (roleId) => {
    firebase
      .firestore()
      .collection(this.collection)
      .where('roleId', '==', roleId)
      .where('createdBy', '==', roleId)
      .get()
      .then((usrsSnap) => {
        if (usrsSnap.size > 0) {
          return usrsSnap.docs.map((usr) => ({
            value: usr.id,
            label: usr.data().name,
          }));
        }
        return [];
      });
  };

  /**
   * Create user
   *
   * @param {Object} data user object data
   */
  create = async (id, admin, data) => {
    const {
      name,
      email,
      adresse,
      idNum,
      location,
      file,
      isAdmin,
      roleId,
      disable,
    } = data;

    let response;
    let uploadLogoTask;
    let createUserDbTask;
    let sendSignInLinkToEmailTask;

    const setData = {
      name,
      idNum: idNum || '',
      location,
      createdAt: new Date().toGMTString(),
      roleId: roleId || '',
      password: '',
      disable,
      adresse: adresse || '',
      createdBy: id,
    };

    if (admin && email) {
      // Call onCreateUser cloud function
      const createUserAuth = firebase
        .functions()
        .httpsCallable('httpsCreateUser');

      response = await createUserAuth({ email, isAdmin });

      // Get created User ID
      const { uid } = response.data;
      if (uid) {
        // Upload Logo if exists
        uploadLogoTask = null;
        let logoUrl = null;
        if (file) {
          logoUrl = this.getFileUrl(uid, file);
          uploadLogoTask = this.uploadFile(uid, file);
        }

        // Set new user to Collection
        createUserDbTask = this.setDoc(uid, {
          ...setData,
          email,
          logoUrl,
          isAdmin,
        });

        // Send Link to change password
        const actionCodeSettings = {
          url: process.env.REACT_APP_LOGIN_PAGE_URL,
          handleCodeInApp: true,
        };

        sendSignInLinkToEmailTask = firebase
          .auth()
          .sendSignInLinkToEmail(email, actionCodeSettings);
      } else {
        throw new Error('Have issue to create user');
      }
    } else {
      // Set new user to Collection
      createUserDbTask = this.addDoc(setData).then(async (docRef) => {
        const userSnap = await this.getDoc(docRef.id);
        if (file) {
          const logoUrl = this.getFileUrl(docRef.id, file);
          await userSnap.ref.update({ logoUrl });
          uploadLogoTask = await this.uploadFile(docRef.id, file);
        }
        response = { data: docRef.id };
      });
    }

    // Execute All promises to create User
    return Promise.all([
      uploadLogoTask,
      createUserDbTask,
      sendSignInLinkToEmailTask,
    ]).then(() => response.data);
  };

  /**
   * Update user
   * @param {Object} data user object data
   */
  update = (data, logoUrl) => {
    const {
      name,
      location,
      isAdmin,
      file,
      roleId,
      adresse,
      idNum,
      id,
      disable,
    } = data;

    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (file) {
      newLogoUrl = this.getFileUrl(id, file);
      if (newLogoUrl) deleteLogoTask = logoUrl && this.deleteFile(logoUrl);
      uploadLogoTask = this.uploadFile(id, file);
    }

    const userData = {
      name,
      location,
      createdAt: new Date().toGMTString(),
      roleId: roleId || '',
      isAdmin: isAdmin || null,
      idNum: idNum || '',
      disable: disable || null,
      adresse: adresse || '',
      logoUrl: newLogoUrl || logoUrl,
    };

    // Update user collection and
    const updateUserDbTask = this.updateDoc(id, userData);

    // Execute all promises
    return Promise.all([
      deleteLogoTask,
      uploadLogoTask,
      updateUserDbTask,
    ]).then(() => ({ ...userData, id }));
  };

  /**
   * Delete user
   * @param {string} id user ID
   * @param {function} init action to initialize
   * @param {function} success action delete user success
   * @param {function} fail action on failed
   */
  delete = (id, logoUrl) => {
    const deleteLogoTask = logoUrl ? this.deleteFile(logoUrl) : null;
    const deleteUserTask = this.destroy(id);
    return Promise.all([deleteLogoTask, deleteUserTask]);
  };

  /**
   * Generate unique ID
   * @returns {string} Generated ID
   */
  genIdNum = async () => {
    const idNum = this.makeid(5);
    return (await this.exists('idNum', idNum)) ? this.genCode() : idNum;
  };

  /**
   * Generate random string
   * @param {int} length number of chars
   * @returns {string} text
   */
  makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  fetchById = async (userId) =>
    userId
      ? this.getDoc(userId).then((userSnap) => {
          if (userSnap.exists) {
            return {
              id: userSnap.id,
              ...userSnap.data(),
            };
          }
          return null;
        })
      : null;
}
