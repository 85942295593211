import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { firebaseError, displayText } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/Controller';
import ControllerWithFileUpload from 'controllers/ControllerWithFileUpload';
import firebase from 'firebase.js';

export const GAMES_FETCH_DATA_INIT = createAction('GAMES_FETCH_DATA_INIT');
export const GAMES_FETCH_DATA_SUCCESS = createAction(
  'GAMES_FETCH_DATA_SUCCESS'
);
export const GAMES_FETCH_DATA_FAIL = createAction('GAMES_FETCH_DATA_FAIL');
export const GAMES_CREATE_GAME_INIT = createAction('GAMES_CREATE_GAME_INIT');
export const GAMES_CREATE_GAME_SUCCESS = createAction(
  'GAMES_CREATE_GAME_SUCCESS'
);
export const GAMES_CREATE_GAME_FAIL = createAction('GAMES_CREATE_GAME_FAIL');
export const GAMES_CLEAR_DATA = createAction('GAMES_CLEAR_DATA');
export const GAMES_DELETE_GAME_INIT = createAction('GAMES_DELETE_GAMES_INIT');
export const GAMES_DELETE_GAME_SUCCESS = createAction(
  'GAMES_DELETE_GAME_SUCCESS'
);
export const GAMES_DELETE_GAME_FAIL = createAction('GAMESS_DELETE_GAMES_FAIL');
export const GAMES_MODIFY_GAME_INIT = createAction('GAMES_MODIFY_GAME_INIT');
export const GAMES_MODIFY_GAME_SUCCESS = createAction(
  'GAMES_MODIFY_GAME_SUCCESS'
);
export const GAMES_MODIFY_GAME_FAIL = createAction('GAMES_MODIFY_GAME_FAIL');
export const GAMES_CLEAN_UP = createAction('GAMES_CLEAN_UP');

// Init the Name of Collection && Item && The unique value
const gc = new Controller('games', 'game');
const cUpload = new ControllerWithFileUpload('marks', 'mark', 'displayName');

export const generateID = () => gc.genIdNum();

/**
 * Action Fetchgames
 * @return dispatch
 */
export const fetchGames = () => {
  return async (dispatch) => {
    dispatch(GAMES_FETCH_DATA_INIT());
    let games;
    try {
      games = await gc.fetch();
    } catch (error) {
      toastr.error('Erreur', 'Operation a échoué');
      return dispatch(GAMES_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      GAMES_FETCH_DATA_SUCCESS({
        games,
      })
    );
  };
};

/**
 * Action Create new game
 * @param {string} name Name of the game
 * @return dispatch
 */
export const createGame = ({ name, idNum, disabled, marks, image }) => {
  return async (dispatch, getState) => {
    dispatch(GAMES_CREATE_GAME_INIT());
    const { locale } = getState().preferences;
    let game;
    let imageUrl = '';

    try {
      const displayName = displayText(name);

      const gameReference = await firebase.firestore().collection('games').add({
        name,
        displayName,
        idNum,
        marks,
        disabled,
        createdAt: new Date().toGMTString(),
        updatedAt: new Date().toGMTString(),
      });

      if (image) {
        await cUpload.uploadFile(gameReference.id, image);
        imageUrl = cUpload.getFileUrl(gameReference.id, image);
        await cUpload.update(gameReference.id, {
          imageUrl,
          updatedAt: new Date().toGMTString(),
        });
      }

      game = await gameReference
        .update({
          name,
          displayName,
          idNum,
          marks,
          disabled,
          imageUrl,
        })
        .then(() => gameReference.get().then((snap) => snap.data()));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Jeu', 'la création a échoué');
      return dispatch(GAMES_CREATE_GAME_FAIL({ error: errorMessage }));
    }
    toastr.success('Jeu', 'créé avec succès');
    return dispatch(GAMES_CREATE_GAME_SUCCESS({ game }));
  };
};

/**
 * Action Delete a game
 * @param {string} id ID of the game
 * @return dispatch
 */
export const deleteGame = (id) => {
  return async (dispatch, getState) => {
    dispatch(GAMES_DELETE_GAME_INIT());
    const { locale } = getState().preferences;
    try {
      await gc.destroy(id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Jeu', 'la suppression a échoué');
      return dispatch(GAMES_DELETE_GAME_FAIL({ error: errorMessage }));
    }
    toastr.success('Jeu', 'Supprimé avec succès');
    return dispatch(GAMES_DELETE_GAME_SUCCESS({ id }));
  };
};

/**
 * Action Update game
 * @param {string} name Name of the game
 * @return dispatch
 */
export const modifyGame = ({ id, name, disabled, marks, image, imageUrl }) => {
  return async (dispatch, getState) => {
    dispatch(GAMES_MODIFY_GAME_INIT());
    const { locale } = getState().preferences;
    let game;
    const displayName = displayText(name);

    try {
      if (!(await cUpload.exists({ displayName }))) {
        let oldImage = imageUrl;

        const gamesReference = await firebase
          .firestore()
          .collection('games')
          .doc(id);

        if (image) {
          await cUpload.deleteFile(imageUrl);
          await cUpload.uploadFile(id, image);
          oldImage = cUpload.getFileUrl(id, image);
        }

        game = await gamesReference
          .update({
            name,
            displayName,
            disabled,
            marks,
            imageUrl: oldImage,
            updatedAt: new Date().toGMTString(),
          })
          .then(() =>
            gamesReference
              .get()
              .then((snap) => ({ id: snap.id, ...snap.data() }))
          );
      } else {
        toastr.error('Jeu', 'Cette article existe déjà!');
        return dispatch(
          GAMES_MODIFY_GAME_INIT({
            error: `This Jeu is already exists!`,
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('Jeu', 'mise à jour a échoué');
      return dispatch(GAMES_MODIFY_GAME_FAIL({ error: errorMessage }));
    }
    toastr.success('Jeu', 'mis à jour avec succès');
    return dispatch(GAMES_MODIFY_GAME_SUCCESS({ game }));
  };
};

export const gamesCleanUp = () => (dispatch) => dispatch(GAMES_CLEAN_UP());

export const clearGamesData = () => {
  return (dispatch) => {
    dispatch(GAMES_CLEAR_DATA());
  };
};
