import React, { useState, useEffect } from 'react';
import PositionForm from 'components/PositionForm';
import PageHeader from 'components/PageHeader';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import {
  createPosition,
  fetchPositionData,
  modifyPosition,
} from 'state/actions/positions';

import { useFormatMessage } from 'hooks';

const Position = () => {
  const { id } = useParams();
  const [position, setPosition] = useState({
    name: '',
    region: '',
    zone: '',
    sector: '',
    startHour: '',
    cumulHour: '',
    matricule: '',
    location: '',
    city: '',
    address: '',
    status: 'INITIAL',
  });

  const { success } = useSelector(
    (state) => ({
      success: state.positions.success,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      fetchPositionData(id)
        .then((positionData) => {
          setPosition({
            ...positionData,
          });
        })
        .catch(() => {
          setPosition({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const positionForm =
    !position.name && id ? (
      <ClipLoader />
    ) : (
      <PositionForm
        isEditing={isEditing}
        positionData={position}
        action={isEditing ? modifyPosition : createPosition}
      />
    );

  const redirect = (position.error || success) && (
    <Redirect to={paths.POSITIONS} />
  );

  return (
    <>
      {redirect}
      <PageHeader hideButton title={useFormatMessage('Position.title')} />
      <div className="page-padding">{positionForm}</div>
    </>
  );
};

export default Position;
