import React, { useState, useEffect } from 'react';
import SupportForm from 'components/SupportForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';

import { createSupport, modifySupport } from 'state/actions/supports';
import PageHeader from 'components/PageHeader';

const Support = () => {
  const { id } = useParams();
  const [support, setSupport] = useState({
    name: '',
    price: 0,
    link: null,
  });

  const { success } = useSelector(
    (state) => ({
      success: state.supports.success,
    }),
    shallowEqual
  );

  useEffect(() => {
    const fetchSupportData = async () => {
      const response = await firebase
        .firestore()
        .collection('supports')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchSupportData(id)
        .then(async (productData) => {
          const fetchedProduct = productData.data();

          const productCategorySnap = await firebase
            .firestore()
            .collection('proCategories')
            .doc(fetchedProduct.categoryId)
            .get();
          const productCategoryInfo = productCategorySnap.data();
          setSupport({
            id: productData.id,
            ...fetchedProduct,
            category: {
              id: productCategorySnap.id,
              ...productCategoryInfo,
            },
          });
        })
        .catch((error) => {
          setSupport({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const supportForm =
    !support.name && id ? (
      <ClipLoader />
    ) : (
      <SupportForm
        isEditing={isEditing}
        supportData={support}
        action={isEditing ? modifySupport : createSupport}
      />
    );

  const redirect = (support.error || success) && (
    <Redirect to={paths.SUPPORTS} />
  );

  return (
    <>
      {redirect}
      <PageHeader title="Support" hideButton />
      <div className="page-padding">{supportForm}</div>
    </>
  );
};
export default Support;
