import { createAction } from 'redux-act';
import { displayText } from 'utils';
/* eslint-disable camelcase */
import Controller from 'controllers/ControllerWithFileUpload';

export const FETCH_APP_NAME_INIT = createAction('FETCH_APP_NAME_INIT');
export const FETCH_APP_NAME_SUCCESS = createAction('FETCH_APP_NAME_SUCCESS');
export const FETCH_APP_NAME_FAIL = createAction('FETCH_APP_NAME_FAIL');
export const CREATE_APP_NAME_INIT = createAction('CREATE_APP_NAME_INIT');
export const CREATE_APP_NAME_SUCCESS = createAction('CREATE_APP_NAME_SUCCESS');
export const CREATE_APP_NAME_FAIL = createAction('CREATE_APP_NAME_FAIL');
export const MODIFY_APP_NAME_INIT = createAction('MODIFY_APP_NAME_INIT');
export const MODIFY_APP_NAME_SUCCESS = createAction('MODIFY_APP_NAME_SUCCESS');
export const MODIFY_APP_NAME_FAIL = createAction('MODIFY_APP_NAME_FAIL');

// Init the Name of Collection && Item && The unique value
const c = new Controller('appNames', 'appName', 'displayName');

export const fetchAppName = () =>
  c.fetch(FETCH_APP_NAME_INIT, FETCH_APP_NAME_SUCCESS, FETCH_APP_NAME_FAIL);

export const createAppName = ({ name }) => {
  const displayName = displayText(name);
  return c.create(
    { name, displayName },
    CREATE_APP_NAME_INIT,
    CREATE_APP_NAME_SUCCESS,
    CREATE_APP_NAME_FAIL
  );
};

export const modifyAppName = ({ name, id }) => {
  const displayName = displayText(name);
  return c.update(
    id,
    { name, displayName },
    MODIFY_APP_NAME_INIT,
    MODIFY_APP_NAME_SUCCESS,
    MODIFY_APP_NAME_FAIL
  );
};
