import React, { useState } from 'react';
import { useEffect } from 'react';

const TimeLine = ({ steps = [], hideLastStep = false }) => {
  /**
   * @param {Array of Objects} steps, each Object should contain a name key and an isActive key
   * ******* name {String} will be the title of each step
   * ******* isActive  {Boolean} only one should be set to true, the rest false
   * ******* change which one is active so that the timeline reacts
   * @param {Boolean} hideLastStep, to hide or not to hide, that is the question.
   */

  /**
   * keeps track of the index of the active step
   */
  const [currentStep, setCurrentStep] = useState(0);
  /**
   * this func sets the index of the step that is active
   * whenever the steps data is updated
   */
  useEffect(() => {
    steps.forEach((e, i) => {
      if (e.isActive) {
        setCurrentStep(() => i);
      }
    });
  }, [steps]);
  /**
   * this function is used to decide which class to return for each step
   * depending on which one is currently active and which ones come before it
   */
  const isActiveOrWasActiveClass = (stepIndex) => {
    if (stepIndex < currentStep) return 'was-active';
    if (stepIndex === currentStep) return 'is-active';
  };

  const Steps = steps
    //for filtering out the last one if hideLastStep is true
    .filter((s, i) => !hideLastStep && i !== steps.length - 1)
    //creating the jsx list
    .map((step, i) => (
      <div
        key={i}
        className={`tw-timeline-section ${isActiveOrWasActiveClass(i)}`}
      >
        <div className="tw-timeline-section-circle" data-name={step.name}></div>
        <div className="tw-timeline-section-lines">
          <div className="tw-timeline-section-solidline"></div>
          <div className="tw-timeline-section-dashedline"></div>
        </div>
      </div>
    ));
  return <div className="tw-timeline tw-timeline-hide-last">{Steps}</div>;
};

export default React.memo(TimeLine);
