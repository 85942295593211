import { createReducer } from 'redux-act';
import {
  MARKS_FETCH_DATA_INIT,
  MARKS_FETCH_DATA_SUCCESS,
  MARKS_FETCH_DATA_FAIL,
  MARKS_CREATE_MARK_INIT,
  MARKS_CREATE_MARK_SUCCESS,
  MARKS_CREATE_MARK_FAIL,
  MARKS_DELETE_MARK_INIT,
  MARKS_DELETE_MARK_SUCCESS,
  MARKS_DELETE_MARK_FAIL,
  MARKS_MODIFY_MARK_INIT,
  MARKS_MODIFY_MARK_SUCCESS,
  MARKS_MODIFY_MARK_FAIL,
  MARKS_CLEAN_UP,
  MARKS_CLEAR_DATA,
} from 'state/actions/marks';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const marksReducer = createReducer(
  {
    [MARKS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MARKS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.marks,
      loading: false,
      error: null,
    }),
    [MARKS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MARKS_CREATE_MARK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MARKS_CREATE_MARK_SUCCESS]: (state, payload) => ({
      ...state,
      data: [{ ...payload.mark, id: state.id }, ...state.data],
      loading: false,
      error: null,
      success: true,
    }),
    [MARKS_CREATE_MARK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MARKS_DELETE_MARK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MARKS_DELETE_MARK_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [MARKS_DELETE_MARK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MARKS_MODIFY_MARK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MARKS_MODIFY_MARK_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: [
          ...state.data.map((elem) => {
            if (elem.id === payload.mark.id) {
              return {
                name: payload.mark.name,
                displayName: payload.mark.displayName,
                isFavorite: payload.mark.isFavorite,
                isMultiMark: payload.mark.isMultiMark,
                id: payload.id,
                createdAt: payload.mark.createdAt,
              };
            }
            return elem;
          }),
        ],
        loading: false,
        error: null,
        success: true,
      };
    },
    [MARKS_MODIFY_MARK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MARKS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [MARKS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
