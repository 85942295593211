/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler } from 'hooks';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';

import { useFormatMessage } from 'hooks';
import { awardsCleanUp } from 'state/actions/awards';
import SelectField from 'components/Common/SelectField';
import transformArrayToObject from 'utils/transformArrayToObject';

const AwardForm = ({ awardData, action }) => {
  const dispatch = useDispatch();

  const { loading, levelsList, levelsLoading } = useSelector(
    (state) => ({
      loading: state.awards.loading,
      levelsList: state.levels.data,
      levelsLoading: state.levels.loading,
    }),
    shallowEqual
  );
  const [award, setAward] = useState(awardData);

  const [levelsIndex, setLevelsIndex] = useState(null);

  useEffect(() => {
    setLevelsIndex(transformArrayToObject(levelsList));
  }, [levelsList]);

  useEffect(() => {
    return () => dispatch(awardsCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setAward);

  const canSubmit = award.name && award.levels;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(award));
  };

  const onLevelChange = (ops) => {
    setAward((prevState) => ({
      ...prevState,
      levels: (ops || []).map((op) => op.value),
    }));
  };

  const getLevelValue = () => {
    try {
      return award.levels.map((id) => ({
        label: levelsIndex[`${id}`].name,
        value: id,
      }));
    } catch (e) {
      return null;
    }
  };

  return (
    <form
      className="flex flex-wrap rounded-lg border border-brand-lighterBlue py-2 max-w-4xl"
      onSubmit={onSubmitHandler}
    >
      <div className="w-full lg:w-1/3 max-w-md">
        <NormalInput
          className=""
          label={useFormatMessage('Award.AwardForm.name')}
          required
          name="name"
          value={award.name}
          onChange={onChangeHandler}
        />
      </div>
      <div className="w-full lg:w-1/3 max-w-md">
        <SelectField
          label="Niveaux"
          type="form"
          placeholder="Selectioner un niveau"
          isMulti
          options={levelsList.map((e) => ({ label: e.name, value: e.id }))}
          onChange={onLevelChange}
          value={getLevelValue()}
          isLoading={levelsLoading}
          isDisabled={levelsLoading || !levelsList}
        />
      </div>
      <div className="flex-1 p-3 flex items-end justify-end max-w-md">
        <Button
          className={`${loading && 'is-loading'}`}
          text={useFormatMessage('Award.AwardForm.save')}
          icon="mdi-plus"
          color="green"
          size="large"
          disabled={!canSubmit}
          type="submit"
        />
      </div>
    </form>
  );
};

export default AwardForm;
