import firebase from 'firebase.js';
import Controller from './Controller';
import RelationController from './RelationController';
import LevelController from './LevelController';

export default class ProductController extends Controller {
  constructor(collection, item, unique) {
    super(collection, item, unique);
    this.collection = 'products';
    this.item = 'product';
  }

  fetch = () => {
    const rc = new RelationController();
    return firebase
      .firestore()
      .collection(this.collection)
      .orderBy('createdAt', 'desc')
      .get()
      .then((posSnaps) => {
        if (posSnaps.size > 0) {
          const promises = posSnaps.docs.map(async (pos) => ({
            ...pos.data(),
            category: await rc.getCategory(
              'proCategories',
              pos.data().categoryId
            ),
          }));
          return Promise.all(promises);
        }
        return [];
      });
  };

  /**
   * Get Product data with Category & Level
   * @param {string} productId Product ID
   * @returns {Object} object of Product data or false
   */
  fetchById = async (productId) =>
    productId
      ? this.getDoc(productId, 'products')
          .then((productSnap) => {
            if (productSnap.exists) {
              return {
                id: productSnap.id,
                ...productSnap.data(),
              };
            }
            return null;
          })
          .then((productData) => {
            if (productData && productData.categoryId)
              return this.getDoc(productData.categoryId, 'proCategories').then(
                (categorySnap) => {
                  if (categorySnap.exists) {
                    return {
                      ...productData,
                      category: {
                        id: categorySnap.id,
                        ...categorySnap.data(),
                      },
                    };
                  }
                  return productData;
                }
              );
            return productData;
          })
          .then(async (productData) => {
            const lc = new LevelController();
            if (productData && productData.levelId)
              return {
                ...productData,
                level: await lc.fetchById(productData.levelId),
              };
            return productData;
          })
      : null;
}
