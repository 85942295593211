import {
  toastr
} from 'react-redux-toastr';
import firebase from 'firebase.js';
import {
  firebaseError
} from 'utils';

export default class ControllerWithFileUpload {
  constructor(collection, item, unique) {
    this.collection = collection;
    this.item = item;
    this.unique = unique;
  }

  /**
   * Fetch collection data with pagination
   * @param {int} size of pagination
   * @param {int} index index of pagination
   * @param {*} init initialization action
   * @param {*} success on success action
   * @param {*} fail on fail action
   */
  fetch = (init, success, fail) => {
    return async (dispatch) => {
      dispatch(init());
      let ref;
      try {
        // get collection ref oredered by created date
        ref = await firebase
          .firestore()
          .collection(this.collection)
          .orderBy('createdAt', 'desc')
          .get();
      } catch (error) {
        toastr.error('Erreur', 'Operation a échoué');
        return dispatch(fail({
          error
        }));
      }
      // sotre new data to reducer
      const docsRef = ref ?
        ref.docs.map((value) => ({
          id: value.id,
          ...value.data(),
        })) :
        [];
      return dispatch(
        success({
          [this.collection]: docsRef,
        })
      );
    };
  };

  /**
   * Get document by ID
   * @param {string} collection name of collection
   * @param {string} docId document ID
   */
  getDoc = (docId, col = this.collection) =>
    firebase.firestore().collection(col).doc(docId).get();

  /**
   * Get document by ID
   * @param {string} collection name of collection
   * @param {string} docId document ID
   */
  fetchSubColl = (docId, subColl, col = this.collection) =>
    firebase.firestore().collection(col).doc(docId).collection(subColl).get();

  /**
   * Add new item to collection
   * @param {object} data to add
   * @param {any} init action to init
   * @param {any} success action on success
   * @param {any} fail action on fail
   */
  create = (data, init, success, fail, file) => {
    return async (dispatch, getState) => {
      dispatch(init());
      const {
        locale
      } = getState().preferences;
      let response;
      let ref;
      try {
        if (!(await this.exists(data))) {
          // create a record and get a ref
          ref = await firebase
            .firestore()
            .collection(this.collection)
            .add({
              ...data,
              createdAt: new Date().toGMTString(),
              updatedAt: new Date().toGMTString(),
            });
          // if there is a file, upload, update link in record and then get data
          if (file) {
            await this.uploadFile(ref.id, file);
            const link = this.getFileUrl(ref.id, file);
            response = await ref
              .update({
                ...data,
                link,
                updatedAt: new Date().toGMTString(),
              })
              .then(() =>
                ref.get().then((snap) => ({
                  ...snap.data(),
                  id: snap.id
                }))
              );
            // end file upload
          } else {
            // if there is no file, just get the data of the ref
            response = await ref
              .get()
              .then((snap) => ({
                ...snap.data(),
                id: snap.id
              }));
          }
        } else {
          toastr.error('Erreur', 'Cette article existe déjà!');
          return dispatch(fail({
            error: 'Cette article existe déjà!'
          }));
        }
      } catch (error) {
        const errorMessage = firebaseError(error.code, locale);
        toastr.error('Erreur', 'la création a échoué');
        return dispatch(fail({
          error: errorMessage
        }));
      }
      toastr.success('', 'créé avec succès');
      return dispatch(success({
        [this.item]: response
      }));
    };
  };

  /**
   * Set New document with ID and data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  setDoc = (docId, data, col = this.collection) =>
    firebase.firestore().collection(col).doc(docId).set(data);

  /**
   * Add New document with data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  addDoc = (data) => firebase.firestore().collection(this.collection).add(data);

  /**
   * Add New document with data to Sub Collection
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  addSubDoc = (docId, subCollection, data) =>
    firebase
    .firestore()
    .collection(this.collection)
    .doc(docId)
    .collection(subCollection)
    .add(data);

  /**
   * Update item from  collection
   * @param {string} id of item
   * @param {*} data to update
   * @param {*} init initialize ation
   * @param {*} success on success action
   * @param {*} fail on failed action
   */
  update = (id, data, init, success, fail, file) => {
    return async (dispatch, getState) => {
      dispatch(init());
      const {
        locale
      } = getState().preferences;
      let response;
      let link = !data.link ? '' : data.link;
      try {
        if (!(await this.exists(data))) {
          // get Ref
          const ref = await firebase
            .firestore()
            .collection(this.collection)
            .doc(id);
          /**
           * if a new file is selected
           * we delete the old one
           * then we upload a new one
           * and then create a new link
           */
          if (file) {
            await this.deleteFile(link);
            await this.uploadFile(id, file);
            link = this.getFileUrl(id, file);
          }
          // update Ref
          response = await ref
            .update({
              ...data,
              link,
              updatedAt: new Date().toGMTString(),
            })
            .then(() =>
              ref.get().then((snap) => ({
                id: snap.id,
                ...snap.data()
              }))
            );
        } else {
          toastr.error('Erreur', 'Cette article existe déjà!');
          return dispatch(fail({
            error: 'Cette article existe déjà!'
          }));
        }
      } catch (error) {
        const errorMessage = firebaseError(error.code, locale);
        toastr.error('Erreur', 'mise à jour a échoué');
        return dispatch(fail({
          error: errorMessage
        }));
      }
      toastr.success('', 'mis à jour avec succès');
      return dispatch(success({
        [this.item]: response
      }));
    };
  };

  /**
   * Set Update document with ID and data
   * @param {string} docId document ID
   * @param {*} data to set
   * @returns Promise
   */
  updateDoc = (docId, data) =>
    firebase.firestore().collection(this.collection).doc(docId).update(data);

  /**
   * Delete Item from Collection
   * @param {string} id of item
   * @param {any} init initialize action
   * @param {any} fail on fail action
   * @param {any} remove on delete success
   */
  destroy = (id, init, fail, remove, link) => {
    return async (dispatch, getState) => {
      dispatch(init());
      const {
        locale
      } = getState().preferences;
      try {
        // delete file if there is any
        if (link) {
          await this.deleteFile(link);
        }
        // then delete record
        await firebase.firestore().collection(this.collection).doc(id).delete();
      } catch (error) {
        console.log('error message: ', error.message);
        const errorMessage = firebaseError(error.code, locale);
        toastr.error('Erreur', 'la suppression a échoué');
        return dispatch(fail({
          error: errorMessage
        }));
      }
      toastr.success('', 'Supprimé avec succès');
      return dispatch(remove({
        id
      }));
    };
  };

  /**
   * Remove document by ID
   * @param {string} docId document ID
   * @returns Promise
   */
  deleteDoc = (docId) =>
    firebase.firestore().collection(this.collection).doc(docId).delete();

  /**
   * Check if unique value exixt on collection
   * @param {Object} data
   * @returns boolean
   */
  exists = (data, key = this.unique) =>
    firebase
    .firestore()
    .collection(this.collection)
    .where(key, '==', data[key])
    .get()
    .then((snap) => snap.exists);

  /**
   * Get file path from storage
   * @param {string} uid user id
   * @param {string} file file path
   * @returns {string} file path
   */
  getFileUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/${this.collection}%2F${uid}-${file.name
      .split('.')
      .shift()}.${fileExtension}?alt=media`;
  };

  /**
   * Upload file path from storage
   * @param {string} uid user id
   * @param {string} file file path
   * @returns Promise
   */
  uploadFile = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}-${file.name.split('.').shift()}.${fileExtension}`;
    return storageRef.child(`${this.collection}/${fileName}`).put(file);
  };

  /**
   * Delete file path from storage
   * @param {string} file file path
   * @returns Promise
   */
  deleteFile = (oldFile) => {
    if (!oldFile.includes('firebasestorage')) {
      return null;
    }
    const FilePath = oldFile
      .split(`${this.collection}%2F`)
      .pop()
      .split('?alt=media')
      .shift();
    return firebase.storage().ref(`${this.collection}/${FilePath}`).delete();
  };
}