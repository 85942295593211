/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler } from 'hooks';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';

import { posCategoriesCleanUp } from 'state/actions/posCategories';
import { useFormatMessage } from 'hooks';

const PosCategoryForm = ({ posCategoryData, action }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state) => ({
      loading: state.posCategories.loading,
    }),
    shallowEqual
  );
  const [posCategory, setPosCategory] = useState(posCategoryData);

  useEffect(() => {
    return () => dispatch(posCategoriesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setPosCategory);

  const canSubmit = posCategory.name;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(posCategory));
  };

  /**
   * updated by issam,
   * according to a task with id SMT-188
   * added a new dropown field that will contains the options below
   */

  /**
   * updated by Ismail,
   * according to Mounim Issue
   * deleted the dropdown dield
   */
  return (
    <form
      className="flex items-center rounded-lg border border-brand-lighterBlue py-2 max-w-2xl"
      onSubmit={onSubmitHandler}
    >
      <NormalInput
        className="flex-1 max-w-sm"
        label={useFormatMessage('PositionsCategory.categoryForm.name')}
        required
        name="name"
        value={posCategory.name}
        onChange={onChangeHandler}
      />
      <div className="flex-1 px-4">
        <Button
          className={`mt-8 ml-auto ${loading && 'is-loading'}`}
          text={useFormatMessage('PositionsCategory.categoryForm.add')}
          icon="mdi-plus"
          color="green"
          size="large"
          disabled={!canSubmit}
          type="submit"
        />
      </div>
    </form>
  );
};

export default PosCategoryForm;
