import LevelForm from 'components/LevelForm';
import PageHeader from 'components/PageHeader';
import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import { createlevel, modifyLevel } from 'state/actions/levels';

import { useFormatMessage } from 'hooks';

const Level = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.levels.success,
    }),
    shallowEqual
  );
  const [level, setLevel] = useState({
    name: '',
    desc: '',
    value: 0,
    criterias: [],
    disabled: true,
    purchaseNumber: '',
  });

  useEffect(() => {
    const fetchLevelData = async () => {
      const response = await firebase
        .firestore()
        .collection('levels')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchLevelData()
        .then((levelData) => {
          setLevel({
            ...levelData.data(),
            id,
          });
        })
        .catch(() => {
          setLevel({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const levelForm =
    !level.name && id ? (
      <ClipLoader />
    ) : (
      <LevelForm
        isEditing={isEditing}
        levelData={level}
        action={isEditing ? modifyLevel : createlevel}
      />
    );

  const redirect = (level.error || success) && <Redirect to={paths.LEVELS} />;

  return (
    <>
      {redirect}
      <PageHeader title={useFormatMessage('Level.title')} hideButton />
      <div className="page-padding">{levelForm}</div>
    </>
  );
};

export default Level;
