import React, { useState, useEffect } from 'react';
import { createPermission, modifyPermission } from 'state/actions/permissions';
import PermissionForm from 'components/PermissionForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import { useFormatMessage } from 'hooks';
import PageHeader from 'components/PageHeader';

const Permission = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.permissions.success,
    }),
    shallowEqual
  );
  /**
   * Initialize editable Permission Data
   * Author Ennaim Yassine <ennaimyassine@gmail.com>
   * @param {string} id permission id
   */
  const [permission, setPermission] = useState({
    name: '',
  });

  useEffect(() => {
    /**
     * fetch editable Permission Data
     * Author Ennaim Yassine <ennaimyassine@gmail.com>
     * @param {string} id permission id
     */
    const fetchPermissionData = async () => {
      const response = await firebase
        .firestore()
        .collection('permissions')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchPermissionData()
        .then((permissionData) => {
          setPermission({
            ...permissionData.data(),
            id,
          });
        })
        .catch(() => {
          setPermission({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const permissionForm =
    !permission.name && id ? (
      <ClipLoader />
    ) : (
      <PermissionForm
        isEditing={isEditing}
        permissionData={permission}
        action={isEditing ? modifyPermission : createPermission}
      />
    );

  const redirect = (permission.error || success) && (
    <Redirect to={paths.PERMISSIONS} />
  );

  return (
    <>
      {redirect}
      <PageHeader title="Permission" hideButton />
      <div className="page-padding">
        {permissionForm}
      </div>
    </>
  );
};

export default Permission;
