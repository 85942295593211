import React, { useState, useEffect } from 'react';
import { useFormatMessage, useFormatDate } from 'hooks';
// import { enableActivation } from 'state/actions/activations';
import Button from './Button';
import CenteredLoader from './Loader';

const ActivationDetailsCard = ({ activation, loading }) => {
  const name = useFormatMessage('ActivationsDetails.card.name');
  const period = useFormatMessage('ActivationsDetails.card.period');
  const cities = useFormatMessage('ActivationsDetails.card.cities');
  const positionNumber = useFormatMessage(
    'ActivationsDetails.card.positionNumber'
  );
  const description = useFormatMessage('ActivationsDetails.card.description');
  // const authorizeCRUD = useCan(['edit-activation', 'enable-activation']);

  const [agencies, setAgencies] = useState([]);
  const [periodStart, setPeriodStart] = useState(new Date());
  const [periodEnd, setPeriodEnd] = useState(new Date());

  const start = useFormatDate(periodStart, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const end = useFormatDate(periodEnd, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  useEffect(() => {
    setAgencies(activation.agencies);
    setPeriodStart(activation.periodStart);
    setPeriodEnd(activation.periodEnd);
  }, [activation]);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <div className="text-gray-700 p-2 rounded-lg border border-blue-200 bg-brand-veryLightBlue flex">
      <div className="w-2/6">
        <div className="flex px-3 py-1">
          <h1 className="text-brand-lightBlue font-semibold pr-4">{name}</h1>
          <p>{activation.name}</p>
        </div>
        <div className="flex px-3 py-1">
          <h1 className="text-brand-lightBlue font-semibold pr-4">{period}</h1>
          <p>
            du {start} au {end}
          </p>
        </div>
        <div className="flex px-3 py-1">
          <h1 className="text-brand-lightBlue font-semibold pr-4">{cities}</h1>
          {(agencies || []).length > 0 && (
            <p className="text-gray-500">
              {(agencies || []).map(({ agency }) => {
                if (agency)
                  return <span key={agency.id}>{agency.location} , </span>;
              })}
            </p>
          )}
        </div>
      </div>
      <div className="w-3/6">
        <div className="flex px-3 py-1">
          <h1 className="text-brand-lightBlue font-semibold pr-4">
            {positionNumber}
          </h1>
          <p> {activation.initPositions}</p>
        </div>

        <div className="flex px-3 py-1">
          <h1 className="text-brand-lightBlue font-semibold pr-4">
            {description}
          </h1>
          <p>{activation.description}</p>
        </div>
      </div>
      <div className="w-1/4 flex">
        <div className="flex flex-col self-center">
          <Button
            className="m-2"
            to={`/activation/contacts/${activation.id}`}
            icon="mdi-account-supervisor"
            size="medium"
            color="blue"
            text="Participants"
          />
          <Button
            className="m-2"
            to={`/activation/reporting/${activation.id}`}
            icon="mdi-table"
            size="medium"
            color="blue"
            text="Reporting"
          />
          <Button
            className="m-2"
            to={`/activation/graphs/${activation.id}`}
            icon="mdi-chart-line"
            size="medium"
            color="blue"
            text="Graphs"
          />
        </div>
      </div>
      {/* {authorizeCRUD && (
        <div className="w-1/4 flex">
          <div className="flex flex-col self-center">
            <Button
              className="m-2"
              to={`/activations/${activation.id}`}
              icon="mdi-pen"
              size="medium"
              color="blue"
              text="Modifier"
            />
            <Button
              className="m-2"
              icon="mdi-pen"
              size="medium"
              color="blue"
              text={activation.enable ? 'Désactiver' : 'Activer'}
              onClick={() => {
                const option = !activation.enable;
                enableActivation(activation.id, option);
              }}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ActivationDetailsCard;
