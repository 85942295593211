import React from 'react';
import SelectField from './SelectField';
import CenteredLoader from './Loader';

/**
 * Component used to filter data on tables
 * author: issam@abweb.ma
 * @param {Object} selectProps, props for the SelectField Comp
 * @param {Boolean} hideSelect, it does exactly what it says
 */
const FilterSearch = ({
  selectProps,

  loading,
}) => {
  // if loading is true, return a spinner
  if (loading) {
    return <CenteredLoader noPadding />;
  }
  // else return the comp
  return (
    <div className="tw-filter-search z-50">
      <SelectField
        placeholder="Selectioner"
        className="tw-filter-search-select"
        type="filter"
        {...selectProps}
      />
    </div>
  );
};

export default FilterSearch;
