import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useFormatMessage } from 'hooks';
import UserForm from 'components/UserForm';
import { modifyUser } from 'state/actions/users';
import ChangePassword from './ChangePassword';
import PageHeader from 'components/PageHeader';

const Profile = () => {
  const { userData } = useSelector(
    (state) => ({
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  return (
    <>
      <PageHeader title={useFormatMessage('Profile.profile')} hideButton />
      <div className="page-padding">
        <UserForm isEditing isProfile userData={userData} action={modifyUser} />
        <ChangePassword />
      </div>
    </>
  );
};

export default Profile;
