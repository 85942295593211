import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useFormatMessage, useChangeHandler } from 'hooks';
import Button from 'components/Common/Button';
import NormalInput from 'components/Common/NormalInput';
import { actCategoriesCleanUp } from 'state/actions/actCategories';
import SelectField from 'components/Common/SelectField';
import { fetchMarks } from 'state/actions/marks';

const ActivationsCategoryForm = ({ actCategoryData, action }) => {
  const dispatch = useDispatch();

  const { loading, marks, marksLoading } = useSelector(
    (state) => ({
      loading: state.posCategories.loading,
      marks: state.marks.data,
      marksLoading: state.marks.loading,
    }),
    shallowEqual
  );
  const [actCategory, setActCategory] = useState(actCategoryData);

  useEffect(() => {
    dispatch(fetchMarks());
    return () => dispatch(actCategoriesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setActCategory);

  const canSubmit = actCategory.name && actCategory.marks.length;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(actCategory));
    // setActCategory({ name: '', marks: [] });
  };

  const selectOptions = marks.map((mrk) => ({
    value: mrk.id,
    label: mrk.displayName,
  }));

  const onChangeSelect = (opts) => {
    const mrks = (opts || []).map((opt) => opt.value);
    setActCategory((mrk) => ({ ...mrk, marks: mrks || '' }));
  };

  const selectValue = (mrks) => {
    if (!marksLoading)
      return mrks.map((mrkId) => {
        const m = marks.find((mrk) => mrk.id === mrkId);
        if (m) return { value: m.id, label: m.displayName };
      });
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="flex items-start rounded-lg border border-brand-lighterBlue py-2 max-w-4xl">
        <div className="flex-1">
          <NormalInput
            className="flex-auto max-w-sm"
            label={useFormatMessage('ActivationsCategory.name')}
            name="name"
            value={actCategory.name}
            onChange={onChangeHandler}
          />
        </div>
        <div className="flex-1">
          <SelectField
            type="form"
            isLoading={marksLoading}
            isDisabled={marksLoading}
            label={useFormatMessage('ActivationsCategory.brands')}
            onChange={(value) => onChangeSelect(value)}
            isMulti
            options={selectOptions}
            value={selectValue(actCategory.marks)}
          />
        </div>
        <Button
          className={`mt-8 mx-4 ${loading && 'is-loading'}`}
          text={useFormatMessage('ActivationsCategory.add')}
          icon="mdi-plus-circle"
          color="green"
          size="large"
          disabled={!canSubmit}
          type="submit"
        />
      </div>
    </form>
  );
};

export default ActivationsCategoryForm;
