import React, { useState, useEffect } from 'react';
import NormalInput from 'components/Common/NormalInput';
import TextArea from 'components/Common/TextArea';
import NormalCheckbox from 'components/Common/NormalCheckbox';
import Button from 'components/Common/Button';

import { useFormatMessage } from 'hooks';

import { levelsCleanUp } from 'state/actions/levels';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useChangeHandler } from 'hooks';
import SelectField from 'components/Common/SelectField';
import { criteriaCleanUp, fetchCriterias } from 'state/actions/criterias';
import transformArrayToObject from 'utils/transformArrayToObject';

const LevelForm = ({ levelData, action, isEditing }) => {
  const dispatch = useDispatch();

  const { loading, criteriasList, criteriasLoading } = useSelector(
    (state) => ({
      loading: state.levels.loading,
      criteriasList: state.criterias.data,
      criteriasLoading: state.criterias.loading,
    }),
    shallowEqual
  );

  const [level, setLevel] = useState(levelData);

  const [criteriasIndex, setCritetiasIndex] = useState(null);

  useEffect(() => {
    setCritetiasIndex(transformArrayToObject(criteriasList));
  }, [criteriasList]);

  useEffect(() => {
    dispatch(fetchCriterias());
    return () => {
      dispatch(levelsCleanUp());
      dispatch(criteriaCleanUp());
    };
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setLevel);

  const handleCheckBoxChange = () => {
    setLevel((prevState) => ({ ...prevState, disabled: !prevState.disabled }));
  };

  const onCriteriasChange = (ops) => {
    setLevel((prevState) => ({
      ...prevState,
      criterias: (ops || []).map((op) => op.value),
    }));
  };

  const getCriteriasValue = () => {
    try {
      return level.criterias.map((id) => ({
        label: criteriasIndex[`${id}`].name,
        value: id,
      }));
    } catch (e) {
      return (level.criterias || []).map((id) => ({
        label: id,
        value: id,
      }));
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(level));
  };

  const canSubmit = level.name && level.criterias.length && level.desc;

  return (
    <form
      onSubmit={onSubmitHandler}
      className="flex flex-wrap border border-brand-lighterBlue rounded-lg p-2"
    >
      {/* left side  */}
      <div className="flex-1 max-w-md flex flex-wrap">
        <NormalInput
          className="w-full"
          label={useFormatMessage('Level.levelForm.name')}
          name="name"
          value={level.name}
          onChange={onChangeHandler}
        />

        {/* <NormalInput
          className="flex-1"
          type="number"
          label={useFormatMessage('Level.levelForm.value')}
          name="value"
          value={level.value}
          onChange={onChangeHandler}
        /> */}
        {/* criterias  */}
        <div className="w-full">
          <SelectField
            label="Critères"
            type="form"
            placeholder="Selectioner des critères"
            isMulti
            options={criteriasList.map((e) => ({ label: e.name, value: e.id }))}
            onChange={onCriteriasChange}
            value={getCriteriasValue()}
            isLoading={criteriasLoading}
            isDisabled={criteriasLoading || !criteriasList}
          />
        </div>
        {/* is active  */}
        <NormalCheckbox
          className="flex-none"
          checked={!level.disabled}
          name="disabled"
          onChange={handleCheckBoxChange}
          label={useFormatMessage('Level.levelForm.disabled')}
        />
      </div>

      {/* middle side  */}
      <div className="w-full lg:flex-1">
        <TextArea
          label={useFormatMessage('Level.levelForm.description')}
          name="desc"
          value={level.desc}
          onChange={onChangeHandler}
          rows="8"
        />
      </div>
      {/* right side  */}
      <div className={`px-4 py-4 flex items-end ${loading && 'is-loading'}`}>
        <Button
          className={`${loading && 'is-loading'}`}
          disabled={!canSubmit}
          text={useFormatMessage('Level.levelForm.add')}
          size="large"
          color="green"
          icon="mdi-plus-circle"
        />
      </div>
    </form>
  );
};

export default LevelForm;
