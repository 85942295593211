import React, { useState, useEffect } from 'react';
import { createAward, modifyAward } from 'state/actions/awards';
import AwardForm from 'components/AwardForm';
import { useParams, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import PageHeader from 'components/PageHeader';

import { useFormatMessage } from 'hooks';

const Award = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.awards.success,
    }),
    shallowEqual
  );
  const [award, setAward] = useState({ name: '', levels: ""});

  useEffect(() => {
    const fetchAwardData = async () => {
      const response = await firebase
        .firestore()
        .collection('awards')
        .doc(id)
        .get();
      return response;
    };
    if (id) {
      fetchAwardData()
        .then((awardData) => {
          setAward({
            ...awardData.data(),
            id,
          });
        })
        .catch(() => {
          setAward({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const awardForm =
    !award.name && id ? (
      <ClipLoader />
    ) : (
      <AwardForm
        isEditing={isEditing}
        awardData={award}
        action={isEditing ? modifyAward : createAward}
      />
    );

  const redirect = (award.error || success) && <Redirect to={paths.AWARDS} />;

  return (
    <>
      {redirect}
      <PageHeader title={useFormatMessage('Award.title')} hideButton />
      <div className="page-padding">{awardForm}</div>
    </>
  );
};

export default Award;
