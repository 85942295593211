import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/Table';
import Button from 'components/Common/Button';
import SelectField from 'components/Common/SelectField';
import { useFormatDate } from 'hooks';
import { updatePlanning } from 'state/actions/activations';
import sortByDate from 'utils/sortByDate';
import { fetchTeams } from 'state/actions/teams';

import CenteredLoader from '../Common/Loader';

const PlanningForm = ({ activation }) => {
  const { isAdmin, userID } = useSelector((state) => {
    return {
      isAdmin: state.auth.userData.isAdmin,
      userID: state.auth.userData.id,
    };
  });
  const { teams, teamsLoading } = useSelector((state) => ({
    teams: state.teams.data.map((t) => ({
      label: t.name,
      value: t.id,
      groups: t.groups,
    })),
    teamsLoading: state.teams.loading,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);
  // more state
  const [planItems, setPlanItems] = useState([]);
  const [planTeams, setPlanTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (activation.positions) {
      const plan = activation.positions
        .sort((a, b) => {
          return a.date.toDate() - b.date.toDate();
        })
        .filter((pos) => pos.teamId !== '')
        .map((p) => ({ posId: p.id, teamId: p.teamId }));
      setPlanItems(plan);
    }
  }, [activation]);

  useEffect(() => {
    if (loading) {
      setInterval(() => {
        window.location.reload();
      }, 10000);
    }
  }, [loading]);
  const columns = [
    {
      Header: 'Position',
      accessor: 'name',
      Cell({ row }) {
        return (
          <div className="">
            <span className="icon">
              <i className="mdi mdi-map-marker" />
            </span>
            <span className="text-gray-800">
              {(row.original.position || {}).name}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Zone',
      Cell: ({ row }) => (row.original.position || {}).zone || '-----',
    },
    {
      Header: 'Secteur',
      Cell: ({ row }) => (row.original.position || {}).sector || '-----',
    },
    {
      Header: 'Adresse',
      Cell: ({ row }) => (row.original.position || {}).address || '-----',
    },
    {
      Header: 'Date',
      accessor: 'date',
      sortType: sortByDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(
            row.original.date ? row.original.date.toDate() : new Date(),
            {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }
          )}
        </small>
      ),
    },
    {
      Header: 'Team',
      accessor: '',
      Cell({ row }) {
        const posId = row.original.id;
        const currTeamId = (planItems.find((pt) => pt.posId === posId) || {})
          .teamId;
        const currTeamName = (teams.find((t) => t.value === currTeamId) || {})
          .label;
        return (
          <SelectField
            options={teams}
            value={{ label: currTeamName, value: currTeamId }}
            onChange={(op) => {
              if (
                !planTeams.find(
                  (pt) => pt.supervisor.id === op.groups[0].supervisor.id
                )
              ) {
                setPlanTeams([
                  ...planTeams,
                  { ...op.groups[0], teamId: op.value, agencyId: userID },
                ]);
              }
              if (planItems.find((pt) => pt.posId === posId)) {
                const index = planItems.findIndex((pt) => pt.posId === posId);
                planItems[index] = { posId, teamId: op.value };
                setPlanItems([...planItems]);
              } else {
                setPlanItems([...planItems, { posId, teamId: op.value }]);
              }
            }}
            type="form"
            isLoading={teamsLoading}
            isDisabled={teamsLoading || !teams}
            placeholder=""
          />
        );
      },
    },
  ];

  const submit =
    activation.positions &&
    !planItems.includes((item) => !item.posId || !item.teamId) &&
    planItems.length === activation.positions.length;

  const setPlanning = () => {
    dispatch(updatePlanning(activation.id, planItems, planTeams));
    setLoading(true);
  };

  return (
    <div className="p-4">
      <div className="">
        <div className="bg-brand-veryLightBlue p-4 rounded-md">
          {loading && (
            <div>
              <h1
                style={{
                  textAlign: 'center',
                  fontSize: 22,
                  fontWeight: 600,
                  color: '#2699fb',
                }}
              >
                Please wait few seconds ...
              </h1>
              <CenteredLoader />
            </div>
          )}
          {!loading && <Table data={activation.positions || []} columns={columns} />}
        </div>
        {/* actions  */}
        <div className="flex flex-col items-center py-6">
          <Button
            onClick={() => setPlanning()}
            text="Valider le planning"
            color="green"
            size="large"
            icon="mdi-plus-circle"
            disabled={!submit}
          />
        </div>
      </div>
    </div>
  );
};
//

export default PlanningForm;
