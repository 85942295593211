import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFormatMessage, useChangeHandler } from 'hooks';
import NormalInput from 'components/Common/NormalInput';
import Button from 'components/Common/Button';

export default function AppName({ isEditing, appNameData, action }) {
  const { loading } = useSelector(
    (state) => ({
      loading: state.appNames.loading,
    }),
    shallowEqual
  );
  const [appName, setAppName] = useState(appNameData);
  const dispatch = useDispatch();
  const onChangeHandler = useChangeHandler(setAppName);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action(appName));
  };

  const tableSubmit = useFormatMessage('AppNameForm.submit');
  const tableChange = useFormatMessage('AppNameForm.change');
  return (
    <>
      <h1 className="text-2xl mb-2 text-brand-darkBlue font-semibold">{useFormatMessage('AppNames.name')}</h1>
      <form
        onSubmit={onSubmitHandler}
        className="p-3 border border-brand-lighterBlue rounded-lg flex flex-wrap"
      >
        <div className="flex flex-col lg:flex-row flex-wrap w-full max-w-xl lg:max-w-full lg:w-2/2">
          <div className=" lg:max-w-sm lg:w-1/2">
            <NormalInput
              label={useFormatMessage('AppNameForm.name')}
              id="name"
              type="text"
              required
              name="name"
              value={appName.name}
              onChange={onChangeHandler}
            />
          </div>
          <div className=" lg:max-w-sm lg:w-1/2 flex items-end lg:pl-6 px-3 py-3">
            <Button
              className={`${loading && 'is-loading'}`}
              type="submit"
              color="green"
              size="large"
              icon="mdi-plus-circle"
              text={isEditing ? tableChange : tableSubmit}
            />
          </div>
        </div>
      </form>
    </>
  );
}
